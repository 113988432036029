import * as React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import ModalCloseButton from './components/ModalCloseButton';

ReactModal.setAppElement('#js-app-container');

export enum ModalSize {
    Small = 'small',
    Default = 'default',
    Large = 'large',
    ExtraLarge = 'extra-large',
    Full = 'full',
}

export enum ModalType {
    Primary = 'primary',
    Secondary = 'secondary',
}

type Props = {
    isOpen?: boolean;
    title?: string;
    onDecline: () => void;
    modalSize?: ModalSize;
    modalType?: ModalType;
    shouldFocusAfterRender?: boolean;
    shouldReturnFocusAfterClose?: boolean;
    children: React.ReactNode;
    deflated?: boolean;
    closeButton?: boolean;
    customCloseIcon?: JSX.Element;
};

const Modal = ({
    isOpen = true,
    modalSize = ModalSize.Default,
    modalType = ModalType.Primary,
    title,
    onDecline,
    shouldFocusAfterRender = true,
    shouldReturnFocusAfterClose = true,
    children,
    deflated = false,
    closeButton = true,
    customCloseIcon,
}: Props) => {
    const portalClassName = classNames('modal', {
        'modal--secondary': modalType === ModalType.Secondary,
    });

    const className = classNames('modal__content', {
        'modal__content--size-small': modalSize === ModalSize.Small,
        'modal__content--size-large': modalSize === ModalSize.Large,
        'modal__content--size-extra-large': modalSize === ModalSize.ExtraLarge,
        'modal__content--size-full': modalSize === ModalSize.Full,
        'modal__content--deflated': deflated,
    });

    return (
        <ReactModal
            isOpen={isOpen}
            closeTimeoutMS={200}
            portalClassName={portalClassName}
            contentLabel={title}
            className={className}
            overlayClassName="modal__overlay"
            onRequestClose={onDecline}
            htmlOpenClassName="ReactModal__Html--open"
            shouldFocusAfterRender={shouldFocusAfterRender}
            shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
        >
            {closeButton && <ModalCloseButton onClick={onDecline} customCloseIcon={customCloseIcon} />}
            {title && <h2 className="modal__heading">{title}</h2>}
            {children}
        </ReactModal>
    );
};

export default Modal;
