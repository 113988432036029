import * as React from 'react';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

const FormErrorMessage = ({ children }: Props) => <small className="form__error-message">{children}</small>;

export default FormErrorMessage;
