import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { Location } from 'history';

type ShouldScrollToTopCallback = (location: Location) => boolean;

export default function useScrollToTopOnLocationChange(shouldScrollToTop?: ShouldScrollToTopCallback): void {
    const location = useLocation();

    useEffect(() => {
        if (shouldScrollToTop && !shouldScrollToTop(location)) {
            return;
        }

        window.scroll(0, 0);
    }, [location.pathname, location.search]);
}
