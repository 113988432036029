import useTrackVisitedPageTypeOnChange from '../../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../../constants/pageTypes';
import ProjectChapterDetail from '../../../../../model/projectChapterDetail/ProjectChapterDetail';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';

export default function useTrackVisitedPageTypeForChapterDetail(details: ProjectChapterDetail | null) {
    const enabled = !!details;

    const title = details ? `${generateSectionNumbering(details.index)}. ${details.title}` : null;

    const project = details
        ? {
              title: details.project.title,
              type: details.project.type,
          }
        : null;

    useTrackVisitedPageTypeOnChange(
        pageType.standardDetailChapter,
        { title, project },
        [details ? details.externalId : null],
        enabled
    );
}
