import type { OnPageChangeCallback } from '../../../shared/CollectionPagination';
import { createProjectSearchPath } from '../../../../../routing/urlGenerator';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import { useHistory } from 'react-router-dom';

export default function useNavigateToNextPageOnPageChange(
    projectDetails: ProjectDetail | null,
    currentQuery: string,
    resetSearchResults: () => void
) {
    const history = useHistory();

    const onPageChange: OnPageChangeCallback = (newPageNumber) => {
        if (!projectDetails) {
            return;
        }

        resetSearchResults();

        const path = createProjectSearchPath(projectDetails.typeSlug, projectDetails.slug, currentQuery, newPageNumber);

        history.push(path);
    };

    return { onPageChange };
}
