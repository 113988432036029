import validate from 'validate.js';
import ValidationResultSet from './model/validationResultSet';
import { logError } from '../utility/Logger';
import emailDoesNotExistValidator from './validator/emailDoesNotExist';
import editorStateIsNotEmptyValidator from './validator/editorStateIsNotEmpty';

// $ExpectError
validate.validators.presence.message = '^Dit veld is verplicht';
validate.validators.emailDoesNotExists = emailDoesNotExistValidator;
validate.validators.editorStateIsNotEmpty = editorStateIsNotEmptyValidator;

export function validateObject(input: any, constraintSet: any): Promise<ValidationResultSet> {
    return validate
        .async(input, constraintSet)
        .then(
            () => new ValidationResultSet(false, _normalizeErrors(input, {})),
            (errors) => {
                if (errors instanceof Error) {
                    throw errors;
                }

                // as validate.js returns only errors for input keys that contain errors, we need to normalize the output object
                // to make sure that all supplied input keys have a key in the error output
                const errorsForEachField = _normalizeErrors(input, errors);

                return new ValidationResultSet(true, errorsForEachField);
            }
        )
        .catch((error: Error) => {
            logError(error, { input, constraintSet });

            return new ValidationResultSet(true, {});
        });
}

function _normalizeErrors(input: Record<string, any>, errors: any): Record<string, any[]> {
    const emptyErrorSetForEachKey: Record<string, any[]> = {};

    Object.keys(input).forEach((key) => (emptyErrorSetForEachKey[key] = []));

    return { ...emptyErrorSetForEachKey, ...errors };
}
