import Scroll from 'react-scroll';
import AnonymousFeedback, { ANONYMOUS_FEEDBACK_TYPE_PARAGRAPH } from '../anonymousFeedback/AnonymousFeedback';
import ChapterDetailSubParagraph from './components/ChapterDetailSubParagraph';
import React from 'react';
import ProjectChapterDetailParagraph from '../../../../model/projectChapterDetail/ProjectChapterDetailParagraph';
import createDispatchBookmarkTriggerOnClickComponent from '../../../../hoc/BookmarkDispatcher';
import BookmarkTrigger, { BookmarkSubject } from '../../../shared/bookmark/BookmarkTrigger';
import useMarkMatchedSearchTerms from './hooks/useMarkMatchedSearchTerms';
import { createBookmarkTriggerForChapterDetailParagraph } from '../../../../model/factory/bookmarkTriggerFactory';
import { generateSectionNumbering } from '../../../../utility/projectSectionNumberingGenerator';
import ContentRender from '../../../shared/lexicalRender/ContentRender';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';

type Props = {
    paragraph: ProjectChapterDetailParagraph;
    chapterIndex: number;
    noOfParagraphsInChapter: number;
};

const AutoDispatchingBookmarkTrigger = createDispatchBookmarkTriggerOnClickComponent(BookmarkTrigger);

const ChapterDetailParagraph = ({ paragraph, chapterIndex, noOfParagraphsInChapter }: Props) => {
    const { isSKILZ } = useCurrentTenant();
    const { wrapperId } = useMarkMatchedSearchTerms(paragraph);
    const bookmark = createBookmarkTriggerForChapterDetailParagraph(paragraph);

    // when a paragraph is the only paragraph in a chapter, we mark it as a introduction text. For introduction
    // texts the title and action buttons are hidden.
    // this only applies for GGZ!
    const paragraphIsIntroduction = noOfParagraphsInChapter === 1 && !isSKILZ;

    const numbering = generateSectionNumbering(chapterIndex, paragraph.index);
    const title = `${numbering} ${paragraph.title}`;

    return (
        <div className="chapter-content">
            <Scroll.Element key={paragraph.id} name={paragraph.path} id={wrapperId}>
                {!paragraphIsIntroduction && (
                    <h2 className="heading heading--paragraph heading--includes-actions">
                        <span>{title}</span>
                        <span className="heading__content-action-holder">
                            {/* @ts-ignore types cannot be read for component as it is not part of Typescript */}
                            <AutoDispatchingBookmarkTrigger
                                className="heading__content-action"
                                bookmarkedId={bookmark.bookmarkedId}
                                href={bookmark.url}
                                title={title}
                                type={BookmarkSubject.paragraph}
                            />
                            <AnonymousFeedback
                                target={{
                                    type: ANONYMOUS_FEEDBACK_TYPE_PARAGRAPH,
                                    id: paragraph.externalId,
                                }}
                            />
                        </span>
                    </h2>
                )}
                <ContentRender content={paragraph.content} contentType={paragraph.contentType} />
            </Scroll.Element>
            {paragraph.subParagraphs.map((subParagraph) => (
                <ChapterDetailSubParagraph
                    subParagraph={subParagraph}
                    key={subParagraph.externalId}
                    chapterIndex={chapterIndex}
                    paragraphIndex={paragraph.index}
                />
            ))}
        </div>
    );
};

export default ChapterDetailParagraph;
