import { useFormik } from 'formik';
import { FormValues } from '../components/NewsletterSubscriptionForm';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { validateInput } from '../utility/subscriptionInputValidator';
import { OnValidSubmitHandler } from './useHandleNewsletterSubscriptionAnswer';
import { useState } from 'react';
import { writeToDataLayer } from '../../../../shared/gdprTreshold/utilities/dataLayerUtilities';

export default function useNewsletterSubscriptionFormHandlers(
    onValidSubmit: OnValidSubmitHandler,
    onDone?: () => void
) {
    const [isPending, setIsPending] = useState<boolean>(false);

    const currentUser = useCurrentUser();

    const { handleSubmit, handleChange, handleBlur, values, touched, errors } = useFormik<FormValues>({
        initialValues: {
            email: currentUser ? currentUser.email : '',
        },
        onSubmit: async ({ email }, { resetForm }) => {
            setIsPending(true);

            const success = await onValidSubmit(email);

            if (success) {
                resetForm();
                onDone && onDone();
                writeToDataLayer({
                    event: 'subscribe_to_newsletter_success',
                    eventCategory: 'newsletter',
                    eventAction: 'subscribe',
                });
            }

            setIsPending(false);
        },
        validate: validateInput,
    });

    return {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isPending,
    };
}
