export default class ProjectChapterDetailProject {
    _id: string;
    _externalId: string;
    _title: string;
    _shortTitle: string | null;
    _type: string;
    _path: string;

    constructor(id: string, externalId: string, title: string, shortTitle: string | null, type: string, path: string) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._shortTitle = shortTitle;
        this._type = type;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get shortTitle() {
        return this._shortTitle;
    }

    get type() {
        return this._type;
    }

    get path() {
        return this._path;
    }
}
