import React, { lazy, Suspense } from 'react';
import LazyLoadingLoader from '../../shared/lazyLoading/LazyLoadingLoader';
import ErrorBoundary from '../../frontend/shared/errorBoundary/ErrorBoundary';

const LazyParagraphDetail = lazy(
    () =>
        // @ts-ignore don't know how to fix this
        import(/* webpackChunkName: "dashboard_paragraph_detail" */ '../paragraphDetail/ParagraphDetail')
);

const LazyLoadingParagraphDetail = () => (
    <ErrorBoundary>
        <Suspense fallback={<LazyLoadingLoader />}>
            <LazyParagraphDetail />
        </Suspense>
    </ErrorBoundary>
);

export default LazyLoadingParagraphDetail;
