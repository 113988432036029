import LoadingQueue from '../queue/loadingQueue';
import * as actionTypes from '../actions/types';
import { ActionType } from 'redux-promise-middleware';

import type { Action } from '../actions/factory';

export type LoadingQueueReducerState = LoadingQueue;

export default function (
    currentState: LoadingQueueReducerState = new LoadingQueue(),
    action: Action
): LoadingQueueReducerState {
    switch (action.type) {
        // FETCH_PROJECT_OVERVIEW
        case `${actionTypes.FETCH_PROJECT_OVERVIEW}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_PROJECT_OVERVIEW);

        case `${actionTypes.FETCH_PROJECT_OVERVIEW}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_PROJECT_OVERVIEW}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_PROJECT_OVERVIEW);

        // FETCH_PROJECT_DETAILS
        case `${actionTypes.FETCH_PROJECT_DETAILS}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_PROJECT_DETAILS);

        case `${actionTypes.FETCH_PROJECT_DETAILS}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_PROJECT_DETAILS}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_PROJECT_DETAILS);

        // FETCH_PROJECT_CHAPTER_DETAILS
        case `${actionTypes.FETCH_PROJECT_CHAPTER_DETAILS}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_PROJECT_CHAPTER_DETAILS);

        case `${actionTypes.FETCH_PROJECT_CHAPTER_DETAILS}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_PROJECT_CHAPTER_DETAILS}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_PROJECT_CHAPTER_DETAILS);

        // UPDATE_PROJECT_PARAGRAPH
        case `${actionTypes.UPDATE_PROJECT_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.UPDATE_PROJECT_PARAGRAPH);

        case `${actionTypes.UPDATE_PROJECT_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.UPDATE_PROJECT_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.UPDATE_PROJECT_PARAGRAPH);

        // ADD_SUB_PARAGRAPH_TO_PARAGRAPH
        case `${actionTypes.ADD_SUB_PARAGRAPH_TO_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.ADD_SUB_PARAGRAPH_TO_PARAGRAPH);

        case `${actionTypes.ADD_SUB_PARAGRAPH_TO_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.ADD_SUB_PARAGRAPH_TO_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.ADD_SUB_PARAGRAPH_TO_PARAGRAPH);

        // UPDATE_PROJECT_SUBPARAGRAPH
        case `${actionTypes.UPDATE_PROJECT_SUBPARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.UPDATE_PROJECT_SUBPARAGRAPH, action.meta.paragraphId);

        case `${actionTypes.UPDATE_PROJECT_SUBPARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.UPDATE_PROJECT_SUBPARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.UPDATE_PROJECT_SUBPARAGRAPH, action.meta.paragraphId);

        // ADD_PARAGRAPH_TO_CHAPTER
        case `${actionTypes.ADD_PARAGRAPH_TO_CHAPTER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.ADD_PARAGRAPH_TO_CHAPTER);

        case `${actionTypes.ADD_PARAGRAPH_TO_CHAPTER}_${ActionType.Fulfilled}`:
        case `${actionTypes.ADD_PARAGRAPH_TO_CHAPTER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.ADD_PARAGRAPH_TO_CHAPTER);

        // UPDATE_PROJECT_CHAPTER
        case `${actionTypes.UPDATE_PROJECT_CHAPTER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.UPDATE_PROJECT_CHAPTER, action.meta.chapterId);

        case `${actionTypes.UPDATE_PROJECT_CHAPTER}_${ActionType.Fulfilled}`:
        case `${actionTypes.UPDATE_PROJECT_CHAPTER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.UPDATE_PROJECT_CHAPTER, action.meta.chapterId);

        // UPDATE_PROJECT
        case `${actionTypes.UPDATE_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.UPDATE_PROJECT);

        case `${actionTypes.UPDATE_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.UPDATE_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.UPDATE_PROJECT);

        // ADD_CHAPTER_TO_PROJECT
        case `${actionTypes.ADD_CHAPTER_TO_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.ADD_CHAPTER_TO_PROJECT);

        case `${actionTypes.ADD_CHAPTER_TO_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.ADD_CHAPTER_TO_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.ADD_CHAPTER_TO_PROJECT);

        // START_PROJECT
        case `${actionTypes.START_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.START_PROJECT);

        case `${actionTypes.START_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.START_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.START_PROJECT);

        // CLOSE_PROJECT
        case `${actionTypes.CLOSE_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.CLOSE_PROJECT, action.meta.id);

        case `${actionTypes.CLOSE_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.CLOSE_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.CLOSE_PROJECT, action.meta.id);

        // PROJECT_EMBED_CHAPTER
        case `${actionTypes.PROJECT_EMBED_CHAPTER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_EMBED_CHAPTER);

        case `${actionTypes.PROJECT_EMBED_CHAPTER}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_EMBED_CHAPTER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_EMBED_CHAPTER);

        // PROJECT_EMBED_PARAGRAPH
        case `${actionTypes.PROJECT_EMBED_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_EMBED_PARAGRAPH);

        case `${actionTypes.PROJECT_EMBED_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_EMBED_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_EMBED_PARAGRAPH);

        // PROJECT_EMBED_SUB_PARAGRAPH
        case `${actionTypes.PROJECT_EMBED_SUB_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_EMBED_SUB_PARAGRAPH);

        case `${actionTypes.PROJECT_EMBED_SUB_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_EMBED_SUB_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_EMBED_SUB_PARAGRAPH);

        // MOVE_PROJECT_CHAPTER
        case `${actionTypes.MOVE_PROJECT_CHAPTER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.MOVE_PROJECT_CHAPTER, action.meta.chapterId);

        case `${actionTypes.MOVE_PROJECT_CHAPTER}_${ActionType.Fulfilled}`:
        case `${actionTypes.MOVE_PROJECT_CHAPTER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.MOVE_PROJECT_CHAPTER, action.meta.chapterId);

        // MOVE_REMOVE_CHAPTER
        case `${actionTypes.PROJECT_REMOVE_CHAPTER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_REMOVE_CHAPTER, action.meta.chapterId);

        case `${actionTypes.PROJECT_REMOVE_CHAPTER}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_REMOVE_CHAPTER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_REMOVE_CHAPTER, action.meta.chapterId);

        // PROJECT_REMOVE_PARAGRAPH
        case `${actionTypes.PROJECT_REMOVE_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_REMOVE_PARAGRAPH, action.meta.paragraphId);

        case `${actionTypes.PROJECT_REMOVE_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_REMOVE_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_REMOVE_PARAGRAPH, action.meta.paragraphId);

        // PROJECT_MOVE_PARAGRAPH
        case `${actionTypes.MOVE_PROJECT_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.MOVE_PROJECT_PARAGRAPH, action.meta.paragraphId);

        case `${actionTypes.MOVE_PROJECT_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.MOVE_PROJECT_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.MOVE_PROJECT_PARAGRAPH, action.meta.paragraphId);

        // PROJECT_REMOVE_SUB_PARAGRAPH
        case `${actionTypes.PROJECT_REMOVE_SUB_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_REMOVE_SUB_PARAGRAPH, action.meta.subParagraphId);

        case `${actionTypes.PROJECT_REMOVE_SUB_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_REMOVE_SUB_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(
                actionTypes.PROJECT_REMOVE_SUB_PARAGRAPH,
                action.meta.subParagraphId
            );

        // MOVE_SUB_PARAGRAPH
        case `${actionTypes.MOVE_SUB_PARAGRAPH}_${ActionType.Pending}`:
            return currentState.add(actionTypes.MOVE_SUB_PARAGRAPH, action.meta.subParagraphId);

        case `${actionTypes.MOVE_SUB_PARAGRAPH}_${ActionType.Fulfilled}`:
        case `${actionTypes.MOVE_SUB_PARAGRAPH}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.MOVE_SUB_PARAGRAPH, action.meta.subParagraphId);

        // PROJECT_ADD_SIDE_PRODUCT
        case `${actionTypes.PROJECT_ADD_SIDE_PRODUCT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_ADD_SIDE_PRODUCT);

        case `${actionTypes.PROJECT_ADD_SIDE_PRODUCT}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_ADD_SIDE_PRODUCT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_ADD_SIDE_PRODUCT);

        // PROJECT_UPDATE_SIDE_PRODUCT
        case `${actionTypes.PROJECT_UPDATE_SIDE_PRODUCT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_UPDATE_SIDE_PRODUCT);

        case `${actionTypes.PROJECT_UPDATE_SIDE_PRODUCT}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_UPDATE_SIDE_PRODUCT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_UPDATE_SIDE_PRODUCT);

        // PROJECT_ADD_LINK
        case `${actionTypes.PROJECT_ADD_LINK}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_ADD_LINK);

        case `${actionTypes.PROJECT_ADD_LINK}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_ADD_LINK}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_ADD_LINK);

        // PROJECT_UPDATE_LINK
        case `${actionTypes.PROJECT_UPDATE_LINK}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_UPDATE_LINK);

        case `${actionTypes.PROJECT_UPDATE_LINK}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_UPDATE_LINK}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_UPDATE_LINK);

        // PROJECT_REMOVE_LINK
        case `${actionTypes.PROJECT_REMOVE_LINK}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_REMOVE_LINK);

        case `${actionTypes.PROJECT_REMOVE_LINK}_${ActionType.Fulfilled}`:
        case `${actionTypes.PROJECT_REMOVE_LINK}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_REMOVE_LINK);

        // REGISTER_USER
        case `${actionTypes.REGISTER_USER}_${ActionType.Pending}`:
            return currentState.add(actionTypes.REGISTER_USER);

        case `${actionTypes.REGISTER_USER}_${ActionType.Fulfilled}`:
        case `${actionTypes.REGISTER_USER}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.REGISTER_USER);

        // GET_BOOKMARKS
        case `${actionTypes.GET_BOOKMARKS}_${ActionType.Pending}`:
            return currentState.add(actionTypes.GET_BOOKMARKS);

        case `${actionTypes.GET_BOOKMARKS}_${ActionType.Fulfilled}`:
        case `${actionTypes.GET_BOOKMARKS}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.GET_BOOKMARKS);

        // MARK_CHAPTER_SEARCHABLE
        case `${actionTypes.MARK_CHAPTER_SEARCHABLE}_${ActionType.Pending}`:
            return currentState.add(actionTypes.MARK_CHAPTER_SEARCHABLE);

        case `${actionTypes.MARK_CHAPTER_SEARCHABLE}_${ActionType.Fulfilled}`:
        case `${actionTypes.MARK_CHAPTER_SEARCHABLE}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.MARK_CHAPTER_SEARCHABLE);

        // MARK_CHAPTER_NOT_SEARCHABLE
        case `${actionTypes.MARK_CHAPTER_NOT_SEARCHABLE}_${ActionType.Pending}`:
            return currentState.add(actionTypes.MARK_CHAPTER_NOT_SEARCHABLE);

        case `${actionTypes.MARK_CHAPTER_NOT_SEARCHABLE}_${ActionType.Fulfilled}`:
        case `${actionTypes.MARK_CHAPTER_NOT_SEARCHABLE}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.MARK_CHAPTER_NOT_SEARCHABLE);

        // PUBLISH_PROJECT
        case `${actionTypes.PUBLISH_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PUBLISH_PROJECT);

        case `${actionTypes.PUBLISH_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.PUBLISH_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.PUBLISH_PROJECT);

        // FETCH_STANDARD_OVERVIEW
        case `${actionTypes.FETCH_STANDARD_OVERVIEW}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_STANDARD_OVERVIEW);

        case `${actionTypes.FETCH_STANDARD_OVERVIEW}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_STANDARD_OVERVIEW}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_STANDARD_OVERVIEW);

        // FETCH_STANDARD_ALL_OVERVIEW
        case `${actionTypes.FETCH_STANDARD_ALL_OVERVIEW}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_STANDARD_ALL_OVERVIEW);

        case `${actionTypes.FETCH_STANDARD_ALL_OVERVIEW}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_STANDARD_ALL_OVERVIEW}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_STANDARD_ALL_OVERVIEW);

        // FETCH_ARTICLE_OVERVIEW
        case `${actionTypes.FETCH_ARTICLE_OVERVIEW}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_ARTICLE_OVERVIEW);

        case `${actionTypes.FETCH_ARTICLE_OVERVIEW}_${ActionType.Fulfilled}`:
        case `${actionTypes.FETCH_ARTICLE_OVERVIEW}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_ARTICLE_OVERVIEW);

        // ADD_WORKING_GROUP_MEMBER_TO_PROJECT
        case `${actionTypes.ADD_WORKING_GROUP_MEMBER_TO_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.ADD_WORKING_GROUP_MEMBER_TO_PROJECT);

        case `${actionTypes.ADD_WORKING_GROUP_MEMBER_TO_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.ADD_WORKING_GROUP_MEMBER_TO_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.ADD_WORKING_GROUP_MEMBER_TO_PROJECT);

        // REMOVE_WORKING_GROUP_MEMBER_TO_PROJECT
        case `${actionTypes.REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT);

        case `${actionTypes.REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT);

        // ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT
        case `${actionTypes.ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT);

        case `${actionTypes.ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT);

        // REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT
        case `${actionTypes.REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT);

        case `${actionTypes.REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT}_${ActionType.Fulfilled}`:
        case `${actionTypes.REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT}_${ActionType.Rejected}`:
            return currentState.removeFirstMatching(actionTypes.REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT);

        // FETCH_USER_OVERVIEW
        case `${actionTypes.FETCH_USER_OVERVIEW}_${ActionType.Pending}`:
            return currentState.add(actionTypes.FETCH_USER_OVERVIEW);

        case `${actionTypes.FETCH_USER_OVERVIEW}_${ActionType.Rejected}`:
        case `${actionTypes.FETCH_USER_OVERVIEW}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.FETCH_USER_OVERVIEW);

        // PROJECT_POST_COMMENT
        case `${actionTypes.PROJECT_POST_COMMENT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_POST_COMMENT);

        case `${actionTypes.PROJECT_POST_COMMENT}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_POST_COMMENT}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_POST_COMMENT);

        // PROJECT_FETCH_COMMENTS
        case `${actionTypes.PROJECT_FETCH_COMMENTS}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_FETCH_COMMENTS);

        case `${actionTypes.PROJECT_FETCH_COMMENTS}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_FETCH_COMMENTS}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_FETCH_COMMENTS);

        // PROJECT_FETCH_COMMENTS
        case `${actionTypes.PROJECT_REACT_ON_COMMENT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_REACT_ON_COMMENT);

        case `${actionTypes.PROJECT_REACT_ON_COMMENT}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_REACT_ON_COMMENT}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_REACT_ON_COMMENT);

        // PROJECT_STOP_CONSULTATION_PHASE
        case `${actionTypes.PROJECT_STOP_CONSULTATION_PHASE}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_STOP_CONSULTATION_PHASE);

        case `${actionTypes.PROJECT_STOP_CONSULTATION_PHASE}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_STOP_CONSULTATION_PHASE}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_STOP_CONSULTATION_PHASE);

        // PROJECT_UPDATE_PATIENT_INTRODUCTION
        case `${actionTypes.PROJECT_UPDATE_PATIENT_INTRODUCTION}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_UPDATE_PATIENT_INTRODUCTION);

        case `${actionTypes.PROJECT_UPDATE_PATIENT_INTRODUCTION}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_UPDATE_PATIENT_INTRODUCTION}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_UPDATE_PATIENT_INTRODUCTION);

        // PROJECT_MOVE_SIDE_PRODUCT
        case `${actionTypes.PROJECT_MOVE_SIDE_PRODUCT}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_MOVE_SIDE_PRODUCT);

        case `${actionTypes.PROJECT_MOVE_SIDE_PRODUCT}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_MOVE_SIDE_PRODUCT}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_MOVE_SIDE_PRODUCT);

        // PROJECT_TOGGLE_COMMENT_VISIBILITY
        case `${actionTypes.PROJECT_TOGGLE_COMMENT_VISIBILITY}_${ActionType.Pending}`:
            return currentState.add(actionTypes.PROJECT_TOGGLE_COMMENT_VISIBILITY);

        case `${actionTypes.PROJECT_TOGGLE_COMMENT_VISIBILITY}_${ActionType.Rejected}`:
        case `${actionTypes.PROJECT_TOGGLE_COMMENT_VISIBILITY}_${ActionType.Fulfilled}`:
            return currentState.removeFirstMatching(actionTypes.PROJECT_TOGGLE_COMMENT_VISIBILITY);

        default:
            return currentState;
    }
}
