import ProjectDetailChapter from '../ProjectDetailChapter';
import { createProjectDetailParagraphCollectionFromApiInput } from './projectDetailParagraphFactory';
import { createUuid } from '../../../utility/idGenerator';

export function createProjectDetailChapterFromApiInput(apiInput: any): ProjectDetailChapter {
    return new ProjectDetailChapter(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.index,
        createProjectDetailParagraphCollectionFromApiInput(apiInput.paragraphs),
        apiInput._path,
        apiInput.in_projects,
        apiInput.searchable,
        apiInput.comments.total_count,
        apiInput.comments.total_answered_count,
        apiInput.authorized_at,
        apiInput.reviewed_at
    );
}

export function createProjectDetailChapterFromAddChapterToProjectPendingActionPayload(
    payload: any,
    id: string | undefined | null,
    index: number
): ProjectDetailChapter {
    return new ProjectDetailChapter(
        id ? id : createUuid(),
        null,
        payload.title,
        index,
        [],
        null,
        [],
        payload.searchable,
        0,
        0,
        null,
        null
    );
}
