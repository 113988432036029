import { truncate } from './truncateHelper';
import useCurrentTenant from '../hooks/useCurrentTenant';
import { TenantType } from '../constants/settings';

export const SIDE_PRODUCTS_PAGE_TITLE = 'Hulpmiddelen';
export const FOR_TEACHERS_PAGE_TITLE = 'Voor docenten';
export const STANDARDS_PAGE_TITLE = 'Zorgstandaarden';

const MAX_LENGTH_SEO_DESCRIPTION = 120;

export function getDefaultSeoTitle(incomingTenant?: TenantType | null | undefined): string {
    // @todo: find different way to set default SEO title as hooks won't work with components
    let currentTenant = incomingTenant;

    if (!currentTenant) {
        const { tenant } = useCurrentTenant();
        currentTenant = tenant;
    }

    if (currentTenant === TenantType.GGZ) return 'GGZ Standaarden';
    if (currentTenant === TenantType.SKILZ) return 'Richtlijnen Langdurige Zorg';

    return '';
}

export function composeSeoTitle(tenant: TenantType | null | undefined = null, ...parts: Array<string>): string {
    return `${parts.join(' - ')} | ${getDefaultSeoTitle(tenant)}`;
}

export const truncateSeoDescription = (description: string): string =>
    truncate(description, MAX_LENGTH_SEO_DESCRIPTION, '...');
