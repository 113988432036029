import React from 'react';
import ListHeading from '../../shared/list/component/ListHeading';
import { extractHighestAdminRoleIfAvailable } from '../../../../helper/userRoleHelper';
import ListItem from '../../shared/list/component/ListItem';
import OverviewUser from '../../../../model/OverviewUser';
import ListAction from '../../shared/list/component/ListAction';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';
import { USER_UPDATE } from '../../../../security/SecurityAttribute';
import EditLinkButton from '../../shared/buttons/EditLinkButton';
import { createDashboardUpdateUserPath } from '../../../../routing/dashboardUrlGenerator';

type Props = {
    user: OverviewUser;
};

const UsersOverviewItem: React.FC<Props> = ({ user }) => (
    <ListItem>
        <ListHeading>
            <h2 className="heading">
                {user.fullLastName}, {user.firstName}
            </h2>
            <small>{user.profession}</small>
        </ListHeading>
        <small className="text--muted">{extractHighestAdminRoleIfAvailable(user.roles)}</small>
        <SecurityThreshold requiredAttributes={USER_UPDATE}>
            <ListAction>
                <EditLinkButton path={createDashboardUpdateUserPath(user.externalId)} />
            </ListAction>
        </SecurityThreshold>
    </ListItem>
);

export default UsersOverviewItem;
