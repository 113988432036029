import { useParams } from 'react-router-dom';

type RouteParams = {
    page: string;
};

export default function useDetermineCurrentPage(): number {
    const { page } = useParams<RouteParams>();

    return page ? parseInt(page) : 1;
}
