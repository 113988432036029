import React from 'react';
import { logError } from '../utility/Logger';
import type { ErrorHandler } from '../middlewares/errorCatchingMiddleware';
import type { Action, Store } from '../actions/factory';
import { extractPath } from '../helper/objectPathHelper';
import { STATUS_CODE_UNAUTHORIZED, STATUS_CODE_FORBIDDEN } from '../constants/statusCode';
import { createChangeCurrentUserAuthenticationStatusAction } from '../actions/userActionFactory';
import { toast } from 'react-toastify';
import { createFrontendEntryPath } from '../routing/urlGenerator';
import { getBrowserHistory } from '../routing/historyManager';
import { GlobalState } from '../store/types';

function determineErrorMessage(action: Action) {
    const customMessage = extractPath('meta.customErrorMessage', action);
    const actionType = action.type || 'Onbekende actie';

    if (customMessage) {
        return customMessage;
    }

    return (
        <div>
            <p>
                Er is een fout opgetreden bij het uitvoeren van actie: {actionType}. Ververs de pagina om zeker te
                weten dat je geen data kwijt raakt.
            </p>
            <a href="#" onClick={() => location.reload()} className="button button--primary">
                Ververs de pagina
            </a>
        </div>
    );
}

const handleMiddlewareError: ErrorHandler = (error: Error, store: Store, action: Action): void => {
    const globalState: GlobalState = store.getState();
    const statusCode: number | undefined | null = extractPath('response.status', error);

    if (statusCode === STATUS_CODE_UNAUTHORIZED) {
        store.dispatch(createChangeCurrentUserAuthenticationStatusAction(false));

        return;
    }

    if (statusCode === STATUS_CODE_FORBIDDEN) {
        toast.error('Je bent niet geautoriseerd om deze actie uit te voeren.');

        const history = getBrowserHistory();
        history.push(createFrontendEntryPath());

        return;
    }

    toast.error(determineErrorMessage(action), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
    });

    logError(error, { globalState, action });
};

export default handleMiddlewareError;
