import type { Cloneable } from './contracts';

export default class BookmarkSimple implements Cloneable<BookmarkSimple> {
    _id: string;
    _externalId: number;
    _bookmarkedId: string;

    constructor(id: string, externalId: number, bookmarkedId: string) {
        this._id = id;
        this._externalId = externalId;
        this._bookmarkedId = bookmarkedId;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get bookmarkedId() {
        return this._bookmarkedId;
    }

    clone() {
        return new BookmarkSimple(this._id, this._externalId, this._bookmarkedId);
    }
}
