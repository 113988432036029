import * as React from 'react';
import createClassName from 'classnames';

type Props = {
    alignRight: boolean | undefined | null;
    children: React.ReactNode;
};

const FormActions = ({ alignRight, children }: Props) => {
    const className = createClassName('form__actions', {
        'form__actions--right': alignRight,
    });

    return <div className={className}>{children}</div>;
};

FormActions.defaultProps = {
    alignRight: false,
};

export default FormActions;
