import React, { VFC } from 'react';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import Heading from '../../../shared/heading/Heading';
import ProjectDetailApplicationOverview from '../../shared/ProjectDetailApplicationOverview/ProjectDetailApplicationOverview';
import { ProjectSideProductType } from '../../../../model/ProjectSideProduct';
import { ProjectDetailLinkType } from '../../../../model/projectDetail/ProjectDetailLink';
import ContentRender from '../../../shared/lexicalRender/ContentRender';

type Props = {
    title: string;
};

const ProjectPatientInformation: VFC<Props> = ({ title }) => {
    const { details: projectDetails } = useProjectDetails();

    if (!projectDetails) {
        return null;
    }

    return (
        <article>
            <Heading tag="h2" variant="h3">
                {title}
            </Heading>
            {projectDetails.patientIntroduction && (
                <div className="layout">
                    <div className="layout__item layout__item--lg-9">
                        <ContentRender
                            content={projectDetails.patientIntroduction}
                            contentType={projectDetails.patientIntroductionContentType}
                        />
                    </div>
                </div>
            )}

            <ProjectDetailApplicationOverview
                sideProductTypes={[ProjectSideProductType.patientTool]}
                linkTypes={[ProjectDetailLinkType.notProfessionalReference]}
            />
        </article>
    );
};

export default ProjectPatientInformation;
