import { FETCH_USER_OVERVIEW } from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { createOverviewUserCollectionFromApiInput } from '../model/factory/overviewUserFactory';
import PaginatedCollection from '../model/collection/paginatedCollection';

import type { Action } from '../actions/factory';
import OverviewUser from '../model/OverviewUser';

export type OverviewUsersReducerState = PaginatedCollection<OverviewUser> | null;

export default function overviewUsersReducer(
    currentState: OverviewUsersReducerState = null,
    action: Action
): OverviewUsersReducerState {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case `${FETCH_USER_OVERVIEW}_${ActionType.Fulfilled}`:
            return createOverviewUserCollectionFromApiInput(action.payload.data);

        default:
            return currentState;
    }
}
