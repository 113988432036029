import * as React from 'react';
import UnexpectedErrorNotificationPage from './components/UnexpectedErrorNotificationPage';
import { logError } from '../../../../utility/Logger';
import { checkIsWebpackChunkLoadError } from '../../../../resolver/errorTypeResolver';
import OfflineErrorNotificationPage from './components/OfflineErrorNotificationPage';

export type ErrorInfo = {
    componentStack: string;
};

type Props = {
    children: React.ReactNode;
};

type State = {
    error: Error | undefined | null;
    errorInfo: ErrorInfo | undefined | null;
};

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error?: Error | null, errorInfo?: ErrorInfo | null) {
        const normalizedError: Error = error ? error : new Error('Something went wrong');

        this.setState(
            (currentState: State): State => ({
                ...currentState,
                error: normalizedError,
                errorInfo,
            })
        );

        logError(normalizedError, { errorInfo });
    }

    render() {
        const { error, errorInfo } = this.state;

        if (error) {
            if (checkIsWebpackChunkLoadError(error)) {
                return <OfflineErrorNotificationPage />;
            }

            return <UnexpectedErrorNotificationPage error={error} errorInfo={errorInfo} />;
        }

        return this.props.children;
    }
}
