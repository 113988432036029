// Keep slugs below in line with page route definition in backend (frontend_page)

export const CONTACT_PAGE_SLUG = 'contact';
export const DISCLAIMER_PAGE_SLUG = 'algemene-voorwaarden';
export const PRIVACY_COOKIES_PAGE_SLUG = 'privacy-en-cookiebeleid';

/* GGZ slugs */
export const VISION_ON_CARE_PAGE_SLUG = 'visie-op-zorg';
export const EXPLAIN_STANDARD_TYPES_SLUG = 'over-zorgstandaarden';
export const DECIDE_TOGETHER_SLUG = 'samen-beslissen';
export const TEAM_TALKS_SLUG = 'team-talks';
export const GUEST_LECTURES_SLUG = 'gastcolleges';

export const CASECARDS_SLUG = 'casussen';
export const WORKCARDS_SLUG = 'werkkaarten';
export const TOOLKITS_SLUG = 'keuzehulpen';
export const VIDEOS_SLUG = 'videos';

/* SKILZ slugs */
export const ABOUT_SLUG = 'over-ons';
export const DEVELOPMENT_PHASES_SLUG = 'ontwikkelfases';
