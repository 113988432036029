import { useEffect } from 'react';
import { createFetchProjectDetailsBySlugAction } from '../actions/projectActionFactory';
import { useDispatch, useSelector } from 'react-redux';
import type { ProjectDetailsReducerState } from '../reducers/projectDetailsReducer';
import type { GlobalState } from '../store/types';
import { useParams } from 'react-router-dom';

type Params = {
    type: string;
    slug: string;
};

export default function useFetchProjectDetailsOnSlugChanges() {
    const { type: currentTypeSlug, slug: currentSlug } = useParams<Params>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentTypeSlug && currentSlug) {
            dispatch(createFetchProjectDetailsBySlugAction(currentTypeSlug, currentSlug));
        }
    }, [currentTypeSlug, currentSlug]);

    const projectDetails = useSelector<GlobalState, ProjectDetailsReducerState>(({ projectDetails }) => projectDetails);

    // make sure that the slugs from the current route params match the details
    // that are in store currently, to prevent the wrong details are rendered.
    if (
        !currentTypeSlug ||
        !currentSlug ||
        !projectDetails ||
        projectDetails.typeSlug !== currentTypeSlug ||
        projectDetails.slug !== currentSlug
    ) {
        return { projectDetails: null };
    }

    return { projectDetails };
}
