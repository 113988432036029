import { OnValidSubmitHandler } from './useHandleCreateBranchFormState';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../../actions/factory';
import { createAddBranchAction } from '../../../../actions/branchActionFactory';
import { extractFormErrorsFromResponseError } from '../../../../helper/formErrorHelper';

export default function usePersistBranchOnValidSubmit(onDone: () => void): OnValidSubmitHandler {
    const dispatch = useDispatch<Dispatch>();

    return async (values) => {
        const action = createAddBranchAction(values.title, values.shortTitle);

        try {
            await dispatch(action);

            onDone();

            return;
        } catch (error: any) {
            const validationErrors = extractFormErrorsFromResponseError(error);

            if (!validationErrors) {
                throw error;
            }

            return validationErrors;
        }
    };
}
