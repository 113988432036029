import React from 'react';
import { StandardOverviewItem } from '../../../../model/standardOverview/StandardOverviewItem';
import Heading from '../../../shared/heading/Heading';
import { Link } from 'react-router-dom';
import ListHeading from '../../shared/list/component/ListHeading';

type Props = {
    item: StandardOverviewItem;
};

const StandardOverviewItemHeading = ({ item }: Props) => (
    <ListHeading>
        <Heading tag="h2">
            <Link to={item.path} className="link list__heading-link">
                {item.shortTitle ?? item.title}
            </Link>
        </Heading>
    </ListHeading>
);

export default StandardOverviewItemHeading;
