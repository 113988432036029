// Keep in line with backend setup in ProjectStatusType.php
export enum SkilzProjectStatusType {
    Preparation = 'preparation',
    BottleneckAnalysis = 'bottleneck_analysis',
    DeskResearch = 'desk_research',
    Draft = 'draft',
    Consultation = 'consultation',
    Authorization = 'authorization',
}

export const skilzProjectStatusTypeLabels = {
    [SkilzProjectStatusType.Preparation]: 'Voorbereiding en afbakening',
    [SkilzProjectStatusType.BottleneckAnalysis]: 'Knelpuntenanalyse',
    [SkilzProjectStatusType.DeskResearch]: 'Literatuuronderzoek',
    [SkilzProjectStatusType.Draft]: 'Conceptversie',
    [SkilzProjectStatusType.Consultation]: 'Commentaarronde',
    [SkilzProjectStatusType.Authorization]: 'Autorisatie',
};

export enum GgzProjectStatusType {
    Preparation = 'preparation',
    Concept = 'concept',
    Consultation = 'consultation',
    Authorization = 'authorization',
    RevisionPreparation = 'revision_preparation',
    RevisionConcept = 'revision_concept',
    RevisionConsultation = 'revision_consultation',
    RevisionAuthorization = 'revision_authorization',
}

export const ggzProjectStatusTypeLabels = {
    [GgzProjectStatusType.Preparation]: 'Voorbereiding',
    [GgzProjectStatusType.Concept]: 'Opstellen conceptversie',
    [GgzProjectStatusType.Consultation]: 'Commentaarronde',
    [GgzProjectStatusType.Authorization]: 'Autorisatieronde',
    [GgzProjectStatusType.RevisionPreparation]: 'Herziening: voorbereiding',
    [GgzProjectStatusType.RevisionConcept]: 'Herziening: opstellen conceptversie',
    [GgzProjectStatusType.RevisionConsultation]: 'Herziening: commentaarronde',
    [GgzProjectStatusType.RevisionAuthorization]: 'Herziening: autorisatieronde',
};
