import { useHistory, useLocation } from 'react-router-dom';
import { extractNumberFromQueryString } from '../resolver/queryStringParameterResolver';
import useFetchArticlesOnFilterChange from './useFetchArticlesOnFilterChange';
import { OnPageChangeCallback } from '../components/frontend/shared/CollectionPagination';
import { createArticleOverviewPath } from '../routing/urlGenerator';

export default function useFetchArticlesOnPageChange() {
    const history = useHistory();

    const location = useLocation();

    const page = extractNumberFromQueryString(location, 'page', 1);

    const { articles, isFetching } = useFetchArticlesOnFilterChange({ page });

    const onPageChange: OnPageChangeCallback = (newPage) => {
        history.push(createArticleOverviewPath(newPage));

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return { articles, onPageChange, isFetching };
}
