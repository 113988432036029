import ProjectDetail from '../projectDetail/ProjectDetail';
import BookmarkTrigger from '../BookmarkTrigger';
import { createProjectDetailPath } from '../../routing/urlGenerator';
import ProjectChapterDetail from '../projectChapterDetail/ProjectChapterDetail';
import ProjectChapterDetailParagraph from '../projectChapterDetail/ProjectChapterDetailParagraph';
import { generateSectionNumbering } from '../../utility/projectSectionNumberingGenerator';

export const createBookmarkTriggerForProjectDetail = (projectDetail: ProjectDetail): BookmarkTrigger => {
    const url = createProjectDetailPath(projectDetail.typeSlug, projectDetail.slug);

    return new BookmarkTrigger(projectDetail.externalId, projectDetail.title, url);
};

export const createBookmarkTriggerForChapterDetailPart = (chapterDetail: ProjectChapterDetail): BookmarkTrigger => {
    const numbering = generateSectionNumbering(chapterDetail.index);

    return new BookmarkTrigger(
        chapterDetail.externalId,
        `${numbering}. ${chapterDetail.title}`,
        window.location.origin + chapterDetail.path
    );
};

export const createBookmarkTriggerForChapterDetailParagraph = (
    paragraph: ProjectChapterDetailParagraph
): BookmarkTrigger => {
    return new BookmarkTrigger(paragraph.externalId, paragraph.title, window.location.origin + paragraph.path);
};
