import React, { VFC } from 'react';
import { motion } from 'framer-motion';

type Props = {
    isHovering: boolean;
    [x: string]: any;
};

const AnimatedArrow: VFC<Props> = ({ isHovering, ...otherProps }) => {
    const path = isHovering
        ? 'M24.403 1.25 31.153 8l-6.75 6.75M30.215 8h-28.6'
        : 'M9.563 1.25 16.313 8l-6.75 6.75M15.375 8H1.687';

    return (
        <svg width="32" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
            <motion.path
                animate={{ d: path }}
                transition={{ ease: 'easeOut', duration: 0.1, bounce: 0 }}
                stroke="var(--arrow-color, var(--brand-primary))"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AnimatedArrow;
