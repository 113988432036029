import type { Comment, CommentUser, CommentReaction, CommentCollection } from '../types';
import { createUuid } from '../../../utility/idGenerator';
import User from '../../User';
import { formatISO } from 'date-fns';

function _createCommentFromApiInput(apiInput: any): Comment {
    return {
        id: createUuid(),
        externalId: apiInput.id,
        text: apiInput.text,
        createdAt: formatISO(new Date(apiInput.created_at)),
        user: _createUserFromApiInput(apiInput.user),
        reactions: apiInput.reactions ? _createCommentReactionFromApiInput(apiInput.reactions) : null,
        source: apiInput.source,
        hiddenForUsers: apiInput.hidden_for_users,
    };
}

function _createCommentReactionFromApiInput(apiInput: any): CommentReaction[] {
    return apiInput.map((input: any) => {
        return {
            id: input.id,
            text: input.text,
            createdAt: formatISO(new Date(input.created_at)),
            updatedAt: formatISO(new Date(input.updated_at)),
            concept: input.concept,
            visibilityOverridden: input.visibility_overridden,
            user: _createUserFromApiInput(input.user),
        };
    });
}

function _createUserFromApiInput(apiInput: any): CommentUser {
    return {
        externalId: apiInput.id,
        name: apiInput.name,
        branch: apiInput.branch,
    };
}

export function createInMemoryCommentReaction(text: string, user: User, concept: boolean, visibilityOverridden: boolean): CommentReaction {
    const nowFormatted = formatISO(new Date());

    return {
        text,
        createdAt: nowFormatted,
        updatedAt: nowFormatted,
        user: {
            externalId: user.externalId,
            name: user.fullName,
            branch: 'Redacteur',
        },
        concept,
        visibilityOverridden
    };
}

export function createInMemoryComment(id: string, text: string, user: User): Comment {
    return {
        id,
        externalId: null,
        text,
        createdAt: formatISO(new Date()),
        user: {
            externalId: user.externalId,
            name: user.fullName,
            branch: null,
        },
        reactions: null,
        source: null,
        hiddenForUsers: [],
    };
}

export function createCommentCollectionFromApiInput(apiInput: Array<any>): CommentCollection {
    return apiInput.map((apiInputItem: any) => _createCommentFromApiInput(apiInputItem));
}
