import { SecuritySubject } from '../SecuritySubjectType';
import { checkUserIsGranted, generateSecurityEntryKey } from '../helpers/securityHelpers';

import type { SecurityAttributes, SecuritySubjectId } from '../helpers/securityHelpers';
import type { SecurityVoteCollection } from '../types';
import PermissionRequirement from './PermissionRequirement';

export function applyIsCurrentUserGrantedSelector(
    allAuthorizationVotes: SecurityVoteCollection,
    requiredAttributes: SecurityAttributes,
    subjectType: SecuritySubject | null = null,
    subjectId: SecuritySubjectId = null,
    returnNullWhenVoteIsMissing = false
): boolean | null {
    const entryKey: string = generateSecurityEntryKey(requiredAttributes, subjectType, subjectId);

    if (returnNullWhenVoteIsMissing && typeof allAuthorizationVotes[entryKey] === 'undefined') {
        return null;
    }

    const requirement: PermissionRequirement = new PermissionRequirement(entryKey);

    return checkUserIsGranted(requirement, allAuthorizationVotes);
}

export function applyHasVoteSelector(
    allAuthorizationVotes: SecurityVoteCollection,
    requiredAttributes: SecurityAttributes,
    subjectType: SecuritySubject | null = null,
    subjectId: SecuritySubjectId = null
): boolean {
    const entryKey: string = generateSecurityEntryKey(requiredAttributes, subjectType, subjectId);

    return typeof allAuthorizationVotes[entryKey] !== 'undefined';
}
