import React from 'react';
import Loader from '../Loader';
import useDisplayAfterTimeout from '../../../hooks/useDisplayAfterTimeout';

const DISPLAY_LOADER_TIMEOUT = 1000;

const LazyLoadingLoader = () => {
    const { visible } = useDisplayAfterTimeout(DISPLAY_LOADER_TIMEOUT);

    return visible ? <Loader /> : null;
};

export default LazyLoadingLoader;
