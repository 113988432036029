import { createAction } from './factory';
import * as apiClient from '../api/client/apiClient';
import { FETCH_SETTINGS } from './types';
import { createSettingsApiPath } from '../routing/apiUrlGenerator';

export function createGetSettingsAction() {
    const promise = apiClient.get(createSettingsApiPath());

    return createAction(FETCH_SETTINGS, promise);
}
