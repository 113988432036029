import React from 'react';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import SideProductOverview from '../sideProductOverview/SideProductOverview';
import SideProductOverviewItem from '../sideProductOverview/components/SideProductOverviewItem';
import Heading from '../../../shared/heading/Heading';
import ProjectSideProduct, {
    ProjectSideProductToolType,
    projectSideProductToolTypeLabels,
    ProjectSideProductType,
} from '../../../../model/ProjectSideProduct';
import SideProductOverviewVideoItem from '../sideProductOverview/components/SideProductOverviewVideoItem';
import styles from './ProjectDetailApplicationOverview.module.scss';
import { getCategorySideProducts } from './utility/getCategorySideProducts';
import ProjectDetailLink, { ProjectDetailLinkType } from '../../../../model/projectDetail/ProjectDetailLink';
import LinkOverviewItem from '../linkOverview/components/LinkOverviewItem';

type Props = {
    sideProductTypes: ProjectSideProductType[];
    linkTypes: ProjectDetailLinkType[];
};

const ProjectDetailApplicationOverview = ({ sideProductTypes, linkTypes }: Props) => {
    const { details: projectDetails } = useProjectDetails();

    if (!projectDetails) {
        return null;
    }

    const { categorySideProducts } = getCategorySideProducts(projectDetails, sideProductTypes, linkTypes);

    return (
        <div className={styles.container}>
            {Object.keys(categorySideProducts).map(
                (categoryLabel) =>
                    categorySideProducts[categoryLabel as ProjectSideProductToolType].length > 0 && (
                        <div key={categoryLabel}>
                            <Heading tag="h2" variant="h3">
                                {projectSideProductToolTypeLabels[categoryLabel as ProjectSideProductToolType]}
                            </Heading>
                            <SideProductOverview>
                                {categorySideProducts[categoryLabel as ProjectSideProductToolType].map(
                                    (sideProduct: ProjectSideProduct | ProjectDetailLink) => {
                                        if (
                                            sideProduct instanceof ProjectSideProduct &&
                                            sideProduct.toolType === ProjectSideProductToolType.video
                                        ) {
                                            return (
                                                <SideProductOverviewVideoItem
                                                    key={sideProduct.id}
                                                    title={sideProduct.title}
                                                    thumbnailUrl={sideProduct.resourceThumbnailUrl}
                                                    videoUrl={sideProduct.resourceUri}
                                                    durationInSeconds={sideProduct.resourceDurationInSeconds}
                                                />
                                            );
                                        }

                                        if (sideProduct instanceof ProjectDetailLink) {
                                            return <LinkOverviewItem key={sideProduct.id} link={sideProduct} />;
                                        }

                                        return (
                                            <SideProductOverviewItem
                                                key={sideProduct.id}
                                                title={sideProduct.title}
                                                url={sideProduct.path || ''}
                                                trackId="download-side-product"
                                                trackData={JSON.stringify({
                                                    type: sideProduct.type,
                                                })}
                                                description={sideProduct.description}
                                                highlightText={sideProduct.highlightText}
                                            />
                                        );
                                    }
                                )}
                            </SideProductOverview>
                        </div>
                    )
            )}
        </div>
    );
};

export default ProjectDetailApplicationOverview;
