export type PaginatedCollectionType<T = any> = {
    results: Array<T>;
    currentPage: number;
    totalNumberOfPages: number;
    numberOfResultsPerPage: number;
    totalNumberOfResults: number;
};

export default class PaginatedCollection<T = any> {
    _results: Array<T>;
    _currentPage: number;
    _totalNumberOfPages: number;
    _numberOfResultsPerPage: number;
    _totalNumberOfResults: number;

    constructor(
        results: Array<T>,
        currentPage: number,
        totalNumberOfPages: number,
        numberOfResultsPerPage: number,
        totalNumberOfResults: number
    ) {
        this._results = results;
        this._currentPage = currentPage;
        this._totalNumberOfPages = totalNumberOfPages;
        this._numberOfResultsPerPage = numberOfResultsPerPage;
        this._totalNumberOfResults = totalNumberOfResults;
    }

    get results() {
        return this._results;
    }

    map(callback: (value: T) => any): Array<any> {
        return this._results.map(callback);
    }

    get currentPage() {
        return this._currentPage;
    }

    get totalNumberOfPages() {
        return this._totalNumberOfPages;
    }

    get numberOfResultsPerPage() {
        return this._numberOfResultsPerPage;
    }

    get totalNumberOfResults() {
        return this._totalNumberOfResults;
    }

    hasResults() {
        return this._totalNumberOfResults > 0;
    }

    hasNoResults() {
        return this._totalNumberOfResults === 0;
    }
}
