import type { Action } from '../actions/factory';
import { SET_LAST_SEARCHED_ITEM } from '../actions/types';
import { extractPathOrThrowIfNotExists } from '../helper/objectPathHelper';
import type { ProjectSearchItemType } from '../model/ProjectSearchItem';

export type LastSearchedItem = {
    type: ProjectSearchItemType;
    id: string;
    matchedSearchTerms: Array<string>;
};

export type LastSearchedItemReducerState = LastSearchedItem | undefined | null;

function _handleSetLastSearchedItemAction(action: Action): LastSearchedItemReducerState {
    const id: string = extractPathOrThrowIfNotExists('payload.data.id', action);
    const type: ProjectSearchItemType = extractPathOrThrowIfNotExists('payload.data.type', action);
    const matchedSearchTerms: Array<string> = extractPathOrThrowIfNotExists('payload.data.matchedSearchTerms', action);

    return { id, type, matchedSearchTerms };
}

export default function (
    currentState: LastSearchedItemReducerState = null,
    action: Action
): LastSearchedItemReducerState {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SET_LAST_SEARCHED_ITEM:
            return _handleSetLastSearchedItemAction(action);

        default:
            return currentState;
    }
}
