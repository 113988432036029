import React from 'react';
import createDispatchBookmarkTriggerOnClickComponent from '../../../../../hoc/BookmarkDispatcher';
import BookmarkTrigger, { BookmarkSubject } from '../../../../shared/bookmark/BookmarkTrigger';
import { createBookmarkTriggerForProjectDetail } from '../../../../../model/factory/bookmarkTriggerFactory';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';

const AutoDispatchingBookmarkTrigger = createDispatchBookmarkTriggerOnClickComponent(BookmarkTrigger);

type Props = {
    projectDetails: ProjectDetail;
    label?: string;
    className?: string;
};

const ProjectDetailBookmarkTrigger = ({ projectDetails, className = '', label = '' }: Props) => {
    const bookmarkTrigger = createBookmarkTriggerForProjectDetail(projectDetails);

    return (
        // @ts-ignore types cannot be read for component as it is not part of Typescript
        <AutoDispatchingBookmarkTrigger
            className={className}
            bookmarkedId={bookmarkTrigger.bookmarkedId}
            href={bookmarkTrigger.url}
            title={bookmarkTrigger.title}
            bookmarkTriggerLabel={label}
            type={BookmarkSubject.standard}
        />
    );
};

export default ProjectDetailBookmarkTrigger;
