import React from 'react';
import useFetchSpotlightArticles from '../../../../hooks/useFetchSpotlightArticles';
import Heading from '../../../shared/heading/Heading';
import ArticleItem, { LayoutOptions } from '../primitives/articleItem/ArticleItem';
import styles from './LatestArticle.module.scss';

const NO_OF_SPOTLIGHT_ITEMS = 3;

const LatestArticles = () => {
    const { articles } = useFetchSpotlightArticles(NO_OF_SPOTLIGHT_ITEMS);

    if (!articles) {
        return null;
    }

    return (
        <section className="section section--light">
            <div className="container">
                <Heading tag="h2" variant="h1" align="center" className="heading--secondary">
                    Actueel
                </Heading>
                <div className={styles.grid}>
                    {articles.map((article) => (
                        <ArticleItem article={article} key={article.id} layout={LayoutOptions.Portrait} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LatestArticles;
