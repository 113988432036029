import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import { Dropdown } from 'semantic-ui-react';
import ArchiveStandardThreshold from './components/ArchiveStandardThreshold';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import useDispatchArchiveStandardActionOnConfirm from './hooks/useDispatchArchiveStandardActionOnConfirm';
import { StandardOverviewItem as StandardOverviewItemModel } from '../../../model/standardOverview/StandardOverviewItem';
import useCurrentTenant from '../../../hooks/useCurrentTenant';

type Props = {
    item: StandardOverviewItemModel;
};

const ArchiveStandard = ({ item }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);
    const { isSKILZ } = useCurrentTenant();

    // @ts-ignore
    const { onConfirm } = useDispatchArchiveStandardActionOnConfirm(item);

    if (!item.externalId) {
        return null;
    }

    return (
        <ArchiveStandardThreshold externalId={item.externalId}>
            <>
                {showModal ? (
                    <ConfirmationModal
                        title="Depubliceren"
                        onConfirm={onConfirm}
                        onDecline={() => doHideModal()}
                        confirmButtonText={'Depubliceren'}
                        declineButtonText={'Annuleren'}
                    >
                        <p>
                            Met deze actie ga je deze {isSKILZ ? 'richtlijn' : 'standaard'} depubliceren. Hiermee is
                            deze {isSKILZ ? 'richtlijn' : 'standaard'} niet meer in te zien op de website. Deze is nog
                            wel terug te vinden onderaan deze pagina.
                        </p>
                    </ConfirmationModal>
                ) : (
                    <Dropdown.Item text="Depubliceren" onClick={() => doShowModal()} className="icon icon-cancel" />
                )}
            </>
        </ArchiveStandardThreshold>
    );
};

export default ArchiveStandard;
