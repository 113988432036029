import * as React from 'react';
import { useMemo, VFC } from 'react';
import Tabs from '../../../shared/tabs/Tabs';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import useDetermineCurrentTabIdentifier from './hooks/useDetermineCurrentTabIdentifier';
import { ProjectTabIdentifier, resolveTabsForProjectDetail } from '../../../../resolver/projectDetailTabsResolver';
import ProjectDetailTab from './components/ProjectDetailTab';
import createClassName from 'classnames';

type Props = {
    style: 'GGZ' | 'Skilz';
};

const ProjectDetailTabs: VFC<Props> = ({ style }) => {
    const { details } = useProjectDetails();

    if (!details) {
        return null;
    }

    const { currentIdentifier } = useDetermineCurrentTabIdentifier();

    const tabs = useMemo(() => resolveTabsForProjectDetail(details), [details]);
    const visibleTabs = Object.keys(tabs).filter((identifier) => tabs[identifier as ProjectTabIdentifier].visible);

    const wrapperClassName = createClassName('project-detail-tabs', {
        'project-detail-tabs--skilz': style === 'Skilz',
    });

    return (
        <div className={wrapperClassName}>
            <div className="container">
                <Tabs style={style}>
                    {visibleTabs.includes(ProjectTabIdentifier.standard) && (
                        <ProjectDetailTab
                            project={details}
                            identifier={ProjectTabIdentifier.standard}
                            currentIdentifier={currentIdentifier}
                        />
                    )}
                    {visibleTabs.includes(ProjectTabIdentifier.application) && (
                        <ProjectDetailTab
                            project={details}
                            identifier={ProjectTabIdentifier.application}
                            currentIdentifier={currentIdentifier}
                        />
                    )}
                    {visibleTabs.includes(ProjectTabIdentifier.indepth) && (
                        <ProjectDetailTab
                            project={details}
                            identifier={ProjectTabIdentifier.indepth}
                            currentIdentifier={currentIdentifier}
                        />
                    )}
                    {visibleTabs.includes(ProjectTabIdentifier.patients) && (
                        <ProjectDetailTab
                            project={details}
                            identifier={ProjectTabIdentifier.patients}
                            currentIdentifier={currentIdentifier}
                        />
                    )}
                    {visibleTabs.includes(ProjectTabIdentifier.accountability) && (
                        <ProjectDetailTab
                            project={details}
                            identifier={ProjectTabIdentifier.accountability}
                            currentIdentifier={currentIdentifier}
                        />
                    )}
                </Tabs>
            </div>
        </div>
    );
};

export default ProjectDetailTabs;
