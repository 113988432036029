import ProjectIndexSubParagraph from './ProjectIndexSubParagraph';

export default class ProjectIndexParagraph {
    _id: string;
    _externalId: string;
    _title: string;
    _subParagraphs: Array<ProjectIndexSubParagraph>;
    _path: string;

    constructor(
        id: string,
        externalId: string,
        title: string,
        subParagraphs: Array<ProjectIndexSubParagraph>,
        path: string
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._subParagraphs = subParagraphs;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get subParagraphs() {
        return this._subParagraphs;
    }

    get path() {
        return this._path;
    }

    containsPath(path: string) {
        if (this._path === path) {
            return true;
        }

        return this._subParagraphs.findIndex((subParagraph) => subParagraph.path === path) !== -1; // eslint-disable-line no-magic-numbers
    }
}
