import { logError } from '../../utility/Logger';
import arrayMove from 'array-move';
import ParagraphDetailChapter from './ParagraphDetailChapter';
import ParagraphDetailSubParagraph from './ParagraphDetailSubParagraph';
import type { Cloneable } from '../contracts';
import ParagraphDetailLastUpdatedInformation from './ParagraphDetailLastUpdatedInformation';
import { ContentType } from '../../constants/content';

export default class ParagraphDetail implements Cloneable<ParagraphDetail> {
    _id: string;
    _externalId: string;
    _title: string;
    _content: string | undefined | null;
    _chapter: ParagraphDetailChapter;
    _subParagraphs: Array<ParagraphDetailSubParagraph>;
    _inChapters: Array<string>;
    _recursiveLastUpdatedInformation: ParagraphDetailLastUpdatedInformation;
    _lastUpdatedInformation: ParagraphDetailLastUpdatedInformation;
    _hasChanges: boolean;
    _index: number;
    _commentCount: number;
    _answeredCommentCount: number;
    _contentType: ContentType | undefined;

    constructor(
        id: string,
        externalId: string,
        title: string,
        content: string | undefined | null,
        chapter: ParagraphDetailChapter,
        subParagraphs: Array<ParagraphDetailSubParagraph>,
        recursiveLastUpdatedInformation: ParagraphDetailLastUpdatedInformation,
        lastUpdatedInformation: ParagraphDetailLastUpdatedInformation,
        inChapters: Array<string>,
        index: number,
        commentCount: number,
        answeredCommentCount: number,
        contentType: ContentType | undefined
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._content = content;
        this._chapter = chapter;
        this._subParagraphs = subParagraphs;
        this._inChapters = inChapters;
        this._hasChanges = false;
        this._recursiveLastUpdatedInformation = recursiveLastUpdatedInformation;
        this._lastUpdatedInformation = lastUpdatedInformation;
        this._index = index;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
        this._contentType = contentType;
    }

    get lastUpdatedInformation() {
        return this._lastUpdatedInformation;
    }

    get recursiveLastUpdatedInformation() {
        return this._recursiveLastUpdatedInformation;
    }

    set hasChanges(value: boolean) {
        this._hasChanges = value;
    }

    get hasChanges(): boolean {
        return this._hasChanges || this._subParagraphs.filter((subParagraph) => subParagraph.hasChanges).length > 0;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get content(): string | undefined | null {
        return this._content;
    }

    set content(content: string | undefined | null) {
        this._content = content;
    }

    get contentType(): ContentType | undefined {
        return this._contentType;
    }

    set contentType(contentType: ContentType | undefined) {
        this._contentType = contentType;
    }

    get chapter(): ParagraphDetailChapter {
        return this._chapter;
    }

    get subParagraphs(): Array<ParagraphDetailSubParagraph> {
        return this._subParagraphs;
    }

    hasSubParagraphs(): boolean {
        return this._subParagraphs.length > 0;
    }

    get inChapters(): Array<string> {
        return this._inChapters;
    }

    getSubParagraphById(subParagraphId: string): ParagraphDetailSubParagraph | undefined | null {
        return this._subParagraphs.find((subParagraph) => subParagraph.id === subParagraphId);
    }

    removeSubParagraphWithId(idOfSubParagraphThatNeedsToBeRemoved: string): void {
        this._subParagraphs = this._subParagraphs.filter(
            (subParagraph) => subParagraph.id !== idOfSubParagraphThatNeedsToBeRemoved
        );
    }

    isPersistedOnTheServer(): boolean {
        return this._externalId !== null;
    }

    isBeingReused(): boolean {
        return this._inChapters.length > 1;
    }

    get commentCount(): number {
        return this._commentCount;
    }

    set commentCount(count: number) {
        this._commentCount = count;
    }

    incrementCommentCount(): void {
        this._commentCount++;
    }

    get answeredCommentCount(): number {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount(): void {
        this._answeredCommentCount++;
    }

    get index() {
        return this._index;
    }

    moveSubParagraphToIndex(subParagraphId: string, newIndex: number): void {
        const oldIndex = this._subParagraphs.findIndex((subParagraph) => subParagraph.id === subParagraphId);

        if (oldIndex === -1) {
            // eslint-disable-line no-magic-numbers
            logError(new Error('Could not find sub paragraph in paragraph'), {
                subParagraphId,
                paragraphId: this._id,
            });

            return;
        }

        this._subParagraphs = arrayMove(this._subParagraphs, oldIndex, newIndex);
    }

    merge(incomingParagraphDetail: ParagraphDetail) {
        this._title = incomingParagraphDetail.title;
        this._content = incomingParagraphDetail.content;
        this._chapter.merge(incomingParagraphDetail.chapter);
        this._recursiveLastUpdatedInformation = incomingParagraphDetail._recursiveLastUpdatedInformation;
        this._lastUpdatedInformation = incomingParagraphDetail._lastUpdatedInformation;
        this._inChapters = incomingParagraphDetail.inChapters;
        this._index = incomingParagraphDetail.index;

        const incomingSubParagraphs = incomingParagraphDetail.subParagraphs;

        incomingSubParagraphs.forEach((incomingSubParagraph) => {
            const existingSubParagraph = this._subParagraphs.find(
                (subParagraph) => subParagraph.externalId === incomingSubParagraph.externalId
            );

            if (existingSubParagraph) {
                existingSubParagraph.merge(incomingSubParagraph);
            } else {
                this._subParagraphs.push(incomingSubParagraph);
            }
        });

        this._commentCount = incomingParagraphDetail.commentCount;
        this._answeredCommentCount = incomingParagraphDetail.answeredCommentCount;
    }

    clone(): ParagraphDetail {
        const theClone = new ParagraphDetail(
            this._id,
            this._externalId,
            this._title,
            this._content,
            this._chapter.clone(),
            this._subParagraphs.map((subParagraph: ParagraphDetailSubParagraph) => subParagraph.clone()),
            this._recursiveLastUpdatedInformation.clone(),
            this._lastUpdatedInformation.clone(),
            this._inChapters,
            this._index,
            this._commentCount,
            this._answeredCommentCount,
            this._contentType
        );

        theClone.hasChanges = this._hasChanges;

        return theClone;
    }
}
