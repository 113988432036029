import React from 'react';
import SideProductOverview from '../../sideProductOverview/SideProductOverview';
import SideProductOverviewItem from '../../sideProductOverview/components/SideProductOverviewItem';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import { ProjectSideProductType } from '../../../../../model/ProjectSideProduct';

type Props = {
    projectDetail: ProjectDetail;
};

const ProjectStatusSideProductOverview = ({ projectDetail }: Props) => {
    const statusSideProducts = projectDetail.getSideProductsByType(ProjectSideProductType.status);

    if (!statusSideProducts || statusSideProducts.length === 0) {
        return null;
    }

    return (
        <article>
            <SideProductOverview>
                {statusSideProducts.map((sideProduct) => (
                    <SideProductOverviewItem
                        key={sideProduct.id}
                        title={sideProduct.title}
                        url={sideProduct.path || ''}
                        trackId="download-side-product"
                        trackData={JSON.stringify({
                            type: sideProduct.type,
                        })}
                        description={sideProduct.description}
                        highlightText={sideProduct.highlightText}
                    />
                ))}
            </SideProductOverview>
        </article>
    );
};

export default ProjectStatusSideProductOverview;
