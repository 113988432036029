import React, { Fragment } from 'react';
import ProjectIndex from '../../../../../model/ProjectIndex/ProjectIndex';
import ProjectIndexItem, { LEVEL_CHAPTER, LEVEL_PARAGRAPH, LEVEL_SUB_PARAGRAPH } from './ProjectIndexItem';
import ProjectIndexChapter from '../../../../../model/ProjectIndex/ProjectIndexChapter';
import ProjectIndexParagraph from '../../../../../model/ProjectIndex/ProjectIndexParagraph';
import useProjectIndexPositionHelpers from '../hooks/useProjectIndexPositionHelpers';
import NestedCounterList from '../../../../shared/nestedCounterList/NestedCounterList';
import createClassName from 'classnames';
import Divider from '../../../../shared/divider/Divider';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

export enum IndexStyles {
    GGZ = 'GGZ',
    SKILZ = 'SKILZ',
}

type Props = {
    projectIndex: ProjectIndex;
    onProjectIndexItemClick: () => void;
    style: IndexStyles;
};

const ProjectIndexList = ({ projectIndex, onProjectIndexItemClick, style }: Props) => {
    const { isSKILZ } = useCurrentTenant();
    const { activeParagraphUrl, onActiveChange, markerStyles, projectIndexListRef } = useProjectIndexPositionHelpers();

    const renderSubParagraphList = (paragraph: ProjectIndexParagraph) => {
        return paragraph.subParagraphs.map((subParagraph) => (
            <ProjectIndexItem
                key={subParagraph.externalId}
                title={subParagraph.title}
                path={subParagraph.path}
                isAnchorLink={true}
                onSetActive={onActiveChange}
                onClick={onProjectIndexItemClick}
                level={LEVEL_SUB_PARAGRAPH}
                style={style}
            />
        ));
    };

    const renderParagraphList = (chapter: ProjectIndexChapter) => {
        return chapter.paragraphs.map((paragraph) => {
            const isActive = activeParagraphUrl === paragraph.path;

            return (
                <ProjectIndexItem
                    key={paragraph.externalId}
                    title={paragraph.title}
                    path={paragraph.path}
                    isAnchorLink={true}
                    onSetActive={onActiveChange}
                    onClick={onProjectIndexItemClick}
                    level={LEVEL_PARAGRAPH}
                    style={style}
                >
                    {/* use visibility instead of not rendering as we need the scroll spies in the items to detect what paragraph is active */}
                    <div className={isActive ? '' : 'hidden'}>{renderSubParagraphList(paragraph)}</div>
                </ProjectIndexItem>
            );
        });
    };

    const renderChapterList = () =>
        projectIndex.chapters.map((chapter) => {
            const isCurrentChapter = chapter.containsPath(location.pathname);

            // when a paragraph is the only paragraph in a chapter, we mark it as a introduction paragraph. Introduction
            // paragraphs are not shown in the index
            // this only applies for GGZ!
            const isIntroductionChapter = chapter.paragraphs.length === 1 && !isSKILZ;

            return (
                <Fragment key={chapter.externalId}>
                    <ProjectIndexItem
                        title={chapter.title}
                        path={chapter.path}
                        onClick={onProjectIndexItemClick}
                        onSetActive={onActiveChange}
                        level={LEVEL_CHAPTER}
                        style={style}
                    >
                        {isCurrentChapter && !isIntroductionChapter ? renderParagraphList(chapter) : null}
                    </ProjectIndexItem>
                    {style === IndexStyles.SKILZ && <Divider isDeflated muted />}
                </Fragment>
            );
        });

    const classNames = createClassName('project-index__list', {
        'project-index__list--ggz': style === IndexStyles.GGZ,
        'project-index__list--skilz': style === IndexStyles.SKILZ,
    });

    return (
        <nav className={classNames} ref={projectIndexListRef}>
            <NestedCounterList>{renderChapterList()}</NestedCounterList>
            {style === IndexStyles.GGZ && <span className="project-index__marker" style={markerStyles} />}
        </nav>
    );
};

export default ProjectIndexList;
