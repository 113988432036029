import React from 'react';
import EditorContent from '../../../../shared/editorContent/EditorContent';
import Heading from '../../../../shared/heading/Heading';
import { transformJsonToObject } from '../../../../../utility/JsonToObjectTransformer';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    partiesAuthorizing: string;
    partiesNotAuthorizing: string | null;
    partiesNotObjecting: string | null;
};

const ProjectAuthorizingParties = ({ partiesAuthorizing, partiesNotAuthorizing, partiesNotObjecting }: Props) => {
    const { isSKILZ } = useCurrentTenant();

    const partiesAuthorizingAsJson = transformJsonToObject(partiesAuthorizing);
    const partiesNotAuthorizingAsJson = partiesNotAuthorizing ? transformJsonToObject(partiesNotAuthorizing) : null;
    const partiesNotObjectingAsJson = partiesNotObjecting ? transformJsonToObject(partiesNotObjecting) : null;

    const slangForStandard = isSKILZ ? 'richtlijn' : 'zorgstandaard';

    return (
        <div className="section section--medium">
            <Heading tag="h2" variant="h3">
                Autoriserende partijen
            </Heading>

            <p>Deze {slangForStandard} is geautoriseerd door de volgende partijen:</p>
            <EditorContent raw={partiesAuthorizingAsJson} />

            {/* GGZ does not want to show non-authorizing parties */}
            {partiesNotAuthorizingAsJson && isSKILZ && (
                <>
                    <p>Deze partijen zijn niet akkoord gegaan met de autorisatie van deze {slangForStandard}:</p>
                    <EditorContent raw={partiesNotAuthorizingAsJson} />
                </>
            )}

            {partiesNotObjectingAsJson && (
                <>
                    <p>Deze partijen hebben geen bezwaar gemaakt tegen de autorisatie van deze {slangForStandard}:</p>
                    <EditorContent raw={partiesNotObjectingAsJson} />
                </>
            )}
        </div>
    );
};

export default ProjectAuthorizingParties;
