import { useLocation, useParams } from 'react-router-dom';
import { extractNumberFromQueryString } from '../../../../../resolver/queryStringParameterResolver';

type Params = {
    query: string;
};

export default function useSearchUrlParams() {
    const { query: queryFromRouteParams } = useParams<Params>();
    const query: string = queryFromRouteParams || '';

    const location = useLocation();

    const page = extractNumberFromQueryString(location, 'page', 1);

    return { query, page };
}
