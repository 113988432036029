import React, { MouseEventHandler, ReactNode, useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { preserveNewLines } from '../../../../helper/enterHelper';

type Props = {
    title?: string;
    content?: string;
    actionButtons?: ReactNode;
    onClose?: MouseEventHandler<HTMLButtonElement>;
    warning?: string;
    children?: ReactNode;
};

const truncateLines = 5;

const ExpandedContentBlock: React.FC<Props> = ({ title, content, actionButtons = [], onClose, warning, children }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [truncated, setTruncated] = useState<boolean>(false);

    const onExpandedToggleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();

        setExpanded((currentValue) => !currentValue);
    };

    const noOfContentLines = expanded ? 1000 : truncateLines;

    return (
        <div className="expanded-content-block">
            {warning && (
                <small className="expanded-content-block__warning">
                    <em>{warning}</em>
                </small>
            )}
            <div className="expanded-content-block__header">
                {title && <h4 className="expanded-content-block__title">{title}</h4>}
                <div className="expanded-content-block__actions-holder">
                    {actionButtons}
                    <button
                        key="close"
                        onClick={onClose}
                        className="expanded-content-block__close"
                        type="button"
                        aria-label="Extra informatie sluiten"
                    >
                        &times;
                    </button>
                </div>
            </div>
            {content && (
                <div className="expanded-content-block__content">
                    <TruncateMarkup
                        lines={noOfContentLines}
                        ellipsis={
                            <span>
                                {' '}
                                <a href="#" onClick={onExpandedToggleClick}>
                                    Lees meer
                                </a>
                            </span>
                        }
                        onTruncate={(truncated) => setTruncated(truncated)}
                    >
                        <div>{preserveNewLines(content)}</div>
                    </TruncateMarkup>

                    {!truncated && expanded && (
                        <span>
                            {' '}
                            <a href="#" onClick={onExpandedToggleClick}>
                                Toon minder
                            </a>
                        </span>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};

export default ExpandedContentBlock;
