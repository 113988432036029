import * as React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    visibleOnMobile: boolean;
};

const ProjectDetailSidebar = ({ children, visibleOnMobile }: Props) => {
    const sidebarClassNames = createClassName('layout-project-view__sidebar', {
        'is-visible-on-mobile': visibleOnMobile,
    });

    return <aside className={sidebarClassNames}>{children}</aside>;
};

export default ProjectDetailSidebar;
