import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import type { OnSubmitCallback } from '../../../shared/searchForm/SearchForm';

export default function useSearchFormHandler(
    initialQuery: string,
    onSubmitHandler: OnSubmitCallback,
    validateBeforeSubmit: boolean
) {
    const [query, setQuery] = useState<string>(initialQuery);

    const onQueryFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setQuery(
            // $ExpectError -> Flow does not know there is a target value somehow
            event.target.value
        );
    };

    const checkInputIsValid = () => query.length > 0;

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        // prevent submit to backend
        event.preventDefault();

        if (!validateBeforeSubmit || checkInputIsValid()) {
            onSubmitHandler({ query });
        }
    };

    return { onQueryFieldChange, onSubmit, query };
}
