import ProjectChapterDetailSubParagraph from './ProjectChapterDetailSubParagraph';

export default class ProjectChapterDetailParagraph {
    _id: string;
    _externalId: string;
    _title: string;
    _index: number;
    _content: string | undefined | null;
    _contentType: string | undefined | null;
    _path: string;
    _subParagraphs: Array<ProjectChapterDetailSubParagraph>;

    constructor(
        id: string,
        externalId: string,
        title: string,
        index: number,
        content: string | undefined | null,
        contentType: string | undefined | null,
        path: string,
        subParagraphs: Array<ProjectChapterDetailSubParagraph>
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._index = index;
        this._content = content;
        this._contentType = contentType;
        this._subParagraphs = subParagraphs;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get index() {
        return this._index;
    }

    get content() {
        return this._content;
    }

    get contentType() {
        return this._contentType;
    }

    get path() {
        return this._path;
    }

    containsPath(path: string) {
        if (this._path === path) {
            return true;
        }

        return this._subParagraphs.findIndex((subParagraph) => subParagraph.path === path) !== -1; // eslint-disable-line no-magic-numbers
    }

    get subParagraphs() {
        return this._subParagraphs;
    }
}
