import React, { MouseEventHandler } from 'react';

type Props = {
    onClick: MouseEventHandler<HTMLButtonElement>;
    customCloseIcon?: JSX.Element;
};

const ModalCloseButton: React.FC<Props> = ({ onClick, customCloseIcon }) => (
    <button onClick={onClick} className="modal__close" type="button" aria-label="Popup sluiten">
        {customCloseIcon ? customCloseIcon : '\u00D7'}
    </button>
);

export default ModalCloseButton;
