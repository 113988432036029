import { isCustomerEnvironment, isProductionEnvironment } from '../helper/environmentHelper';
import * as Sentry from '@sentry/browser';
import { checkIsWebpackChunkLoadError } from '../resolver/errorTypeResolver';

let sentryLoggingIsInitialized = false;

export function initializeSentryLogging(): void {
    if (sentryLoggingIsInitialized || !isCustomerEnvironment()) {
        return;
    }

    Sentry.init({
        dsn: 'https://41180f85c31b48cfa9c256a88118c994@sentry.io/194333',
        whitelistUrls: [
            'www.ggzstandaarden.nl/assets/frontend/build',
            'staging.ggzstandaarden.nl/assets/frontend/build',
            'ggz-zorgstandaarden.test.dev.freshheads.local//assets/frontend/build',
        ],
        ignoreErrors: [
            'Network Error',
            'Request failed with status code 400', // Bad request
            'Request failed with status code 404', // Page not found
            'Request failed with status code 408', // Timeout
            'Request failed with status code 403', // Logout / Access denied
            'Request aborted',
            'Timeout',
        ],
    });

    sentryLoggingIsInitialized = true;
}

export function logDebug(section: string, ...itemsToLog: Array<any>): void {
    return;
    if (!isProductionEnvironment()) {
        const formattedSection = `[${section.toUpperCase()}]`;

        // prepend formatted section
        itemsToLog.unshift(formattedSection);

        // console.log.apply with null as object context, does not seem to work in IE
        try {
            console.debug.apply(null, itemsToLog); // eslint-disable-line no-console
        } catch (error) {
            console.log(itemsToLog); // eslint-disable-line no-console
        }
    }
}

function checkErrorIsToBePushedToSentry(error: Error): boolean {
    return isCustomerEnvironment() && !checkIsWebpackChunkLoadError(error);
}

export function logError(
    error: any,
    context: {
        [x: string]: any;
    } = {}
): void {
    if (checkErrorIsToBePushedToSentry(error)) {
        try {
            Sentry.captureException(error);
        } catch (error: any) {
            // Catch exceptions about exceeded API quota's from Sentry or others, to
            // not have to bother the end user

            console.error(`Could not push to Sentry. Reason: '${error.message || 'unknown'}'`);
        }
    }

    console.error(error, context); // eslint-disable-line no-console
}
