import { isObject } from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import toContentStateTransformer from './stringToDraftEditorContentStateTransformer';
import { logError } from '../../../../utility/Logger';
import type { EditorStateTransformerType } from './types';

function _createEmptyEditorState(): EditorState {
    return EditorState.createEmpty();
}

function transform(content?: string | null): EditorState {
    const contentState: ContentState | undefined | null = toContentStateTransformer.transformJson(content);

    if (!(contentState instanceof ContentState)) {
        return _createEmptyEditorState();
    }

    return EditorState.createWithContent(contentState);
}

function reverseTransform(editorState: EditorState): string | null {
    if (!isObject(editorState)) {
        console.error('No object supplied to reverseTransform. Supplied: ', editorState);

        return null;
    }

    const rawObject = convertToRaw(editorState.getCurrentContent());

    try {
        return JSON.stringify(rawObject);
    } catch (error) {
        logError(error, {
            message: 'Content cannot be json stringified',
            rawObject,
        });

        return null;
    }
}

const exportObject: EditorStateTransformerType = {
    transform,
    reverseTransform,
};

export default exportObject;
