import ReferenceOverviewItem from '../ReferenceOverviewItem';
import PaginatedCollection from '../../../../model/collection/paginatedCollection';

export function createReferenceOverviewItemCollectionFromApiData(apiData: any) {
    const results = apiData.results.map((apiInputItem: any) => createReferenceOverviewItemFromApiData(apiInputItem));

    return new PaginatedCollection(
        results,
        apiData.current_page,
        apiData.total_no_of_pages,
        apiData.no_of_results_per_page,
        apiData.total_no_of_results
    );
}

export function createReferenceOverviewItemFromApiData(apiData: any): ReferenceOverviewItem {
    return new ReferenceOverviewItem(apiData.id, apiData.description, apiData.archived);
}
