import React from 'react';
import { compose } from 'redux';
import requireAuthenticationStatus from '../../../hoc/RequireAuthenticatedStatus';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import { PROJECT_VIEW_OVERVIEW } from '../../../security/SecurityAttribute';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardContent from '../shared/containers/DashboardContent';
import HeadingGroup from '../shared/headings/HeadingGroup';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';

import type { PermissionRequirementCallback } from '../../../security/types';
import useFetchProjectOverviewItems from './hooks/useFetchProjectOverviewItems';
import ProjectOverview from '../projectOverview/ProjectOverview';
import StartNewProject from '../startNewProject/StartNewProject';
import StartRevisionProject from '../startRevisionProject/StartRevisionProject';
import Loader from '../../shared/Loader';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import { projectsTabTextItems, userMenuTextItems } from '../../../translations/translations';
import { TenantType } from '../../../constants/settings';
import { composeSeoTitle } from '../../../helper/seoHelper';
import { Helmet } from 'react-helmet';

const ProjectTypesOverview = () => {
    const { newProjectItems, projectRevisionItems } = useFetchProjectOverviewItems();
    const { tenant } = useCurrentTenant();
    const title = tenant ? composeSeoTitle(tenant, userMenuTextItems[tenant as TenantType].projects) : '';

    if (!tenant) return null;

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading={true}>
                        {userMenuTextItems[tenant as TenantType].projects}
                    </DashboardContentHeading>
                </HeadingGroup>
                {newProjectItems && projectRevisionItems ? (
                    <>
                        <ProjectOverview
                            items={newProjectItems}
                            title={projectsTabTextItems[tenant as TenantType].newProjects}
                        >
                            <StartNewProject />
                        </ProjectOverview>
                        <ProjectOverview
                            items={projectRevisionItems}
                            title={projectsTabTextItems[tenant as TenantType].revisions}
                        >
                            <StartRevisionProject />
                        </ProjectOverview>
                    </>
                ) : (
                    <Loader />
                )}
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(PROJECT_VIEW_OVERVIEW));
};

export default compose(
    requireAuthenticationStatus,
    requireAuthorizedPermissions(getRequiredPermissions)
)(ProjectTypesOverview);
