import * as React from 'react';
import classNameFactory from 'classnames';
import classNames from '../Tabs.module.scss';

export type OnClickHandler = () => void;

type Props = {
    isActive: boolean;
    onClick: OnClickHandler;
    children: React.ReactNode | Array<React.ReactNode>;
    to?: string;
    trackId?: string;
    trackData?: any;
};

const Tab = ({ isActive, onClick: onClickHandler, children, to, trackId, trackData }: Props) => {
    const className = classNameFactory(classNames.link, {
        [classNames.linkIsActive]: isActive,
    });

    const onClick: React.MouseEventHandler = (event) => {
        // prevent following the links href
        event.preventDefault();

        onClickHandler();
    };

    const href = to || '#';

    return (
        <li className={classNames.tab}>
            <a
                href={href}
                className={className}
                onClick={onClick}
                data-track-id={trackId}
                data-track-data={JSON.stringify(trackData)}
            >
                {children}
            </a>
        </li>
    );
};

export default Tab;
