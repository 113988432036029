import React, { lazy, Suspense } from 'react';
import LazyLoadingLoader from '../../shared/lazyLoading/LazyLoadingLoader';
import ErrorBoundary from '../../frontend/shared/errorBoundary/ErrorBoundary';

const LazyUserDashboard = lazy(
    () => import(/* webpackChunkName: "dashboard_user_dashboard" */ '../userDashboard/UserDashboard')
);

const LazyLoadingUserDashboard = () => (
    <ErrorBoundary>
        <Suspense fallback={<LazyLoadingLoader />}>
            <LazyUserDashboard />
        </Suspense>
    </ErrorBoundary>
);

export default LazyLoadingUserDashboard;
