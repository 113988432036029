import React, { FC, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export const MAIN_CONTENT_ID = 'main-content';

const ProjectDetailPublicationMainContent: FC<Props> = ({ children }) => {
    return (
        <main className="layout-project-view__content-holder" id={MAIN_CONTENT_ID}>
            <article className="layout-project-view__content">{children}</article>
        </main>
    );
};

export default ProjectDetailPublicationMainContent;
