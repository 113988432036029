import type { FormErrorCollectionType } from '../../lib/forms/model/FormState';

export default class ValidationResultSet {
    _containsErrors: boolean;
    _errorMessages: FormErrorCollectionType;

    constructor(containsErrors: boolean, errorMessages: FormErrorCollectionType) {
        this._containsErrors = containsErrors;
        this._errorMessages = errorMessages;
    }

    get containsErrors() {
        return this._containsErrors;
    }

    get errorMessages() {
        return this._errorMessages;
    }
}
