import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import type { OnSetActiveCallback } from '../components/ProjectIndexItem';
import {
    createChapterDetailPath,
    createParagraphDetailPath,
    createProjectSearchPath,
} from '../../../../../routing/urlGenerator';
import { LEVEL_PARAGRAPH, LEVEL_SUB_PARAGRAPH } from '../components/ProjectIndexItem';

const THROTTLE_WINDOW_SCROLL = 500; // half a second

export default function useProjectIndexPositionHelpers() {
    const location = useLocation();
    const history = useHistory();
    const projectIndexListRef = useRef<HTMLElement | null>(null);

    const [activeParagraphUrl, setActiveParagraphUrl] = useState<string | null>(null);

    const [markerStyles, setMarkerStyles] = useState<any>({});

    // uses window.location as useLocation's updated value is not available
    // in event listener callback due to this being a stale closure problem
    const checkIsProjectSearchPath = (): boolean => {
        const match = matchPath(window.location.pathname, createProjectSearchPath());

        return match ? match.isExact : false;
    };

    const positionActiveSectionMarker = () => {
        const activeItem = document.querySelector('.project-index__item > .is-active');

        if (!activeItem || !projectIndexListRef.current || checkIsProjectSearchPath()) {
            setMarkerStyles({
                opacity: 0,
            });

            return;
        }

        const projectIndexListRect = projectIndexListRef.current.getBoundingClientRect();
        const activeItemRect = activeItem.getBoundingClientRect();
        const markerTopPosition = activeItemRect.top - projectIndexListRect.top;

        setMarkerStyles({
            transform: `translate3d(0px, ${markerTopPosition}px, 0px)`,
            height: activeItemRect.height,
            opacity: 1,
        });
    };

    // when the user scrolls to the top of the page, make sure that the chapter url
    // is set in the address bar to match the location.
    const onWindowScroll = throttle(
        () => {
            // search results url should always remain the same, only determine that marker should not show
            if (checkIsProjectSearchPath()) {
                return positionActiveSectionMarker();
            }

            // @todo remove header height offset when we know how to calculate it
            const onTopOfPage = window.scrollY === 0;

            if (onTopOfPage) {
                const chapterMatch = matchPath(window.location.pathname, createChapterDetailPath());

                if (chapterMatch) {
                    history.replace(chapterMatch.url);

                    positionActiveSectionMarker();
                }
            }
        },
        THROTTLE_WINDOW_SCROLL,
        { leading: false, trailing: true }
    );

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);

        positionActiveSectionMarker();

        return () => {
            window.removeEventListener('scroll', onWindowScroll);
        };
    }, []);

    // When scroll spies become active, use the callback to reflect the changes
    // to the url in the address bar, so they match
    const onActiveChange: OnSetActiveCallback = (anchor, level) => {
        if (level === LEVEL_PARAGRAPH) {
            setActiveParagraphUrl(anchor);
        }

        if (level === LEVEL_SUB_PARAGRAPH) {
            // extract paragraph detail path out of sub-paragraph detail path
            const match = matchPath(anchor, createParagraphDetailPath());

            if (match) {
                setActiveParagraphUrl(match.url);
            }
        }

        if (location.pathname !== anchor) {
            history.replace(anchor);
        }

        positionActiveSectionMarker();
    };

    return {
        onActiveChange,
        activeParagraphUrl,
        markerStyles,
        projectIndexListRef,
    };
}
