import React from 'react';
import createDispatchBookmarkTriggerOnClickComponent from '../../../../../hoc/BookmarkDispatcher';
import BookmarkTrigger, { BookmarkSubject } from '../../../../shared/bookmark/BookmarkTrigger';
import ProjectChapterDetail from '../../../../../model/projectChapterDetail/ProjectChapterDetail';
import { createBookmarkTriggerForChapterDetailPart } from '../../../../../model/factory/bookmarkTriggerFactory';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';

type Props = {
    details: ProjectChapterDetail;
};

const AutoDispatchingBookmarkTrigger = createDispatchBookmarkTriggerOnClickComponent(BookmarkTrigger);

const ChapterHeader = ({ details }: Props) => {
    const bookmarkTrigger = createBookmarkTriggerForChapterDetailPart(details);
    const numbering = generateSectionNumbering(details.index);

    return (
        <header className="chapter-header">
            <h2 className="heading heading--chapter heading--includes-actions">
                <span>
                    <span>{numbering}.</span> {details.title}
                </span>
                <span className="heading__content-action-holder">
                    {/* @ts-ignore types cannot be read for component as it is not part of Typescript */}
                    <AutoDispatchingBookmarkTrigger
                        className="heading__content-action"
                        bookmarkedId={bookmarkTrigger.bookmarkedId}
                        href={bookmarkTrigger.url}
                        title={bookmarkTrigger.title}
                        type={BookmarkSubject.chapter}
                    />
                </span>
            </h2>
        </header>
    );
};

export default ChapterHeader;
