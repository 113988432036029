import { useState } from 'react';
import type { OverviewArticle } from '../../../../model/Article';
import { deleteArticleFromServer } from '../../../../repository/articleRepository';
import { toast } from 'react-toastify';
import { STATUS_CODE_NOT_FOUND } from '../../../../constants/statusCode';
import { logError } from '../../../../utility/Logger';
import { useHistory } from 'react-router-dom';
import { createDashboardArticleOverviewPath } from '../../../../routing/dashboardUrlGenerator';

export default function useDeleteArticleOnConfirmation(
    article: OverviewArticle,
    hideConfirmationModal: () => void,
    onDone: () => void
) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const history = useHistory();

    const onConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteArticleFromServer(article.id);

            toast.success(`Artikel '${article.title}' is verwijderd`);
        } catch (error: any) {
            if (!error.response && error.response.status !== STATUS_CODE_NOT_FOUND) {
                logError(error);
            }

            toast.error('Er is iets foutgegaan bij het verwijderen van het artikel. Probeer het later nog eens!');
        }

        setIsDeleting(false);

        hideConfirmationModal();

        history.push(createDashboardArticleOverviewPath());

        onDone();
    };

    return { onConfirm, isDeleting };
}
