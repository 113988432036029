import React from 'react';
import Page from '../../../shared/page/Page';
import Heading from '../../../../shared/heading/Heading';

const PrivacyCookiesPage = () => {
    return (
        <Page title="Privacy- en cookiebeleid">
            <p>
                SKILZ hecht veel waarde aan de bescherming van persoonsgegevens die aan ons zijn toevertrouwd. In ons
                privacybeleid leggen we helder en transparant uit hoe wij omgaan met persoonsgegevens.
            </p>
            <p>Wij doen er alles aan om de privacy te waarborgen en gaat daarom zorgvuldig om met persoonsgegevens.</p>
            <Heading tag="h2" variant="h3">
                Disclaimer
            </Heading>
            <p>
                Op{' '}
                <a href="http://www.richtlijnenlangdurigezorg.nl/" target="_blank">
                    www.richtlijnenlangdurigezorg.nl
                </a>{' '}
                publiceert SKILZ richtlijnen voor de langdurige zorg. Ondanks de zorg en aandacht die wordt besteed aan
                de informatie op{' '}
                <a href="http://www.richtlijnenlangdurigezorg.nl/" target="_blank">
                    www.richtlijnenlangdurigezorg.nl
                </a>{' '}
                is het mogelijk dat inhoud niet volledig en/of onjuist is.
            </p>
            <strong>Aansprakelijkheid</strong>
            <p>
                SKILZ sluit alle aansprakelijkheid voor de opmaak en de inhoud van de richtlijnen uit, evenals gevolgen
                die de toepassing van de richtlijnen in de langdurige zorg mocht hebben uit. Richtlijnen Langdurige Zorg
                staat open voor het attenderen op (vermoedelijke) fouten c.q. onjuistheden in de inhoud of opmaak van de
                richtlijnen. Hiervoor kan contact worden opgenomen met{' '}
                <a href={'mailto:vraag@richtlijnenlangdurigezorg.nl'} target="_blank">
                    vraag@richtlijnenlangdurigezorg.nl
                </a>
                .
            </p>
            <strong>Intellectueel eigendom</strong>
            <p>
                Het intellectueel eigendom van de richtlijnen en de afgeleide producten, ligt bij de beheerder van de
                richtlijn. Voor eigen gebruik mag informatie worden gedownload en/of afgedrukt worden. Zonder
                voorafgaande toestemming van SKILZ mogen gepubliceerde bestanden (of delen daarvan) niet worden
                overgenomen, gepubliceerd of op een andere manier openbaar gemaakt of verveelvoudigd worden. U kunt een
                verzoek voor toestemming sturen aan SKILZ via{' '}
                <a href={'mailto:bureau@skilz.nu'} target={'_blank'}>
                    bureau@skilz.nu
                </a>{' '}
                SKILZ zal uw verzoek behandelen.
            </p>
            <p>
                Het is toegestaan een directe link naar{' '}
                <a href="http://www.richtlijnenlangdurigezorg.nl/" target="_blank">
                    www.richtlijnenlangdurigezorg.nl
                </a>{' '}
                te plaatsen op een andere website of direct te linken naar de richtlijnen op dit platform.
            </p>
        </Page>
    );
};

export default PrivacyCookiesPage;
