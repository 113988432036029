import { useEffect } from 'react';
import type { PageTypeType } from '../constants/pageTypes';
import { trackPageTypeVisit } from '../utility/clientTrackingUtilities';
import useIsLoggedInStatus from './useLoginStatus';
import type { PageTypeContext } from '../utility/clientTrackingUtilities';

export default function useTrackVisitedPageTypeOnChange(
    pageType: PageTypeType,
    context: PageTypeContext = {},
    dependencies: Array<unknown> = [],
    enabled = true
) {
    const loggedIn = useIsLoggedInStatus();

    useEffect(() => {
        if (!enabled) {
            return;
        }

        trackPageTypeVisit({
            pageType,
            context: { ...context, loggedIn },
        });
    }, dependencies);
}
