import axios from 'axios';
import { formatBodyAsFormData, formatBodyAsQueryString } from '../helper/apiHelper';
import type { FormattingOptions } from '../helper/apiHelper';
import * as requestQueue from '../request/requestQueue';

type RequestBodyValue = unknown | RequestBody | null;

export type RequestBody = {
    [x: string]: RequestBodyValue | Array<RequestBodyValue>;
};

// $ExpectError -> don't know why Axios does not accept this
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';

export function get(url: string, queryParams: RequestBody = {}): Promise<any> {
    return requestQueue.add(() => axios.get(url, { params: queryParams }));
}

export function doDelete(url: string): Promise<any> {
    return requestQueue.add(() => axios.delete(url));
}

export function post(
    url: string,
    body: RequestBody | FormData = {},
    formattingOptions: FormattingOptions | null = null
): Promise<any> {
    const formattedBody: FormData = body instanceof FormData ? body : formatBodyAsFormData(body, formattingOptions);

    return requestQueue.add(() => axios.post(url, formattedBody));
}

export function put(url: string, body: RequestBody = {}): Promise<any> {
    const formattedBody = formatBodyAsQueryString(body);

    return requestQueue.add(() => axios.put<RequestBody | string, {}>(url, formattedBody));
}

export function patch(url: string, body: RequestBody = {}): Promise<any> {
    const formattedBody = formatBodyAsQueryString(body);

    return requestQueue.add(() => axios.patch<RequestBody | string, {}>(url, formattedBody));
}
