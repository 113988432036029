import { writeToDataLayer } from '../../../../shared/gdprTreshold/utilities/dataLayerUtilities';
import { saveDisplayed as saveDisplayedInCookie } from '../utility/newsletterCookieStorage';
import { subscribeToNewsletter } from '../utility/subscriptionHandler';
import { getBooleanFromCookieStorage } from '../../../../../utility/cookieStorageHelper';

export type OnValidSubmitHandler = (email: string) => Promise<boolean>;

export default function useHandleNewsletterSubscriptionAnswer(
    hideModal?: () => void,
    type?: string,
    isSKILZ?: boolean
) {
    const hasCookieConsent = getBooleanFromCookieStorage('cookie_consent_acquired');

    const trackEvent = (action: string) => {
        writeToDataLayer({
            event: 'subscribe-to-newsletter',
            eventCategory: `newsletter-subscription-${type}`,
            eventAction: action,
            cookiesAccepted: !!hasCookieConsent,
        });
    };

    const onValidSubmit: OnValidSubmitHandler = async (email) => {
        const success = await subscribeToNewsletter(email, isSKILZ);

        if (success) {
            saveDisplayedInCookie();

            trackEvent('subscribed');

            // hide modal after timeout has passed, as otherwise a warning is shown
            // due to a state change in NewsletterSubscriptionForm that occurs after
            // that component is unmounted. The state change is required, so we
            // postpone the unmount for a bit
            setTimeout(() => {
                if (hideModal) {
                    hideModal();
                }
                // eslint-disable-next-line no-magic-numbers
            }, 500);
        }

        return success;
    };

    const onDecline = () => {
        saveDisplayedInCookie();

        if (hideModal) {
            hideModal();
        }

        trackEvent('declined');
    };

    return { onValidSubmit, onDecline };
}
