import * as React from 'react';
import Modal, { ModalSize } from '../../../shared/modal/Modal';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import { projectsTabTextItems } from '../../../../translations/translations';
import { TenantType } from '../../../../constants/settings';

type Props = {
    onDecline: () => void;
    children: React.ReactNode;
};

const StartRevisionProjectModal = ({ onDecline, children }: Props) => {
    const { tenant } = useCurrentTenant();

    return (
        <Modal
            title={projectsTabTextItems[tenant as TenantType].newProjectFromStandard}
            modalSize={ModalSize.Small}
            onDecline={onDecline}
        >
            {children}
        </Modal>
    );
};

export default StartRevisionProjectModal;
