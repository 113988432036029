export default class ProjectChapterDetailSubParagraph {
    _id: string;
    _externalId: string;
    _title: string;
    _index: number;
    _content: string | undefined | null;
    _contentType: string | undefined | null;
    _position: number;
    _path: string;

    constructor(
        id: string,
        externalId: string,
        title: string,
        index: number,
        content: string | undefined | null,
        contentType: string | undefined | null,
        position: number,
        path: string
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._index = index;
        this._content = content;
        this._contentType = contentType;
        this._position = position;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get index() {
        return this._index;
    }

    get content() {
        return this._content;
    }

    get contentType() {
        return this._contentType;
    }


    get position() {
        return this._position;
    }

    get path() {
        return this._path;
    }
}
