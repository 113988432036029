import React, { MouseEventHandler } from 'react';
import Button, { PRIMARY } from '../../../shared/buttons/Button';

type Props = {
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const CreateUserButton: React.FC<Props> = ({ onClick }) => (
    <Button onClick={onClick} style={PRIMARY} icon="plus" isSmall>
        Gebruiker toevoegen
    </Button>
);

export default CreateUserButton;
