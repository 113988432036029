import type { Cloneable } from './contracts';

export default class BookmarkTag implements Cloneable<BookmarkTag> {
    _id: string;
    _externalId: number | undefined | null;
    _title: string;

    constructor(id: string, externalId: number | undefined | null, title: string) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    set externalId(externalId: number | undefined | null) {
        this._externalId = externalId;
    }

    get title() {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    clone() {
        return new BookmarkTag(this._id, this._externalId, this._title);
    }
}
