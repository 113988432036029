import * as React from 'react';
import ListItem from '../../shared/list/component/ListItem';
import List from '../../shared/list/List';
import { FC, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

const BranchOverviewList: FC<Props> = ({ children }) => (
    <List>
        {React.Children.map(children, (child, index) => {
            // @ts-ignore -> Somehow typescript does not know about key property
            const key = child?.key || index;

            return <ListItem key={key}>{child}</ListItem>;
        })}
    </List>
);

export default BranchOverviewList;
