import React, { FC, ReactNode } from 'react';
import createClassName from 'classnames';

type Props = {
    children: ReactNode;
    isHeading?: boolean;
};

const DashboardContentHeading: FC<Props> = ({ children, isHeading = false }) => {
    const classNames = createClassName('dashboard__content-heading', {
        heading: isHeading,
    });

    return <h1 className={classNames}>{children}</h1>;
};

export default DashboardContentHeading;
