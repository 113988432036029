import { StylesConfig, OptionTypeBase } from 'react-select';
import { DefaultOptionType } from '../Select';
import { CSSProperties } from 'react';

const createStyles = <
    OptionType extends OptionTypeBase = DefaultOptionType,
    IsMulti extends boolean = false
>(): StylesConfig<OptionType, IsMulti> => ({
    option: (provided: CSSProperties, state: any) => ({
        ...provided,
        color: '#29363b',
        backgroundColor: state.isSelected || state.isFocused ? '#d1e9f5' : '#fff',
    }),
    control: (provided: CSSProperties, state) => ({
        ...provided,
        borderWidth: 2,
        borderColor: state.isFocused ? '#6ebde0' : '#29363b',
        boxShadow: 'none',
    }),
    menu: (provided: CSSProperties) => ({
        ...provided,
        'z-index': 100,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
        ...provided,
        color: '#29363b',
    }),
    menuPortal: (provided: CSSProperties) => ({
        ...provided,
        zIndex: 200,
    }),
});

export default createStyles;
