import { useFormik } from 'formik';
import BookmarkTag from '../../../../model/BookmarkTag';
import { OnFormValidHandler } from '../BookmarkForm';
import { useState } from 'react';
import { DefaultOptionType } from '../../form/select/Select';

type FormValues = {
    tags: DefaultOptionType[];
};

const determineOptionValue = (tag: BookmarkTag) => {
    // temporarily use id as value, until backend persist is handled and external id is available
    return tag.externalId ? tag.externalId.toString() : tag.id;
};

const convertBookmarkTagToOption = (tag: BookmarkTag) => ({
    value: determineOptionValue(tag),
    label: tag.title,
});

export default function useManageBookmarkFormValues(
    initialTags: BookmarkTag[],
    tags: BookmarkTag[],
    onFormValid: OnFormValidHandler
) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { values, setFieldValue, handleSubmit } = useFormik<FormValues>({
        initialValues: {
            tags: initialTags.map((tag) => convertBookmarkTagToOption(tag)),
        },

        onSubmit: (values) => {
            setIsSubmitting(true);

            const externalTagIds = tags
                .filter((tag) => {
                    const tagValue = determineOptionValue(tag);

                    return values.tags.some((option) => option.value === tagValue);
                })
                .map((tag) => tag.externalId);

            onFormValid(externalTagIds as number[]);
        },
    });

    const tagOptions: DefaultOptionType[] = tags.map((tag) => convertBookmarkTagToOption(tag));

    return {
        setFieldValue,
        isSubmitting,
        handleSubmit,
        tagOptions,
        values,
    };
}
