import React from 'react';
import styles from './DirectiveOverviewList.module.scss';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

const DirectiveOverviewList = ({ children }: Props) => <ul className={styles.list}>{children}</ul>;

export default DirectiveOverviewList;
