/**
 * @abstract
 */
class ProjectMember {
    _id: string;
    _externalUserId: string;
    _name: string;
    _branch: string;

    constructor(id: string, externalUserId: string, name: string, branch: string) {
        this._id = id;
        this._externalUserId = externalUserId;
        this._name = name;
        this._branch = branch;
    }

    get id(): string {
        return this._id;
    }

    get externalUserId(): string {
        return this._externalUserId;
    }

    get name(): string {
        return this._name;
    }

    get branch(): string {
        return this._branch;
    }
}

export default ProjectMember;
