import { useState, useRef } from 'react';

export default function useSidebarMobileVisibilityHandlers() {
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    const [sidebarIsVisibleOnMobile, setSidebarIsVisibleOnMobile] = useState(false);

    const hideSidebarOnMobile = () => setSidebarIsVisibleOnMobile(false);
    const toggleSidebarVisibilityState = () => {
        setSidebarIsVisibleOnMobile(!sidebarIsVisibleOnMobile);
    };

    const onTriggerSearchClick = () => {
        toggleSidebarVisibilityState();

        // somehow this does not work without a timeout
        setTimeout(() => {
            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }
        }, 200); // eslint-disable-line no-magic-numbers
    };

    const onTriggerProjectIndexClick = () => toggleSidebarVisibilityState();

    return {
        sidebarIsVisibleOnMobile,
        hideSidebarOnMobile,
        toggleSidebarVisibilityState,
        onTriggerSearchClick,
        onTriggerProjectIndexClick,
        searchInputRef,
    };
}
