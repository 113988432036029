import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
    createArticleDetailPath,
    createArticleOverviewPath,
    createContactPagePath,
    createDisclaimerPagePath,
    createForgotPasswordPath,
    createFrontendEntryPath,
    createPrivacyAndCookiesPagePath,
    createProjectDetailPath,
    createResetPasswordPath,
    createSearchPath,
} from '../../../routing/urlGenerator';
import { createGetCurrentUserAction } from '../../../actions/userActionFactory';
import type { GlobalState } from '../../../store/types';
import { createGetSettingsAction } from '../../../actions/settingsActionFactory';
import { SettingsReducerState } from '../../../reducers/settingsReducer';
import User from '../../../model/User';
import Home from './home/Home';
import Header from './header/Header';
import ArticleOverview from './articleOverview/ArticleOverview';
import ContactPage from './page/components/ContactPage';
import { createAboutPagePath, createDevelopmentPhasesPagePath } from '../../../routing/skilzUrlGenerator';
import AboutPage from './page/components/AboutPage';
import PageNotFound from './page/components/PageNotFound';
import PrivacyCookiesPage from './page/components/PrivacyCookiesPage';
import DisclaimerPage from './page/components/DisclaimerPage';
import ProjectDetail from './projectDetail/ProjectDetail';
import ErrorBoundary from '../shared/errorBoundary/ErrorBoundary';
import ForgotPassword from './forgotPassword/ForgotPassword';
import ResetPassword from './forgotPassword/components/ResetPassword';
import DevelopmentPhasesPage from './page/components/DevelopmentPhasesPage';
import Footer from './footer/Footer';
import ArticleDetail from './articleDetail/ArticleDetail';
import GlobalSearchResults from './searchResults/GlobalSearchResults';
import StickyNewsletterCta from './newsletterCta/components/StickyNewsletterCta';

type ReduxSuppliedProps = {
    authenticated: boolean;
    currentUser: User | undefined | null;
    settings: SettingsReducerState;
};

const SkilzApp = () => {
    const dispatch = useDispatch();
    const hideHeaderAndFooter = useRouteMatch([createForgotPasswordPath(), createResetPasswordPath()]);

    const { authenticated, currentUser, settings } = useSelector<GlobalState, ReduxSuppliedProps>(
        ({ authenticated, currentUser, settings }) => ({ authenticated, currentUser, settings })
    );

    useEffect(() => {
        if (authenticated && !currentUser) {
            dispatch(createGetCurrentUserAction());
        }

        if (!settings) {
            dispatch(createGetSettingsAction());
        }
    }, []);

    return (
        <div className="wrapper">
            <ErrorBoundary>
                {!hideHeaderAndFooter && <Header />}
                <Switch>
                    <Route path={createResetPasswordPath()} component={ResetPassword} />
                    <Route path={createForgotPasswordPath()} component={ForgotPassword} />
                    <Route exact path={createFrontendEntryPath()} component={Home} />
                    <Route exact path={createAboutPagePath()} component={AboutPage} />
                    <Route exact path={createArticleOverviewPath()} component={ArticleOverview} />
                    <Route exact path={createArticleDetailPath()} component={ArticleDetail} />
                    <Route exact path={createContactPagePath()} component={ContactPage} />
                    <Route exact path={createSearchPath()} component={GlobalSearchResults} />
                    <Route exact path={createDisclaimerPagePath()} component={DisclaimerPage} />
                    <Route exact path={createPrivacyAndCookiesPagePath()} component={PrivacyCookiesPage} />
                    <Route path={createDevelopmentPhasesPagePath()} component={DevelopmentPhasesPage} />
                    <Route path={createProjectDetailPath()} component={ProjectDetail} />
                    <Route component={PageNotFound} />
                </Switch>
                {!hideHeaderAndFooter && <Footer />}
            </ErrorBoundary>
            <StickyNewsletterCta />
        </div>
    );
};

export default SkilzApp;
