import React from 'react';
import createClassName from 'classnames';

type Props = {
    title?: string;
    isSecondary?: boolean;
    muted?: boolean;
    isDeflated?: boolean;
};

const Divider = ({ title, isSecondary = false, muted = false, isDeflated = false }: Props) => {
    const className: string = createClassName('divider', {
        'divider--secondary': isSecondary,
        'divider--muted': muted,
        'divider--deflated': isDeflated,
    });

    return <div className={className}>{title && <span className="divider__title">{title}</span>}</div>;
};

export default Divider;
