import * as React from 'react';
import { FC } from 'react';
import ListHeading from '../../shared/list/component/ListHeading';
import Heading from '../../../shared/heading/Heading';
import ListAction from '../../shared/list/component/ListAction';
import { BranchOverviewItem } from '../../../../model/branchOverview/BranchOverviewItem';
import EditLinkButton from '../../shared/buttons/EditLinkButton';
import { createDashboardUpdateBranchPath } from '../../../../routing/dashboardUrlGenerator';

type Props = {
    branch: BranchOverviewItem;
};

const BranchOverviewListItem: FC<Props> = ({ branch }) => (
    <>
        <ListHeading>
            <Heading tag="h2">{branch.title}</Heading>
            {branch.shortTitle && <small>{branch.shortTitle}</small>}
        </ListHeading>
        <ListAction>
            <EditLinkButton path={createDashboardUpdateBranchPath(branch.id)} />
        </ListAction>
    </>
);

export default BranchOverviewListItem;
