import React, { MouseEventHandler, ReactNode, useState } from 'react';
import ExpandedContentBlock from './ExpandedContentBlock';
import Link from './Link';

type Props = {
    children: ReactNode;
    definition: string;
    link?: {
        href: string;
        external: boolean;
    };
};

const Definition: React.FC<Props> = ({ children, definition, link }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();

        setExpanded((currentValue) => !currentValue);
    };

    const onContentBlockClose: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();

        setExpanded(false);
    };

    return (
        <span>
            <a href="#" onClick={onClick} className="definition">
                {children}
            </a>

            {expanded && (
                <ExpandedContentBlock content={definition} onClose={onContentBlockClose}>
                    {link && (
                        <div className="expanded-content-block__actions">
                            <Link
                                href={link.href}
                                external={link.external}
                                className="button button--outline-bordered button--small"
                            >
                                Meer informatie
                            </Link>
                        </div>
                    )}
                </ExpandedContentBlock>
            )}
        </span>
    );
};

export default Definition;
