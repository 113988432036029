import { get } from '../api/client/apiClient';
import { createProjectSearchResultsFromApiInput } from '../model/factory/projectSearchItemFactory';
import type { ProjectSearchResultsResponse } from '../model/factory/projectSearchItemFactory';
import { createProjectSearchApiPath } from '../routing/apiUrlGenerator';

export function findProjectSearchItemsMatching(
    query: string,
    page = 1,
    externalProjectId: string | null = null
): Promise<ProjectSearchResultsResponse> {
    const url = createProjectSearchApiPath(query, page, externalProjectId);

    return get(url).then((response) => createProjectSearchResultsFromApiInput(response.data));
}
