import React from 'react';
import classNameFactory from 'classnames';
import Loader from '../Loader';
import Button, { BUTTON } from '../buttons/Button';
import BookmarkIcon from '../../../../../icons/ggz/bookmark.svg';
import type { MouseEvent } from 'react';
import useSettings from '../../../hooks/useSettings';

export const BookmarkSubject = {
    standard: 'standard',
    chapter: 'chapter',
    paragraph: 'paragraph',
    subParagraph: 'subParagraph',
};

type BookmarkSubjectType = keyof typeof BookmarkSubject;

type Props = {
    className: string;
    title: string;
    marked?: boolean;
    onClick: () => any;
    isLoading?: boolean;
    label?: string;
    type: BookmarkSubjectType;
};

const BookmarkTrigger = ({
    className,
    title,
    marked = false,
    onClick: onClickHandler,
    isLoading = false,
    label,
    type,
}: Props) => {
    const settings = useSettings();

    if (settings && !settings.featureFlags.favoritesEnabled) {
        return null;
    }

    if (isLoading) {
        return <Loader sizeSmall={true} />;
    }

    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        onClickHandler();
    };

    const buttonClassName = classNameFactory(`${className}`, {
        'button--icon-only': !label,
        'is-marked': marked,
    });

    return (
        <Button
            type={BUTTON}
            onClick={onClick}
            additionalClassName={buttonClassName}
            isCleared
            title={`${title} toevoegen aan favorieten`}
            data-track-id="bookmark-click"
            data-track-data={JSON.stringify({ title, type })}
        >
            <BookmarkIcon className="button__icon" />
            {label && <span className="button__text">{label}</span>}
        </Button>
    );
};

export default BookmarkTrigger;
