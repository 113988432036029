import { PaginatedCollectionType } from '../model/collection/paginatedCollection';
import { createInMemoryCache } from '@freshheads/javascript-essentials/build/cache/factory/inMemoryCacheFactory';
import { Faq } from '../model/Faq';
import { doDelete, get, get as fetch, post } from '../api/client/apiClient';
import {
    createFaqCreateApiPath,
    createFaqDeleteApiPath,
    createFaqUpdateApiPath,
    createFetchFaqDetailApiPath,
    createFetchFaqOverviewApiPath,
} from '../routing/apiUrlGenerator';

const MAX_CACHE_LIFETIME = 300; // 5 minutes

const pageCache = createInMemoryCache<PaginatedCollectionType<Faq>>('page_items_per_page', MAX_CACHE_LIFETIME);

export const findAllForPage = async (page: number, perPage: number | null): Promise<PaginatedCollectionType<Faq>> => {
    const cacheKey = `${page}_${perPage}`;

    return await pageCache.getOrCreate(cacheKey, async () => {
        const response = await fetch(createFetchFaqOverviewApiPath(page, perPage));

        return await response.data;
    });
};

export const getOneWithId = async (id: string, isEdit: boolean): Promise<Faq> => {
    const response = await get(createFetchFaqDetailApiPath(id, isEdit));

    return await response.data;
};

export const persistNewFaqItemToServer = async (title: string, content: string) => {
    const url = createFaqCreateApiPath();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);

    const response = await post(url, formData);

    const { id } = await response.data;

    pageCache.clear();

    return id;
};

export const pushFaqItemUpdateToServer = async (id: string, title: string, content: string) => {
    const url = createFaqUpdateApiPath(id);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);

    const response = await post(url, formData);

    pageCache.clear();

    await response.data;
};

export const deleteFaqItemFromServer = async (id: string) => {
    await doDelete(createFaqDeleteApiPath(id));

    pageCache.clear();
};
