import queryString from 'qs';
import { createForgotPasswordAction } from '../../../../actions/userActionFactory';
import { useDispatch } from 'react-redux';
import { extractPath } from '../../../../helper/objectPathHelper';
import { STATUS_CODE_BAD_REQUEST } from '../../../../constants/statusCode';
import { extractFormErrorsFromResponseError } from '../../../../helper/formErrorHelper';
import { logError } from '../../../../utility/Logger';
import { toast } from 'react-toastify';
import { FormEventHandler, useEffect, useState } from 'react';
import { FormErrorCollectionType } from '../../../../lib/forms/model/FormState';
import { Dispatch } from '../../../../actions/factory';

export default function useForgotPasswordHandler() {
    const dispatch = useDispatch<Dispatch>();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<string>('');
    const [errors, setErrors] = useState<FormErrorCollectionType>();

    const queryStringParams = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        const email = typeof queryStringParams.email !== 'undefined' ? queryStringParams.email : '';
        setValue(email as string);
    }, []);

    const onChange = (email: string) => setValue(email);

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        const action = createForgotPasswordAction(value);

        setLoading(true);

        dispatch(action)
            .then(() => {
                setLoading(false);
                setValue('');
            })
            .catch((error: any) => {
                setLoading(false);

                if (extractPath('response.status', error) === STATUS_CODE_BAD_REQUEST) {
                    const type = extractPath('response.data.type', error);

                    switch (type) {
                        case 'GGZ\\Infrastructure\\Http\\Exception\\FormValidationException':
                            setErrors(extractFormErrorsFromResponseError(error));
                            break;

                        default:
                            logError(error);
                            toast.error(
                                'Er is een onbekende fout opgetreden. Probeer het later nog eens of neem contact met ons op!'
                            );
                            break;
                    }
                }
            });
    };

    return {
        value,
        onSubmit,
        onChange,
        loading,
        errors,
    };
}
