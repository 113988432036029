import { createPagePath } from './urlGenerator';
import { ABOUT_SLUG, DEVELOPMENT_PHASES_SLUG } from '../constants/pageSlugs';

export function createAboutPagePath(): string {
    return createPagePath(ABOUT_SLUG);
}

export function createDevelopmentPhasesPagePath(): string {
    return createPagePath(DEVELOPMENT_PHASES_SLUG);
}
