export enum pageType {
    home = 'home',
    standardOverview = 'standardOverview',
    standardDetail = 'standardDetail',
    standardDetailChapter = 'standardDetailChapter',
    globalSearch = 'globalSearch',
    projectSearch = 'projectSearch',
    page = 'page',
}

export type PageTypeType = keyof typeof pageType;
