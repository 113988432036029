import React from 'react';
import { isArray, isString } from 'lodash';

/**
 * @param {String|XML[]|XML} text
 *
 * @returns {XML[]|XML|null}
 */
export function applySoftNewlines(text) {
    if (isArray(text)) {
        return text.map((htmlItem) => applySoftNewlines(htmlItem));
    }

    if (isString(text)) {
        var textPartsSplitByNewLine = text.split(/\r?\n/g);

        // only apply br tags if there were line breaks found in the text
        if (textPartsSplitByNewLine.length > 1) {
            return textPartsSplitByNewLine.map((child, index) => {
                // make sure last item does not get a <br /> at the end
                if (index !== textPartsSplitByNewLine.length - 1) {
                    return [child, <br key={index + 'br'} />];
                }

                return child;
            });
        }
    }

    return text;
}

/**
 * @param {String} text
 *
 * @returns {XML[]|String}
 */
export function preserveNewLines(text) {
    var textPartsSplitByNewLine = text.split(/\r?\n/g);

    // only apply br tags if there were line breaks found in the text
    if (textPartsSplitByNewLine.length > 1) {
        return textPartsSplitByNewLine.map((child, index, array) => {
            var line = <span key={index}>{child}</span>;

            if (index === array.length - 1) {
                return line;
            }

            return [line, <br key={index + 'br'} />];
        });
    }

    return text;
}
