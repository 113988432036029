import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import useDispatchStartActionOnValidSubmit from './hooks/useDispatchStartActionOnValidSubmit';
import StartProjectThreshold from './components/StartProjectThreshold';
import StartProjectButton from './components/StartProjectButton';
import StartProjectModal from './components/StartProjectModal';
import useOpenStartProjectModalOnKeyBindingPressed from './hooks/useOpenStartProjectModalOnKeyBindingPressed';
import ProjectForm from '../projectForm/ProjectForm';

const StartNewProject = () => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const { onFormValid } = useDispatchStartActionOnValidSubmit();

    useOpenStartProjectModalOnKeyBindingPressed(doShowModal);

    return (
        <StartProjectThreshold>
            {showModal ? (
                <StartProjectModal onDecline={() => doHideModal()}>
                    <ProjectForm onFormValid={onFormValid} onDone={() => doHideModal()} />
                </StartProjectModal>
            ) : (
                <StartProjectButton onClick={() => doShowModal()} />
            )}
        </StartProjectThreshold>
    );
};

export default StartNewProject;
