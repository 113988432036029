import ParagraphDetailChapter from '../ParagraphDetailChapter';
import { createUuid } from '../../../utility/idGenerator';
import { createParagraphDetailProjectFromApiInput } from './paragraphDetailProjectFactory';

export function createParagraphDetailChapterFromApiInput(apiInput: any): ParagraphDetailChapter {
    return new ParagraphDetailChapter(
        createUuid(),
        apiInput.id,
        apiInput.title,
        createParagraphDetailProjectFromApiInput(apiInput.project),
        apiInput.in_projects,
        apiInput.index
    );
}
