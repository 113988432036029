import { useEffect } from 'react';
import mousetrap from 'mousetrap';

export default function useShowModalOnKeyBindingPress(doShowModal: () => void, keyBinding = 'r') {
    useEffect(() => {
        mousetrap.bind([keyBinding], () => doShowModal());

        return () => {
            mousetrap.unbind([keyBinding]);
        };
    });
}
