import React, { forwardRef } from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    isDragging?: boolean;
    isDraggable?: boolean;
    isActive?: boolean;
};

const ListItem = forwardRef<HTMLLIElement, Props>((props, ref) => {
    const { children, isDragging = false, isDraggable = false, isActive = false } = props;

    const className = createClassName('list__item', {
        'list__item--is-dragging': isDragging,
        'list__item--is-draggable': isDraggable,
        'list__item--is-active': isActive,
    });

    return (
        <li className={className} ref={ref}>
            {children}
        </li>
    );
});

export default ListItem;
