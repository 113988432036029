import React, { FC } from 'react';
import styles from './ProjectDetailHeader.module.scss';
import Heading from '../../../../shared/heading/Heading';
import { IoDownloadOutline } from 'react-icons/io5';
import { IoReaderOutline } from 'react-icons/io5';
import Button, { ButtonStyles, ButtonTypes, SizeOptions } from '../../primitives/button/Button';
import ProjectSideProduct from '../../../../../model/ProjectSideProduct';
import Sticky from 'react-sticky-el';
import { useProjectDetailContext } from '../../../ggz/projectDetail/components/ProjectDetailContext';
import createClassName from 'classnames';
import { skilzProjectTypesSingular } from '../../../../../constants/types';
import SkilzLogo from '../../../../../../images/skilz/skilz-logo-full.svg';
import SriLogo from '../../../../../../images/skilz/sri-logo-full.svg';
import AawvbLogo from '../../../../../../images/skilz/aawvb-logo.svg';
import NvavgLogo from '../../../../../../images/skilz/nvavg-logo.svg';

type Props = {
    title: string;
    type: string;
    pdfFilePath: string | undefined | null;
    summarySideProducts: ProjectSideProduct[];
    owner: string | null;
};

const STICKY_HEADER_TOP_OFFSET = 280; // amount of pixels before header becomes sticky; necessary to not look odd with tabs

const getOwnerLogo = (owner: string | null) => {
    switch (owner) {
        case 'sri':
            return <SriLogo />;
        case 'nvavg':
            return <NvavgLogo />;
        case 'aawvb':
            return <AawvbLogo />;
        default:
            return <SkilzLogo />;
    }
};

const ProjectDetailHeader: FC<Props> = ({ title, type, pdfFilePath, owner, summarySideProducts }) => {
    const { onProjectDetailHeaderStickyStatusChange } = useProjectDetailContext();

    const classNames = createClassName(styles.sticky, 'js-sticky-project-detail-header');
    const projectType = skilzProjectTypesSingular[type as string] || 'richtlijn';

    return (
        <Sticky
            stickyClassName={classNames}
            topOffset={STICKY_HEADER_TOP_OFFSET}
            onFixedToggle={onProjectDetailHeaderStickyStatusChange}
            // @ts-ignore Don't know how to fix this? Somehow this property seems not to be there?
            dontUpdateHolderHeightWhenSticky
        >
            <div className={styles.header}>
                <div className="container">
                    <section className={styles.content}>
                        <div className={styles.logoWrapper}>{getOwnerLogo(owner)}</div>
                        <Heading tag="h1" variant="h2" flatten className={styles.heading}>
                            {title}
                        </Heading>
                        <div className={styles.description}>
                            Deze {projectType.toLowerCase()} is opgesteld om de kwaliteit van de langdurige zorg voor
                            dit onderwerp te verbeteren. Het bevat de nieuwste wetenschappelijke inzichten, gecombineerd
                            met ervaring en kennis van zorgverleners, cliënten en naasten.
                        </div>
                        <div className={styles.buttonGroup}>
                            {pdfFilePath && (
                                <Button
                                    type={ButtonTypes.External}
                                    style={ButtonStyles.Primary}
                                    size={SizeOptions.Small}
                                    icon={<IoDownloadOutline className={styles.icon} />}
                                    href={pdfFilePath}
                                >
                                    Download PDF
                                </Button>
                            )}
                            {summarySideProducts.length > 0 &&
                                summarySideProducts.map(
                                    (summary) =>
                                        summary.path && (
                                            <Button
                                                key={summary.id}
                                                type={ButtonTypes.External}
                                                style={ButtonStyles.Primary}
                                                size={SizeOptions.Small}
                                                icon={<IoReaderOutline className={styles.icon} />}
                                                href={summary.path}
                                            >
                                                {summary.title}
                                            </Button>
                                        )
                                )}
                        </div>
                    </section>
                </div>
            </div>
        </Sticky>
    );
};
export default ProjectDetailHeader;
