import React, { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import Heading from '../../../../shared/heading/Heading';
import styles from './DirectiveOverviewList.module.scss';
import AnimatedArrow from '../../primitives/animatedArrow/AnimatedArrow';

type Props = {
    title: string;
    link?: string;
    subProperties?: string[];
};
const DirectiveOverviewListItem: FC<Props> = ({ title, link, subProperties }) => {
    const [isHovering, setIsHovering] = useState(false);

    const getWrapper = (children: ReactNode) => {
        if (link) {
            return (
                <Link
                    to={link}
                    className={styles.link}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {children}
                </Link>
            );
        }

        return <div className={styles.blockWrapper}>{children}</div>;
    };

    return (
        <li className={styles.item}>
            {getWrapper(
                <>
                    <Heading tag="span" variant="h4" style={link ? 'secondary' : 'primary'}>
                        {title} {link && <AnimatedArrow isHovering={isHovering} className={styles.arrow} />}
                    </Heading>
                    {subProperties && (
                        // render subProperties with '·' as separator
                        <span className={styles.subProperties}>
                            {subProperties.map((subProperty, index) => (
                                <span key={index}>
                                    {subProperty}
                                    {index !== subProperties.length - 1 && ' · '}
                                </span>
                            ))}
                        </span>
                    )}
                </>
            )}
        </li>
    );
};

export default DirectiveOverviewListItem;
