import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { createProjectDetailPath, createProjectDetailTabPath } from '../../../../../routing/urlGenerator';
import {
    ProjectTabIdentifier,
    projectTabSlugs,
    skilzProjectTabSlugs,
} from '../../../../../resolver/projectDetailTabsResolver';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type RootRouteParams = {
    slug: string;
    type: string;
};

type TabRouteParams = RootRouteParams & {
    tab: string;
};

export default function useDetermineCurrentTabIdentifier() {
    const [currentIdentifier, setCurrentIdentifier] = useState<ProjectTabIdentifier | null>(null);
    const { isSKILZ } = useCurrentTenant();
    const currentEnvProjectTabSlugs = isSKILZ ? skilzProjectTabSlugs : projectTabSlugs;

    const location = useLocation();

    useEffect(() => {
        const projectDetailTabMatch = matchPath<TabRouteParams>(location.pathname, {
            path: createProjectDetailTabPath(),
            exact: true,
        });

        if (projectDetailTabMatch) {
            const tab = projectDetailTabMatch.params.tab;

            const identifier = Object.keys(currentEnvProjectTabSlugs).find((identifier) => {
                // @ts-ignore → don't know how to fix this
                return currentEnvProjectTabSlugs[identifier] === tab;
            }) as ProjectTabIdentifier | undefined;

            if (identifier) {
                setCurrentIdentifier(identifier);

                return;
            }

            setCurrentIdentifier(null);

            return;
        }

        const projectDetailRootMatch = matchPath<RootRouteParams>(location.pathname, {
            path: createProjectDetailPath(),
            exact: false,
        });

        if (projectDetailRootMatch) {
            setCurrentIdentifier(ProjectTabIdentifier.standard);

            return;
        }

        setCurrentIdentifier(null);
    }, [location.pathname, currentEnvProjectTabSlugs]);

    return { currentIdentifier };
}
