import { ProjectSectionType } from '../constants/projectSection';
import {
    createProjectBreadcrumbCollectionFromApiInput,
    createProjectBreadcrumbFromApiInput,
} from '../model/factory/ProjectBreadcrumbFactory';
import { get } from '../api/client/apiClient';
import ProjectBreadcrumb from '../model/ProjectBreadcrumb';
import { createProjectBreadcrumbsApiPath, createProjectBreadcrumbsDetailApiPath } from '../routing/apiUrlGenerator';

export async function findUnpublishedOrLastPublishedBreadcrumbsMatching(
    query: string,
    type: ProjectSectionType | null
): Promise<Array<ProjectBreadcrumb>> {
    const response = await get(createProjectBreadcrumbsApiPath(), {
        query,
        type,
        includeOldPublications: 0,
    });

    return createProjectBreadcrumbCollectionFromApiInput(response.data);
}

export async function findAllBreadcrumbsWithTypeAndTypeId(
    type: string,
    externalTypeId: string
): Promise<Array<ProjectBreadcrumb>> {
    const response = await get(createProjectBreadcrumbsApiPath(), {
        type,
        typeId: externalTypeId,
    });

    return createProjectBreadcrumbCollectionFromApiInput(response.data);
}

export async function findBreadcrumbById(externalId: string): Promise<ProjectBreadcrumb> {
    const url: string = createProjectBreadcrumbsDetailApiPath(externalId);

    const response = await get(url);

    return createProjectBreadcrumbFromApiInput(response.data);
}
