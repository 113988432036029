import ProjectBreadcrumb from '../../../../model/ProjectBreadcrumb';

export enum NodeType {
    COMMENT = 'comment',
    DEFINITION = 'definition',
    IMAGE = 'image',
    LINK = 'link',
    LINE_BREAK = 'linebreak',
    LIST = 'list',
    LIST_ITEM = 'listitem',
    PARAGRAPH = 'paragraph',
    REFERENCE = 'reference',
    ROOT = 'root',
    TAB = 'tab',
    TABLE = 'table',
    TABLE_ROW = 'tablerow',
    TABLE_CELL = 'tablecell',
    TEXT = 'text',
    VIMEO = 'vimeo',
    INTERNAL_LINK = 'internal_link',
    FILE_LINK = 'file_link',
    SIDE_PRODUCT_LINK = 'side_product_link',
    HIGHLIGHT = 'highlight',
    QUOTE = 'quote',
}

export enum NodeFormat {
    BOLD = 1,
    ITALIC = 1 << 1,
    STRIKETHROUGH = 1 << 2,
    UNDERLINE = 1 << 3,
    CODE = 1 << 4,
    SUBSCRIPT = 1 << 5,
    SUPERSCRIPT = 1 << 6,
    HIGHLIGHT = 1 << 7, // corresponds to <mark> html element, but we don't use this
}

export default NodeFormat;

export type LexicalNode = {
    type: string;
};

export type LexicalTopNode = {
    root: LexicalRootNode;
};

export type LexicalChildrenNode = {
    children: LexicalNode[];
};

export type LexicalListItemNode = LexicalNode &
    LexicalChildrenNode & {
        type: string;
    };

export type LexicalListNode = LexicalNode &
    LexicalChildrenNode & {
        listType: string;
        depth: number;
    };

export type LexicalLineBreakNode = LexicalNode & {};

export type LexicalLinkNode = LexicalNode &
    LexicalChildrenNode & {
        rel: string;
        target?: string;
        url: string;
    };

export type LexicalInternalLinkNode = LexicalNode &
    LexicalChildrenNode & {
        path: string;
    };

export type LexicalFileLinkNode = LexicalNode &
    LexicalChildrenNode & {
        file: string;
    };

export type LexicalSideProductLinkNode = LexicalNode &
    LexicalChildrenNode & {
        sideProductId: string;
    };

export type LexicalVimeoNode = LexicalNode & {
    videoID: string;
};

export type LexicalImageNode = LexicalNode & {
    src: string;
};

export type LexicalReferenceNode = LexicalNode & {
    referenceId: string;
};

export type LexicalTextNode = LexicalNode & {
    text: string;
    format: number;
};

export type LexicalTableCellNode = LexicalNode &
    LexicalChildrenNode & {
        headerState: number;
    };

export type LexicalTableRowNode = LexicalNode & LexicalChildrenNode & {};

export type LexicalTableNode = LexicalNode & LexicalChildrenNode & {};

export type LexicalParagraphNode = LexicalNode & LexicalChildrenNode & {};

export type LexicalHighlightNode = LexicalNode & LexicalChildrenNode & {};

export type LexicalQuoteNode = LexicalNode & LexicalChildrenNode & {};

export type LexicalDefinitionNode = LexicalNode &
    LexicalChildrenNode & {
        definition: string;
        externalLink: string | null;
        internalLink: ProjectBreadcrumb | null;
    };

export type LexicalRootNode = LexicalNode & LexicalChildrenNode & {};
