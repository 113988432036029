import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGetProjectOverviewAction } from '../../../../actions/projectActionFactory';
import type { GlobalState } from '../../../../store/types';
import ProjectOverviewItem from '../../../../model/projectOverview/ProjectOverviewItem';
import { applyNewProjectSelector, applyProjectRevisionSelector } from '../../../../model/projectOverview/selectors';
import { ProjectOverviewReducerState } from '../../../../reducers/projectOverviewReducer';

export type ReduxSuppliedProps = {
    projectRevisionItems: Array<ProjectOverviewItem> | null;
    newProjectItems: Array<ProjectOverviewItem> | null;
    allProjectOverviewItems: ProjectOverviewReducerState;
};

export default function useFetchProjectOverviewItems() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createGetProjectOverviewAction());
    }, []);

    return useSelector<GlobalState, ReduxSuppliedProps>(({ projectOverviewItems }) => ({
        projectRevisionItems: projectOverviewItems ? applyProjectRevisionSelector(projectOverviewItems) : null,
        newProjectItems: projectOverviewItems ? applyNewProjectSelector(projectOverviewItems) : null,
        allProjectOverviewItems: projectOverviewItems,
    }));
}
