export default class BreadcrumbItem {
    _id: string;
    _title: string;
    _path: string | undefined | null;
    _numbering: string | undefined | null;

    constructor(
        id: string,
        title: string,
        path: string | undefined | null = null,
        numbering: string | undefined | null = null
    ) {
        this._id = id;
        this._title = title;
        this._path = path;
        this._numbering = numbering;
    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get path() {
        return this._path;
    }

    get numbering() {
        return this._numbering;
    }

    get titleWithNumbering() {
        if (!this._numbering) {
            return this._title;
        }

        return `${this._numbering}. ${this._title}`;
    }
}
