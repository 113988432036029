import React from 'react';
import classNameFactory from 'classnames';
import classNames from './Tabs.module.scss';

type Props = {
    style: 'GGZ' | 'Skilz' | 'clean' | 'outline';
    additionalClassNames?: string;
    children: React.ReactNode;
};

const Tabs = ({ style, additionalClassNames, children }: Props) => {
    const className = classNameFactory(
        classNames.container,
        {
            [classNames.isStyleGGZ]: style === 'GGZ',
            [classNames.isStyleSkilz]: style === 'Skilz',
            [classNames.isStyleClean]: style === 'clean',
            [classNames.isStyleOutline]: style === 'outline',
        },
        additionalClassNames
    );

    return <ul className={className}>{children}</ul>;
};

export default Tabs;
