import React from 'react';
import { createFetchUserOverviewAction } from '../../../../actions/userActionFactory';
import { GlobalState } from '../../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { OverviewUsersReducerState } from '../../../../reducers/overviewUsersReducer';
import PaginatedCollection from '../../../../model/collection/paginatedCollection';
import Button, { BUTTON } from '../../../shared/buttons/Button';
import FormGroup from '../../../shared/form/FormGroup';
import createClassName from 'classnames';
import styles from '../UsersOverview.module.scss';

type FormValues = {
    query: string;
};

const SearchUserForm = () => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const { values, handleChange, resetForm, handleSubmit } = useFormik<FormValues>({
        initialValues: {
            query: '',
        },
        onSubmit: async (values) => {
            const action = createFetchUserOverviewAction(1, values.query);
            dispatch(action);

            useSelector<GlobalState, OverviewUsersReducerState>((globalState) => {
                let collection = globalState.overviewUsers;

                if (collection instanceof PaginatedCollection && collection.currentPage !== 1) {
                    collection = null;
                }

                return collection;
            });
        },
    });

    const clear = () => {
        resetForm();
        handleSubmit();

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
            <FormGroup additionalClassName={styles.formGroup}>
                <input
                    ref={inputRef}
                    type="text"
                    value={values.query}
                    name="query"
                    placeholder="Zoek op naam, functie, of e-mailadres"
                    className="form__field"
                    onChange={handleChange}
                />
                <Button
                    type={BUTTON}
                    icon="cancel"
                    onClick={clear}
                    isCleared
                    additionalClassName={styles.resetButton}
                />
            </FormGroup>
            <button
                type="submit"
                className={createClassName(styles.submitButton, 'button button--primary button--small')}
            >
                Zoeken
            </button>
        </form>
    );
};

export default SearchUserForm;
