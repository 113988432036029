import type { Cloneable } from '../contracts';
import ParagraphDetailLastUpdatedInformation from './ParagraphDetailLastUpdatedInformation';
import { ContentType } from '../../constants/content';

export default class ParagraphDetailSubParagraph implements Cloneable<ParagraphDetailSubParagraph> {
    _id: string;
    _externalId: string | undefined | null;
    _title: string | undefined | null;
    _content: string | undefined | null;
    _contentType: ContentType | undefined;
    _inParagraphs: Array<string>;
    _hasChanges: boolean;
    _index: number;
    _commentCount: number;
    _answeredCommentCount: number;
    private readonly _lastUpdatedInformation: ParagraphDetailLastUpdatedInformation;

    constructor(
        id: string,
        externalId: string | undefined | null,
        title: string | undefined | null,
        content: string | undefined | null,
        contentType: ContentType | undefined,
        inParagraphs: Array<string>,
        index: number,
        commentCount: number,
        answeredCommentCount: number,
        lastUpdatedInformation: ParagraphDetailLastUpdatedInformation
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._content = content;
        this._contentType = contentType;
        this._inParagraphs = inParagraphs;
        this._index = index;
        this._lastUpdatedInformation = lastUpdatedInformation;
        this._hasChanges = false;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
    }

    set hasChanges(value: boolean) {
        this._hasChanges = value;
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string | undefined | null {
        return this._externalId;
    }

    set externalId(externalId: string | undefined | null) {
        this._externalId = externalId;
    }

    hasExternalId(): boolean {
        return this._externalId !== null;
    }

    get title(): string | undefined | null {
        return this._title;
    }

    set title(title: string | undefined | null) {
        this._title = title;
    }

    get content(): string | undefined | null {
        return this._content;
    }

    set content(content: string | undefined | null) {
        this._content = content;
    }

    get contentType(): ContentType | undefined {
        return this._contentType;
    }

    set contentType(contentType: ContentType | undefined) {
        this._contentType = contentType;
    }

    get inParagraphs(): Array<string> {
        return this._inParagraphs;
    }

    isNew(): boolean {
        return !this.hasExternalId();
    }

    is(subParagraph: ParagraphDetailSubParagraph): boolean {
        return this._id === subParagraph.id;
    }

    isUnaltered(): boolean {
        return this.title === null && this.content === null;
    }

    isBeingReused(): boolean {
        return this._inParagraphs.length > 1;
    }

    isPersistedOnTheServer(): boolean {
        return this.hasExternalId();
    }

    get commentCount(): number {
        return this._commentCount;
    }

    set commentCount(count: number) {
        this._commentCount = count;
    }

    incrementCommentCount(): void {
        this._commentCount++;
    }

    get answeredCommentCount(): number {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount(): void {
        this._answeredCommentCount++;
    }

    get lastUpdatedInformation() {
        return this._lastUpdatedInformation;
    }

    get index() {
        return this._index;
    }

    merge(incomingSubParagraph: ParagraphDetailSubParagraph): void {
        this._title = incomingSubParagraph.title;
        this._content = incomingSubParagraph.content;
        this._inParagraphs = incomingSubParagraph.inParagraphs;
        this._index = incomingSubParagraph.index;
    }

    clone(): ParagraphDetailSubParagraph {
        const theClone = new ParagraphDetailSubParagraph(
            this._id,
            this._externalId,
            this._title,
            this._content,
            this._contentType,
            this._inParagraphs,
            this._index,
            this._commentCount,
            this._answeredCommentCount,
            this._lastUpdatedInformation.clone()
        );

        theClone.hasChanges = this._hasChanges;

        return theClone;
    }
}
