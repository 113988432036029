import type { LoadingQueueReducerState } from '../loadingQueueReducer';

export const applyIsLoadingSelector = (
    allLoadingQueueItems: LoadingQueueReducerState,
    action: string | string[]
): boolean => {
    const actions = Array.isArray(action) ? action : [action];

    for (let i = 0, l = actions.length; i < l; i++) {
        if (allLoadingQueueItems.countWithType(actions[i])) {
            return true;
        }
    }

    return false;
};
