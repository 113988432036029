import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxIsOpen: false,
        };

        this._closeLightbox = this._closeLightbox.bind(this);
        this._openLightbox = this._openLightbox.bind(this);
    }

    /**
     * @private
     */
    _closeLightbox() {
        this.setState({ lightboxIsOpen: false });
    }

    /**
     * @private
     */
    _openLightbox() {
        this.setState({ lightboxIsOpen: true });
    }

    /**
     * @return {XML|null}
     *
     * @private
     */
    _renderLightbox() {
        var { originalSrc } = this.props;

        if (!this.state.lightboxIsOpen) {
            return null;
        }

        return <Lightbox mainSrc={originalSrc} onCloseRequest={this._closeLightbox} />;
    }

    /**
     * @returns {XML}
     */
    render() {
        var { src, title, children } = this.props;

        return (
            <div className="block-list__image">
                {this._renderLightbox()}
                <figure onClick={this._openLightbox} data-mobile-zoom-caption="(Klik om in te zoomen)">
                    <img src={src} alt={title} />
                </figure>
                {children}
            </div>
        );
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    originalSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Image;
