import { NavLink } from 'react-router-dom';
import {
    createArticleOverviewPath,
    createContactPagePath,
    createFrontendEntryPath,
} from '../../../../../routing/urlGenerator';
import styles from '../Header.module.scss';
import { createAboutPagePath } from '../../../../../routing/skilzUrlGenerator';
import React, { FC } from 'react';

type Props = {
    onClick?: () => void;
};

const NavItems: FC<Props> = ({ onClick }) => (
    <>
        <NavLink
            to={createFrontendEntryPath()}
            exact
            className={styles.navItem}
            activeClassName={styles.isActive}
            onClick={onClick}
        >
            Richtlijnen
        </NavLink>
        <NavLink
            to={createAboutPagePath()}
            exact
            className={styles.navItem}
            activeClassName={styles.isActive}
            onClick={onClick}
        >
            Over ons
        </NavLink>
        <NavLink
            to={createArticleOverviewPath()}
            exact
            className={styles.navItem}
            activeClassName={styles.isActive}
            onClick={onClick}
        >
            Actueel
        </NavLink>
        <NavLink
            to={createContactPagePath()}
            exact
            className={styles.navItem}
            activeClassName={styles.isActive}
            onClick={onClick}
        >
            Contact
        </NavLink>
    </>
);

export default NavItems;
