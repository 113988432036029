import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import { useState, useEffect } from 'react';
import { findProjectSearchItemsMatching } from '../../../../../repository/projectSearchItemRepository';
import { logError } from '../../../../../utility/Logger';
import { toast } from 'react-toastify';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';

export default function useFetchSearchResultsOnQueryOrFilterChange(
    query: string,
    page: number,
    projectDetails: ProjectDetail | null
) {
    const externalProjectId = projectDetails ? projectDetails.externalId : null;

    const [searchResults, setSearchResults] = useState<PaginatedCollection<ProjectSearchItem> | null>(null);

    useEffect(() => {
        if (!externalProjectId) {
            return;
        }

        findProjectSearchItemsMatching(query, page, externalProjectId)
            .then((results) => setSearchResults(results.nestedResults))
            .catch((error) => {
                logError(error);

                setSearchResults(new PaginatedCollection([], 1, 1, 0, 0));

                toast.error('Er is iets foutgegaan bij het ophalen van de zoekresultaten. Probeer het later nog eens!');
            });
    }, [query, page, externalProjectId]);

    const resetSearchResults = () => setSearchResults(null);

    return { searchResults, resetSearchResults };
}
