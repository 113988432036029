import React, { FC, Fragment, lazy, Suspense } from 'react';
import type { ErrorInfo } from '../ErrorBoundary';
import { isDevelopmentEnvironment } from '../../../../../helper/environmentHelper';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';
import LazyLoadingLoader from '../../../../shared/lazyLoading/LazyLoadingLoader';

const GgzErrorPage = lazy(() => import('../../../ggz/page/components/GgzErrorPage'));
const SkilzErrorPage = lazy(() => import('../../../skilz/page/components/SkilzErrorPage'));

type Props = {
    error: Error;
    errorInfo: ErrorInfo | undefined | null;
};

const UnexpectedErrorNotificationPage: FC<Props> = ({ error, errorInfo }) => {
    const { isSKILZ } = useCurrentTenant();

    const renderErrorIfRequired = () => {
        if (!isDevelopmentEnvironment()) {
            return null;
        }

        return <h2>{error.toString()}</h2>;
    };

    const renderErrorInfoIfRequired = () => {
        if (!errorInfo || !isDevelopmentEnvironment()) {
            return null;
        }

        return (
            <Fragment>
                <strong>Component stack:</strong>
                <pre>{errorInfo.componentStack}</pre>
            </Fragment>
        );
    };

    if (isSKILZ) {
        return (
            <Suspense fallback={<LazyLoadingLoader />}>
                <SkilzErrorPage
                    renderErrorIfRequired={renderErrorIfRequired}
                    renderErrorInfoIfRequired={renderErrorInfoIfRequired}
                />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<LazyLoadingLoader />}>
            <GgzErrorPage
                renderErrorIfRequired={renderErrorIfRequired}
                renderErrorInfoIfRequired={renderErrorInfoIfRequired}
            />
        </Suspense>
    );
};

export default UnexpectedErrorNotificationPage;
