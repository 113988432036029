import React from 'react';
import { renderNode } from './utilities/renderNode';
import { LexicalTopNode } from './model/Node';

type LexicalRenderProps = {
    lexicalContent: LexicalTopNode;
};

const LexicalRender = ({ lexicalContent }: LexicalRenderProps): JSX.Element | null => {
    return <div className="editor-content">{renderNode(lexicalContent.root)}</div>;
};

export default LexicalRender;
