import React from 'react';
import useFetchArticlesOnPageChange from '../../../../hooks/useFetchArticlesOnPageChange';
import Heading from '../../../shared/heading/Heading';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../../helper/seoHelper';
import CollectionPagination from '../../shared/CollectionPagination';
import ArticleItem, { LayoutOptions } from '../primitives/articleItem/ArticleItem';
import Loader from '../../../shared/Loader';
import styles from './ArticleOverview.module.scss';

const ArticleOverview = () => {
    const { articles, onPageChange, isFetching } = useFetchArticlesOnPageChange();

    return (
        <main className="section section--light">
            <Helmet>
                <title>{composeSeoTitle(null, 'Actueel')}</title>
            </Helmet>
            <div className="container">
                <div className="layout layout--align-center">
                    <section className="layout__item layout__item--lg-10 layout__item--xl-8">
                        <Heading tag="h1">Actueel</Heading>
                        <div className={styles.list}>
                            {isFetching && <Loader />}
                            {articles && articles.results.length > 0 && (
                                <>
                                    {articles.results.map((article) => (
                                        <ArticleItem
                                            article={article}
                                            key={article.id}
                                            layout={LayoutOptions.Landscape}
                                        />
                                    ))}
                                    <CollectionPagination
                                        collection={articles}
                                        currentPage={articles.currentPage}
                                        onPageChange={onPageChange}
                                    />
                                </>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default ArticleOverview;
