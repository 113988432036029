import { doDelete, get, post, put } from '../api/client/apiClient';
import { createReferenceCollectionFromApiInput, createReferenceFromApiInput } from '../model/factory/referenceFactory';
import Reference from '../model/Reference';
import type { ReferenceListResponse, ReferenceResponse } from '../api/response/types';
import {
    createProjectReferenceDeleteApiPath,
    createProjectReferenceEditApiPath,
    createProjectReferencePath,
    createProjectReferenceSearchApiPath,
    createProjectReferenceSearchByIdPath,
} from '../routing/apiUrlGenerator';

export function findReferenceMatching(query: string): Promise<Array<Reference>> {
    return get(createProjectReferenceSearchApiPath(query)).then((response: { data: ReferenceListResponse }) => {
        return createReferenceCollectionFromApiInput(response.data);
    });
}

export function findReferenceMatchingId(id: number): Promise<Reference> {
    return get(createProjectReferenceSearchByIdPath(id)).then((response: { data: ReferenceResponse }) => {
        return createReferenceFromApiInput(response.data);
    });
}

export function createReference(description: string): Promise<any> {
    return post(createProjectReferencePath(description), { description }).then((response: { data: any }) => {
        return response.data;
    });
}

export function updateReference(id: number, newDescription: string): Promise<any> {
    const url = createProjectReferenceEditApiPath(id);

    return put(url, {
        description: newDescription,
    });
}

export async function deleteReferenceFromServer(id: number) {
    await doDelete(createProjectReferenceDeleteApiPath(id));
}
