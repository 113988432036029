import { createAction } from './factory';
import { toast } from 'react-toastify';
import { createProjectDetailPath, createFrontendEntryPath } from '../routing/urlGenerator';
import {
    START_PROJECT,
    FETCH_PROJECT_OVERVIEW,
    CLOSE_PROJECT,
    DUPLICATE_PROJECT,
    UPDATE_PROJECT,
    UPDATE_PROJECT_STATUS,
    FETCH_PROJECT_DETAILS,
    CLEAR_PROJECT_DETAILS,
    ADD_CHAPTER_TO_PROJECT,
    UPDATE_PROJECT_CHAPTER,
    MOVE_PROJECT_CHAPTER,
    ADD_PARAGRAPH_TO_CHAPTER,
    FETCH_PARAGRAPH_DETAILS,
    UPDATE_PROJECT_PARAGRAPH,
    UPDATE_PROJECT_SUBPARAGRAPH,
    START_SUB_PARAGRAPH,
    ADD_SUB_PARAGRAPH_TO_PARAGRAPH,
    PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED,
    PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED,
    PROJECT_EMBED_CHAPTER,
    PROJECT_EMBED_PARAGRAPH,
    PROJECT_EMBED_SUB_PARAGRAPH,
    MOVE_PROJECT_PARAGRAPH,
    MOVE_SUB_PARAGRAPH,
    PROJECT_REMOVE_CHAPTER,
    PROJECT_REMOVE_PARAGRAPH,
    PROJECT_REMOVE_SUB_PARAGRAPH,
    REMOVE_NON_PERSISTED_SUB_PARAGRAPH,
    FETCH_PROJECT_CHAPTER_DETAILS,
    FETCH_PROJECT_INDEX,
    PROJECT_ADD_SIDE_PRODUCT,
    PROJECT_REMOVE_SIDE_PRODUCT,
    MARK_CHAPTER_SEARCHABLE,
    MARK_CHAPTER_NOT_SEARCHABLE,
    PUBLISH_PROJECT,
    ADD_WORKING_GROUP_MEMBER_TO_PROJECT,
    REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT,
    ADD_SOUNDING_BOARD_MEMBER_TO_PROJECT,
    REMOVE_SOUNDING_BOARD_MEMBER_FROM_PROJECT,
    REQUEST_FEEDBACK_FROM_WORKING_GROUP_MEMBERS,
    REQUEST_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS,
    REQUEST_FEEDBACK_FROM_SOUNDING_BOARD_MEMBERS,
    ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT,
    REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT,
    PROJECT_STOP_CONSULTATION_PHASE,
    REQUEST_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS,
    SET_LAST_SEARCHED_ITEM,
    PROJECT_ADD_LINK,
    PROJECT_REMOVE_LINK,
    PROJECT_UPDATE_PATIENT_INTRODUCTION,
    PROJECT_UPDATE_LINK,
    PROJECT_UPDATE_SIDE_PRODUCT,
    PROJECT_MOVE_SIDE_PRODUCT,
    AUTHORIZE_PROJECT,
    EDIT_AUTHORIZATION_STATUS,
    REMIND_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS,
    REMIND_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS,
    ARCHIVE_COMMENTS_CHAPTER,
    RESET_PARAGRAPH_DETAILS,
    ALLOW_WORKING_GROUP_MEMBER_WRITING_ACCESS,
} from './types';
import { extractPath } from '../helper/objectPathHelper';
import * as apiClient from '../api/client/apiClient';
import { STATUS_CODE_NOT_FOUND } from '../constants/statusCode';
import { extractErrorTypeFromApiError } from '../helper/apiErrorHelper';
import { logError } from '../utility/Logger';
import { createFetchStandardOverviewItemsAction } from './standardActionFactory';
import type { Thunk, Action, GetState } from './factory';
import type { ChapterAuthorizationCollection } from '../model/collection/chapterAuthorizationCollection';
import { applyIsLoadingSelector } from '../reducers/selectors/loadingQueueSelectors';
import type { GlobalState } from '../store/types';
import { getBrowserHistory } from '../routing/historyManager';
import type { ProjectDetailLinkType } from '../model/projectDetail/ProjectDetailLink';
import { createUuid } from '../utility/idGenerator';
import type { ProjectSearchItemType } from '../model/ProjectSearchItem';
import type { ProjectSideProductType } from '../model/ProjectSideProduct';
import { AxiosError } from 'axios';
import { ProjectSideProductToolType } from '../model/ProjectSideProduct';
import { ProjectDetailLinkToolType } from '../model/projectDetail/ProjectDetailLink';
import { AuthorizationStatusType, authorizationStatusTypeLabels } from '../constants/authorization';
import {
    createDashboardProjectDetailPath,
    createDashboardProjectTypesOverviewPath,
} from '../routing/dashboardUrlGenerator';
import {
    createAddChapterToProjectApiPath,
    createAddExternalPartyMemberToProjectApiPath,
    createAddSoundingBoardMemberToProjectApiPath,
    createAddParagraphToChapterApiPath,
    createAddSubParagraphToParagraphApiPath,
    createAddWorkingGroupMemberToProjectApiPath,
    createAuthorizeProjectApiPath,
    createChapterDetailApiPath,
    createChapterEmbedParagraphApiPath,
    createChapterRemoveParagraphApiPath,
    createCloseProjectApiPath,
    createDuplicateProjectApiPath,
    createEditAuthorizationStatusForMemberApiPath,
    createMarkChapterNotSearchableApiPath,
    createMarkChapterSearchableApiPath,
    createMoveSubParagraphApiPath,
    createParagraphDetailApiPath,
    createParagraphEmbedSubParagraphApiPath,
    createParagraphRemoveSubParagraphApiPath,
    createProjectAddLinkToProjectApiPath,
    createProjectAddSideProductApiPath,
    createProjectDetailApiPath,
    createProjectDetailBySlugApiPath,
    createProjectEmbedChapterApiPath,
    createProjectIndexApiPath,
    createProjectMoveChapterApiPath,
    createProjectMoveParagraphApiPath,
    createProjectMoveSideProductApiPath,
    createProjectOverviewApiPath,
    createProjectRemoveChapterApiPath,
    createProjectRemoveSideProductApiPath,
    createProjectUpdateLinkApiPath,
    createProjectUpdatePatientIntroductionApiPath,
    createProjectUpdateSideProductApiPath,
    createPublishProjectApiPath,
    createRemindAuthorizationFromExternalPartyMembersApiPath,
    createRemoveSoundingBoardMemberFromProjectApiPath,
    createRemoveExternalPartyMemberFromProjectApiPath,
    createRemoveLinkFromProjectApiPath,
    createRemoveWorkingGroupMemberFromProjectApiPath,
    createRequestAuthorizationFromExternalPartyMembersApiPath,
    createRequestFeedbackFromExternalPartyMembersApiPath,
    createRequestFeedbackFromWorkingGroupMembersApiPath,
    createRequestFeedbackFromSoundingBoardMembersApiPath,
    apiUrlGenerator,
    createStartRevisionProjectApiPath,
    createStopConsultationPhaseForProjectApiPath,
    createUpdateParagraphApiPath,
    createUpdateProjectApiPath,
    createUpdateProjectStatusApiPath,
    createUpdateProjectChapterApiPath,
    createUpdateSubParagraphApiApth,
    createRemindFeedbackFromExternalPartyMembersApiPath,
    createArchiveCommentaryForChaptersApiPath,
    createAllowWorkingGroupMembersWritingAccessApiPath,
} from '../routing/apiUrlGenerator';
import { Dispatch } from 'react';

export function createStartProjectAction(
    title: string,
    shortTitle: string,
    type: string,
    tags: string[],
    templateId: string
): Thunk {
    return (dispatch) => {
        const action: Action = createAction(
            START_PROJECT,
            apiClient.post(apiUrlGenerator(), {
                title,
                shortTitle,
                type,
                tags,
                templateId,
            }),
            { title, shortTitle, type, tags, templateId, revision: false }
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Project '${title}' is aangemaakt`);
            })
            .finally(() => dispatch(createGetProjectOverviewAction()));
    };
}

export function createAddWorkingGroupMemberToProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string,
    branchId: string
): Thunk {
    return (dispatch) => {
        const url = createAddWorkingGroupMemberToProjectApiPath(externalProjectId);

        const action = createAction(
            ADD_WORKING_GROUP_MEMBER_TO_PROJECT,
            apiClient.post(url, { userId: externalUserId, branchId: branchId }),
            {
                externalProjectId,
                externalUserId,
                branchId,
                name,
                customErrorMessage: `Er is iets foutgegaan met het toevoegen van gebruiker '${name}' als werkgroeplid aan dit project. Probeer het nog eens.`,
            }
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Werkgroeplid ${name} is toegevoegd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemoveWorkingGroupMemberFromProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string
): Thunk {
    return (dispatch) => {
        const url = createRemoveWorkingGroupMemberFromProjectApiPath(externalProjectId, externalUserId);

        const action = createAction(REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT, apiClient.doDelete(url), {
            externalProjectId,
            externalUserId,
            customErrorMessage: `Er is iets foutgegaan met het ontkoppelen van gebruiker '${name}' als werkgroeplid voor dit project. Mogelijk is de gebruiker al ontkoppeld?`,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Werkgroeplid ${name} is verwijderd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createAddExternalPartyMemberToProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string,
    branchId: string
): Thunk {
    return (dispatch) => {
        const url = createAddExternalPartyMemberToProjectApiPath(externalProjectId);

        const action = createAction(
            ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT,
            apiClient.post(url, { userId: externalUserId, branchId: branchId }),
            {
                externalProjectId,
                externalUserId,
                branchId,
                name,
                customErrorMessage: `Er is iets foutgegaan met het toevoegen van gebruiker '${name}' als externe partij aan dit project. Probeer het nog eens.`,
            }
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Externe partij ${name} is toegevoegd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemoveSoundingBoardMemberFromProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string
): Thunk {
    return (dispatch) => {
        const url = createRemoveSoundingBoardMemberFromProjectApiPath(externalProjectId, externalUserId);

        const action = createAction(REMOVE_SOUNDING_BOARD_MEMBER_FROM_PROJECT, apiClient.doDelete(url), {
            externalProjectId,
            externalUserId,
            customErrorMessage: `Er is iets foutgegaan met het ontkoppelen van gebruiker '${name}' als klankbordgroeplid voor dit project. Mogelijk is de gebruiker al ontkoppeld?`,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Klankbordgroeplid gebruiker ${name} is verwijderd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createAddSoundingBoardMemberToProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string,
    branchId: string
): Thunk {
    return (dispatch) => {
        const url = createAddSoundingBoardMemberToProjectApiPath(externalProjectId);

        const action = createAction(
            ADD_SOUNDING_BOARD_MEMBER_TO_PROJECT,
            apiClient.post(url, { userId: externalUserId, branchId: branchId }),
            {
                externalProjectId,
                externalUserId,
                branchId,
                name,
                customErrorMessage: `Er is iets foutgegaan met het toevoegen van gebruiker '${name}' als klankbordgroeplid aan dit project. Probeer het nog eens.`,
            }
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Klankbordgroeplid ${name} is toegevoegd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemoveExternalPartyMemberFromProjectAction(
    externalProjectId: string,
    externalUserId: string,
    name: string
): Thunk {
    return (dispatch) => {
        const url = createRemoveExternalPartyMemberFromProjectApiPath(externalProjectId, externalUserId);

        const action = createAction(REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT, apiClient.doDelete(url), {
            externalProjectId,
            externalUserId,
            customErrorMessage: `Er is iets foutgegaan met het ontkoppelen van gebruiker '${name}' als externe partij voor dit project. Mogelijk is de gebruiker al ontkoppeld?`,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Externe partij gebruiker ${name} is verwijderd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createStartRevisionProjectAction(standardId: string): Thunk {
    return (dispatch, getState) => {
        const standardOverviewItems = getState().standardOverviewItems;

        if (!Array.isArray(standardOverviewItems)) {
            throw new Error('No standard overview items found to start revision on');
        }

        // find existing standard overview item in global state to get required data for optimistic updating
        const standardOverviewItem = standardOverviewItems.find((item) => item.externalId === standardId);

        if (!standardOverviewItem) {
            toast.error('Het project dat je probeert te herzien kan niet meer gevonden worden. Probeer het nog eens.');

            return;
        }

        const action = createAction(START_PROJECT, apiClient.post(createStartRevisionProjectApiPath(standardId)), {
            title: standardOverviewItem.title,
            type: standardOverviewItem.type,
            revision: true,
        });

        return dispatch(action)
            .then(() => toast.success(`Er is een nieuw project gestart voor '${standardOverviewItem.title}'`))
            .catch((error: AxiosError) => {
                const type = extractErrorTypeFromApiError(error);
                let errorMessage;

                // noinspection JSRedundantSwitchStatement
                switch (type) {
                    case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                        errorMessage =
                            'Het project dat je probeert te herzien kan niet meer gevonden worden. Probeer het nog eens.';
                        break;

                    default:
                        logError(error);
                        errorMessage = 'Er is iets onbekends fout gegaan. Probeer het later nog eens!';
                }

                toast.error(errorMessage);
            })
            .finally(() => {
                dispatch(createGetProjectOverviewAction());
                dispatch(createFetchStandardOverviewItemsAction());
            });
    };
}

export function createPublishProjectAction(
    preservePdfFileOfLatestPublication: boolean,
    title: string,
    externalId: string,
    authorizedAt: Date,
    reviewedAt: Date,
    chapterAuthorizationCollection: ChapterAuthorizationCollection,
    partiesAuthorizing: string,
    partiesNotAuthorizing: string | null,
    partiesNotObjecting: string | null
): Thunk {
    return (dispatch) => {
        const url = createPublishProjectApiPath(externalId),
            promise = apiClient.put(url, {
                preservePdfFileOfLatestPublication,
                publishedAt: new Date(),
                authorizedAt: new Date(authorizedAt),
                reviewedAt: new Date(reviewedAt),
                chapterAuthorizationCollection: chapterAuthorizationCollection.map((chapter) => ({
                    ...chapter,
                    authorizedAt: new Date(chapter.authorizedAt),
                    reviewedAt: new Date(chapter.reviewedAt),
                })),
                partiesAuthorizing,
                partiesNotAuthorizing,
                partiesNotObjecting,
            });

        const action = createAction(PUBLISH_PROJECT, promise, { externalId });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Project '${title}' is gepubliceerd`);

                // the project is no longer accessible after publishing , so we need to move the user back to overview
                const history = getBrowserHistory();
                history.push(createDashboardProjectTypesOverviewPath());
            })
            .catch((error: Error) => {
                const type = extractErrorTypeFromApiError(error);
                const errorMessage =
                    type ===
                    'GGZ\\Domain\\Entity\\Project\\Exception\\ProjectIsClosedAndThereforCannotBeAlteredException'
                        ? 'Dit project is al gepubliceerd'
                        : 'Er is iets onbekends fout gegaan. Probeer het later nog eens!';

                toast.error(errorMessage);
            })
            .finally(() => dispatch(createGetProjectOverviewAction()));
    };
}

export function createGetProjectOverviewAction(): Action {
    const promise = apiClient.get(createProjectOverviewApiPath());

    return createAction(FETCH_PROJECT_OVERVIEW, promise);
}

export function createEditProjectStatusAction(externalId: string, title: string, status: string | null): Thunk {
    return (dispatch) => {
        const url = createUpdateProjectStatusApiPath(externalId);

        const action = createAction(UPDATE_PROJECT_STATUS, apiClient.patch(url, { status }), { status });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Ontwikkelfase van project '${title}' is gewijzigd`);
            })
            .finally(() => {
                dispatch(createFetchProjectDetailsAction(externalId));
                dispatch(createGetProjectOverviewAction());
            });
    };
}

export function createCloseProjectAction(id: string, externalId: string, title: string): Thunk {
    return (dispatch) => {
        const url = createCloseProjectApiPath(externalId);

        const action = createAction(CLOSE_PROJECT, apiClient.put(url), { id }, { id });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Project '${title}' is gesloten`);
            })
            .finally(() => {
                dispatch(createGetProjectOverviewAction());
                dispatch(createFetchStandardOverviewItemsAction());
            });
    };
}

export function createDuplicateProjectAction(id: string, externalId: string, title: string): Thunk {
    return (dispatch) => {
        const url = createDuplicateProjectApiPath(externalId);

        const action = createAction(DUPLICATE_PROJECT, apiClient.put(url), { id }, { id });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Project '${title}' is gedupliceerd`);
            })
            .finally(() => {
                dispatch(createGetProjectOverviewAction());
                dispatch(createFetchStandardOverviewItemsAction());
            });
    };
}

export function createAllowWorkingGroupMembersWritingAccess(externalId: string, allowed: boolean): Thunk {
    return (dispatch) => {
        const url = createAllowWorkingGroupMembersWritingAccessApiPath(externalId);

        const action = createAction(
            ALLOW_WORKING_GROUP_MEMBER_WRITING_ACCESS,
            apiClient.post(url, { allowed: allowed ? 1 : 0 })
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Schrijfrechten voor werkgroepleden zijn ${allowed ? 'ingeschakeld' : 'uitgeschakeld'}`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalId)));
    };
}

export function createUpdateProjectAction(
    id: string,
    externalId: string,
    title: string,
    shortTitle: string,
    tags: string[]
): Thunk {
    return (dispatch) => {
        const url = createUpdateProjectApiPath(externalId);

        const action = createAction(UPDATE_PROJECT, apiClient.put(url, { title, shortTitle, tags }), {
            id,
            title,
            shortTitle,
            tags,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Je wijzigingen zijn opgeslagen');
            })
            .finally(() => {
                dispatch(createFetchProjectDetailsAction(externalId));
                dispatch(createGetProjectOverviewAction());
            });
    };
}

export function createFetchProjectDetailsAction(externalId: string): Thunk {
    return (dispatch) => {
        const url = createProjectDetailApiPath(externalId),
            action = createAction(FETCH_PROJECT_DETAILS, apiClient.get(url));

        return dispatch(action).catch((error: Error) => {
            if (extractPath('response.status', error) === STATUS_CODE_NOT_FOUND) {
                toast.error('Project niet gevonden of is gesloten!');

                const history = getBrowserHistory();
                history.push(createDashboardProjectTypesOverviewPath());

                return;
            }

            logError(error, {
                action: FETCH_PROJECT_DETAILS,
            });
        });
    };
}

export function createFetchProjectIndexAction(externalId: string): Action {
    const promise = apiClient.get(createProjectIndexApiPath(externalId));

    return createAction(FETCH_PROJECT_INDEX, promise);
}

export function createFetchChapterDetails(projectTypeSlug: string, projectSlug: string, slug: string): Thunk {
    return (dispatch) => {
        const url = createChapterDetailApiPath(projectTypeSlug, projectSlug, slug),
            action = createAction(FETCH_PROJECT_CHAPTER_DETAILS, apiClient.get(url));

        return dispatch(action).catch((error: Error) => {
            if (extractPath('response.status', error) === STATUS_CODE_NOT_FOUND) {
                toast.error('Hoofdstuk niet gevonden!');

                const history = getBrowserHistory();
                history.push(createProjectDetailPath(projectTypeSlug, projectSlug));

                return;
            }

            logError(error, {
                action: FETCH_PROJECT_CHAPTER_DETAILS,
            });
        });
    };
}

export function createFetchProjectDetailsBySlugAction(type: string, slug: string): Thunk {
    return (dispatch) => {
        const url = createProjectDetailBySlugApiPath(type, slug);
        const action = createAction(FETCH_PROJECT_DETAILS, apiClient.get(url));

        return dispatch(action).catch((error: Error) => {
            if (extractPath('response.status', error) === STATUS_CODE_NOT_FOUND) {
                toast.error('Project niet gevonden!');

                const history = getBrowserHistory();
                history.push(createFrontendEntryPath());

                return;
            }

            logError(error, {
                action: FETCH_PROJECT_DETAILS,
            });
        });
    };
}

export function createAddChapterToProjectAction(
    projectId: string,
    externalProjectId: string,
    title: string,
    searchable: boolean
): Thunk {
    return (dispatch) => {
        const url = createAddChapterToProjectApiPath(externalProjectId),
            promise = apiClient.post(url, {
                title,
                searchable: searchable ? 1 : 0,
            });

        const action = createAction(ADD_CHAPTER_TO_PROJECT, promise, {
            title,
            projectId,
            searchable,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Hoofdstuk '${title}' toegevoegd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createArchiveCommentaryForChaptersAction(
    projectId: string,
    externalProjectId: string,
    project: boolean,
    chapterIds: string[]
): Thunk {
    return (dispatch) => {
        const url = createArchiveCommentaryForChaptersApiPath(externalProjectId),
            promise = apiClient.post(url, { chapterIds, project });

        const action = createAction(ARCHIVE_COMMENTS_CHAPTER, promise, {
            projectId,
            externalProjectId,
            project,
            chapterIds,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Commentaar is gearchiveerd');
            })
            .finally(() => {
                dispatch(resetParagraphDetailsAction());
                dispatch(createFetchProjectDetailsAction(externalProjectId));
            });
    };
}

export function createUpdateProjectChapterAction(
    projectId: string,
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    title: string
): Thunk {
    return (dispatch) => {
        const url = createUpdateProjectChapterApiPath(externalProjectId, externalChapterId),
            promise = apiClient.put(url, { title });

        const action = createAction(UPDATE_PROJECT_CHAPTER, promise, { projectId, chapterId, title }, { chapterId });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Hoofdstuk '${title}' gewijzigd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createProjectRemoveChapterAction(
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    title: string
): Thunk {
    return (dispatch) => {
        const url = createProjectRemoveChapterApiPath(externalProjectId, externalChapterId);

        const action = createAction(PROJECT_REMOVE_CHAPTER, apiClient.doDelete(url), { chapterId }, { chapterId });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`Hoofdstuk '${title}' verwijderd`);
        });
    };
}

export function createMoveProjectChapterAction(
    projectId: string,
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    newIndex: number
): Thunk {
    return (dispatch) => {
        const url = createProjectMoveChapterApiPath(externalProjectId, externalChapterId);

        const action = createAction(
            MOVE_PROJECT_CHAPTER,
            apiClient.put(url, { index: newIndex }),
            { projectId, chapterId, newIndex },
            { chapterId }
        );

        return dispatch(action).finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createMoveProjectParagraphAction(
    projectId: string,
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    paragraphId: string,
    externalParagraphId: string,
    newIndex: number
): Thunk {
    return (dispatch) => {
        const url = createProjectMoveParagraphApiPath(externalProjectId, externalChapterId, externalParagraphId);

        const action = createAction(
            MOVE_PROJECT_PARAGRAPH,
            apiClient.put(url, { index: newIndex }),
            { projectId, chapterId, newIndex, paragraphId },
            { paragraphId }
        );

        return dispatch(action).finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createAddParagraphToChapterAction(
    externalProjectId: string,
    projectId: string,
    externalChapterId: string,
    chapterId: string,
    title: string
): Thunk {
    return (dispatch) => {
        const url = createAddParagraphToChapterApiPath(externalChapterId, externalProjectId);

        const action = createAction(ADD_PARAGRAPH_TO_CHAPTER, apiClient.post(url, { title }), {
            projectId,
            chapterId,
            title,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Paragraaf '${title}' toegevoegd`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

const checkParagraphUpdatesAreInProgress = ({ loadingQueue }: GlobalState): boolean =>
    applyIsLoadingSelector(loadingQueue, [
        UPDATE_PROJECT_SUBPARAGRAPH,
        UPDATE_PROJECT_PARAGRAPH,
        ADD_SUB_PARAGRAPH_TO_PARAGRAPH,
        MOVE_SUB_PARAGRAPH,
        PROJECT_REMOVE_SUB_PARAGRAPH,
        PROJECT_EMBED_SUB_PARAGRAPH,
    ]);

export function createFetchProjectParagraphDetailsAction(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    forReadingPurposes: boolean,
    onlyWhenNoUpdatesAreInProgress = false
): Thunk {
    return (dispatch, getState: GetState) => {
        if (onlyWhenNoUpdatesAreInProgress && checkParagraphUpdatesAreInProgress(getState())) {
            return Promise.resolve();
        }

        const url = createParagraphDetailApiPath(
                externalProjectId,
                externalChapterId,
                externalParagraphId,
                forReadingPurposes
            ),
            action = createAction(FETCH_PARAGRAPH_DETAILS, apiClient.get(url));

        return dispatch(action).catch((error: Error) => {
            if (extractPath('response.status', error) === STATUS_CODE_NOT_FOUND) {
                toast.error('Paragraaf niet gevonden of onderdeel van een gesloten project!');

                const history = getBrowserHistory();
                history.push(createDashboardProjectDetailPath(externalProjectId, externalChapterId));

                return;
            }

            logError(error, {
                action: FETCH_PARAGRAPH_DETAILS,
            });
        });
    };
}

export const resetParagraphDetailsAction = () => {
    return (dispatch: Dispatch<any>) => {
        return dispatch({
            type: RESET_PARAGRAPH_DETAILS,
        });
    };
};

export function createUpdateProjectParagraphAction(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    paragraphId: string,
    title: string,
    content: string | null
): Thunk {
    return (dispatch) => {
        const url = createUpdateParagraphApiPath(externalProjectId, externalParagraphId);

        const action = createAction(
            UPDATE_PROJECT_PARAGRAPH,
            apiClient.put(url, { title, content }),
            { paragraphId, title, content },
            { paragraphId }
        );

        return dispatch(action).finally(() => {
            dispatch(
                createFetchProjectParagraphDetailsAction(
                    externalProjectId,
                    externalChapterId,
                    externalParagraphId,
                    false,
                    true
                )
            );
            dispatch(createFetchProjectDetailsAction(externalProjectId));
        });
    };
}

export function createProjectRemoveParagraphAction(
    externalProjectId: string,
    externalChapterId: string,
    chapterId: string,
    externalParagraphId: string,
    paragraphId: string,
    title: string
): Thunk {
    return (dispatch) => {
        const url = createChapterRemoveParagraphApiPath(externalProjectId, externalChapterId, externalParagraphId);

        const action = createAction(
            PROJECT_REMOVE_PARAGRAPH,
            apiClient.doDelete(url),
            { chapterId, paragraphId },
            { paragraphId }
        );

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`Paragraaf '${title}' verwijderd`);
        });
    };
}

export function createAddSubParagraphToParagraphAction(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    paragraphId: string,
    subParagraphId: string,
    title: string,
    content: string | null
): Thunk {
    return (dispatch) => {
        const url = createAddSubParagraphToParagraphApiPath(externalProjectId, externalChapterId, externalParagraphId);

        const action = createAction(
            ADD_SUB_PARAGRAPH_TO_PARAGRAPH,
            apiClient.post(url, { title, content }),
            { paragraphId, subParagraphId, title, content },
            { paragraphId, subParagraphId } // supply sub paragraph id as meta to be able to connect incoming externalId to the internaly already created item
        );

        return dispatch(action).finally(() =>
            dispatch(
                createFetchProjectParagraphDetailsAction(
                    externalProjectId,
                    externalChapterId,
                    externalParagraphId,
                    false,
                    true
                )
            )
        );
    };
}

export function createUpdateSubParagraphAction(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    externalSubParagraphId: string,
    paragraphId: string,
    subParagraphId: string,
    title: string,
    content: string | null
): Thunk {
    return (dispatch) => {
        const url = createUpdateSubParagraphApiApth(externalProjectId, externalSubParagraphId);

        const action = createAction(
            UPDATE_PROJECT_SUBPARAGRAPH,
            apiClient.put(url, { title, content }),
            { paragraphId, subParagraphId, title, content },
            { paragraphId }
        );

        return dispatch(action).finally(() =>
            dispatch(
                createFetchProjectParagraphDetailsAction(
                    externalProjectId,
                    externalChapterId,
                    externalParagraphId,
                    false,
                    true
                )
            )
        );
    };
}

export function createProjectRemoveSubParagraphAction(
    externalProjectId: string,
    _externalChapterId: string,
    chapterId: string,
    externalParagraphId: string,
    paragraphId: string,
    externalSubParagraphId: string,
    subParagraphId: string,
    title: string
): Thunk {
    return (dispatch) => {
        const url = createParagraphRemoveSubParagraphApiPath(
            externalProjectId,
            externalParagraphId,
            externalSubParagraphId
        );

        const action = createAction(
            PROJECT_REMOVE_SUB_PARAGRAPH,
            apiClient.doDelete(url),
            { chapterId, paragraphId, subParagraphId },
            { subParagraphId }
        );

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`Sub-paragraaf '${title}' losgekoppeld`);
        });
    };
}

export function createStartSubParagraphAction(): Action {
    return createAction(START_SUB_PARAGRAPH);
}

export function createRemoveNonPersistedSubParagraphAction(id: string): Action {
    return createAction(REMOVE_NON_PERSISTED_SUB_PARAGRAPH, null, { id });
}

export function createClearProjectDetailsAction(): Action {
    return createAction(CLEAR_PROJECT_DETAILS);
}

export function createParagraphDetailSubParagraphHasChangesChanged(
    subParagraphId: string,
    hasChanges: boolean
): Action {
    return createAction(PARAGRAPH_DETAIL_SUBPARAGRAPH_HAS_CHANGES_CHANGED, null, { subParagraphId, hasChanges });
}

export function createParagraphDetailHasChangesChanged(paragraphId: string, hasChanges: boolean): Action {
    return createAction(PARAGRAPH_DETAIL_HAS_CHANGES_CHANGED, null, {
        paragraphId,
        hasChanges,
    });
}

export function createUpdateProjectPatientIntroductionAction(
    externalProjectId: string,
    introduction: string | null
): Thunk {
    return (dispatch) => {
        const url = createProjectUpdatePatientIntroductionApiPath(externalProjectId);

        const action = createAction(PROJECT_UPDATE_PATIENT_INTRODUCTION, apiClient.put(url, { introduction }), {
            introduction,
        });

        return dispatch(action).then(() => toast.success('De patienten introductie is gewijzigd!'));
    };
}

export function createProjectEmbedChapterAction(
    projectId: string,
    externalProjectId: string,
    externalChapterId: string,
    chapterTitle: string
): Thunk {
    return (dispatch) => {
        const url = createProjectEmbedChapterApiPath(externalProjectId);

        const action = createAction(
            PROJECT_EMBED_CHAPTER,
            apiClient.put(url, { chapterId: externalChapterId }),
            {
                title: chapterTitle,
                projectId,
                searchable: true,
            },
            { id: createUuid() }
        );

        return dispatch(action)
            .then(() => toast.success(`Hoofdstuk snippet '${chapterTitle}' toegevoegd`))
            .catch((error: Error) => {
                const type: string | undefined | null = extractPath('response.data.type', error);

                if (type === 'GGZ\\Domain\\Entity\\Project\\Exception\\ProjectAlreadyContainsChapterException') {
                    toast.error('Het hoofdstuk wat je probeert toe te voegen bestaat al in dit project');

                    return;
                }

                toast.error('Er is iets foutgegaan. Probeer het later nog eens!');

                logError(error, {
                    action: PROJECT_EMBED_CHAPTER,
                });
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createProjectEmbedParagraphAction(
    projectId: string,
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    externalParagraphId: string,
    paragraphTitle: string
): Thunk {
    return (dispatch) => {
        const url = createChapterEmbedParagraphApiPath(externalProjectId, externalChapterId),
            promise = apiClient.put(url, { paragraphId: externalParagraphId });

        const action = createAction(
            PROJECT_EMBED_PARAGRAPH,
            promise,
            {
                title: paragraphTitle,
                projectId,
                chapterId,
            },
            {
                id: createUuid(),
                chapterId,
            }
        );

        return dispatch(action)
            .then(() => toast.success(`Paragraaf snippet '${paragraphTitle}' toegevoegd`))
            .catch((error: Error) => {
                const type: string | undefined | null = extractPath('response.data.type', error);

                if (type === 'GGZ\\Domain\\Entity\\Chapter\\Exception\\ChapterAlreadyContainsParagraphException') {
                    toast.error('De paragraaf die je probeert toe te voegen bestaat al in dit hoofdstuk');

                    return;
                }

                toast.error('Er is iets foutgegaan. Probeer het later nog eens!');

                logError(error, {
                    action: PROJECT_EMBED_PARAGRAPH,
                });
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createProjectEmbedSubParagraphAction(
    projectId: string,
    externalProjectId: string,
    chapterId: string,
    externalChapterId: string,
    paragraphId: string,
    externalParagraphId: string,
    externalSubParagraphId: string,
    subParagraphTitle: string
): Thunk {
    return (dispatch) => {
        const url = createParagraphEmbedSubParagraphApiPath(externalProjectId, externalParagraphId);

        const action = createAction(
            PROJECT_EMBED_SUB_PARAGRAPH,
            apiClient.put(url, { subParagraphId: externalSubParagraphId }),
            { projectId, chapterId, paragraphId }
        );

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Subparagraaf snippet '${subParagraphTitle}' toegevoegd`);
            })
            .finally(() =>
                dispatch(
                    createFetchProjectParagraphDetailsAction(
                        externalProjectId,
                        externalChapterId,
                        externalParagraphId,
                        false,
                        true
                    )
                )
            );
    };
}

export function createMoveSubParagraphAction(
    externalProjectId: string,
    externalChapterId: string,
    externalParagraphId: string,
    subParagraphId: string,
    externalSubParagraphId: string,
    newIndex: number
): Thunk {
    return (dispatch) => {
        const url = createMoveSubParagraphApiPath(externalProjectId, externalParagraphId, externalSubParagraphId);

        const action = createAction(
            MOVE_SUB_PARAGRAPH,
            apiClient.put(url, { index: newIndex }),
            { subParagraphId, newIndex },
            { subParagraphId }
        );

        return dispatch(action).finally(() =>
            dispatch(
                createFetchProjectParagraphDetailsAction(
                    externalProjectId,
                    externalChapterId,
                    externalParagraphId,
                    false,
                    true
                )
            )
        );
    };
}

export function createAddSideProductToProjectAction(
    externalProjectId: string,
    file: any | null,
    title: string,
    type: string,
    toolType: string,
    description: string | null,
    highlightText: string | null,
    resourceUri: string | null,
    visibleOnOverview: boolean
): Thunk {
    return (dispatch) => {
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        if (resourceUri) {
            formData.append('resourceUri', resourceUri);
        }
        formData.append('title', title);
        formData.append('type', type);
        formData.append('toolType', toolType);
        formData.append('description', description || '');
        formData.append('highlightText', highlightText || '');
        formData.append('visibleOnOverview', String(visibleOnOverview));

        const url = createProjectAddSideProductApiPath(externalProjectId);

        const action = createAction(PROJECT_ADD_SIDE_PRODUCT, apiClient.post(url, formData), { title, type });

        return dispatch(action).finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createUpdateProjectSideProductAction(
    externalProjectId: string,
    id: string,
    title: string,
    file: File | null,
    type: ProjectSideProductType,
    toolType: ProjectSideProductToolType,
    description: string | null,
    highlightText: string | null,
    resourceUri: string | null,
    visibleOnOverview: boolean
): Thunk {
    return (dispatch) => {
        const data = {
            title,
            file,
            resourceUri,
            type,
            toolType,
            description,
            highlightText,
            visibleOnOverview,
        };
        const apiPath = createProjectUpdateSideProductApiPath(externalProjectId, id);

        const promise = apiClient.post(apiPath, data);

        const action = createAction(PROJECT_UPDATE_SIDE_PRODUCT, promise, data, { id });

        return dispatch(action).finally(() => {
            dispatch(createFetchProjectDetailsAction(externalProjectId));
        });
    };
}

export function createMoveProjectSideProductAction(externalProjectId: string, id: string, newIndex: number): Thunk {
    return (dispatch) => {
        const data = { newIndex };

        const apiPath = createProjectMoveSideProductApiPath(externalProjectId, id);

        const promise = apiClient.put(apiPath, data);

        const action = createAction(PROJECT_MOVE_SIDE_PRODUCT, promise, data, {
            id,
            newIndex,
        });

        return dispatch(action);
    };
}

export function createRemoveSideProductFromProjectAction(externalProjectId: string, sideProductId: string): Thunk {
    return (dispatch) => {
        const url = createProjectRemoveSideProductApiPath(externalProjectId, sideProductId);

        const action = createAction(PROJECT_REMOVE_SIDE_PRODUCT, apiClient.doDelete(url), { sideProductId });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Het verwijderen is voltooid');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createMarkChapterSearchableAction(externalProjectId: string, externalChapterId: string): Thunk {
    return (dispatch) => {
        const url = createMarkChapterSearchableApiPath(externalChapterId);

        const action = createAction(MARK_CHAPTER_SEARCHABLE, apiClient.put(url), {
            externalChapterId,
        });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('De aanpassing is doorgevoerd');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createMarkChapterNotSearchableAction(externalProjectId: string, externalChapterId: string): Thunk {
    return (dispatch) => {
        const url = createMarkChapterNotSearchableApiPath(externalChapterId);

        const action = createAction(MARK_CHAPTER_NOT_SEARCHABLE, apiClient.put(url), { externalChapterId });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('De aanpassing is doorgevoerd');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRequestFeedbackFromSoundingBoardMembersAction(externalProjectId: string, text: string): Thunk {
    return (dispatch) => {
        const url = createRequestFeedbackFromSoundingBoardMembersApiPath(externalProjectId),
            action = createAction(REQUEST_FEEDBACK_FROM_SOUNDING_BOARD_MEMBERS, apiClient.post(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Je verzoek om feedback is verstuurd naar de klankbordgroepleden');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRequestFeedbackFromExternalPartyMembersAction(externalProjectId: string, text: string): Thunk {
    return (dispatch) => {
        const url = createRequestFeedbackFromExternalPartyMembersApiPath(externalProjectId),
            action = createAction(REQUEST_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS, apiClient.post(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Je verzoek om feedback is verstuurd naar de externe partijen');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemindFeedbackFromExternalPartyMembersAction(externalProjectId: string, text: string): Thunk {
    return (dispatch) => {
        const url = createRemindFeedbackFromExternalPartyMembersApiPath(externalProjectId),
            action = createAction(REMIND_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS, apiClient.post(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Je herinnering voor feedback is verstuurd naar de externe partijen');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRequestFeedbackFromWorkingGroupMembersAction(externalProjectId: string, text: string): Thunk {
    return (dispatch) => {
        const url = createRequestFeedbackFromWorkingGroupMembersApiPath(externalProjectId),
            action = createAction(REQUEST_FEEDBACK_FROM_WORKING_GROUP_MEMBERS, apiClient.post(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Je verzoek om feedback is verstuurd naar de werkgroepleden');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createStopConsultationPhaseForProjectAction(externalProjectId: string): Thunk {
    return (dispatch) => {
        const url = createStopConsultationPhaseForProjectApiPath(externalProjectId),
            action = createAction(PROJECT_STOP_CONSULTATION_PHASE, apiClient.put(url));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('De consultatie fase is stop gezet, externe partijen kunnen niet langer reageren.');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRequestAuthorizationFromExternalPartyMembersAction(
    externalProjectId: string,
    text: string
): Thunk {
    return (dispatch) => {
        const url = createRequestAuthorizationFromExternalPartyMembersApiPath(externalProjectId),
            action = createAction(REQUEST_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS, apiClient.post(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('Het verzoek om autorisatie is verstuurd.');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemindAuthorizationFromExternalPartyMembersAction(
    externalProjectId: string,
    text: string
): Thunk {
    return (dispatch) => {
        const url = createRemindAuthorizationFromExternalPartyMembersApiPath(externalProjectId),
            action = createAction(REMIND_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS, apiClient.put(url, { text }));

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success('De herinnering is verstuurd');
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createUpdateProjectLinkAction(
    externalProjectId: string,
    id: string,
    title: string,
    url: string,
    type: ProjectDetailLinkType,
    description: string | null,
    highlightText: string | null,
    toolType: ProjectDetailLinkToolType
): Thunk {
    return (dispatch) => {
        const data = { title, url, type, description, highlightText, toolType };
        const apiPath = createProjectUpdateLinkApiPath(externalProjectId, id);

        const promise = apiClient.put(apiPath, data);

        const action = createAction(PROJECT_UPDATE_LINK, promise, data, { id });

        return dispatch(action).finally(() => {
            dispatch(createFetchProjectDetailsAction(externalProjectId));
        });
    };
}

export function createAddLinkToProjectAction(
    externalProjectId: string,
    title: string,
    url: string,
    type: ProjectDetailLinkType,
    description: string | null,
    highlightText: string | null,
    toolType: ProjectDetailLinkToolType
): Thunk {
    return (dispatch) => {
        const id = createUuid();

        const data = { id, title, url, type, description, highlightText, toolType };

        const promise = apiClient.post(createProjectAddLinkToProjectApiPath(externalProjectId), data);

        const action = createAction(PROJECT_ADD_LINK, promise, data, {
            id,
        });

        return dispatch(action).finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createRemoveLinkFromProjectAction(externalProjectId: string, externalLinkId: string): Thunk {
    return (dispatch) => {
        const promise = apiClient.doDelete(createRemoveLinkFromProjectApiPath(externalProjectId, externalLinkId));

        const action = createAction(
            PROJECT_REMOVE_LINK,
            promise,
            {
                externalProjectId,
                externalLinkId,
            },
            {
                externalProjectId,
                externalLinkId,
            }
        );

        return dispatch(action).finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function createSetLastSearchedItemAction(
    type: ProjectSearchItemType,
    id: string,
    matchedSearchTerms: Array<string>
): Action {
    return createAction(SET_LAST_SEARCHED_ITEM, null, {
        type,
        id,
        matchedSearchTerms,
    });
}

export function authorizeProjectAction(
    externalProjectId: string,
    authorization: string,
    authorizationRemark: string | null
): Thunk {
    return (dispatch) => {
        const data = { authorization, authorizationRemark };

        const promise = apiClient.put(createAuthorizeProjectApiPath(externalProjectId), data);

        const action = createAction(AUTHORIZE_PROJECT, promise, data);

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(
                    `Je reactie ('${
                        authorizationStatusTypeLabels[authorization as AuthorizationStatusType]
                    }') voor autorisatie is verstuurd`
                );
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}

export function editAuthorizationStatusForMemberAction(
    externalProjectId: string,
    userId: string,
    authorization: string,
    authorizationRemark: string | null,
    setSubmitting: (isSubmitting: boolean) => void
): Thunk {
    return (dispatch) => {
        const data = { userId, authorization, authorizationRemark };

        const promise = apiClient.put(createEditAuthorizationStatusForMemberApiPath(externalProjectId), data);

        const action = createAction(EDIT_AUTHORIZATION_STATUS, promise, data);

        return dispatch(action)
            .then(() => {
                setSubmitting(false);
                toast.success(`Je wijzigingen aan de autorisatie status zijn opgeslagen.`);
            })
            .finally(() => dispatch(createFetchProjectDetailsAction(externalProjectId)));
    };
}
