import * as apiClient from '../api/client/apiClient';
import { createAction, Thunk } from './factory';
import {
    ARCHIVE_STANDARD,
    FETCH_STANDARD_ALL_OVERVIEW,
    FETCH_STANDARD_OVERVIEW,
    START_PROJECT,
    ADD_RECOMMENDED_STANDARDS,
} from './types';
import type { Action } from './factory';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { extractErrorTypeFromApiError } from '../helper/apiErrorHelper';
import { logError } from '../utility/Logger';
import { createGetProjectOverviewAction } from './projectActionFactory';
import {
    createStandardAllOverviewApiPath,
    createStandardArchiveApiPath,
    createStandardOverviewApiPath,
    createStartRevisionProjectApiPath,
    createAddRecommendedStandardsApiPath,
} from '../routing/apiUrlGenerator';

export function createFetchStandardOverviewItemsAction(): Action {
    const promise: Promise<any> = apiClient.get(createStandardOverviewApiPath());

    return createAction(FETCH_STANDARD_OVERVIEW, promise);
}

export function createFetchStandardAllOverviewItemsAction(): Action {
    const promise: Promise<any> = apiClient.get(createStandardAllOverviewApiPath());

    return createAction(FETCH_STANDARD_ALL_OVERVIEW, promise);
}

export function createStandardArchiveAction(id: string, externalId: string, title: string): Thunk {
    return (dispatch) => {
        const url = createStandardArchiveApiPath(externalId);

        const action = createAction(ARCHIVE_STANDARD, apiClient.post(url), { id }, { id });

        return dispatch(action)
            .then((response: Object) => {
                if (response instanceof Error) {
                    return;
                }

                toast.success(`Standaard '${title}' is gearchiveerd`);
            })
            .finally(() => {
                dispatch(createFetchStandardAllOverviewItemsAction());
            });
    };
}

export function createStartRevisionStandardAction(standardId: string): Thunk {
    return (dispatch, getState) => {
        const standardOverviewItems = getState().standardAllOverviewItems;

        if (!Array.isArray(standardOverviewItems)) {
            throw new Error('No standard overview items found to start revision on');
        }

        // find existing standard overview item in global state to get required data for optimistic updating
        const standardOverviewItem = standardOverviewItems.find((item) => item.externalId === standardId);

        if (!standardOverviewItem) {
            toast.error('De standaard die je probeert te herzien kan niet meer gevonden worden. Probeer het nog eens.');

            return;
        }

        const action = createAction(START_PROJECT, apiClient.post(createStartRevisionProjectApiPath(standardId)), {
            title: standardOverviewItem.title,
            type: standardOverviewItem.type,
            revision: true,
        });

        return dispatch(action)
            .then(() => toast.success(`Er is een nieuw project gestart voor standaard '${standardOverviewItem.title}'`))
            .catch((error: AxiosError) => {
                const type = extractErrorTypeFromApiError(error);
                let errorMessage;

                // noinspection JSRedundantSwitchStatement
                switch (type) {
                    case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                        errorMessage =
                            'De standaard die je probeert te herzien kan niet meer gevonden worden. Probeer het nog eens.';
                        break;

                    default:
                        logError(error);
                        errorMessage = 'Er is iets onbekends fout gegaan. Probeer het later nog eens!';
                }

                toast.error(errorMessage);
            })
            .finally(() => {
                dispatch(createGetProjectOverviewAction());
                dispatch(createFetchStandardAllOverviewItemsAction());
            });
    };
}

export function createAddRecommendedStandardsAction(standardId: string, standardIds: string[]): Thunk {
    return (dispatch) => {
        const action = createAction(
            ADD_RECOMMENDED_STANDARDS,
            apiClient.post(createAddRecommendedStandardsApiPath(standardId), { standardIds }),
            { standardIds }
        );

        return dispatch(action)
            .then(() => toast.success('De aanbevolen standaarden zijn gewijzigd.'))
            .catch((error: AxiosError) => {
                logError(error);
                const errorMessage =
                    'Er is iets fout gegaan bij het wijzigen van de aanbevolen standaarden. Probeer het later nog eens!';
                toast.error(errorMessage);
            })
            .finally(() => {
                dispatch(createFetchStandardAllOverviewItemsAction());
            });
    };
}
