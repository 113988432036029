import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import { Dropdown } from 'semantic-ui-react';
import CloseProjectThreshold from './components/CloseProjectThreshold';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import useDispatchCloseProjectActionOnConfirm from './hooks/useDispatchCloseProjectActionOnConfirm';
import ProjectOverviewItem from '../../../model/projectOverview/ProjectOverviewItem';

type Props = {
    item: ProjectOverviewItem;
};

const CloseProject = ({ item }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const { onConfirm } = useDispatchCloseProjectActionOnConfirm(item);

    if (!item.externalId) {
        return null;
    }

    return (
        <CloseProjectThreshold externalId={item.externalId}>
            {showModal ? (
                <ConfirmationModal title="Weet je het zeker?" onConfirm={onConfirm} onDecline={() => doHideModal()}>
                    <p>Deze actie kan niet meer ongedaan gemaakt worden.</p>
                </ConfirmationModal>
            ) : (
                <Dropdown.Item text="Project sluiten" onClick={() => doShowModal()} className="icon icon-cancel" />
            )}
        </CloseProjectThreshold>
    );
};

export default CloseProject;
