import React, { FC, ReactNode } from 'react';
import styles from '../ForgotPassword.module.scss';
import { Link } from 'react-router-dom';
import { createFrontendEntryPath } from '../../../../../routing/urlGenerator';
import Logo from '../../../../../../images/skilz/skilz-logo.svg';

type Props = {
    children: ReactNode;
};

const ForgotPasswordWrapper: FC<Props> = ({ children }) => (
    <div className={styles.page}>
        <div className="container">
            <div className={styles.logoWrapper}>
                <Link to={createFrontendEntryPath()}>
                    <Logo />
                </Link>
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.island}>{children}</div>
        </div>
    </div>
);

export default ForgotPasswordWrapper;
