import { createUuid } from '../../../utility/idGenerator';
import ParagraphDetailSubParagraph from '../ParagraphDetailSubParagraph';
import ParagraphDetailLastUpdatedInformation from '../ParagraphDetailLastUpdatedInformation';
import { createParagraphDetailLastUpdatedInformationFromApiInput } from './paragraphDetailLastUpdatedInformationFactory';
import { formatISO } from 'date-fns';

function _createParagraphDetailSubParagraphFromApiInput(apiInput: any): ParagraphDetailSubParagraph {
    return new ParagraphDetailSubParagraph(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.content,
        apiInput.contentType,
        apiInput.in_paragraphs,
        apiInput.index,
        apiInput.comments.count,
        apiInput.comments.answered_count,
        createParagraphDetailLastUpdatedInformationFromApiInput(apiInput.last_updated_information)
    );
}

export function createEmptyParagraphDetailSubParagraph(): ParagraphDetailSubParagraph {
    return new ParagraphDetailSubParagraph(
        createUuid(),
        null,
        null,
        null,
        'lexical',
        [],
        0,
        0,
        0,
        new ParagraphDetailLastUpdatedInformation(formatISO(new Date()), null)
    );
}

export function createParagraphDetailSubParagraphCollectionFromApiInput(
    apiInputItems: Array<any>
): Array<ParagraphDetailSubParagraph> {
    return apiInputItems.map((apiInput) => _createParagraphDetailSubParagraphFromApiInput(apiInput));
}
