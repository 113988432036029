import Reference from '../Reference';
import { createUuid } from '../../utility/idGenerator';
import type { ReferenceListResponse, ReferenceResponse } from '../../api/response/types';

export function createReferenceFromApiInput(apiInput: ReferenceResponse): Reference {
    return new Reference(createUuid(), apiInput.id, apiInput.description, apiInput.archived);
}

export function createReferenceCollectionFromApiInput(apiInput: ReferenceListResponse): Array<Reference> {
    return apiInput.map((apiInputItem) => createReferenceFromApiInput(apiInputItem));
}
