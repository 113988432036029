import * as React from 'react';
import { PROJECT_DUPLICATE } from '../../../../security/SecurityAttribute';
import { SecuritySubject } from '../../../../security/SecuritySubjectType';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';

type Props = {
    children: React.ReactNode;
    externalId: string;
};

const DuplicateProjectThreshold = ({ children, externalId }: Props) => (
    <SecurityThreshold requiredAttributes={PROJECT_DUPLICATE} subjectType={SecuritySubject.project} subjectId={externalId}>
        {children}
    </SecurityThreshold>
);

export default DuplicateProjectThreshold;
