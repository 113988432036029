import { ActionType } from 'redux-promise-middleware';
import { FETCH_PROJECT_INDEX } from '../actions/types';
import { createProjectIndexFromApiInput } from '../model/ProjectIndex/factory/projectIndexFactory';
import ProjectIndex from '../model/ProjectIndex/ProjectIndex';

import type { Action } from '../actions/factory';

export type ProjectIndexReducerState = ProjectIndex | null;

const DEFAULT_STATE: ProjectIndexReducerState = null;

export default function (
    currentState: ProjectIndexReducerState = DEFAULT_STATE,
    action: Action
): ProjectIndexReducerState {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case `${FETCH_PROJECT_INDEX}_${ActionType.Fulfilled}`:
            return createProjectIndexFromApiInput(action.payload.data);

        default:
            return currentState;
    }
}
