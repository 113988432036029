import { BranchOverviewItem } from '../model/branchOverview/BranchOverviewItem';
import { Action } from '../actions/factory';
import { FETCH_BRANCH_OVERVIEW } from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { createBranchOverviewItemCollectionFromApiData } from '../components/dashboard/branchOverview/factory/branchOverviewItemFactory';

export type BranchOverviewReducerState = Array<BranchOverviewItem> | undefined | null;

export default function (currentState: BranchOverviewReducerState = null, action: Action): BranchOverviewReducerState {
    switch (action.type) {
        case `${FETCH_BRANCH_OVERVIEW}_${ActionType.Fulfilled}`:
            // @ts-ignore -> typescript does not know action contents
            return createBranchOverviewItemCollectionFromApiData(action.payload.data.items);

        default:
            return currentState;
    }
}
