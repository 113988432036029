import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    createFrontendEntryPath,
    createExplainStandardTypesPagePath,
    createDisclaimerPagePath,
    createPrivacyAndCookiesPagePath,
    createArticleOverviewPath,
    createStandardsOverviewPagePath,
} from '../../../../routing/urlGenerator';
import ListIcon from '../../../../../../icons/ggz/list.svg';
import SideProductsIcon from '../../../../../../icons/ggz/side-products.svg';
import ForTeachersIcon from '../../../../../../icons/ggz/for-teachers.svg';
import LoginButton from './LoginButton';
import { createForTeachersPagePath, createSideProductsOverviewPagePath } from '../../../../routing/ggzUrlGenerator';

const MainMenu = () => {
    // local state is only used for aria at this moment. We toggle a global class to change visibility
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toggleNavigationOverlay = () => {
        const wrapperElement = document.querySelector('.wrapper');

        if (wrapperElement) {
            wrapperElement.classList.toggle('mobile-nav-is-active');
            setIsVisible(!isVisible);
        }
    };

    return (
        <>
            <nav className="header-overlay-nav" id="header-overlay-nav" aria-hidden={!isVisible}>
                <div className="header-overlay-nav__holder">
                    <div className="header-overlay-nav__wrapper header-overlay-nav__wrapper--secondary">
                        <ul className="header-overlay-nav__primary">
                            <li>
                                <NavLink
                                    exact
                                    className="header-overlay-nav__link visible visisble--until-lg"
                                    activeClassName="is-active"
                                    to={createStandardsOverviewPagePath()}
                                    onClick={toggleNavigationOverlay}
                                >
                                    <ListIcon className="icon icon--md" />
                                    Zorgstandaarden A-Z
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    className="header-overlay-nav__link visible visisble--until-lg"
                                    activeClassName="is-active"
                                    to={createSideProductsOverviewPagePath()}
                                    onClick={toggleNavigationOverlay}
                                >
                                    <SideProductsIcon className="icon icon--md" />
                                    Hulpmiddelen
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    className="header-overlay-nav__link visible visisble--until-lg"
                                    activeClassName="is-active"
                                    to={createForTeachersPagePath()}
                                    onClick={toggleNavigationOverlay}
                                >
                                    <ForTeachersIcon className="icon icon--md" />
                                    Voor docenten
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="header-overlay-nav__wrapper">
                        <ul className="header-overlay-nav__secondary">
                            <li>
                                <LoginButton isSecondary onClick={toggleNavigationOverlay} />
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={createFrontendEntryPath()}
                                    activeClassName="is-active"
                                    onClick={toggleNavigationOverlay}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={createExplainStandardTypesPagePath()}
                                    activeClassName="is-active"
                                    onClick={toggleNavigationOverlay}
                                >
                                    Over zorgstandaarden
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={createArticleOverviewPath()}
                                    activeClassName="is-active"
                                    onClick={toggleNavigationOverlay}
                                >
                                    Actueel
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="header-overlay-nav__tertiary">
                            <li>
                                <NavLink
                                    to={createDisclaimerPagePath()}
                                    activeClassName="is-active"
                                    onClick={toggleNavigationOverlay}
                                >
                                    Algemene voorwaarden
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={createPrivacyAndCookiesPagePath()}
                                    activeClassName="is-active"
                                    onClick={toggleNavigationOverlay}
                                >
                                    Privacy- en cookiebeleid
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div
                className="header-navigation-trigger"
                onClick={toggleNavigationOverlay}
                aria-expanded={isVisible}
                aria-controls="header-overlay-nav"
            >
                <span className="header-navigation-trigger__hamburger" />
            </div>
        </>
    );
};

export default MainMenu;
