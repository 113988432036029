import { useEffect, useState } from 'react';
import { PaginatedCollectionType } from '../model/collection/paginatedCollection';
import { logError } from '../utility/Logger';
import { toast } from 'react-toastify';
import { findAllForPage } from '../repository/faqRepository';
import { Faq } from '../model/Faq';

type OnErrorCallback = (error: any) => void;

type Filters = {
    page: number;
    standardId?: string;
    perPage?: number;
    includeUnpublished?: boolean;
};

export default function useRefetchFaqItemsOnFilterChange(
    { page, perPage }: Filters,
    enabled: boolean = true,
    onError?: OnErrorCallback
) {
    const [faqItems, setFaqItems] = useState<PaginatedCollectionType<Faq> | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchCurrentPage = async () => {
        setIsFetching(true);
        setFaqItems((currentState) => {
            if (!currentState) {
                return null;
            }

            return {
                ...currentState,
                results: [],
            };
        });

        try {
            const faqItems = await findAllForPage(page, perPage || null);

            setFaqItems(faqItems);
        } catch (error) {
            logError(error);

            toast.error(
                'Er is iets foutgegaan bij het ophalen van de veelgestelde vragen. Probeer het later nog eens!'
            );

            if (onError) {
                onError(error);
            }
        }
    };

    useEffect(() => {
        if (!enabled) {
            return;
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchCurrentPage();
    }, [page, enabled]);

    return { fetchCurrentPage, isFetching, faqItems };
}
