import React, { FocusEventHandler, KeyboardEventHandler } from 'react';
import { Formik } from 'formik';
import FormErrorMessage from '../../../../shared/form/FormErrorMessage';
import FormGroup from '../../../../shared/form/FormGroup';
import { validateObject } from '../../../../../validation/validator';
import { createAnonymousFeedbackConstraintSet } from '../../../../../validation/constraint/Factory/ProjectConstraintSetFactory';
import ValidationResultSet from '../../../../../validation/model/validationResultSet';
import Button, { SUBMIT, PRIMARY, LINK, BUTTON } from '../../../../shared/buttons/Button';
import TextareaAutosize from 'react-textarea-autosize';
import { checkIsEventThatWasInitiatedByButtonClick } from '../../../../../lib/forms/helpers/eventHelper';
import FormText from '../../../../shared/form/FormText';

type Props = {
    onSubmittedAndValid: (values: FormValues) => void;
    onCancelClick: () => void;
};

export type FormValues = {
    feedback: string;
    email: string;
};

class AnonymousFeedbackForm extends React.Component<Props> {
    static _validateFormInput(values: FormValues) {
        // make sure that e-mail field, which is optional, has null as value when nothing is filled in. Otherwise it is
        // considered filled-in by validate.js and validated against e-mail pattern
        const normalizedValues = {
            ...values,
            email: values.email === '' ? null : values.email,
        };

        return validateObject(normalizedValues, createAnonymousFeedbackConstraintSet()).then(
            (resultSet: ValidationResultSet) => {
                if (!resultSet.containsErrors) {
                    return {};
                }

                return {
                    feedback: resultSet.errorMessages.feedback ? resultSet.errorMessages.feedback.pop() : undefined,
                    email: resultSet.errorMessages.email ? resultSet.errorMessages.email.pop() : undefined,
                };
            }
        );
    }

    _onSubmit = (values: FormValues) => {
        this.props.onSubmittedAndValid(values);
    };

    _onFieldKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === 'Escape') {
            this.props.onCancelClick();
        }

        return true;
    };

    render() {
        const initialValues: FormValues = {
            feedback: '',
            email: '',
        };

        return (
            <Formik<FormValues>
                initialValues={initialValues}
                onSubmit={this._onSubmit}
                validate={AnonymousFeedbackForm._validateFormInput}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid }) => {
                    const handleBlurButOnlyWhenNotSubmnittingOrCancelling: FocusEventHandler = (event) => {
                        if (!checkIsEventThatWasInitiatedByButtonClick(event)) {
                            handleBlur(event);
                        }
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <FormGroup hasErrors={touched.feedback && !!errors.feedback} key="feedback">
                                <label htmlFor="feedback">Feedback</label>
                                <TextareaAutosize
                                    className="form__field form__field--solid"
                                    id="feedback"
                                    onChange={handleChange}
                                    onBlur={handleBlurButOnlyWhenNotSubmnittingOrCancelling}
                                    value={values.feedback}
                                    minRows={3}
                                    name="feedback"
                                    autoFocus
                                    onKeyDown={this._onFieldKeyDown}
                                />
                                {errors.feedback && touched.feedback && (
                                    <FormErrorMessage>{errors.feedback}</FormErrorMessage>
                                )}
                            </FormGroup>
                            <FormGroup hasErrors={touched.email && !!errors.email} key="email">
                                <label htmlFor="email">E-mailadres (optioneel)</label>
                                <input
                                    type="text"
                                    className="form__field form__field--solid"
                                    id="email"
                                    onChange={handleChange}
                                    onKeyDown={this._onFieldKeyDown}
                                    onBlur={handleBlurButOnlyWhenNotSubmnittingOrCancelling}
                                    value={values.email}
                                    name="email"
                                />
                                <FormText>
                                    Het invullen van je e-mailadres stelt ons in staat om contact met je op te nemen
                                    voor meer informatie. Daarnaast biedt het ons ook de mogelijkheid om je laten weten
                                    wat we met je feedback doen. Het e-mailadres zal nergens anders voor gebruikt
                                    worden.
                                </FormText>
                                {errors.email && touched.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                            </FormGroup>
                            <Button type={SUBMIT} style={PRIMARY} isSmall isDisabled={!isValid}>
                                Verstuur
                            </Button>
                            <Button type={BUTTON} style={LINK} isSmall onClick={this.props.onCancelClick}>
                                Annuleren
                            </Button>
                        </form>
                    );
                }}
            </Formik>
        );
    }
}

export default AnonymousFeedbackForm;
