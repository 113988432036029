import React, { FC } from 'react';
import Heading from '../../../shared/heading/Heading';
import SearchForm, { SearchFormStyle, SearchIconPlacement } from '../../shared/searchForm/SearchForm';
import { IoSearchOutline } from 'react-icons/io5';
import useSearchUrlParams from '../../shared/searchResults/hooks/useSearchUrlParams';
import useFetchSearchResultsOnFilterChange from '../../shared/searchResults/hooks/useFetchSearchResultsOnFilterChange';
import useUpdateUrlOnFilterChange from '../../shared/searchResults/hooks/useUpdateUrlOnFilterChange';
import useTrackVisitedPageTypeForSearch from '../../shared/chapterDetail/hooks/useTrackVisitedPageTypeForSearch';
import Loader from '../../../shared/Loader';
import CollectionPagination from '../../shared/CollectionPagination';
import styles from './GlobalSearchResults.module.scss';
import SearchResultOverview from './components/SearchResultOverview';

const GlobalSearchResults: FC = () => {
    const { query, page } = useSearchUrlParams();
    const { isLoading, nestedResults, toplevelResults } = useFetchSearchResultsOnFilterChange(query, page);
    const { onFormSubmit, onPageChange } = useUpdateUrlOnFilterChange(query);

    useTrackVisitedPageTypeForSearch(query, page);

    return (
        <>
            <div>
                <div className={styles.headingContainer}>
                    <Heading tag="h1" flatten>
                        Zoekresultaten
                    </Heading>
                </div>
                <SearchForm
                    className={styles.searchForm}
                    onSubmit={onFormSubmit}
                    query={query}
                    placeholder="Bijv. slapen medicatie"
                    buttonClassName="button button--primary"
                    buttonText="Zoeken"
                    style={SearchFormStyle.light}
                    searchIconPlacement={SearchIconPlacement.field}
                    hasAutoFocus={query.length === 0}
                    icon={<IoSearchOutline className="form__field-icon" />}
                />
            </div>
            <section className="section section--light">
                {isLoading && <Loader />}
                <div className="container">
                    <SearchResultOverview
                        nestedResults={nestedResults}
                        toplevelResults={toplevelResults}
                        isLoading={isLoading}
                    />
                    <CollectionPagination collection={nestedResults} currentPage={page} onPageChange={onPageChange} />
                </div>
            </section>
        </>
    );
};

export default GlobalSearchResults;
