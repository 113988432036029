import * as React from 'react';
import { ARTICLE_CREATE } from '../../../../security/SecurityAttribute';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';
import CreateArticleLink from './CreateArticleLink';

type Props = {
    children: React.ReactElement<typeof CreateArticleLink>;
};

const CreateArticleSecurityThreshold = ({ children }: Props) => (
    <SecurityThreshold requiredAttributes={ARTICLE_CREATE}>{children}</SecurityThreshold>
);

export default CreateArticleSecurityThreshold;
