import { extractPathWithPlaceholdersFromRoute } from './utilities/pathWithPlaceholdersExtractor';
import { createQueryString } from './utilities/queryStringGenerator';
import { generatePath } from './urlGenerator';

const DASHBOARD_ROUTE_PREFIX = '/dashboard'; // Make sure this matches the value in ProjectBundle::routing/frontend.yml!!

export function createDashboardEntryPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}`;
}

export function createDashboardUserAccountPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/account`;
}

export function createBookmarksEntryPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/favorieten`;
}

export function createDashboardProjectDetailPath(externalProjectId?: string, externalChapterId?: string): string {
    const routeName = 'dashboard_project_detail';

    if (!externalProjectId) {
        return extractPathWithPlaceholdersFromRoute(routeName);
    }

    const hash = externalChapterId ? `#${externalChapterId}` : '';

    return generatePath(routeName, { projectId: externalProjectId }) + hash;
}

export function createDashboardParagraphDetailPath(
    externalProjectId?: string,
    externalChapterId?: string,
    externalParagraphId?: string
): string {
    const routeName = 'dashboard_paragraph_detail';

    if (externalProjectId && externalChapterId && externalParagraphId) {
        return generatePath(routeName, {
            projectId: externalProjectId,
            chapterId: externalChapterId,
            paragraphId: externalParagraphId,
        });
    }

    return extractPathWithPlaceholdersFromRoute(routeName);
}

export function createDashboardProjectTypesOverviewPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/projecten`;
}

export function createDashboardStandardOverviewPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/standaarden`;
}

export function createReferenceOverviewPath(page: string | number = ':page'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/referenties/${page}`;
}

export function createDashboardUpdateReferencePath(
    externalReferenceId: number | string = ':externalReferenceId'
): string {
    return `${DASHBOARD_ROUTE_PREFIX}/referenties/bijwerken/${externalReferenceId}`;
}

export function createDashboardArticleOverviewPath(page?: number): string {
    const queryString = page ? createQueryString({ page }) : '';

    return `${DASHBOARD_ROUTE_PREFIX}/artikelen${queryString}`;
}

export function createDashboardArticleCreatePath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/artikelen/toevoegen`;
}

export function createDashboardArticleUpdatePath(id: number | string = ':id'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/artikelen/wijzigen/${id}`;
}

export function createDashboardSideProductCategoriesOverviewPath(page?: number): string {
    const queryString = page ? createQueryString({ page }) : '';

    return `${DASHBOARD_ROUTE_PREFIX}/hulpmiddelen${queryString}`;
}

export function createDashboardSideProductCategoriesUpdatePath(id: number | string = ':id'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/hulpmiddelen/wijzigen/${id}`;
}

export function createDashboardBranchOverviewPath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/partijen`;
}

export function createDashboardUpdateBranchPath(id = ':id'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/partijen/bijwerken/${id}`;
}

export function createDashboardUsersOverviewPath(page: string | number = ':page'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/gebruikers/${page}`;
}

export function createDashboardUpdateUserPath(externalUserId = ':externalUserId'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/gebruikers/bijwerken/${externalUserId}`;
}

export function createDashboardPageOverviewPath(page?: number) {
    const queryString = page ? createQueryString({ page }) : '';

    return `${DASHBOARD_ROUTE_PREFIX}/paginas${queryString}`;
}

export function createDashboardPageCreatePath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/paginas/toevoegen`;
}

export function createDashboardPageUpdatePath(id: number | string = ':id'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/paginas/wijzigen/${id}`;
}

export function createDashboardFaqOverviewPath(page?: number): string {
    const queryString = page ? createQueryString({ page }) : '';

    return `${DASHBOARD_ROUTE_PREFIX}/veelgestelde-vragen${queryString}`;
}

export function createDashboardFaqCreatePath(): string {
    return `${DASHBOARD_ROUTE_PREFIX}/veelgestelde-vragen/toevoegen`;
}

export function createDashboardFaqUpdatePath(id: number | string = ':id'): string {
    return `${DASHBOARD_ROUTE_PREFIX}/veelgestelde-vragen/wijzigen/${id}`;
}
