import { isObject } from 'lodash';
import { EditorState, ContentState } from 'draft-js';
import { logError } from '../../../../utility/Logger';
import toContentStateTransformer from './stringToDraftEditorContentStateTransformer';
import type { EditorStateTransformerType } from './types';

function transform(content?: string | null): EditorState {
    const contentState = toContentStateTransformer.transformString(content);

    if (!(contentState instanceof ContentState)) {
        return EditorState.createEmpty();
    }

    return EditorState.createWithContent(contentState);
}

function reverseTransform(editorState: EditorState): string | null {
    if (!isObject(editorState) || !(editorState instanceof EditorState)) {
        logError(new Error('No EditorState supplied to reverseTransform. Supplied: '), { editorState });

        return null;
    }

    return editorState.getCurrentContent().getPlainText();
}

const exportObject: EditorStateTransformerType = {
    transform,
    reverseTransform,
};

export default exportObject;
