import React from 'react';
import { Link } from 'react-router-dom';
import { createFrontendEntryPath } from '../../../../../routing/urlGenerator';
import Page from '../../../shared/page/Page';
import DefaultHeader from '../../../../shared/header/DefaultHeader';
import ContactTeaser from '../../shared/ContactTeaser/ContactTeaser';
import Footer from '../../shared/Footer';

const PageNotFound = () => (
    <>
        <DefaultHeader />
        <Page title="Sorry, we kunnen deze pagina niet vinden">
            <h2>Page not found</h2>
            <p>
                We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd
                is. Je kunt natuurlijk altijd naar de <Link to={createFrontendEntryPath()}>homepage</Link> of de
                zoekfunctie gebruiken.
            </p>
        </Page>
        <ContactTeaser />
        <Footer />
    </>
);

export default PageNotFound;
