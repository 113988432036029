import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createFrontendEntryPath } from '../routing/urlGenerator';
import { toast } from 'react-toastify';

/**
 * Wraps a component and forms a gateway to make sure that the component is only displayed
 * if the user is authenticated.
 *
 * @param {React.Component|React.FC} ComposedComponent
 *
 * @return {React.Component}
 */
export default function requireAuthenticatedStatus(ComposedComponent) {
    var RequireAuthenticatedStatus = (props) => {
        var { redirectToSafety, ...otherProps } = props;

        if (redirectToSafety) {
            toast.error('Je bent te lang inactief geweest, en daarom uitgelogd.');

            return <Redirect to={createFrontendEntryPath()} />;
        }

        // Pass along the props that we want to go the the wrapped component
        return <ComposedComponent {...otherProps} />;
    };

    RequireAuthenticatedStatus.propTypes = {
        redirectToSafety: PropTypes.bool.isRequired,
    };

    /**
     * @param {Object} globalReduxState
     *
     * @returns {Object}
     */
    var mapGlobalReduxStateToAuthenticationProps = function (globalReduxState) {
        var redirectToSafety = globalReduxState.authenticated === false;

        return { redirectToSafety };
    };

    return connect(mapGlobalReduxStateToAuthenticationProps)(RequireAuthenticatedStatus);
}
