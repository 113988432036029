import React from 'react';
import Page from '../../../shared/page/Page';
import Heading from '../../../../shared/heading/Heading';

const DevelopmentPhasesPage = () => {
    return (
        <Page title="Ontwikkelfases">
            <ul>
                <li>Voorbereiding en afbakening</li>
                <li>Knelpuntenanalyse</li>
                <li>Literatuuronderzoek</li>
                <li>Conceptversie</li>
                <li>Commentaarronde</li>
                <li>Autorisatie</li>
                <li>Publicatie</li>
            </ul>
            <Heading tag="h2" variant="h3">
                Voorbereiding en afbakening
            </Heading>
            <p>
                In deze fase worden de stappen doorlopen die nodig zijn voordat de ontwikkeling van de richtlijn gestart
                kan worden. In deze wordt onder meer een plan van aanpak gemaakt, een werkgroep (en eventueel een
                klankbordgroep) samengesteld en deskresearch gedaan.
            </p>
            <Heading tag="h2" variant="h3">
                Knelpuntenanalyse
            </Heading>
            <p>
                Uitgangspunt van de ontwikkeling van een richtlijn is een knelpuntenanalyse. Hierin wordt in kaart
                gebracht welke knelpunten er zijn voor zowel zorgverleners als cliënten en naasten. De belangrijkste
                knelpunten vormen de basis voor de modules in de richtlijn. Ook worden in deze fase de uitgangsvragen
                per module opgesteld. De werkgroep gaat aan de slag om te bepalen hoe de vragen het beste beantwoord
                kunnen worden: via wetenschappelijk bewijs (evidence based) of aan de hand van andere kennis dan
                wetenschappelijke kennis (best practice).
                <br />
                <br />
                In deze fase wordt ook het literatuuronderzoek voorbereid om de beantwoording van de uitgangsvragen te
                kunnen onderbouwen.
            </p>
            <Heading tag="h2" variant="h3">
                Literatuuronderzoek
            </Heading>
            <p>
                Het literatuuronderzoek wordt gedaan aan de hand van het zoeken in diverse wetenschappelijke databases.
                Dit gebeurt aan de hand van een literatuurprotocol dat voldoet aan wetenschappelijke standaarden. Dit
                wordt opgesteld samen met de werkgroep. Op basis van deze resultaten van het literatuuronderzoek wordt
                bepaald of de uitgangsvragen hiermee beantwoord kunnen worden. Ook de aanbevelingen worden op basis
                hiervan geformuleerd.
            </p>
            <Heading tag="h2" variant="h3">
                Conceptversie
            </Heading>
            <p>
                In de conceptfase wordt de richtlijn opgesteld aan de hand van de modules. De modules bevatten de
                uitgangsvragen, overwegingen, onderbouwing, conclusies en aanbevelingen. Naast de modules en
                aanbevelingen, wordt in deze fase ook gewerkt aan andere onderdelen van de richtlijn. Dit zijn onder
                meer producten voor het gebruik in de praktijk, zoals een implementatieplan, samenvattingen en
                werkkaarten. Als er een klankbordgroep is ingesteld, dan geeft deze ook commentaar op de
                conceptrichtlijn.
            </p>
            <Heading tag="h2" variant="h3">
                Commentaarronde
            </Heading>
            <p>
                In de commentaarronde wordt de conceptversie voorgelegd aan verschillende partijen in het werkveld. Het
                ontvangen commentaar wordt door de werkgroep besproken en verwerkt.
            </p>
            <Heading tag="h2" variant="h3">
                Autorisatie
            </Heading>
            <p>
                Nadat de conceptversie is aangepast op basis van de commentaarronde, wordt de richtlijn ter autorisatie
                (voor akkoord) voorgelegd aan de beroepsverenigingen, beroepsorganisaties en cliëntenorganisaties die
                betrokken waren bij de ontwikkeling van de richtlijn.
            </p>
            <Heading tag="h2" variant="h3">
                Publicatie
            </Heading>
            <p>Na autorisatie wordt de richtlijn gepubliceerd op Richtlijnen Langdurige Zorg en verspreid.</p>
        </Page>
    );
};

export default DevelopmentPhasesPage;
