export enum DomElementApplication {
    ScrollableContainer = 'scrollable-container',
}

export const dataApplicationTag = 'data-application';

export const resolveClosestParentMatchingApplication = (
    el: HTMLElement,
    application: DomElementApplication
): HTMLElement | null => {
    return el.closest(`[${dataApplicationTag}="${application}"]`);
};
