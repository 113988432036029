import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import requireAuthenticationStatus from '../../hoc/RequireAuthenticatedStatus';
import { createGetCurrentUserAction } from '../../actions/userActionFactory';
import PageNotFound from '../frontend/ggz/page/components/PageNotFound';
import LazyLoadingUserDashboard from './lazyLoading/LazyLoadingUserDashboard';
import LazyLoadingDashboardParagraphDetail from './lazyLoading/LazyLoadingParagraphDetail';
import User from '../../model/User';
import type { DispatchProps } from '../../actions/factory';
import ErrorBoundary from '../frontend/shared/errorBoundary/ErrorBoundary';
import type { GlobalState } from '../../store/types';
import { createGetSettingsAction } from '../../actions/settingsActionFactory';
import { createDashboardEntryPath, createDashboardParagraphDetailPath } from '../../routing/dashboardUrlGenerator';

type OwnProps = {};

type ReduxSuppliedProps = {
    shouldConfirmBeforeUnload: boolean;
    hasCurrentUser: boolean;
    hasSettings: boolean;
};

type Props = OwnProps & ReduxSuppliedProps & DispatchProps;

class DashboardApp extends React.Component<Props> {
    _hasBeforeUnloadRegistered: boolean;

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        shouldConfirmBeforeUnload: PropTypes.bool.isRequired,
        hasCurrentUser: PropTypes.bool.isRequired,
    };

    constructor(props: Props) {
        super(props);

        this._hasBeforeUnloadRegistered = false;
    }

    componentDidMount() {
        const { hasCurrentUser, hasSettings, dispatch, shouldConfirmBeforeUnload } = this.props;

        if (!hasCurrentUser) {
            dispatch(createGetCurrentUserAction());
        }

        if (!hasSettings) {
            dispatch(createGetSettingsAction());
        }

        if (shouldConfirmBeforeUnload) {
            this._registerOnBeforeUnload();
        }
    }

    componentWillUnmount() {
        this._unregisterOnBeforeUnload();
    }

    _unregisterOnBeforeUnload() {
        if (!this._hasBeforeUnloadRegistered) {
            return;
        }

        window.removeEventListener('beforeunload', this._onBeforeUnload);
        this._hasBeforeUnloadRegistered = false;
    }

    _registerOnBeforeUnload() {
        if (this._hasBeforeUnloadRegistered) {
            return;
        }

        window.addEventListener('beforeunload', this._onBeforeUnload);
        this._hasBeforeUnloadRegistered = true;
    }

    _onBeforeUnload(event: Event): string {
        const confirmationMessage = 'Er zijn nog lopende zaken die niet opgeslagen zijn';

        // @ts-ignore -> flow does not know about this error type
        event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+

        return confirmationMessage; // Gecko, WebKit, Chrome <34
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.shouldConfirmBeforeUnload) {
            this._registerOnBeforeUnload();
        } else {
            this._unregisterOnBeforeUnload();
        }
    }

    render() {
        // Keep routes in the correct order, as the order matters!
        return (
            <div className="wrapper" id="top">
                <ErrorBoundary>
                    <Switch>
                        <Route
                            exact
                            path={createDashboardParagraphDetailPath()}
                            component={LazyLoadingDashboardParagraphDetail}
                        />
                        <Route path={createDashboardEntryPath()} component={LazyLoadingUserDashboard} />
                        <Route component={PageNotFound} />
                    </Switch>
                </ErrorBoundary>
            </div>
        );
    }
}

const withGlobalStateAccess = connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>((globalState) => ({
    shouldConfirmBeforeUnload: globalState.loadingQueue.count() > 0,
    hasCurrentUser: globalState.currentUser instanceof User,
    hasSettings: globalState.settings !== null,
}));

export default requireAuthenticationStatus(
    // @ts-ignore -> don't know how to fix This
    withGlobalStateAccess(DashboardApp)
);
