export const ENTITY_TYPE_LINK = 'LINK';
export const ENTITY_TYPE_EXTERNAL_LINK = 'EXTERNAL_LINK';
export const ENTITY_TYPE_INTERNAL_LINK = 'INTERNAL_LINK';
export const ENTITY_TYPE_FILE_LINK = 'FILE_LINK';
export const ENTITY_TYPE_REFERENCE = 'REFERENCE';
export const ENTITY_TYPE_HIGHLIGHT = 'HIGHLIGHT';
export const ENTITY_TYPE_DEFINITION = 'DEFINITION';
export const ENTITY_TYPE_IMAGE = 'IMAGE';
export const ENTITY_TYPE_VIDEO = 'VIDEO';
export const ENTITY_TYPE_TABLE = 'TABLE';
