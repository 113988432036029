import React from 'react';
import useFetchArticleDetailsOnIdChange from '../../../../hooks/useFetchArticleOnIdChange';
import { transformJsonToObject } from '../../../../utility/JsonToObjectTransformer';
import { createArticleOverviewPath } from '../../../../routing/urlGenerator';
import EditorContent from '../../../shared/editorContent/EditorContent';
import ArticleDetailMeta from './components/ArticleDetailMeta';
import ArticleDetailHeader from './components/ArticleDetailHeader';
import ArticleRelatedStandard from './components/ArticleRelatedStandard';
import BackButton from '../primitives/backButton/BackButton';
import createClassName from 'classnames';
import styles from './ArticleDetail.module.scss';

const ArticleDetail = () => {
    const { article } = useFetchArticleDetailsOnIdChange();
    const description = article ? transformJsonToObject(article.description) : null;

    if (!article) {
        return null;
    }

    const hasSidebar = !!article.standard;
    const layoutClassName = createClassName('layout', {
        'layout--align-between': hasSidebar,
        'layout--align-center': !hasSidebar,
    });

    return (
        <main className="section section--light">
            <ArticleDetailMeta article={article} />
            <article className="container">
                <div className={layoutClassName}>
                    <div className="layout__item layout__item--lg-8">
                        <BackButton to={createArticleOverviewPath()} text="Terug" />
                        <ArticleDetailHeader article={article} />
                    </div>
                </div>
                <div className={layoutClassName}>
                    <div className="layout__item layout__item--lg-8">
                        {article.imageUrl && (
                            <img
                                src={article.imageUrl}
                                width="760"
                                height="367"
                                alt={article.title}
                                className={styles.image}
                            />
                        )}
                        <EditorContent raw={description} />
                    </div>
                    {article.standard && (
                        <aside className="layout__item layout__item--lg-3">
                            <ArticleRelatedStandard standard={article.standard} />
                        </aside>
                    )}
                </div>
            </article>
        </main>
    );
};

export default ArticleDetail;
