import { FormValues } from '../hooks/useHandleCreateUserFormState';
import { FormikErrors } from 'formik';
import { checkIsValidEmail } from '../../../../utility/validationUtilities';
import { userRoles } from '../../../../model/User';
import { FormErrorCollectionType } from '../../../../lib/forms/model/FormState';

const maxFirstNameLength = 100;
const maxPrepositionLength = 50;
const maxLastNameLength = 150;

export const validateUserInput = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.firstName) {
        errors.firstName = 'Dit veld is verplicht';
    } else if (values.firstName.length > maxFirstNameLength) {
        errors.firstName = 'Een voornaam langer dan 100 tekens is niet toegestaan';
    }

    if (values.preposition && values.preposition.length > maxPrepositionLength) {
        errors.preposition = 'Een tussenvoegsel langer dan 50 tekens is niet toegestaan';
    }

    if (!values.lastName) {
        errors.lastName = 'Dit veld is verplicht';
    } else if (values.lastName.length > maxLastNameLength) {
        errors.lastName = 'Een achternaam langer dan 150 tekens is niet toegestaan';
    }

    if (!values.email) {
        errors.email = 'Dit veld is verplicht';
    } else if (!checkIsValidEmail(values.email)) {
        errors.email = 'Vul een geldig e-mailadres in';
    }

    if (!values.globalRole) {
        errors.globalRole = 'Dit veld is verplicht';
    } else if (!Object.keys(userRoles).includes(values.globalRole)) {
        errors.globalRole = 'Kies een geldige gebruikersrol';
    }

    return errors;
};

export const extractFormErrorsFromBackendValidationErrors = (
    backendValidationErrors: FormErrorCollectionType,
    values: FormValues
): FormikErrors<FormValues> => {
    const fields = Object.keys(values) as Array<keyof FormValues>;

    const newErrors: FormikErrors<FormValues> = {};

    fields.forEach((field) => {
        if (backendValidationErrors[field] !== undefined && backendValidationErrors[field].length > 0) {
            newErrors[field] = backendValidationErrors[field][0];
        }
    });

    return newErrors;
};
