import React from 'react';
import { StandardOverviewItem } from '../../../../model/standardOverview/StandardOverviewItem';
import { formatDate } from '../../../../helper/dateTimeHelper';

type Props = {
    item: StandardOverviewItem;
};

const StandardOverviewItemPublicationDate = ({ item }: Props) => (
    <small className="text--muted">{formatDate(item.publishedAt)}</small>
);

export default StandardOverviewItemPublicationDate;
