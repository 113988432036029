import type { OnSubmitCallback } from '../../searchForm/SearchForm';
import { createSearchPath } from '../../../../../routing/urlGenerator';
import { useHistory } from 'react-router-dom';

export default function useUpdateUrlOnFilterChange(currentQuery: string) {
    const history = useHistory();

    const onFormSubmit: OnSubmitCallback = (formdata) => {
        history.push(createSearchPath(formdata.query, 1));
    };

    const onPageChange = (newPageNumber: number) => {
        history.push(createSearchPath(currentQuery, newPageNumber));
    };

    return { onFormSubmit, onPageChange };
}
