import { ROLE_USER, userRoles } from '../model/User';

/*
 * Returns if the user is super admin or admin but does not return regular user
 */
export function extractHighestAdminRoleIfAvailable(userRoleKeys: Array<string>): string {
    const adminRoles = userRoleKeys.filter((roleKey) => roleKey !== ROLE_USER);

    return adminRoles.length ? userRoles[adminRoles[0]] : '';
}
