import React, { FC } from 'react';
import NavItems from './NavItems';
import { createDisclaimerPagePath, createPrivacyAndCookiesPagePath } from '../../../../../routing/urlGenerator';
import styles from '../Header.module.scss';
import { NavLink } from 'react-router-dom';
import Modal, { ModalSize } from '../../../../shared/modal/Modal';
import useShowHide from '../../../../../hooks/useShowHide';
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5';
import Button from '../../primitives/button/Button';

const MobileNav: FC = () => {
    const { visible, show, hide } = useShowHide();

    return (
        <div className={styles.mobileNav}>
            <Button
                icon={<IoMenuOutline className={styles.icon} />}
                additionalClassName={styles.menuButton}
                onClick={show}
                isCleared
            />
            <Modal isOpen={visible} onDecline={hide} modalSize={ModalSize.Full} customCloseIcon={<IoCloseOutline />}>
                <div className={styles.mobileNavContentWrapper}>
                    <div className={styles.mobileNavContent}>
                        <NavItems onClick={hide} />
                    </div>
                    <div className={styles.navFooter}>
                        <NavLink to={createDisclaimerPagePath()} exact className={styles.navItem} onClick={hide}>
                            Algemene voorwaarden
                        </NavLink>
                        <NavLink to={createPrivacyAndCookiesPagePath()} exact className={styles.navItem} onClick={hide}>
                            Privacy- en cookiebeleid
                        </NavLink>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MobileNav;
