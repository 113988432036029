import React from 'react';
import { useProjectDetailContext } from '../../../ggz/projectDetail/components/ProjectDetailContext';
import Button from '../../../../shared/buttons/Button';
import ProjectDetailBookmarkTrigger from '../../../ggz/projectDetail/components/ProjectDetailBookmarkTrigger';
import { useProjectDetails } from '../../../../../hooks/useProjectDetails';

// @todo: replace with skilz icons
import TocIcon from '../../../../../../../icons/ggz/toc.svg';
import SearchIcon from '../../../../../../../icons/ggz/search.svg';

import type { MouseEvent } from 'react';

type Props = {
    onTriggerProjectIndexClick: (event: MouseEvent<HTMLButtonElement>) => void;
    onTriggerSearchClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const ADDITIONAL_OFFSET = 10;

const LayoutProjectViewMobileActions = ({ onTriggerProjectIndexClick, onTriggerSearchClick }: Props) => {
    const { details } = useProjectDetails();
    const { projectDetailHeaderHeight } = useProjectDetailContext();
    const calculatedTopOffsetWhenSticky = projectDetailHeaderHeight + ADDITIONAL_OFFSET;

    const styles = {
        top: calculatedTopOffsetWhenSticky,
    };

    return (
        <div className="layout-project-view__mobile-actions" style={styles}>
            <div className="layout-project-view__mobile-action-buttons">
                {details && (
                    <ProjectDetailBookmarkTrigger
                        projectDetails={details}
                        className="layout-project-view__project-detail-bookmark-trigger"
                    />
                )}
                <Button onClick={onTriggerProjectIndexClick} title="Inhoudsopgave" isCleared>
                    <TocIcon className="button__icon" />
                </Button>
                <Button onClick={onTriggerSearchClick} title="Zoeken" isCleared>
                    <SearchIcon className="button__icon" />
                </Button>
            </div>
        </div>
    );
};

export default LayoutProjectViewMobileActions;
