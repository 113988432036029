import { isObject, get, has } from 'lodash';

type Path = string | Array<string>;

export function checkPathExists(path: Path, theObject: any): boolean {
    if (!isObject(theObject)) {
        return false;
    }

    return has(theObject, path);
}

export function extractPath(path: Path, theObject: any, defaultValue: any = null): any {
    if (!isObject(theObject)) {
        return defaultValue;
    }

    return get(theObject, path, defaultValue);
}

export function extractPathOrThrowIfNotExists(
    path: Path,
    theObject: any,
    errorMessageIfNotExists?: string | null
): any {
    if (!isObject(theObject) || has(theObject, path) === false) {
        throw new Error(errorMessageIfNotExists || undefined);
    }

    return get(theObject, path);
}

export function extractMultiplePathsOrThrowIfNotExists(
    paths: Array<string>,
    theObject: any,
    errorMessageIfNotExists?: string | null
): Record<string, any> {
    const pathItems: Record<string, any> = {};

    paths.forEach((path) => {
        const lastPathPart = path.split('.').pop();
        const value = extractPathOrThrowIfNotExists(path, theObject, errorMessageIfNotExists);

        if (!lastPathPart) {
            throw new Error('Expecting there to be a last part');
        }

        pathItems[lastPathPart] = value;
    });

    return pathItems;
}
