import React from 'react';

type Props = {
    href?: string;
    external?: boolean;
    className?: string;
    children: React.ReactNode;
    file?: string;
    rel?: string;
};

const Link: React.FC<Props> = ({ children, href, external, file, className, rel }) => {
    const otherProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};

    if (external || file) {
        otherProps.target = '_blank';
    }

    if (className) {
        otherProps.className = className;
    }

    if (rel) {
        otherProps.rel = rel;
    }

    return (
        <a href={file ? file : href} {...otherProps}>
            {file && <i className="icon-docs"></i>}
            {children}
        </a>
    );
};

export default Link;
