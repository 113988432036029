import * as React from 'react';
import ProjectIndexList, { IndexStyles } from './components/ProjectIndexList';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import useFetchProjectIndex from './hooks/useFetchProjectIndex';
import Button, { BUTTON } from '../../../shared/buttons/Button';
import { ReactNode } from 'react';
import { useProjectDetailContext } from '../../ggz/projectDetail/components/ProjectDetailContext';

type OwnProps = {
    onProjectIndexItemClick: () => void;
    onProjectIndexCloseClick: () => void;
    indexStyle: IndexStyles;
    children: ReactNode;
};

const ProjectIndex = ({ onProjectIndexItemClick, onProjectIndexCloseClick, indexStyle, children }: OwnProps) => {
    const { details } = useProjectDetails();
    const additionalOffset = 40;

    const { projectIndex } = useFetchProjectIndex(details);

    const { projectDetailHeaderHeight } = useProjectDetailContext();

    const calculatedTopOffsetWhenSticky = projectDetailHeaderHeight + additionalOffset;

    const styles = {
        top: calculatedTopOffsetWhenSticky,
        maxHeight: `calc(100vh - ${calculatedTopOffsetWhenSticky}px)`,
    };

    return (
        <div className="project-index" style={styles}>
            {children}
            {projectIndex && (
                <>
                    <Button
                        type={BUTTON}
                        icon="cancel"
                        onClick={onProjectIndexCloseClick}
                        additionalClassName="project-index__close"
                        isCleared
                    />
                    <ProjectIndexList
                        projectIndex={projectIndex}
                        onProjectIndexItemClick={onProjectIndexItemClick}
                        style={indexStyle}
                    />
                </>
            )}
        </div>
    );
};

export default ProjectIndex;
