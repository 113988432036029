import React from 'react';
import { formatDate } from '../../../../../helper/dateTimeHelper';
import styles from '../ProjectAccountability.module.scss';
import createClassName from 'classnames';

type Props = {
    authorizedAt: string | undefined | null;
    reviewedAt: string | undefined | null;
};

const ProjectAccountabilitySummary = ({ authorizedAt, reviewedAt }: Props) => (
    <div className={createClassName(styles.island, styles.isSummaryIsland)}>
        <div className={styles.summary}>
            <div>
                <span className={styles.label}>Geautoriseerd op</span>
                <span className={styles.value}>{authorizedAt ? formatDate(authorizedAt) : 'Onbekend'}</span>
            </div>
            <div>
                <span className={styles.label}>Beoordeeld op</span>
                <span className={styles.value}>{reviewedAt ? formatDate(reviewedAt) : 'Onbekend'}</span>
            </div>
        </div>
    </div>
);

export default ProjectAccountabilitySummary;
