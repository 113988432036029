import { fetchProjectOverviewPreviews } from '../repository/projectPreviewRepository';
import { useState } from 'react';
import { ProjectPreview } from '../model/projectOverview/ProjectPreview';
import useOnMount from './useOnMount';
import { logError } from '../utility/Logger';

export default function useFetchProjectOverviewPreviews() {
    const [previewItems, setPreviewItems] = useState<ProjectPreview[] | null>(null);

    useOnMount(() => {
        fetchProjectOverviewPreviews()
            .then((previewItems) => {
                const itemsWithValidStatus = previewItems
                    .filter((item) => item.status)
                    .map((item) => {
                        // eslint-disable-next-line camelcase
                        item.template_id = item.template_id || 'default';

                        return item;
                    });

                setPreviewItems(itemsWithValidStatus);
            })
            .catch((error) => logError(error));
    });

    return { previewItems };
}
