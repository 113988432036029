import { useEffect } from 'react';
import mousetrap from 'mousetrap';

export default function useOpenStartProjectModalOnKeyBindingPressed(doShowModal: () => void) {
    useEffect(() => {
        const onKeyBindingPressed = (event: Event) => {
            // prevent typing in title input field already, as it is autofocussed
            event.preventDefault();

            doShowModal();
        };

        mousetrap.bind(['n', 'a'], onKeyBindingPressed);

        return () => {
            mousetrap.unbind(['n', 'a']);
        };
    }, [doShowModal]);
}
