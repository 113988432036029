import * as React from 'react';
import Button, { PRIMARY } from '../../../shared/buttons/Button';
import { projectsTabTextItems } from '../../../../translations/translations';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import { TenantType } from '../../../../constants/settings';

type Props = {
    onClick: () => void;
};

const StartProjectButton = ({ onClick }: Props) => {
    const { tenant } = useCurrentTenant();

    return (
        <Button onClick={onClick} style={PRIMARY} icon="plus" isSmall>
            {projectsTabTextItems[tenant as TenantType].newProject}
        </Button>
    );
};

export default StartProjectButton;
