import { combineReducers } from 'redux';
import currentUser from './currentUserReducer';
import authenticatedReducer from './authenticationReducer';
import projectOverviewItems from './projectOverviewReducer';
import projectDetailsReducer from './projectDetailsReducer';
import paragraphDetailsReducer from './paragraphDetailsReducer';
import loadingQueueReducer from './loadingQueueReducer';
import projectChapterDetailsReducer from './projectChapterDetailsReducer';
import projectIndexReducer from './projectIndexReducer';
import bookmarksReducer from './bookmarksReducer';
import standardOverviewReducer from './standardOverviewReducer';
import authorizationReducer from './authorizationReducer';
import overviewUsersReducer from './overviewUsersReducer';
import commentsReducer from './commentsReducer';
import lastSearchedItemReducer from './lastSearchedItemReducer';
import sideProductOverviewReducer from './sideProductOverviewReducer';
import branchOverviewReducer from './branchOverviewReducer';
import settingsReducer from './settingsReducer';
import referenceOverviewReducer from './referenceOverviewReducer';

const rootReducer = combineReducers({
    authenticated: authenticatedReducer,
    overviewUsers: overviewUsersReducer,
    currentUser: currentUser,
    loadingQueue: loadingQueueReducer,
    projectOverviewItems: projectOverviewItems,
    projectDetails: projectDetailsReducer,
    chapterDetails: projectChapterDetailsReducer,
    paragraphDetails: paragraphDetailsReducer,
    projectIndex: projectIndexReducer,
    bookmarks: bookmarksReducer,
    standardOverviewItems: standardOverviewReducer,
    standardAllOverviewItems: standardOverviewReducer,
    branches: branchOverviewReducer,
    authorizationVotes: authorizationReducer,
    comments: commentsReducer,
    lastSearchedItem: lastSearchedItemReducer,
    sideProductOverview: sideProductOverviewReducer,
    settings: settingsReducer,
    references: referenceOverviewReducer,
});

export default rootReducer;
