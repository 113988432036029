import { resolvePathBackToStandard } from '../utility/pathBackToStandardResolver';
import { Link } from 'react-router-dom';
import React from 'react';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import ArrowLeftIcon from '../../../../../../../icons/ggz/arrow-left.svg';

type Props = {
    projectDetails: ProjectDetail;
};

const BackToStandardLink = ({ projectDetails }: Props) => {
    const to = resolvePathBackToStandard(projectDetails);

    return (
        <Link className="link link--secondary" to={to}>
            <ArrowLeftIcon className="link__icon" />
            <span>Terug naar de standaard</span>
        </Link>
    );
};

export default BackToStandardLink;
