import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import type { ArticleStandard } from '../../../../../model/Article';
import { Link } from 'react-router-dom';
import { IoLinkOutline } from 'react-icons/io5';
import styles from '../ArticleDetail.module.scss';

type Props = {
    standard: ArticleStandard;
};

const RelatedStandard = ({ standard }: Props) => {
    if (standard.lastPublication === null) {
        return null;
    }

    return (
        <div className={styles.relatedStandard}>
            <Link to={standard.lastPublication._path} className="link link--primary">
                <IoLinkOutline aria-hidden className={styles.relatedStandardIcon} />
                <Heading tag="h4" weight="semi-bold" className={styles.relatedStandardTitle}>
                    {standard.lastPublication.title}
                </Heading>
            </Link>
            <p className={styles.relatedStandardDescription}>{standard.introduction}</p>
        </div>
    );
};

export default RelatedStandard;
