import type { FormData } from '../../searchForm/SearchForm';
import { createProjectSearchPath } from '../../../../../routing/urlGenerator';
import { useLocation, useHistory } from 'react-router-dom';
import { storeLastVisitedProjectDetailPath } from '../../../ggz/searchResults/storage/lastVisitedProjectDetailPathStorage';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';

export default function useNavigateToProjectSearchOnFormSubmit(
    projectDetails: ProjectDetail | null,
    hideSidebarOnMobile: () => void
) {
    const history = useHistory();
    const location = useLocation();

    const onSearchSubmit = (formData: FormData) => {
        if (!projectDetails) {
            return;
        }

        const newPath = createProjectSearchPath(projectDetails.typeSlug, projectDetails.slug, formData.query);

        storeLastVisitedProjectDetailPath(location.pathname);

        history.push(newPath);

        hideSidebarOnMobile();
    };

    return { onSearchSubmit };
}
