import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logError } from '../utility/Logger';
import Unauthorized from '../components/dashboard/unauthorized/Unauthorized';
import PermissionRequirement from '../security/model/PermissionRequirement';
import { checkUserIsGranted } from '../security/helpers/securityHelpers';
import type { GlobalState } from '../store/types';
import type { PermissionRequirementCallback, SecurityVoteCollection } from '../security/types';
import type { DispatchProps } from '../actions/factory';

type OwnProps = {};

type State = {
    authorized: boolean | undefined | null;
};

type ReduxSuppliedProps = {
    authenticated: boolean;
    authorizationVotes: SecurityVoteCollection;
};

type CombinedProps = {} & OwnProps & ReduxSuppliedProps & DispatchProps;

export default (permissionRequirementCallback: PermissionRequirementCallback) =>
    (ComposedComponent: React.ComponentType<any>) => {
        class RequireAuthorizedPermissions extends React.Component<CombinedProps, State> {
            static propTypes = {
                authenticated: PropTypes.bool.isRequired,
            };

            constructor(props: any) {
                super(props);

                this.state = {
                    authorized: null,
                };
            }

            componentDidMount() {
                this._validateUserIsAuthenticated(this.props);
                this._checkIsAuthorized(this.props);
            }

            // eslint-disable-next-line camelcase
            UNSAFE_componentWillUpdate(nextProps: CombinedProps) {
                this._validateUserIsAuthenticated(nextProps);
                this._checkIsAuthorized(nextProps);
            }

            _validateUserIsAuthenticated(props: CombinedProps) {
                if (!props.authenticated) {
                    logError(
                        new Error(
                            'Expecting the user to be authenticated at this point. Use `RequireAuthenticatedStatus` HOC to tackle this.'
                        )
                    );
                }
            }

            _checkIsAuthorized(props: CombinedProps) {
                const permissionRequirement: PermissionRequirement = permissionRequirementCallback(props);
                const authorized: boolean = checkUserIsGranted(permissionRequirement, props.authorizationVotes);

                this._setAuthorizedState(authorized);
            }

            _setAuthorizedState(isAuthorized: boolean): void {
                if (this.state.authorized === isAuthorized) {
                    return;
                }

                this.setState((currentState: State) => ({
                    ...currentState,
                    authorized: isAuthorized,
                }));
            }

            render() {
                const { authorized } = this.state;

                // as long is not is clear if the user is authorized to view this content, render nothing
                if (authorized === null) {
                    return null;
                }

                if (authorized === false) {
                    return <Unauthorized />;
                }

                return <ComposedComponent {...this.props} />;
            }
        }

        const withGlobalStateAccess = connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>(
            (globalState: GlobalState): ReduxSuppliedProps => {
                // @todo replace with selectors as the props are supplied to this method as the second argument?

                return {
                    authenticated: globalState.authenticated,
                    authorizationVotes: globalState.authorizationVotes,
                };
            }
        );

        // @ts-ignore -> replace this setup with functional component with useSelector
        return withGlobalStateAccess(RequireAuthorizedPermissions);
    };
