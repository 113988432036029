import * as React from 'react';
import createClassName from 'classnames';

type tagOptionsType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
type variantOptionsType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type weightOptionsType = 'thin' | 'semi-bold' | 'bold';
type alignOptionsType = 'left' | 'center' | 'right';
type styleOptions = 'primary' | 'secondary';

type Props = {
    tag: tagOptionsType;
    variant?: variantOptionsType;
    uppercase?: boolean;
    align?: alignOptionsType;
    flatten?: boolean;
    weight?: weightOptionsType;
    withPrefix?: boolean;
    isMuted?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: styleOptions;
};

const Heading = ({
    tag,
    variant,
    uppercase = false,
    align = 'left',
    flatten = false,
    weight = 'bold',
    children,
    withPrefix = false,
    isMuted = false,
    className: incomingClassName,
    style = 'primary',
    ...otherProps
}: Props) => {
    const className: string = createClassName(
        'heading',
        {
            'heading--uppercase': uppercase,
            'heading--center': align === 'center',
            'heading--right': align === 'right',
            'heading--flat': flatten,
            'heading--thin': weight === 'thin',
            'heading--semi-bold': weight === 'semi-bold',
            'heading--with-prefix': withPrefix,
            'heading--muted': isMuted,
            'heading--variant-h1': variant === 'h1',
            'heading--variant-h2': variant === 'h2',
            'heading--variant-h3': variant === 'h3',
            'heading--variant-h4': variant === 'h4',
            'heading--variant-h5': variant === 'h5',
            'heading--variant-h6': variant === 'h6',
            'heading--secondary': style === 'secondary',
        },
        incomingClassName
    );

    return React.createElement(tag, { className, ...otherProps }, children);
};

export default Heading;
