import { useDispatch, useSelector } from 'react-redux';
import { createFetchUserOverviewAction } from '../../../../actions/userActionFactory';
import { useEffect } from 'react';
import PaginatedCollection from '../../../../model/collection/paginatedCollection';
import { GlobalState } from '../../../../store/types';
import { OverviewUsersReducerState } from '../../../../reducers/overviewUsersReducer';

export default function useFetchUsers(currentPage: number): OverviewUsersReducerState {
    const dispatch = useDispatch();

    useEffect(() => {
        const action = createFetchUserOverviewAction(currentPage);

        dispatch(action);
    }, [currentPage]);

    return useSelector<GlobalState, OverviewUsersReducerState>((globalState) => {
        let collection = globalState.overviewUsers;

        if (collection instanceof PaginatedCollection && collection.currentPage !== currentPage) {
            collection = null;
        }

        return collection;
    });
}
