import type { PageTypeType } from '../constants/pageTypes';
import { logDebug } from './Logger';

type PageTypeDataLayerEntry = {
    pageType: PageTypeType;
    context?: PageTypeContext;
};

export type PageTypeContext = {
    [key: string]: unknown;
};

type DataLayerEntry = PageTypeDataLayerEntry;

function resolveDataLayer(): Array<DataLayerEntry> {
    if (typeof window.dataLayer === 'undefined') {
        window.dataLayer = [];
    } else if (!Array.isArray(window.dataLayer)) {
        throw new Error('Expecting window.dataLayer to be of type array');
    }

    return window.dataLayer;
}

function addDataLayerEntry(entry: DataLayerEntry) {
    logDebug('data layer', entry);

    resolveDataLayer().push(entry);
}

export function trackPageTypeVisit(entry: PageTypeDataLayerEntry) {
    addDataLayerEntry(entry);
}
