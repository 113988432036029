import React from 'react';
import PdfIcon from '../../../../../../../icons/ggz/pdf.svg';
import Heading from '../../../../shared/heading/Heading';
import ArrowDownIcon from '../../../../../../../icons/ggz/arrow-down.svg';
import createClassName from 'classnames';
import { IoBriefcaseOutline } from 'react-icons/io5';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    title: string;
    description?: string | null;
    url: string;
    trackId: string;
    trackData?: string;
    highlightText?: string | null;
};

const SideProductOverviewItem = ({ title, trackId, trackData, url, description, highlightText }: Props) => {
    const { isSKILZ, isGGZ } = useCurrentTenant();

    const iconHolderClassname = createClassName('side-product-item__icon-holder', {
        'side-product-item__icon-holder--with-highlight-text': !!highlightText,
    });

    return (
        <div className="side-product-item">
            <div className={iconHolderClassname}>
                {isSKILZ ? <IoBriefcaseOutline className="icon" /> : <PdfIcon className="icon" />}
            </div>
            <div className="side-product-item__content">
                {highlightText && (
                    <span className="side-product-item__highlight-text">
                        {isGGZ && <ArrowDownIcon className="side-product-item__highlight-text-icon" />}
                        {highlightText}
                    </span>
                )}
                <Heading tag="h4" className="side-product-item__title" weight="semi-bold">
                    <a
                        href={url}
                        className="link link--primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-track-id={trackId || 'download-side-product'}
                        data-track-data={trackData}
                    >
                        {title}
                    </a>
                </Heading>
                {description && <p className="side-product-item__description">{description}</p>}
            </div>
        </div>
    );
};

export default SideProductOverviewItem;
