import React from 'react';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import Loader from '../../shared/Loader';
import CollectionPagination from '../../frontend/shared/CollectionPagination';
import { composeSeoTitle } from '../../../helper/seoHelper';
import { USER_OVERVIEW } from '../../../security/SecurityAttribute';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardContent from '../shared/containers/DashboardContent';
import DashboardIsland from '../shared/island/DashboardIsland';
import { Helmet } from 'react-helmet';
import HeadingGroup from '../shared/headings/HeadingGroup';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import type { PermissionRequirementCallback } from '../../../security/types';
import List from '../shared/list/List';
import CreateUser from '../createUser/CreateUser';
import useFetchUsers from './hooks/useFetchUsers';
import useDetermineCurrentPage from './hooks/useDetermineCurrentPage';
import useScrollToTopOnDependencyChange from '@freshheads/javascript-essentials/build/react/hooks/useScrollToTopOnDependencyChange';
import useNavigateToNewPageOnChange from './hooks/useNavigateToNewPageOnChange';
import UsersOverviewItem from './components/UsersOverviewItem';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import SearchUserForm from './components/SearchUserForm';
import styles from '../referenceOverview/ReferenceOverview.module.scss';

const UsersOverview = () => {
    const currentPage = useDetermineCurrentPage();

    const collection = useFetchUsers(currentPage);

    useScrollToTopOnDependencyChange(collection);

    const onPageChange = useNavigateToNewPageOnChange();

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{composeSeoTitle(null, 'Gebruikers')}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading={true}>Gebruikers</DashboardContentHeading>
                    <CreateUser />
                </HeadingGroup>
                <SearchUserForm />
                {collection ? (
                    <>
                        <DashboardIsland>
                            <List>
                                {collection.results.length === 0 ? (
                                    <p className={styles.text}>Geen resultaten</p>
                                ) : (
                                    collection.results.map((user) => (
                                        <UsersOverviewItem key={user.externalId} user={user} />
                                    ))
                                )}
                            </List>
                        </DashboardIsland>
                        <CollectionPagination
                            collection={collection}
                            currentPage={collection.currentPage}
                            onPageChange={onPageChange}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(USER_OVERVIEW));
};

export default requireAuthorizedPermissions(getRequiredPermissions)(UsersOverview);
