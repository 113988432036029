import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import CommentCounter from '../../commenting/components/CommentCounter';
import ProjectOverviewItemModel from '../../../../model/projectOverview/ProjectOverviewItem';
import { getProjectTypeTitleSingular } from '../../../../constants/types';
import CloseProject from '../../closeProject/CloseProject';
import CloseProjectThreshold from '../../closeProject/components/CloseProjectThreshold';
import ListItem from '../../shared/list/component/ListItem';
import ListAction from '../../shared/list/component/ListAction';
import ListHeading from '../../shared/list/component/ListHeading';
import ListLabel from '../../shared/list/component/ListLabel';
import ProjectOverviewItemTitle from './ProjectOverviewItemTitle';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import DuplicateProject from '../../duplicateProject/DuplicateProject';

type Props = {
    item: ProjectOverviewItemModel;
};

const ProjectOverviewItem = ({ item }: Props) => {
    const { tenant } = useCurrentTenant();

    if (!tenant) return null;

    const externalId = item.externalId;

    return (
        <ListItem>
            <ListLabel>{getProjectTypeTitleSingular(tenant, item.type)}</ListLabel>
            <ListHeading>
                <ProjectOverviewItemTitle item={item} />
            </ListHeading>
            {externalId && (
                <>
                    <CommentCounter
                        count={item.commentCount}
                        answeredCount={item.answeredCommentCount}
                        externalProjectId={externalId}
                    />
                    <CloseProjectThreshold externalId={externalId}>
                        <ListAction>
                            <Dropdown icon={false} className="list__menu icon-kebab" text="">
                                <Dropdown.Menu>
                                    <CloseProject item={item} />
                                    <DuplicateProject item={item} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListAction>
                    </CloseProjectThreshold>
                </>
            )}
        </ListItem>
    );
};

export default ProjectOverviewItem;
