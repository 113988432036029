import React from 'react';
import createClassName from 'classnames';
import styles from './SideProductOverview.module.scss';
import { ReactNode } from 'react';

type OverviewStyleOptionsType = 'list' | 'grid';

type Props = {
    children: ReactNode;
    style?: OverviewStyleOptionsType;
    additionalClassNames?: string;
    emptyStateText?: string;
};

const SideProductOverview = ({ children, style = 'list', emptyStateText, additionalClassNames }: Props) => {
    if (React.Children.count(children) === 0) {
        return <p>{emptyStateText ?? 'Verdiepende documenten volgen binnenkort...'}</p>;
    }

    const gridStyleClassNames = createClassName(styles.gridOverview, {}, additionalClassNames);

    if (style === 'grid') {
        return (
            <div className={gridStyleClassNames}>
                {React.Children.map(
                    children,
                    (child) =>
                        // @ts-ignore Somehow Typescript does not know about key property
                        child && <>{child}</>
                )}
            </div>
        );
    }

    const listStyleClassNames = createClassName(
        'list-unstyled list-unstyled--medium-spacing',
        {},
        additionalClassNames
    );

    return (
        <ul className={listStyleClassNames}>
            {/* in case of a newly published standard, the standard pdf file may still be null*/}
            {/* hence there would be no child to map over, causing an error*/}
            {React.Children.map(
                children,
                (child, index) =>
                    // @ts-ignore Somehow Typescript does not know about key property
                    child && <li key={child.key || index}>{child}</li>
            )}
        </ul>
    );
};

export default SideProductOverview;
