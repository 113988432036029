import React, { ReactNode } from 'react';
import ListHeading from '../../shared/list/component/ListHeading';
import Heading from '../../../shared/heading/Heading';
import ListAction from '../../shared/list/component/ListAction';
import ListItem from '../../shared/list/component/ListItem';
import { Page } from '../../../../model/Page';

type Props = {
    page: Page;
    children: ReactNode;
};

const PageOverviewListItem = ({ page, children }: Props) => (
    <ListItem>
        <ListHeading>
            <Heading tag="h2">{page.title}</Heading>
        </ListHeading>
        <ListAction>{children}</ListAction>
    </ListItem>
);

export default PageOverviewListItem;
