import type { Cloneable } from './contracts';

export default class ProjectBreadcrumb implements Cloneable<ProjectBreadcrumb> {
    _id: string;
    _externalId: string;
    _title: string;
    _parts: string[];
    _indexes: number[];
    _type: string;
    _projectId: string;
    _chapterId: string | undefined | null;
    _paragraphId: string | undefined | null;
    _subParagraphId: string | undefined | null;
    _path: string;
    _closed: boolean;

    constructor(
        id: string,
        externalId: string,
        title: string,
        parts: string[],
        indexes: number[],
        type: string,
        projectId: string,
        chapterId: string | undefined | null,
        paragraphId: string | undefined | null,
        subParagraphId: string | undefined | null,
        path: string,
        closed: boolean
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._parts = parts;
        this._indexes = indexes;
        this._type = type;
        this._projectId = projectId;
        this._chapterId = chapterId;
        this._paragraphId = paragraphId;
        this._subParagraphId = subParagraphId;
        this._path = path;
        this._closed = closed;
    }

    /**
     * @param {Object} nativeObject
     *
     * @returns {ProjectBreadcrumb}
     */
    static fromNativeObject(nativeObject: any): ProjectBreadcrumb {
        return new ProjectBreadcrumb(
            nativeObject.id,
            nativeObject.externalId,
            nativeObject.title,
            nativeObject.parts,
            nativeObject.indexes || [],
            nativeObject.type,
            nativeObject.projectId,
            nativeObject.chapterId,
            nativeObject.paragraphId,
            nativeObject.subParagraphId,
            nativeObject.path,
            nativeObject.closed || false
        );
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    get parts(): string[] {
        return this._parts;
    }

    get indexes(): number[] {
        return this._indexes;
    }

    get type(): string {
        return this._type;
    }

    get projectId(): string {
        return this._projectId;
    }

    get chapterId(): string | undefined | null {
        return this._chapterId;
    }

    get paragraphId(): string | undefined | null {
        return this._paragraphId;
    }

    get subParagraphId(): string | undefined | null {
        return this._subParagraphId;
    }

    get path(): string {
        return this._path;
    }

    get closed(): boolean {
        return this._closed;
    }

    clone(): ProjectBreadcrumb {
        return new ProjectBreadcrumb(
            this._id,
            this._externalId,
            this._title,
            [...this._parts],
            [...this._indexes],
            this._type,
            this._projectId,
            this._chapterId,
            this._paragraphId,
            this._subParagraphId,
            this._path,
            this._closed
        );
    }

    toNativeObject(): Record<string, any> {
        return {
            id: this._id,
            externalId: this._externalId,
            title: this._title,
            parts: [...this._parts],
            indexes: [...this._indexes],
            type: this._type,
            projectId: this._projectId,
            chapterId: this._chapterId,
            paragraphId: this._paragraphId,
            subParagraphId: this._subParagraphId,
            path: this._path,
            closed: this._closed,
        };
    }
}
