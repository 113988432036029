import ParagraphDetailProject from '../ParagraphDetailProject';
import { createUuid } from '../../../utility/idGenerator';

export function createParagraphDetailProjectFromApiInput(apiInput: any): ParagraphDetailProject {
    return new ParagraphDetailProject(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.short_title,
        apiInput.type,
        apiInput.contentType,
        apiInput.comments.count,
        apiInput.comments.answered_count
    );
}
