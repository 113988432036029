import React, { FC } from 'react';
import ResetPasswordForm from '../../../shared/forgotPassword/ResetPasswordForm';
import { useHistory, useLocation } from 'react-router-dom';
import ForgotPasswordWrapper from './ForgotPasswordWrapper';
import { useDispatch } from 'react-redux';
import { createResetPasswordAction } from '../../../../../actions/userActionFactory';
import { createDashboardEntryPath } from '../../../../../routing/dashboardUrlGenerator';
import { extractPath } from '../../../../../helper/objectPathHelper';
import { STATUS_CODE_NOT_FOUND } from '../../../../../constants/statusCode';
import { toast } from 'react-toastify';
import { createForgotPasswordPath } from '../../../../../routing/urlGenerator';
import { Dispatch } from '../../../../../actions/factory';

const ResetPassword: FC = () => {
    const dispatch = useDispatch<Dispatch>();
    const location = useLocation();
    const history = useHistory();
    const token = location.pathname.split('/').pop();

    const onSubmit = (newPassword: string) => {
        if (!token) return;

        const action = createResetPasswordAction(token, newPassword);

        dispatch(action).then((response: any) => {
            // Error doesn't end up in catch - not sure why..
            const responseCode = extractPath('response.status', response);
            if (responseCode === STATUS_CODE_NOT_FOUND) {
                toast.error('De door u gebruikte link is niet meer geldig. Doorloop het proces opnieuw!');

                history.push(createForgotPasswordPath());

                return response;
            }

            // use window.location.href to force a hard redirect instead of client side redirect, to make sure that
            // the new authentication cookie is set, and the 'data-loggedin' flag is passed to the application component
            window.location.href = createDashboardEntryPath();
        });
    };

    return (
        <ForgotPasswordWrapper>
            <h1>Wachtwoord resetten</h1>
            <p>Vul hieronder een nieuw wachtwoord in waarmee je wil inloggen.</p>
            <ResetPasswordForm token={token} onSubmit={onSubmit} />
        </ForgotPasswordWrapper>
    );
};

export default ResetPassword;
