import { logError } from '../../utility/Logger';
import arrayMove from 'array-move';
import ProjectDetailParagraph from './ProjectDetailParagraph';
import type { Cloneable } from '../contracts';

export default class ProjectDetailChapter implements Cloneable<ProjectDetailChapter> {
    _id: string;
    _externalId: string | undefined | null;
    _title: string;
    _index: number;
    _paragraphs: Array<ProjectDetailParagraph>;
    _path: string | undefined | null;
    _inProjects: Array<string>;
    _searchable: boolean;
    _commentCount: number;
    _answeredCommentCount: number;
    _authorizedAt: string | undefined | null;
    _reviewedAt: string | undefined | null;

    constructor(
        id: string,
        externalId: string | undefined | null,
        title: string,
        index: number,
        paragraphs: Array<ProjectDetailParagraph>,
        path: string | undefined | null,
        inProjects: Array<string>,
        searchable: boolean,
        commentCount: number,
        answeredCommentCount: number,
        authorizedAt?: string | null,
        reviewedAt?: string | null
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._index = index;
        this._paragraphs = paragraphs;
        this._path = path;
        this._inProjects = inProjects;
        this._searchable = searchable;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
        this._authorizedAt = authorizedAt;
        this._reviewedAt = reviewedAt;
    }

    is(otherChapter: ProjectDetailChapter): boolean {
        return this._id === otherChapter.id;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get index(): number {
        return this._index;
    }

    get externalId(): string | undefined | null {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get paragraphs(): Array<ProjectDetailParagraph> {
        return this._paragraphs;
    }

    getParagraphById(paragraphId: string): ProjectDetailParagraph | undefined | null {
        return this._paragraphs.find((paragraph) => paragraph.id === paragraphId);
    }

    getParagraphByExternalId(externalId: string): ProjectDetailParagraph | undefined | null {
        return this._paragraphs.find((paragraph) => paragraph.externalId === externalId);
    }

    removeParagraphWithId(idOfParagraphToBeRemoved: string): void {
        this._paragraphs = this._paragraphs.filter((paragraph) => paragraph.id !== idOfParagraphToBeRemoved);
    }

    get path(): string | undefined | null {
        return this._path;
    }

    get inProjects(): Array<string> {
        return this._inProjects;
    }

    get searchable(): boolean {
        return this._searchable;
    }

    set searchable(searchable: boolean) {
        this._searchable = searchable;
    }

    moveParagraphToIndex(paragraphId: string, newIndex: number): void {
        const oldIndex = this._paragraphs.findIndex((paragraph) => paragraph.id === paragraphId);

        if (oldIndex === -1) {
            // eslint-disable-line no-magic-numbers
            logError(new Error('Could not find paragraph in chapter'), {
                paragraphId,
                chapterId: this._id,
            });

            return;
        }

        this._paragraphs = arrayMove(this._paragraphs, oldIndex, newIndex);
    }

    get commentCount(): number {
        return this._commentCount;
    }

    incrementCommentCount(): void {
        this._commentCount++;
    }

    get answeredCommentCount(): number {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount(): void {
        this._answeredCommentCount++;
    }

    get authorizedAt(): Date | string | undefined | null {
        return this._authorizedAt;
    }

    get reviewedAt(): Date | string | undefined | null {
        return this._reviewedAt;
    }

    clone(): ProjectDetailChapter {
        return new ProjectDetailChapter(
            this._id,
            this._externalId,
            this._title,
            this._index,
            this._paragraphs.map((paragraph) => paragraph.clone()),
            this._path,
            this._inProjects,
            this._searchable,
            this._commentCount,
            this._answeredCommentCount,
            this._authorizedAt,
            this._reviewedAt
        );
    }

    isPersistedOnTheServer(): boolean {
        return this._externalId !== null;
    }

    isBeingReused(): boolean {
        return this._inProjects.length > 1;
    }
}
