import React from 'react';
import PropTypes from 'prop-types';
import User from '../../../model/User';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { checkUserIsGranted } from '../../../security/helpers/securityHelpers';

class SecureDropdownItem extends React.Component {
    /**
     * @returns {String}
     */
    render() {
        var { currentUser, permissionRequirementCallback, authorizationVotes, ...otherProps } = this.props;

        if (!(currentUser instanceof User)) {
            return null;
        }

        var permissionRequirement = permissionRequirementCallback(this.props),
            authorized = checkUserIsGranted(permissionRequirement, authorizationVotes);

        if (!authorized) {
            return null;
        }

        // remove other redandant keys for SecureDropdownItem
        delete otherProps.dispatch;

        return <Dropdown.Item {...otherProps} />;
    }
}

SecureDropdownItem.propTypes = {
    currentUser: PropTypes.instanceOf(User),
    permissionRequirementCallback: PropTypes.func.isRequired,
    authorizationVotes: PropTypes.object.isRequired,
};

/**
 * @param {Object} globalState
 *
 * @returns {Object}
 *
 * @private
 */
function _mapGlobalStateToProps(globalState) {
    return {
        currentUser: globalState.currentUser,
        authorizationVotes: globalState.authorizationVotes,
    };
}

export default connect(_mapGlobalStateToProps)(SecureDropdownItem);
