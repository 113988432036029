import createClassName from 'classnames';
import FormActions from '../../../shared/form/FormActions';
import React from 'react';
import useSearchFormHandler from '../../ggz/searchResults/hooks/useSearchFormHandler';

export enum SearchIconPlacement {
    field = 'field',
    button = 'button',
}

export enum SearchFormStyle {
    light = 'light',
    dark = 'dark',
    default = 'default',
}

export type FormData = {
    query: string;
};

export type OnSubmitCallback = (formData: FormData) => void;

type Props = {
    onSubmit: OnSubmitCallback;
    className?: string;
    buttonClassName: string;
    buttonText?: string;
    showSubmitButton?: boolean;
    placeholder: string;
    hasAutoFocus?: boolean;
    query?: string;
    searchIconPlacement?: SearchIconPlacement;
    formFieldType?: 'primary' | 'solid';
    style?: SearchFormStyle;
    size?: 'small' | 'default';
    rounded?: boolean;
    validateBeforeSubmit?: boolean;
    icon: JSX.Element;
};

const SearchForm = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        onSubmit: onSubmitHandler,
        className,
        buttonClassName,
        buttonText = '',
        showSubmitButton = true,
        placeholder,
        hasAutoFocus = false,
        query: initialQuery = '',
        searchIconPlacement = SearchIconPlacement.button,
        formFieldType = 'solid',
        style = SearchFormStyle.default,
        size = 'default',
        rounded = false,
        validateBeforeSubmit = true,
        icon,
    } = props;

    const { onQueryFieldChange, onSubmit, query } = useSearchFormHandler(
        initialQuery,
        onSubmitHandler,
        validateBeforeSubmit
    );

    const formClassName = createClassName(
        'form search-form',
        {
            'form--small': size === 'small',
            'search-form--light': style === SearchFormStyle.light,
        },
        className
    );

    const formGroupClassName = createClassName('form__group', {
        'form__group--has-field-icon': searchIconPlacement === SearchIconPlacement.field,
    });

    const formFieldClassName = createClassName('form__field', {
        'form__field--solid': formFieldType === 'solid',
        'form__field--rounded': rounded,
        'form__field--light': style === SearchFormStyle.light,
        'form__field--dark': style === SearchFormStyle.dark,
    });

    return (
        <form onSubmit={onSubmit} className={formClassName}>
            <div className={formGroupClassName}>
                <input
                    autoFocus={hasAutoFocus}
                    value={query}
                    placeholder={placeholder}
                    onChange={onQueryFieldChange}
                    className={formFieldClassName}
                    name="search"
                    type="search"
                    ref={ref}
                />
                {searchIconPlacement === SearchIconPlacement.field && icon}
            </div>
            {showSubmitButton && (
                <FormActions>
                    <button type="submit" className={buttonClassName}>
                        {searchIconPlacement === SearchIconPlacement.button && icon}
                        {buttonText && <span>{buttonText}</span>}
                    </button>
                </FormActions>
            )}
        </form>
    );
});

export default SearchForm;
