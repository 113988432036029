import React from 'react';
import FormGroup from '../../shared/form/FormGroup';
import { StandardOverviewItem } from '../../../model/standardOverview/StandardOverviewItem';
import FormErrorMessage from '../../shared/form/FormErrorMessage';
import Loader from '../../shared/Loader';
import useHandleStartRevisionProjectFormValues from './hooks/useHandleStartRevisionProjectFormValues';
import Select, { DefaultOptionType } from '../../shared/form/select/Select';
import Button, { ButtonStyles } from '../../shared/buttons/Button';
import useCurrentTenant from '../../../hooks/useCurrentTenant';

export type OnFormValidHandler = (externalStandardId: string) => void;
export type OnCancelHandler = () => void;

type Props = {
    showLoader?: boolean;
    onFormValid: OnFormValidHandler;
    onCancel: OnCancelHandler;
    standardOverviewItemChoices: StandardOverviewItem[];
};

const StartRevisionProjectForm: React.FC<Props> = ({
    showLoader,
    onFormValid,
    onCancel,
    standardOverviewItemChoices,
}) => {
    // @ts-ignore not sure why this error is suddenly popping up...
    const { handleSubmit, setFieldValue, handleBlur, touched, errors, isSubmitting, options, currentStandardIdOption } =
        useHandleStartRevisionProjectFormValues(onFormValid, standardOverviewItemChoices);
    const { isSKILZ } = useCurrentTenant();

    // in case of choices still being fetched
    if (showLoader) {
        return <Loader />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup hasErrors={!!touched.standardId && !!errors.standardId}>
                <label className="form__label" htmlFor="standard_select">
                    Kies een&nbsp;{isSKILZ ? 'richtlijn' : 'standaard'}
                </label>
                <Select
                    id="standard_select"
                    placeholder="Start met typen"
                    autoFocus
                    onChange={(selectedOption: DefaultOptionType) => {
                        setFieldValue('standardId', selectedOption ? selectedOption.value : null);
                    }}
                    value={currentStandardIdOption}
                    onBlur={handleBlur}
                    options={options}
                    disabled={isSubmitting}
                />
                {touched.standardId && errors.standardId && <FormErrorMessage>{errors.standardId}</FormErrorMessage>}
            </FormGroup>
            {isSubmitting ? (
                <Loader />
            ) : (
                <div>
                    <Button type="submit" style={ButtonStyles.primary}>
                        Start project
                    </Button>
                    <Button style={ButtonStyles.link} onClick={() => onCancel()}>
                        Annuleren
                    </Button>
                </div>
            )}
        </form>
    );
};

export default StartRevisionProjectForm;
