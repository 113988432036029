import {
    createFetchPublishedPageDetailApiPath,
    createFetchPageOverviewApiPath,
    createPageCreateApiPath,
    createFetchPageDetailApiPath,
    createPageUpdateApiPath,
    createPageDeleteApiPath,
} from '../routing/apiUrlGenerator';
import { Page } from '../model/Page';
import { doDelete, get as fetch, get, post } from '../api/client/apiClient';
import { PaginatedCollectionType } from '../model/collection/paginatedCollection';
import { createInMemoryCache } from '@freshheads/javascript-essentials/build/cache/factory/inMemoryCacheFactory';

const MAX_CACHE_LIFETIME = 300; // 5 minutes

const pageCache = createInMemoryCache<PaginatedCollectionType<Page>>('page_items_per_page', MAX_CACHE_LIFETIME);

export const getOneWithSlug = async (slug: string): Promise<Page> => {
    const response = await get(createFetchPublishedPageDetailApiPath(slug));

    return await response.data;
};

export const getOneWithId = async (id: string, isEdit: boolean): Promise<Page> => {
    const response = await get(createFetchPageDetailApiPath(id, isEdit));

    return await response.data;
};

export const persistNewPageToServer = async (
    title: string,
    publishedAt: string | null,
    content: string,
    seoDescription: string | null
) => {
    const url = createPageCreateApiPath();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('publishedAt', publishedAt || '');
    formData.append('content', content);
    formData.append('seoDescription', seoDescription || '');

    const response = await post(url, formData);

    const { id } = await response.data;

    pageCache.clear();

    return id;
};

export const pushPageUpdateToServer = async (
    id: string,
    title: string,
    publishedAt: string | null,
    content: string,
    seoDescription: string | null,
    image: any | null
) => {
    const url = createPageUpdateApiPath(id);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('publishedAt', publishedAt || '');
    formData.append('content', content);
    formData.append('seoDescription', seoDescription || '');
    formData.append('image', image);

    const response = await post(url, formData);

    pageCache.clear();

    await response.data;
};

export const deletePageFromServer = async (id: string) => {
    await doDelete(createPageDeleteApiPath(id));

    pageCache.clear();
};

export const findAllForPage = async (
    page: number,
    standardId: string | null,
    perPage: number | null,
    includeUnpublished: boolean
): Promise<PaginatedCollectionType<Page>> => {
    const cacheKey = `${page}_${standardId}_${perPage}`;

    return await pageCache.getOrCreate(cacheKey, async () => {
        const response = await fetch(createFetchPageOverviewApiPath(page, standardId, perPage, includeUnpublished));

        return await response.data;
    });
};
