import React from 'react';

type Props = {
    code: string;
};

const Video = ({ code }: Props) => (
    <div className="embed-container">
        <iframe
            src={`https://player.vimeo.com/video/${code}?title=0&byline=0&portrait=0`}
            width="640"
            height="360"
            frameBorder="0"
        />
    </div>
);

export default Video;
