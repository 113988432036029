import { GET_BOOKMARKS, ADD_BOOKMARK, REMOVE_BOOKMARK, DELETE_BOOKMARK_TAG } from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import {
    createBookmarkFromAddBookmarkFulfilledAction,
    createBookmarkFromApiData,
} from '../model/factory/bookmarkFactory';
import Bookmark from '../model/Bookmark';

import type { Action } from '../actions/factory';

export type BookmarksReducerState = Array<Bookmark>;

function _handleAddBookMarkFulfilledAction(currentState: BookmarksReducerState, action: Action): BookmarksReducerState {
    return [...currentState, createBookmarkFromAddBookmarkFulfilledAction(action)];
}

function _handleRemoveBookMarkPendingAction(
    currentState: BookmarksReducerState,
    action: Action
): BookmarksReducerState {
    const externalBookmarkId = action.meta.externalId;

    return currentState.filter((bookmark) => bookmark.externalId !== externalBookmarkId);
}

function _handleFetchBookmarksFulfilledAction(
    currentState: BookmarksReducerState,
    action: Action
): BookmarksReducerState {
    const apiBookmarksData = action.payload.data;

    // @ts-ignore -> typescript does not know the action contents
    const incomingBookmarks: Bookmark[] = apiBookmarksData.map((bookmark) => createBookmarkFromApiData(bookmark));

    // when this is the first fetch return everything that comes back from API
    if (currentState.length === 0) {
        return incomingBookmarks;
    }

    // When we have items in are state start merging them with incoming bookmarks
    const newState: typeof currentState = [];

    currentState.forEach((currentBookmark) => {
        const incomingBookmark = incomingBookmarks.find(
            (incomingBookmark) => incomingBookmark.externalId === currentBookmark.externalId
        );

        if (incomingBookmark) {
            // set currentBookmark id to re-use the same client-side id to be able to hold references
            // to the bookmarks throughout the application's lifetime
            incomingBookmark.id = currentBookmark.id;

            newState.push(incomingBookmark);
        }
    });

    return newState;
}

function _handleDeleteBookmarkTagPendingAction(
    currentState: BookmarksReducerState,
    action: Action
): BookmarksReducerState {
    const newState = currentState.map((bookmark) => bookmark.clone());

    for (let i = 0, l = newState.length; i < l; i++) {
        const bookmark = newState[i];

        bookmark.removeTagWithExternalId(action.meta.externalId);
    }

    return newState;
}

export default function bookmarksReducer(
    currentState: BookmarksReducerState = [],
    action: Action
): BookmarksReducerState {
    switch (action.type) {
        case `${ADD_BOOKMARK}_${ActionType.Fulfilled}`:
            return _handleAddBookMarkFulfilledAction(currentState, action);

        case `${REMOVE_BOOKMARK}_${ActionType.Pending}`:
            return _handleRemoveBookMarkPendingAction(currentState, action);

        case `${GET_BOOKMARKS}_${ActionType.Fulfilled}`:
            return _handleFetchBookmarksFulfilledAction(currentState, action);

        case `${DELETE_BOOKMARK_TAG}_${ActionType.Pending}`:
            return _handleDeleteBookmarkTagPendingAction(currentState, action);

        default:
            return currentState;
    }
}
