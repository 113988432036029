import LoadingQueueItem from './loadingQueueItem';
import type { ItemIdType } from './loadingQueueItem';

type ItemsType = Array<LoadingQueueItem>;

export default class LoadingQueue {
    _items: ItemsType;

    constructor(items: ItemsType = []) {
        this._items = items;
    }

    add(type: string, id: ItemIdType = null): LoadingQueue {
        const newItems = this._items.map((item) => item.clone());

        newItems.push(new LoadingQueueItem(type, id));

        return new LoadingQueue(newItems);
    }

    removeFirstMatching(type: string, id: ItemIdType = null): LoadingQueue {
        const indexToRemove = this._findIndex(type, id);

        if (indexToRemove < 0) {
            // index not found, no need to continue

            return this;
        }

        const newItems = this._items.map((item) => item.clone());
        newItems.splice(indexToRemove, 1);

        return new LoadingQueue(newItems);
    }

    count() {
        return this._items.length;
    }

    countWithType(type: string, id: ItemIdType = null): number {
        return this.filterAllWithTYpe(type, id).count();
    }

    filterAllWithTYpe(type: string, id: ItemIdType = null): LoadingQueue {
        return new LoadingQueue(
            this._items
                .filter((item) => item.type === type && (id === null || item.id === id))
                .map((item) => item.clone())
        );
    }

    _findIndex(type: string, id: ItemIdType = null): number {
        return this._items.findIndex((item) => item.type === type && (id === null || item.id === id));
    }
}
