import { FETCH_STANDARD_OVERVIEW, FETCH_STANDARD_ALL_OVERVIEW } from '../actions/types';
import { createStandardOverviewItemCollectionFromApiData } from '../model/standardOverview/factory/standardOverviewItemFactory';
import { ActionType } from 'redux-promise-middleware';
import { StandardOverviewItem } from '../model/standardOverview/StandardOverviewItem';

import type { Action } from '../actions/factory';

export type StandardOverviewReducerState = Array<StandardOverviewItem> | undefined | null;

export default function (
    currentState: StandardOverviewReducerState = null,
    action: Action
): StandardOverviewReducerState {
    switch (action.type) {
        case `${FETCH_STANDARD_OVERVIEW}_${ActionType.Fulfilled}`:
            return createStandardOverviewItemCollectionFromApiData(
                // @ts-ignore -> Flow does not know payload contents
                action.payload.data.results,
            );

        case `${FETCH_STANDARD_ALL_OVERVIEW}_${ActionType.Fulfilled}`:
            return createStandardOverviewItemCollectionFromApiData(
                // @ts-ignore -> Flow does not know payload contents
                action.payload.data.results,
            );

        default:
            return currentState;
    }
}
