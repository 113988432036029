import { extractPath } from './objectPathHelper';
import { isArray } from 'lodash';
import { STATUS_CODE_BAD_REQUEST } from '../constants/statusCode';
import type { FormErrorCollectionType } from '../lib/forms/model/FormState';

type ResponseError = {
    field: string;
    message: string;
};

export function extractFormErrorsFromResponseError(error: any): FormErrorCollectionType {
    const responseStatusCode: number | undefined | null = extractPath('response.status', error);

    // check if bad request response, as those are returned for form errors
    if (responseStatusCode !== STATUS_CODE_BAD_REQUEST) {
        // not possible to extract error codes

        return {};
    }

    const errors: Array<ResponseError> = extractPath('response.data.data.errors', error, []);

    if (!isArray(errors)) {
        // invalid response format

        return {};
    }

    const formErrors: FormErrorCollectionType = {};

    errors.forEach((error) => {
        if (typeof formErrors[error.field] === 'undefined') {
            formErrors[error.field] = [];
        }

        formErrors[error.field].push(error.message);
    });

    return formErrors;
}
