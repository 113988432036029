import React from 'react';
import PropTypes from 'prop-types';
import ExpandedContentBlock from './ExpandedContentBlock';
import { findReferenceMatchingId } from '../../../../repository/referenceRepository';
import Loader from '../../Loader';

class Reference extends React.Component {
    /**
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            reference: null,
        };

        this._onContentBlockClose = this._onContentBlockClose.bind(this);
        this._onLinkClick = this._onLinkClick.bind(this);
    }

    /**
     * @private
     */
    _onContentBlockClose() {
        this.setState({
            expanded: false,
        });
    }

    /**
     * @returns {XML|null}
     *
     * @private
     */
    _renderContentBlockIfRequired() {
        var { expanded, reference } = this.state;

        var child = reference ? null : <Loader sizeSmall={true} />;

        if (!expanded) {
            return null;
        }

        return (
            <ExpandedContentBlock
                content={reference ? reference.description : undefined}
                title="Referentie"
                onClose={this._onContentBlockClose}
            >
                {child}
            </ExpandedContentBlock>
        );
    }

    /**
     * @private
     */
    _fetchReference() {
        findReferenceMatchingId(this.props.id).then((reference) => this.setState({ reference }));
    }

    /**
     * @param {Event} event
     *
     * @private
     */
    _onLinkClick(event) {
        event.preventDefault();

        if (!this.state.reference) {
            this._fetchReference();
        }

        this.setState({
            expanded: true,
        });
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <span>
                <a href="#" className="reference" onClick={this._onLinkClick} />

                {this._renderContentBlockIfRequired()}
            </span>
        );
    }
}

Reference.propTypes = {
    id: PropTypes.number.isRequired,
};

export default Reference;
