import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createFrontendEntryPath, createLoginPath, createLogoutPath } from '../../../../routing/urlGenerator';
import { Dropdown } from 'semantic-ui-react';
import User from '../../../../model/User';
import SecureDropdownItem from '../../security/SecureDropdownItem';
import { getRequiredPermissions as getRequiredPermissionsForUsersOverview } from '../../../dashboard/usersOverview/UsersOverview';
import { getRequiredPermissions as getRequiredPermissionsForProjectOverview } from '../../../dashboard/projectTypesOverview/ProjectTypesOverview';
import { getRequiredPermissions as getRequiredPermissionsForStandardOverview } from '../../../dashboard/standardOverview/StandardOverview';
import { getRequiredPermissions as getRequiredPermissionsForBranchOverview } from '../../../dashboard/branchOverview/BranchOverview';
import { getRequiredPermissions as getRequiredPermissionsForPageOverview } from '../../../dashboard/pageOverview/PageOverview';
import { getRequiredPermissions as getRequiredPermissionsForSideProductCategoryOverview } from '../../../dashboard/sideProductCategoriesOverview/SideProductCategoriesOverview';
import { getRequiredPermissions as getRequiredPermissionsForFaqOverview } from '../../../dashboard/faqOverview/FaqOverview';
import type { DispatchProps } from '../../../../actions/factory';
import type { GlobalState } from '../../../../store/types';
import { withRouter } from 'react-router-dom';
import type { History } from 'history';
import { getRequiredPermissions as getRequiredPermissionsForArticleOverview } from '../../../dashboard/articleOverview/ArticleOverview';
import AccountIcon from '../../../../../../icons/ggz/account.svg';
import { TenantType } from '../../../../constants/settings';
import { userMenuTextItems } from '../../../../translations/translations';
import {
    createBookmarksEntryPath,
    createDashboardArticleOverviewPath,
    createDashboardBranchOverviewPath,
    createDashboardEntryPath,
    createDashboardFaqOverviewPath,
    createDashboardPageOverviewPath,
    createDashboardProjectTypesOverviewPath,
    createDashboardSideProductCategoriesOverviewPath,
    createDashboardStandardOverviewPath,
    createDashboardUserAccountPath,
    createDashboardUsersOverviewPath,
} from '../../../../routing/dashboardUrlGenerator';

type OwnProps = {
    history: History;
};

type ReduxSuppliedProps = {
    isAuthenticated: boolean;
    currentUser: User | undefined | null;
    tenant: string | undefined | null;
};

type CombinedProps = {} & OwnProps & ReduxSuppliedProps & DispatchProps;

class UserMenu extends Component<CombinedProps> {
    _onDashboardClick = () => {
        this.props.history.push(createDashboardEntryPath());
    };

    _onBookmarksClick = () => {
        this.props.history.push(createBookmarksEntryPath());
    };

    _onAccountClick = () => {
        this.props.history.push(createDashboardUserAccountPath());
    };

    _onUserOverviewClick = () => {
        this.props.history.push(createDashboardUsersOverviewPath(1));
    };

    _onBranchOverviewClick = () => {
        this.props.history.push(createDashboardBranchOverviewPath());
    };

    _onStandardOverviewClick = () => {
        this.props.history.push(createDashboardStandardOverviewPath());
    };

    _onProjectOverviewClick = () => {
        this.props.history.push(createDashboardProjectTypesOverviewPath());
    };

    _onPageOverviewClick = () => {
        this.props.history.push(createDashboardPageOverviewPath());
    };

    _onSideProductCategoryOverviewClick = () => {
        this.props.history.push(createDashboardSideProductCategoriesOverviewPath());
    };

    _onFaqOverviewClick = () => {
        this.props.history.push(createDashboardFaqOverviewPath());
    };

    _onArticleOverviewClick = () => {
        this.props.history.push(createDashboardArticleOverviewPath());
    };

    _onHomeClick = () => {
        this.props.history.push(createFrontendEntryPath());
    };

    _onLogoutClick = () => {
        window.location.href = createLogoutPath();
    };

    _renderLoginState() {
        const { isAuthenticated, currentUser } = this.props;

        if (!this.props.tenant) return null;
        if (isAuthenticated && currentUser instanceof User) {
            const triggerElement = (
                <button className="header__navigation-user-trigger">
                    <AccountIcon />
                    <span className="header__navigation-user-text">{currentUser.firstName}</span>
                </button>
            );

            return (
                <>
                    <Dropdown icon={false} trigger={triggerElement} className="dropdown--small">
                        <Dropdown.Menu>
                            <Dropdown.Item text={`Ingelogd als ${currentUser.firstName}`} disabled />
                            <Dropdown.Item text="Home" onClick={this._onHomeClick} />
                            <Dropdown.Item text="Dashboard" onClick={this._onDashboardClick} />
                            <Dropdown.Item text="Account" onClick={this._onAccountClick} />
                            {this.props.tenant === TenantType.GGZ && (
                                <Dropdown.Item text="Favorieten" onClick={this._onBookmarksClick} />
                            )}
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].users}
                                onClick={this._onUserOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForUsersOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].branches}
                                onClick={this._onBranchOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForBranchOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].projects}
                                onClick={this._onProjectOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForProjectOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].standards}
                                onClick={this._onStandardOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForStandardOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].pages}
                                onClick={this._onPageOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForPageOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].articles}
                                onClick={this._onArticleOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForArticleOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].sideProductCategories}
                                onClick={this._onSideProductCategoryOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForSideProductCategoryOverview}
                            />
                            <SecureDropdownItem
                                // @ts-ignore
                                text={userMenuTextItems[this.props.tenant as TenantType].faq}
                                onClick={this._onFaqOverviewClick}
                                permissionRequirementCallback={getRequiredPermissionsForFaqOverview}
                            />
                            <Dropdown.Item text="Uitloggen" onClick={this._onLogoutClick} />
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            );
        }

        return (
            <a href={createLoginPath()} className="header__navigation-user-trigger">
                <AccountIcon />
                <span className="header__navigation-user-text">Inloggen</span>
            </a>
        );
    }

    render() {
        return <nav className="header__navigation-user">{this._renderLoginState()}</nav>;
    }
}

const withGlobalStateAccess = connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>(
    (globalState: GlobalState): ReduxSuppliedProps => {
        return {
            currentUser: globalState.currentUser,
            isAuthenticated: globalState.authenticated,
            tenant: globalState.settings?.context.tenant,
        };
    }
);

// @ts-ignore don't know how to fix this beside move to functional component with useSelector hook
export default withRouter(withGlobalStateAccess(UserMenu));
