export const REQUIRE_ALL = 'require_all';
export const REQUIRE_ONE = 'require_one';

export default class PermissionRequirement {
    _permissions: Array<string>;
    _flag: string;

    constructor(permissions: Array<string> | string, flag: string = REQUIRE_ONE) {
        this._permissions = Array.isArray(permissions) ? permissions : [permissions];
        this._flag = flag;
    }

    get permissions(): Array<string> {
        return this._permissions;
    }

    get flag(): string {
        return this._flag;
    }

    requireAll(): boolean {
        return this._flag === REQUIRE_ALL;
    }

    requireOne(): boolean {
        return this._flag === REQUIRE_ONE;
    }
}
