import { StandardOverviewItem } from '../StandardOverviewItem';

export function createStandardOverviewItemCollectionFromApiData(apiData: Array<any>): Array<StandardOverviewItem> {
    return apiData.map((data) => {
        return createStandardOverviewItemFromApiData(data);
    });
}

export function createStandardOverviewItemFromApiData(apiData: any): StandardOverviewItem {
    return {
        externalId: apiData.id,
        type: apiData.type,
        title: apiData.title,
        shortTitle: apiData.short_title,
        path: apiData._path,
        slug: apiData.slug,
        typeSlug: apiData.type_slug,
        publishedAt: apiData.published_at,
        authorizedAt: apiData.authorized_at,
        archivedAt: apiData.archived_at,
        activeRevisionProjectId: apiData.active_revision_project_id,
        sideProductTypes: apiData.side_product_types,
        linkTypes: apiData.link_types,
        tags: apiData.tags,
        publicationOverviewItems: apiData.publication_overview_items.map((publicationOverviewItemData: any) => {
            return {
                publishedAt: publicationOverviewItemData.published_at,
                pdfFile: publicationOverviewItemData.pdf_file,
            };
        }),
        owner: apiData.template_id || 'default',
    };
}
