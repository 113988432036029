import React, { FC } from 'react';
import useFetchProjectDetailsOnSlugChanges from '../../../../hooks/useFetchProjectDetailsOnSlugChanges';
import ProjectDetailHeader from './components/ProjectDetailHeader';
import ProjectSideProduct, { ProjectSideProductType } from '../../../../model/ProjectSideProduct';
import Loader from '../../../shared/Loader';
import { Route, Switch } from 'react-router-dom';
import ProjectDetailDirectivePublication from './components/ProjectDetailDirectivePublication';
import ProjectDetailTabs from '../../shared/projectDetailTabs/ProjectDetailTabs';
import { createProjectDetailTabPath } from '../../../../routing/urlGenerator';
import { skilzProjectTabSlugs } from '../../../../resolver/projectDetailTabsResolver';
import ProjectDetailApplicationOverview from '../../shared/ProjectDetailApplicationOverview/ProjectDetailApplicationOverview';
import { ProjectDetailContextProvider } from '../../ggz/projectDetail/components/ProjectDetailContext';
import ProjectPatientInformation from '../../skilz/projectPatientInformation/ProjectPatientInformation';
import ProjectAccountability from '../../shared/projectAccountability/ProjectAccountability';
import { ProjectDetailLinkType } from '../../../../model/projectDetail/ProjectDetailLink';
import styles from './ProjectDetail.module.scss';

const ProjectDetail: FC = () => {
    const { projectDetails } = useFetchProjectDetailsOnSlugChanges();

    const summarySideProducts: ProjectSideProduct[] = projectDetails
        ? projectDetails.getSideProductsByType(ProjectSideProductType.summaryCard)
        : [];

    if (!projectDetails) {
        return <Loader />;
    }

    return (
        <ProjectDetailContextProvider>
            <ProjectDetailHeader
                title={projectDetails.shortTitle ?? projectDetails.title}
                type={projectDetails.type}
                pdfFilePath={projectDetails.pdfFile}
                summarySideProducts={summarySideProducts}
                owner={projectDetails.owner}
            />
            <ProjectDetailTabs style="Skilz" />
            <div className={styles.contentContainer}>
                <div className="container">
                    <Switch>
                        <Route
                            exact
                            path={createProjectDetailTabPath(
                                projectDetails.typeSlug,
                                projectDetails.slug,
                                skilzProjectTabSlugs.application
                            )}
                        >
                            <ProjectDetailApplicationOverview
                                sideProductTypes={[ProjectSideProductType.apply]}
                                linkTypes={[ProjectDetailLinkType.apply]}
                            />
                        </Route>
                        <Route
                            exact
                            path={createProjectDetailTabPath(
                                projectDetails.typeSlug,
                                projectDetails.slug,
                                skilzProjectTabSlugs.indepth
                            )}
                            // @todo: find a better way for these changes
                            // See what went wrong in https://github.com/freshheads/ggz-zorgstandaarden/pull/2073/files
                            // transparency side products should NOT have been merged with indepth overview as it is styled differently
                        >
                            <ProjectDetailApplicationOverview
                                sideProductTypes={[ProjectSideProductType.transparency]}
                                linkTypes={[ProjectDetailLinkType.transparency]}
                            />
                        </Route>
                        <Route
                            exact
                            path={createProjectDetailTabPath(
                                projectDetails.typeSlug,
                                projectDetails.slug,
                                skilzProjectTabSlugs.patients
                            )}
                        >
                            <ProjectPatientInformation title="Cliënteninformatie" />
                        </Route>
                        <Route
                            exact
                            path={createProjectDetailTabPath(
                                projectDetails.typeSlug,
                                projectDetails.slug,
                                skilzProjectTabSlugs.accountability
                            )}
                            component={ProjectAccountability}
                        />
                        <Route component={ProjectDetailDirectivePublication} />
                    </Switch>
                </div>
            </div>
        </ProjectDetailContextProvider>
    );
};

export default ProjectDetail;
