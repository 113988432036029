import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import styles from '../SideProductOverview.module.scss';
import PlayIcon from '../../../../../../../icons/ggz/play.svg';
import useShowHideModal from '../../../../../hooks/useShowHideModal';
import Button from '../../../../shared/buttons/Button';
import VideoModal from './VideoModal';
import Video from '../../../../shared/editorContent/components/Video';
import { getVimeoIdFromUrl } from '../utility/getVimeoIdFromUrl';

type Props = {
    title: string;
    thumbnailUrl: string | null;
    videoUrl: string | null;
    durationInSeconds: number | null;
};

const SideProductOverviewVideoItem = ({ title, thumbnailUrl, videoUrl, durationInSeconds }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);
    let durationAsString = null;

    if (!thumbnailUrl || !videoUrl) {
        return null;
    }

    const videoId = getVimeoIdFromUrl(videoUrl);

    if (durationInSeconds) {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = (durationInSeconds % 60).toString().padStart(2, '0');
        durationAsString = `${minutes}:${seconds}`;
    }

    return (
        <div className={styles.videoItem}>
            <Button
                isCleared
                additionalClassName={styles.modalButton}
                onClick={doShowModal}
                data-track-id="start_video_click"
            >
                <div className={styles.videoThumbnailContainer}>
                    <PlayIcon className={styles.playIcon} />
                    <img className={styles.videoThumbnail} src={thumbnailUrl} alt={`Video thumbnail: ${title}`} />
                </div>
                <Heading tag="h5" flatten className={styles.heading}>
                    {title}
                </Heading>
            </Button>
            {durationAsString && <span className={styles.duration}>{durationAsString}</span>}
            {showModal && (
                <VideoModal title={title} onDecline={doHideModal}>
                    {videoId ? <Video code={videoId} /> : <p>Video kan niet worden weergeven.</p>}
                </VideoModal>
            )}
        </div>
    );
};

export default SideProductOverviewVideoItem;
