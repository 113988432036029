import * as React from 'react';
import { PROJECT_START } from '../../../../security/SecurityAttribute';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';

type Props = {
    children: React.ReactNode;
};

const StartProjectThreshold = ({ children }: Props) => (
    <SecurityThreshold requiredAttributes={PROJECT_START}>{children}</SecurityThreshold>
);

export default StartProjectThreshold;
