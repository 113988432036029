import { useDispatch } from 'react-redux';
import { createStartProjectAction } from '../../../../actions/projectActionFactory';
import { OnFormValidHandler } from '../../projectForm/ProjectForm';

export default function useDispatchStartActionOnValidSubmit() {
    const dispatch = useDispatch();

    const onFormValid: OnFormValidHandler = async (values) => {
        try {
            await dispatch(
                createStartProjectAction(values.title, values.shortTitle, values.type, values.tags, values.templateId)
            );

            return true;
        } catch {
            return false;
        }
    };

    return { onFormValid };
}
