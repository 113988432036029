import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const NestedCounterList = ({ children }: Props) => {
    return <ol className="nested-counter-list">{children}</ol>;
};

export default NestedCounterList;
