import {
    START_PROJECT,
    FETCH_PROJECT_OVERVIEW,
    CLOSE_PROJECT,
    UPDATE_PROJECT,
    PUBLISH_PROJECT,
    PROJECT_POST_COMMENT,
    PROJECT_REACT_ON_COMMENT,
} from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import {
    createProjectOverviewItemCollectionFromApiData,
    createProjectOverviewItemFromStartProjectPendingActionPayload,
} from '../model/projectOverview/factory/projectOverviewItemFactory';
import ProjectOverviewItem from '../model/projectOverview/ProjectOverviewItem';

import type { Action } from '../actions/factory';

export type ProjectOverviewReducerState = Array<ProjectOverviewItem> | undefined | null;

function _handleCloseProjectPendingAction(
    currentState: ProjectOverviewReducerState,
    action: Action
): ProjectOverviewReducerState {
    if (!Array.isArray(currentState)) {
        return null;
    }

    return currentState.map((item) => item.clone()).filter((item) => item.id !== action.payload.id);
}

function _handleUpdateProjectPendingAction(
    currentState: ProjectOverviewReducerState,
    action: Action
): ProjectOverviewReducerState {
    // there might not be state at this point, ie. when you are on another screen and you have not yet loaded
    // the project overview
    if (!Array.isArray(currentState)) {
        return currentState;
    }

    const id = action.payload.id;
    const newTitle = action.payload.title;

    return currentState.map((projectOverviewItem) => {
        const clonedProjectOverviewItem = projectOverviewItem.clone();

        if (clonedProjectOverviewItem.id === id) {
            clonedProjectOverviewItem.title = newTitle;
        }

        return clonedProjectOverviewItem;
    });
}

function _handlePublishProjectPendingAction(
    currentState: ProjectOverviewReducerState,
    action: Action
): ProjectOverviewReducerState {
    if (!Array.isArray(currentState)) {
        return currentState;
    }

    return currentState.map((item) => item.clone()).filter((item) => item.externalId !== action.payload.externalId);
}

function _handleProjectPostCommentPendingAction(
    currentState: ProjectOverviewReducerState,
    action: Action
): ProjectOverviewReducerState {
    if (!Array.isArray(currentState)) {
        return currentState;
    }

    const externalProjectId: string = action.meta.externalProjectId;
    const newState: Array<ProjectOverviewItem> = currentState.map((projectOverviewItem) => projectOverviewItem.clone());
    const projectOverviewItem: ProjectOverviewItem | undefined | null = newState.find(
        (item: ProjectOverviewItem) => item.externalId === externalProjectId
    );

    if (!(projectOverviewItem instanceof ProjectOverviewItem)) {
        return currentState;
    }

    projectOverviewItem.incrementCommentCount();

    return newState;
}

function _handleProjectReactOnCommentPendingAction(
    currentState: ProjectOverviewReducerState,
    action: Action
): ProjectOverviewReducerState {
    if (!Array.isArray(currentState)) {
        return currentState;
    }

    const isConcept = action.payload.concept;

    if (isConcept) {
        return currentState;
    }

    const externalProjectId: string = action.meta.externalProjectId;
    const newState: Array<ProjectOverviewItem> = currentState.map((projectOverviewItem) => projectOverviewItem.clone());
    const projectOverviewItem: ProjectOverviewItem | undefined | null = newState.find(
        (item: ProjectOverviewItem) => item.externalId === externalProjectId
    );

    if (!(projectOverviewItem instanceof ProjectOverviewItem)) {
        return currentState;
    }

    projectOverviewItem.incrementAnsweredCommentCount();

    return newState;
}

export default function (
    currentState: ProjectOverviewReducerState = null,
    action: Action
): ProjectOverviewReducerState {
    switch (action.type) {
        case `${START_PROJECT}_${ActionType.Pending}`:
            return [
                createProjectOverviewItemFromStartProjectPendingActionPayload(action.payload),
                ...(Array.isArray(currentState) ? currentState : []),
            ];

        case `${FETCH_PROJECT_OVERVIEW}_${ActionType.Fulfilled}`:
            return createProjectOverviewItemCollectionFromApiData(
                // @ts-ignore -> typescript does not know action contents
                action.payload.data.results
            );

        case `${CLOSE_PROJECT}_${ActionType.Pending}`:
            return _handleCloseProjectPendingAction(currentState, action);

        case `${UPDATE_PROJECT}_${ActionType.Pending}`:
            return _handleUpdateProjectPendingAction(currentState, action);

        case `${PUBLISH_PROJECT}_${ActionType.Pending}`:
            return _handlePublishProjectPendingAction(currentState, action);

        case `${PROJECT_POST_COMMENT}_${ActionType.Pending}`:
            return _handleProjectPostCommentPendingAction(currentState, action);

        case `${PROJECT_REACT_ON_COMMENT}_${ActionType.Pending}`:
            return _handleProjectReactOnCommentPendingAction(currentState, action);

        default:
            return currentState;
    }
}
