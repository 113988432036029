import React from 'react';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';

type Props = {
    searchResults: PaginatedCollection<ProjectSearchItem>;
};

const AmountOfStandardResults = ({ searchResults }: Props) => {
    const totalNumberOfResults = searchResults.totalNumberOfResults;

    const prefix = totalNumberOfResults === 1 ? '1 resultaat' : `${totalNumberOfResults} resultaten`;

    return <div className="search-result__amount">{`${prefix} gevonden`}</div>;
};

export default AmountOfStandardResults;
