import { DispatchProps } from '../../../actions/factory';
import { SecuritySubject } from '../../../security/SecuritySubjectType';
import { GlobalState } from '../../../store/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { applyHasVoteSelector, applyIsCurrentUserGrantedSelector } from '../../../security/model/selectors';
import { createIsGrantedAction } from '../../../actions/userActionFactory';

type ReduxSuppliedProps = {
    hasVote: boolean;
    isAllowed: boolean | null;
};

type OwnProps = {
    requiredAttributes: string | string[];
    subjectType?: SecuritySubject;
    subjectId?: string;
    alternateContent?: React.ReactNode;
    children: React.ReactNode;
};

type CombinedProps = ReduxSuppliedProps & OwnProps & DispatchProps;

class SecurityThreshold extends React.Component<CombinedProps> {
    componentDidMount() {
        const { hasVote } = this.props;

        if (!hasVote) {
            this._fetchVote();
        }
    }

    _fetchVote() {
        const { dispatch, requiredAttributes, subjectType, subjectId } = this.props;

        dispatch(createIsGrantedAction(requiredAttributes, subjectType, subjectId));
    }

    render() {
        const { children, isAllowed, alternateContent } = this.props;

        if (!isAllowed) {
            return alternateContent || null;
        }

        return children;
    }
}

const mapGlobalStateToProps = (globalState: GlobalState, props: OwnProps) => {
    const authorizationVotes = globalState.authorizationVotes;

    const hasVote = applyHasVoteSelector(
        authorizationVotes,
        props.requiredAttributes,
        props.subjectType,
        props.subjectId
    );

    const isAllowed = applyIsCurrentUserGrantedSelector(
        authorizationVotes,
        props.requiredAttributes,
        props.subjectType,
        props.subjectId
    );

    return { isAllowed, hasVote };
};

export default connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>(mapGlobalStateToProps)(
    // @ts-ignore don't know how to get this fixed -> transform to functional component with useDispatch and useSelector
    SecurityThreshold
);
