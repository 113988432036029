import type { Action } from '../actions/factory';
import type { Comment, CommentCollection } from '../model/comment/types';
import { ActionType } from 'redux-promise-middleware';
import {
    PROJECT_FETCH_COMMENTS,
    PROJECT_POST_COMMENT,
    PROJECT_REACT_ON_COMMENT,
    PROJECT_TOGGLE_COMMENT_VISIBILITY,
} from '../actions/types';
import { createInMemoryComment, createInMemoryCommentReaction } from '../model/comment/factory/commentFactory';
import { extractPathOrThrowIfNotExists } from '../helper/objectPathHelper';
import { produce } from 'immer';
import User from '../model/User';
import { Subject } from '../context/comments/CommentSubjectContext';

type CommentsReducerStateItem = {
    subject: string | null;
    results: CommentCollection;
};

export type CommentsReducerState = CommentsReducerStateItem[];

const RESET_STATE: CommentsReducerState = [];

export const InvalidSubjectError: Error = new Error('Expect subject to match that of incoming comment');
export const CommentThatWasReactedOnNotFoundError: Error = new Error('Could not find the error that was reacted upon');

function _checkSubjectsMatch(currentSubject: string | null, otherSubject: Subject): boolean {
    return (
        !!currentSubject && !!otherSubject && !!otherSubject.externalId && otherSubject.externalId === currentSubject
    );
}

function _handleProjectFetchCommentsFulfilledAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const incomingSubject = action.meta.subject as Subject;

    const existingStateForSubject = currentState.find((state) => _checkSubjectsMatch(state.subject, incomingSubject));
    const indexOfExistingState = currentState.findIndex((state) => _checkSubjectsMatch(state.subject, incomingSubject));

    if (!incomingSubject) {
        throw new Error('Expecting incoming subject to be available at this point');
    }

    if (!incomingSubject.externalId) {
        throw new Error('Expecting incoming subject external id to be available at this point');
    }

    const newSubjectComments = {
        subject: incomingSubject.externalId,
        // @ts-ignore -> typescript does not know the action contents
        results: action.payload as CommentCollection,
    };

    if (!existingStateForSubject) {
        return [...currentState, newSubjectComments];
    }

    return [
        ...currentState.slice(0, indexOfExistingState),
        newSubjectComments,
        ...currentState.slice(indexOfExistingState + 1),
    ];
}

function _handleProjectPostCommentPendingAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const incomingSubject = action.meta.subject as Subject;
    const existingStateIndexForSubject = currentState.findIndex((state) =>
        _checkSubjectsMatch(state.subject, incomingSubject)
    );

    if (existingStateIndexForSubject === -1) {
        throw InvalidSubjectError;
    }

    return produce<CommentsReducerState>(currentState, (draft) => {
        const newComment: Comment = createInMemoryComment(action.meta.id, action.payload.text, action.payload.user);

        // $ExpectError -> draft should be modify-able. This is something the Immer type definition should fix, but it doesn't
        draft[existingStateIndexForSubject].results.unshift(newComment);
    });
}

function _handleProjectPostCommentFulfilledAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const incomingSubject = action.meta.subject as Subject;
    const existingStateIndexForSubject = currentState.findIndex((state) =>
        _checkSubjectsMatch(state.subject, incomingSubject)
    );

    if (existingStateIndexForSubject === -1) {
        return currentState;
    }

    const externalId: string = extractPathOrThrowIfNotExists('payload.data.id', action);
    const id: string = extractPathOrThrowIfNotExists('meta.id', action);

    return produce<CommentsReducerState>(currentState, (draft) => {
        const indexOfAlreadyCreatedComment: number = draft[existingStateIndexForSubject].results.findIndex(
            (comment: Comment) => comment.id === id
        );

        if (indexOfAlreadyCreatedComment >= 0) {
            // eslint-disable-line no-magic-numbers

            // $ExpectError -> draft should be modify-able. This is something the Immer type definition should fix, but it doesn't
            draft[existingStateIndexForSubject].results[indexOfAlreadyCreatedComment].externalId = externalId;
        }
    });
}

function _handleProjectPostCommentRejectedAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const incomingSubject = action.meta.subject as Subject;
    const existingStateIndexForSubject = currentState.findIndex((state) =>
        _checkSubjectsMatch(state.subject, incomingSubject)
    );

    if (existingStateIndexForSubject === -1) {
        return currentState;
    }

    const id: string = extractPathOrThrowIfNotExists('meta.id', action);

    return produce<CommentsReducerState>(currentState, (draft) => {
        const indexOfAlreadyCreatedComment: number = draft[existingStateIndexForSubject].results.findIndex(
            (comment: Comment) => comment.id === id
        );

        if (indexOfAlreadyCreatedComment >= 0) {
            // eslint-disable-line no-magic-numbers

            // $ExpectError -> draft should be modify-able. This is something the Immer type definition should fix, but it doesn't
            draft[existingStateIndexForSubject].results = draft[existingStateIndexForSubject].results.filter(
                (comment: Comment) => comment.id !== id
            );
        }
    });
}

function _handleProjectReactOnCommentPendingAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const incomingSubject = action.meta.subject as Subject;
    const existingStateIndexForSubject = currentState.findIndex((state) =>
        _checkSubjectsMatch(state.subject, incomingSubject)
    );

    if (existingStateIndexForSubject === -1) {
        throw InvalidSubjectError;
    }

    const externalCommentId: string = extractPathOrThrowIfNotExists('meta.externalCommentId', action);
    const text: string = extractPathOrThrowIfNotExists('payload.text', action);
    const concept: boolean = extractPathOrThrowIfNotExists('payload.concept', action);
    const visibilityOverridden: boolean = extractPathOrThrowIfNotExists('payload.visibilityOverridden', action);
    const user: User = extractPathOrThrowIfNotExists('payload.user', action);

    return produce<CommentsReducerState>(currentState, (draft) => {
        const commentThatTheReactionIsFor = draft[existingStateIndexForSubject].results.find(
            (comment: Comment) => comment.externalId === externalCommentId
        );

        if (!commentThatTheReactionIsFor || !commentThatTheReactionIsFor.reactions) {
            throw CommentThatWasReactedOnNotFoundError;
        }

        const lastIndex = commentThatTheReactionIsFor.reactions.length;
        commentThatTheReactionIsFor.reactions[lastIndex] = createInMemoryCommentReaction(
            text,
            user,
            concept,
            visibilityOverridden
        );
    });
}

function _handleProjectToggleCommentVisibilityAction(
    currentState: CommentsReducerState,
    action: Action
): CommentsReducerState {
    const currentUser: User = extractPathOrThrowIfNotExists('meta.currentUser', action);

    const externalCommentId: string = extractPathOrThrowIfNotExists('meta.externalCommentId', action);

    const incomingSubject = action.meta.subject as Subject;
    const existingStateIndexForSubject = currentState.findIndex((state) =>
        _checkSubjectsMatch(state.subject, incomingSubject)
    );

    if (existingStateIndexForSubject === -1) {
        throw InvalidSubjectError;
    }

    return produce<CommentsReducerState>(currentState, (draft) => {
        const commentToUpdate = draft[existingStateIndexForSubject].results.find(
            (cursorComment: Comment) => cursorComment.externalId === externalCommentId
        );

        if (!commentToUpdate) {
            return;
        }

        if (commentToUpdate.hiddenForUsers.includes(currentUser.externalId)) {
            commentToUpdate.hiddenForUsers = commentToUpdate.hiddenForUsers.filter(
                (cursorId) => cursorId !== currentUser.externalId
            );
        } else {
            commentToUpdate.hiddenForUsers.push(currentUser.externalId);
        }
    });
}

export default function commentsReducer(
    currentState: CommentsReducerState = RESET_STATE,
    action: Action
): CommentsReducerState {
    switch (action.type) {
        case `${PROJECT_FETCH_COMMENTS}_${ActionType.Fulfilled}`:
            return _handleProjectFetchCommentsFulfilledAction(currentState, action);

        case `${PROJECT_POST_COMMENT}_${ActionType.Pending}`:
            return _handleProjectPostCommentPendingAction(currentState, action);

        case `${PROJECT_POST_COMMENT}_${ActionType.Rejected}`:
            return _handleProjectPostCommentRejectedAction(currentState, action);

        case `${PROJECT_POST_COMMENT}_${ActionType.Fulfilled}`:
            return _handleProjectPostCommentFulfilledAction(currentState, action);

        case `${PROJECT_REACT_ON_COMMENT}_${ActionType.Pending}`:
            return _handleProjectReactOnCommentPendingAction(currentState, action);

        case `${PROJECT_TOGGLE_COMMENT_VISIBILITY}_${ActionType.Pending}`:
            return _handleProjectToggleCommentVisibilityAction(currentState, action);

        default:
            return currentState;
    }
}
