import { createAction } from './factory';
import {
    FETCH_CURRENT_USER,
    CHANGE_CURRENT_USER_AUTHENTICATION_STATUS,
    REGISTER_USER,
    ADD_BOOKMARK,
    ADD_BOOKMARK_TAG,
    REMOVE_BOOKMARK,
    EDIT_PROFILE,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    GET_BOOKMARKS,
    CHANGE_EMAIL,
    EDIT_BOOKMARK,
    ANONIMIZE_ACCOUNT,
    EDIT_BOOKMARK_TAG,
    DELETE_BOOKMARK_TAG,
    ADD_USER,
    CHECK_IS_GRANTED,
    FETCH_USER_OVERVIEW,
} from './types';
import { createFrontendEntryPath } from '../routing/urlGenerator';
import * as apiClient from '../api/client/apiClient';
import { toast } from 'react-toastify';
import { createUuid } from '../utility/idGenerator';
import type { Action, Thunk } from './factory';
import type { RequestBody } from '../api/client/apiClient';
import { getBrowserHistory } from '../routing/historyManager';
import {
    createAddBookmarkApiPath,
    createAddBookmarkTagApiPath,
    createAddUserApiPath,
    createAnonimizeAccountApiPath,
    createChangeEmailApiPath,
    createChangePasswordApiPath,
    createCurrentUserApiPath,
    createDeleteBookmarkTagApiPath,
    createEditBookmarkApiPath,
    createEditBookmarkTagApiPath,
    createEditProfileApiPath,
    createForgotPasswordApiPath,
    createGetCurrentUserBookmarksApiPath,
    createRemoveBookmarkApiPath,
    createResetPasswordApiPath,
    createSecurityVoteApiPath,
    createUserOverviewApiPath,
    createUserRegistrationApiPath,
} from '../routing/apiUrlGenerator';

export function createGetCurrentUserAction(): Action {
    const promise = apiClient.get(createCurrentUserApiPath());

    return createAction(FETCH_CURRENT_USER, promise);
}

export function createChangeCurrentUserAuthenticationStatusAction(loggedIn: boolean): Action {
    return createAction(CHANGE_CURRENT_USER_AUTHENTICATION_STATUS, null, {
        loggedIn: loggedIn,
    });
}

export function createRegisterUserAction(
    firstName: string,
    preposition = '',
    lastName: string,
    profession: string,
    email: string,
    password: string,
    agreement: boolean,
    reCaptchaToken: string
): Thunk {
    return (dispatch) => {
        const url: string = createUserRegistrationApiPath(),
            body: RequestBody = {
                firstName: firstName,
                preposition: preposition,
                lastName: lastName,
                profession: profession,
                email: email,
                password: password,
                agreement: agreement,
                reCaptchaToken: reCaptchaToken,
            };

        const action: Action = createAction(REGISTER_USER, apiClient.post(url, body));

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('Bedankt voor het registreren er is een e-mail verstuurd om je account te activeren');

            const history = getBrowserHistory();
            history.push(createFrontendEntryPath());
        });
    };
}

export function createAddBookmarkAction(bookmarkedId: string, title: string, externalTagIds: Array<number>): Thunk {
    return (dispatch) => {
        const url: string = createAddBookmarkApiPath(),
            body: RequestBody = { bookmarkedId, tagIds: externalTagIds },
            promise: Promise<any> = apiClient.post(url, body, {
                removeEmptyArrays: true,
            }),
            action: Action = createAction(ADD_BOOKMARK, promise, body, {
                bookmarkedId,
            });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`'${title}' is toegevoegd aan je favorieten`);
        });
    };
}

export function createEditBookmarkAction(externalId: number, externalTagIds: Array<number>, title: string): Thunk {
    return (dispatch) => {
        const url: string = createEditBookmarkApiPath(externalId),
            body: RequestBody = { tagIds: externalTagIds },
            promise: Promise<any> = apiClient.post(url, body),
            action: Action = createAction(EDIT_BOOKMARK, promise, body);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`'${title}' is gewijzigd`);

            return dispatch(createGetCurrentUserBookmarksAction());
        });
    };
}

export function createAddBookmarkTagAction(title: string): Thunk {
    return (dispatch) => {
        const url: string = createAddBookmarkTagApiPath(),
            body: RequestBody = { title },
            promise: Promise<any> = apiClient.post(url, body),
            action: Action = createAction(ADD_BOOKMARK_TAG, promise, body, {
                id: createUuid(),
            });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`Map '${title}' is toegevoegd`);
        });
    };
}

export function createDeleteBookmarkTagAction(externalId: number): Thunk {
    return (dispatch) => {
        const url: string = createDeleteBookmarkTagApiPath(externalId),
            promise: Promise<any> = apiClient.doDelete(url),
            action: Action = createAction(DELETE_BOOKMARK_TAG, promise, {}, { externalId });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('De categorie is verwijderd');

            return dispatch(createGetCurrentUserBookmarksAction());
        });
    };
}

export function createEditBookmarkTagAction(externalId: number, newTitle: string): Thunk {
    return (dispatch) => {
        const url: string = createEditBookmarkTagApiPath(externalId),
            body: RequestBody = { title: newTitle },
            promise: Promise<any> = apiClient.put(url, body),
            action: Action = createAction(EDIT_BOOKMARK_TAG, promise, body, {
                externalId,
            });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('De wijziging is doorgevoerd');
        });
    };
}

export function createRemoveBookmarkAction(externalId: number, title: string): Thunk {
    return (dispatch) => {
        const url: string = createRemoveBookmarkApiPath(externalId),
            promise: Promise<any> = apiClient.doDelete(url),
            action: Action = createAction(REMOVE_BOOKMARK, promise, {}, { externalId });

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`${title} is verwijderd uit je favorieten`);
        });
    };
}

export function createChangeEmailAction(_externalUserId: string, email: string): Thunk {
    return (dispatch) => {
        const url: string = createChangeEmailApiPath(),
            body: RequestBody = { email },
            promise: Promise<any> = apiClient.put(url, body),
            action: Action = createAction(CHANGE_EMAIL, promise, body);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(
                'Er is een e-mail naar je nieuwe adres gestuurd met een link waarmee je je wijziging kan finaliseren.'
            );
        });
    };
}

export function createEditProfileAction(
    externalUserId: string,
    firstName: string,
    preposition: string | undefined | null,
    lastName: string,
    profession?: string | null
): Thunk {
    return (dispatch) => {
        const url: string = createEditProfileApiPath(externalUserId),
            body: RequestBody = {
                firstName,
                preposition,
                lastName,
                profession,
            },
            promise: Promise<any> = apiClient.put(url, body),
            action: Action = createAction(EDIT_PROFILE, promise, body);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('Je wijzigingen zijn opgeslagen');
        });
    };
}

export function createChangePasswordAction(externalUserId: string, newPassword: string): Thunk {
    return (dispatch) => {
        const url: string = createChangePasswordApiPath(externalUserId),
            body: RequestBody = { newPassword },
            promise: Promise<any> = apiClient.put(url, body),
            action: Action = createAction(CHANGE_PASSWORD, promise);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('Je wijzigingen zijn opgeslagen');
        });
    };
}

export function createForgotPasswordAction(email: string): Thunk {
    return (dispatch) => {
        const url: string = createForgotPasswordApiPath(),
            promise: Promise<any> = apiClient.post(url, { email }),
            action: Action = createAction(FORGOT_PASSWORD, promise);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success('Bekijk je email voor verdere instructies om je e-mail te resetten.');
        });
    };
}

export function createResetPasswordAction(token: string, newPassword: string): Thunk {
    return (dispatch) => {
        const url: string = createResetPasswordApiPath(token),
            promise: Promise<any> = apiClient.post(url, { newPassword }),
            action: Action = createAction(RESET_PASSWORD, promise);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return response;
            }

            toast.success('Je wachtwoord is gereset!');

            return response;
        });
    };
}

export function createGetCurrentUserBookmarksAction(): Thunk {
    return (dispatch) => {
        const url: string = createGetCurrentUserBookmarksApiPath(),
            promise: Promise<any> = apiClient.get(url),
            action: Action = createAction(GET_BOOKMARKS, promise);

        return dispatch(action);
    };
}

export function createAnonimizeAccountAction(): Thunk {
    return (dispatch) => {
        const url: string = createAnonimizeAccountApiPath(),
            promise: Promise<any> = apiClient.doDelete(url),
            action: Action = createAction(ANONIMIZE_ACCOUNT, promise);

        return dispatch(action);
    };
}

export function createAddUserAction(
    firstName: string,
    preposition: string | undefined | null,
    lastName: string,
    profession: string | undefined | null,
    email: string,
    globalRole: string,
    personalMessage?: string | null
): Thunk {
    return (dispatch) => {
        const url: string = createAddUserApiPath(),
            promise: Promise<any> = apiClient.post(url, {
                firstName,
                preposition,
                lastName,
                profession,
                email,
                globalRole,
                personalMessage,
            }),
            action: Action = createAction(ADD_USER, promise);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`${firstName} is als gebruiker aangemaakt`);

            return dispatch(createFetchUserOverviewAction(1));
        });
    };
}

export function createIsGrantedAction(
    attributes: string | Array<string>,
    subjectType: string | null = null,
    subjectId: string | null | number = null
): Thunk {
    return (dispatch) => {
        const normalizedAttributes: Array<string> = Array.isArray(attributes) ? attributes : [attributes],
            url: string = createSecurityVoteApiPath(),
            queryParams: RequestBody = {
                attributes: normalizedAttributes,
                subject_type: subjectType, // eslint-disable-line camelcase
                subject_id: subjectId, // eslint-disable-line camelcase
            },
            promise: Promise<any> = apiClient.get(url, queryParams);

        return dispatch(createAction(CHECK_IS_GRANTED, promise, {}, { normalizedAttributes, subjectType, subjectId }));
    };
}

export function createFetchUserOverviewAction(page: number, query = ''): Thunk {
    return (dispatch) => {
        const url: string = createUserOverviewApiPath(page, query),
            promise: Promise<any> = apiClient.get(url),
            action: Action = createAction(FETCH_USER_OVERVIEW, promise);

        return dispatch(action);
    };
}
