import React from 'react';
import { isString } from 'lodash';
import { transformJsonToObject } from '../../../utility/JsonToObjectTransformer';
import EditorContent from '../../shared/editorContent/EditorContent';
import LexicalRender from './LexicalRender';

type ContentRenderProps = {
    content: string | undefined | null;
    contentType: string | undefined | null;
};

const ContentRender = ({ content, contentType }: ContentRenderProps) => {
    const contentAsJson = isString(content) ? transformJsonToObject(content || null) : null;

    if (contentAsJson === null) {
        return null;
    }

    if (contentType === 'lexical') {
        return <LexicalRender lexicalContent={contentAsJson} />;
    }

    return <EditorContent raw={contentAsJson} />;
};

export default ContentRender;
