import { isEmpty } from 'lodash';
import arrayMove from 'array-move';
import { logError } from '../../utility/Logger';
import ProjectDetailChapter from './ProjectDetailChapter';
import ProjectSideProduct, { ProjectSideProductType } from '../ProjectSideProduct';
import WorkingGroupMember from './WorkingGroupMember';
import type { Cloneable } from '../contracts';
import ExternalPartyMember from './ExternalPartyMember';
import ProjectDetailLink, { ProjectDetailLinkType } from './ProjectDetailLink';
import SoundingBoardMember from './SoundingBoardMember';
import { RecommendedStandard } from '../recommendedStandard/RecommendedStandard';
import { ContentType } from '../../constants/content';

export default class ProjectDetail implements Cloneable<ProjectDetail> {
    _id: string;
    _externalId: string;
    _standardId: string | null;
    _title: string;
    _shortTitle: string | null;
    _type: string;
    _chapters: Array<ProjectDetailChapter>;
    _typeSlug: string;
    _slug: string;
    _authorizedAt: string | undefined | null;
    _sideProducts: Array<ProjectSideProduct>;
    _links: Array<ProjectDetailLink>;
    _createdAt: string;
    _isRevision: boolean;
    _isClosed: boolean;
    _workingGroupMembers: Array<WorkingGroupMember>;
    _externalPartyMembers: Array<ExternalPartyMember>;
    _soundingBoardMembers: Array<SoundingBoardMember>;
    _reviewedAt: string | undefined | null;
    _consultationPhaseStartedAt: string | undefined | null;
    _consultationPhaseEndedAt: string | undefined | null;
    _isInConsultationPhase: boolean;
    _pdfFile: string | undefined | null;
    _patientIntroduction: string | null;
    _patientIntroductionContentType: ContentType | undefined;
    _partiesAuthorizing: string | null;
    _partiesNotAuthorizing: string | null;
    _partiesNotObjecting: string | null;
    _previousPublicationProjectId: string | null;
    _articleCount: number;
    _tags: string[];
    _status: string | null;
    _recommendedStandards: RecommendedStandard[];
    _workingGroupMembersWritingAccess: boolean;
    _owner: string | null;

    constructor(
        id: string,
        externalId: string,
        standardId: string | null,
        title: string,
        shortTitle: string | null,
        type: string,
        chapters: Array<ProjectDetailChapter>,
        typeSlug: string,
        slug: string,
        authorizedAt: string | undefined | null,
        sideProducts: Array<ProjectSideProduct>,
        links: Array<ProjectDetailLink>,
        createdAt: string,
        isRevision: boolean,
        isClosed: boolean,
        workingGroupMembers: Array<WorkingGroupMember>,
        externalPartyMembers: Array<ExternalPartyMember>,
        soundingBoardMembers: Array<SoundingBoardMember>,
        reviewedAt: string | undefined | null,
        consultationPhaseStartedAt: string | undefined | null,
        consultationPhaseEndedAt: string | undefined | null,
        isInConsultationPhase: boolean,
        pdfFile: string | undefined | null,
        patientIntroduction: string | null,
        patientIntroductionContentType: ContentType | undefined,
        partiesAuthorizing: string | null,
        partiesNotAuthorizing: string | null,
        partiesNotObjecting: string | null,
        previousPublicationProjectId: string | null,
        articleCount: number,
        tags: string[],
        status: string | null,
        recommendedStandards: RecommendedStandard[],
        workingGroupMembersWritingAccess: boolean,
        owner: string | null
    ) {
        this._id = id;
        this._externalId = externalId;
        this._standardId = standardId;
        this._title = title;
        this._shortTitle = shortTitle;
        this._type = type;
        this._chapters = chapters;
        this._typeSlug = typeSlug;
        this._slug = slug;
        this._authorizedAt = authorizedAt;
        this._sideProducts = sideProducts;
        this._links = links;
        this._createdAt = createdAt;
        this._isRevision = isRevision;
        this._isClosed = isClosed;
        this._workingGroupMembers = workingGroupMembers;
        this._externalPartyMembers = externalPartyMembers;
        this._soundingBoardMembers = soundingBoardMembers;
        this._reviewedAt = reviewedAt;
        this._consultationPhaseStartedAt = consultationPhaseStartedAt;
        this._consultationPhaseEndedAt = consultationPhaseEndedAt;
        this._isInConsultationPhase = isInConsultationPhase;
        this._pdfFile = pdfFile;
        this._patientIntroduction = patientIntroduction;
        this._patientIntroductionContentType = patientIntroductionContentType;
        this._partiesAuthorizing = partiesAuthorizing;
        this._partiesNotAuthorizing = partiesNotAuthorizing;
        this._partiesNotObjecting = partiesNotObjecting;
        this._previousPublicationProjectId = previousPublicationProjectId;
        this._articleCount = articleCount;
        this._tags = tags;
        this._status = status;
        this._recommendedStandards = recommendedStandards;
        this._workingGroupMembersWritingAccess = workingGroupMembersWritingAccess;
        this._owner = owner;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get externalId(): string {
        return this._externalId;
    }

    get standardId() {
        return this._standardId;
    }

    get title(): string {
        return this._title;
    }

    get shortTitle() {
        return this._shortTitle;
    }

    set title(title: string) {
        this._title = title;
    }

    get type(): string {
        return this._type;
    }

    set type(type: string) {
        this._type = type;
    }

    get chapters(): Array<ProjectDetailChapter> {
        return this._chapters;
    }

    getFirstChapter(): ProjectDetailChapter | null {
        return this._chapters.length > 0 ? this._chapters[0] : null;
    }

    removeChapterWithId(idToRemove: string): void {
        this._chapters = this._chapters.filter((chapter) => chapter.id !== idToRemove);
    }

    getChapterById(chapterId: string): ProjectDetailChapter | undefined | null {
        return this._chapters.find((chapter) => chapter.id === chapterId);
    }

    getChapterByExternalId(externalChapterId: string): ProjectDetailChapter | undefined | null {
        return this._chapters.find((chapter) => chapter.externalId === externalChapterId);
    }
    get typeSlug(): string {
        return this._typeSlug;
    }

    get slug(): string {
        return this._slug;
    }

    get authorizedAt(): string | undefined | null {
        return this._authorizedAt;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    get isRevision(): boolean {
        return this._isRevision;
    }

    get isClosed(): boolean {
        return this._isClosed;
    }

    isAuthorized(): boolean {
        return !isEmpty(this._authorizedAt);
    }

    get sideProducts(): Array<ProjectSideProduct> {
        return this._sideProducts;
    }

    get links() {
        return this._links;
    }

    removeLinkWithId(id: string) {
        this._links = this._links.filter((cursorLink) => cursorLink.id !== id);
    }

    get pdfFile(): string | undefined | null {
        return this._pdfFile;
    }

    get workingGroupMembers(): Array<WorkingGroupMember> {
        return this._workingGroupMembers;
    }

    get externalPartyMembers(): Array<ExternalPartyMember> {
        return this._externalPartyMembers;
    }

    get soundingBoardMembers(): Array<SoundingBoardMember> {
        return this._soundingBoardMembers;
    }

    get reviewedAt(): string | undefined | null {
        return this._reviewedAt;
    }

    get consultationPhaseStartedAt(): string | undefined | null {
        return this._consultationPhaseStartedAt;
    }

    get consultationPhaseEndedAt(): string | undefined | null {
        return this._consultationPhaseEndedAt;
    }

    get isInConsultationPhase(): boolean {
        return this._isInConsultationPhase;
    }

    get articleCount() {
        return this._articleCount;
    }

    get workingGroupMembersWritingAccess(): boolean {
        return this._workingGroupMembersWritingAccess;
    }

    removeWorkingGroupMemberWithExternalUserId(externalUserId: string): void {
        this._workingGroupMembers = this._workingGroupMembers.filter(
            (member) => member.externalUserId !== externalUserId
        );
    }

    removeExternalPartyMemberWithExternalUserId(externalUserId: string): void {
        this._externalPartyMembers = this._externalPartyMembers.filter(
            (member) => member.externalUserId !== externalUserId
        );
    }

    moveChapterToIndex(chapterId: string, newIndex: number): void {
        const oldIndex = this._chapters.findIndex((chapter) => chapter.id === chapterId);

        if (oldIndex === -1) {
            // eslint-disable-line no-magic-numbers
            logError(new Error('Could not find chapter in project'), {
                chapterId,
                projectId: this._id,
            });

            return;
        }

        this._chapters = arrayMove(this._chapters, oldIndex, newIndex);
    }

    countSideProductsWithTypes(types: ProjectSideProductType[]): number {
        if (types.length === 0) {
            return 0;
        }

        return this._sideProducts.filter((cursor) => types.includes(cursor.type)).length;
    }

    countLinksWithTypes(types: ProjectDetailLinkType[]): number {
        if (types.length === 0) {
            return 0;
        }

        return this._links.filter((cursor) => types.includes(cursor.type)).length;
    }

    getSideProductById(sideProductId: string): ProjectSideProduct | undefined | null {
        return this._sideProducts.find((product) => product.id === sideProductId);
    }

    getSideProductsByType(type: ProjectSideProductType | Array<ProjectSideProductType>): Array<ProjectSideProduct> {
        const types = Array.isArray(type) ? type : [type];

        return this._sideProducts.filter((cursorSideProduct) => types.includes(cursorSideProduct.type));
    }

    removeSideProductById(sideProductId: string): void {
        this._sideProducts = this._sideProducts.filter((product) => product.id !== sideProductId);
    }

    getLinksByType(type: ProjectDetailLinkType | ProjectDetailLinkType[]): Array<ProjectDetailLink> {
        const types = Array.isArray(type) ? type : [type];

        return this._links.filter((cursorLink) => {
            return types.includes(cursorLink.type);
        });
    }

    get patientIntroduction(): string | null {
        return this._patientIntroduction;
    }

    set patientIntroduction(patientIntroduction: string | null) {
        this._patientIntroduction = patientIntroduction;
    }

    get patientIntroductionContentType(): ContentType | undefined {
        return this._patientIntroductionContentType;
    }

    get partiesAuthorizing(): string | null {
        return this._partiesAuthorizing;
    }

    set partiesAuthorizing(partiesAuthorizing: string | null) {
        this._partiesAuthorizing = partiesAuthorizing;
    }

    get partiesNotAuthorizing(): string | null {
        return this._partiesNotAuthorizing;
    }

    set partiesNotAuthorizing(partiesNotAuthorizing: string | null) {
        this._partiesNotAuthorizing = partiesNotAuthorizing;
    }

    get partiesNotObjecting(): string | null {
        return this._partiesNotObjecting;
    }

    set partiesNotObjecting(partiesNotObjecting: string | null) {
        this._partiesNotObjecting = partiesNotObjecting;
    }

    get previousPublicationProjectId() {
        return this._previousPublicationProjectId;
    }

    get tags(): string[] {
        return this._tags;
    }

    set tags(tags: string[]) {
        this._tags = tags;
    }

    get status(): string | null {
        return this._status;
    }

    get recommendedStandards(): RecommendedStandard[] {
        return this._recommendedStandards;
    }

    get owner(): string | null {
        return this._owner;
    }

    clone(): ProjectDetail {
        return new ProjectDetail(
            this._id,
            this._externalId,
            this._standardId,
            this._title,
            this._shortTitle,
            this._type,
            this._chapters.map((chapter: ProjectDetailChapter) => chapter.clone()),
            this._typeSlug,
            this._slug,
            this._authorizedAt,
            this._sideProducts.map((product: ProjectSideProduct) => product.clone()),
            this._links.map((link: ProjectDetailLink) => link.clone()),
            this._createdAt,
            this._isRevision,
            this._isClosed,
            this._workingGroupMembers.map((workingGroupMember: WorkingGroupMember) => workingGroupMember.clone()),
            this._externalPartyMembers.map((externalPartyMember: ExternalPartyMember) => externalPartyMember.clone()),
            this._soundingBoardMembers.map((soundingBoardMember: SoundingBoardMember) => soundingBoardMember.clone()),
            this._reviewedAt,
            this._consultationPhaseStartedAt,
            this._consultationPhaseEndedAt,
            this._isInConsultationPhase,
            this._pdfFile,
            this._patientIntroduction,
            this._patientIntroductionContentType,
            this._partiesAuthorizing,
            this._partiesNotAuthorizing,
            this._partiesNotObjecting,
            this._previousPublicationProjectId,
            this._articleCount,
            this._tags,
            this._status,
            this._recommendedStandards,
            this._workingGroupMembersWritingAccess,
            this._owner
        );
    }
}
