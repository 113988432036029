import * as React from 'react';
import Modal, { ModalSize, ModalType } from '../../../../shared/modal/Modal';

type Props = {
    children: React.ReactNode;
    onDecline: () => void;
    title?: string;
};

const NewsletterSubscriptionModal = ({ children, onDecline, title }: Props) => (
    <Modal title={title} onDecline={onDecline} modalSize={ModalSize.Small} modalType={ModalType.Secondary}>
        {children}
    </Modal>
);

export default NewsletterSubscriptionModal;
