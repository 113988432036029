export default class ProjectOverviewItem {
    private readonly _id: string;
    private readonly _externalId: string | undefined | null;
    private readonly _type: string;
    private _title: string;
    private readonly _shortTitle: string | null;
    private readonly _isRevision: boolean;
    private readonly _path: string | undefined | null;
    private _commentCount: number;
    private _answeredCommentCount: number;

    constructor(
        id: string,
        externalId: string | undefined | null,
        type: string,
        title: string,
        shortTitle: string | null,
        isRevision: boolean,
        path: string | undefined | null,
        commentCount: number,
        answeredCommentCount: number
    ) {
        this._id = id;
        this._externalId = externalId;
        this._type = type;
        this._title = title;
        this._shortTitle = shortTitle;
        this._path = path;
        this._isRevision = isRevision;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string | undefined | null {
        return this._externalId;
    }

    get commentCount(): number {
        return this._commentCount;
    }

    incrementCommentCount(): void {
        this._commentCount++;
    }

    get answeredCommentCount(): number {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount(): void {
        this._answeredCommentCount++;
    }

    get type(): string {
        return this._type;
    }

    get title(): string {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get shortTitle() {
        return this._shortTitle;
    }

    get path(): string | undefined | null {
        return this._path;
    }

    get isRevision(): boolean {
        return this._isRevision;
    }

    clone(): ProjectOverviewItem {
        return new ProjectOverviewItem(
            this._id,
            this._externalId,
            this._type,
            this._title,
            this._shortTitle,
            this._isRevision,
            this._path,
            this._commentCount,
            this._answeredCommentCount
        );
    }
}
