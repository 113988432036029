import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const HighlightBlock = ({ children }: Props) => {
    return (
        <div className="highlight-content-block">
            {children && (
                <div className="highlight-content-block__content">
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
};

export default HighlightBlock;
