import BreadcrumbItem from '../BreadcrumbItem';
import { createUuid } from '../../utility/idGenerator';
import ProjectBreadcrumb from '../ProjectBreadcrumb';
import { generateSectionNumbering } from '../../utility/projectSectionNumberingGenerator';
import {
    createDashboardParagraphDetailPath,
    createDashboardProjectDetailPath,
} from '../../routing/dashboardUrlGenerator';

export function createDashboardProjectBreadcrumbItem(externalId: string, title: string): BreadcrumbItem {
    return new BreadcrumbItem(createUuid(), title, createDashboardProjectDetailPath(externalId));
}

export function createDashboardChapterBreadcrumbItem(
    projectExternalId: string,
    externalChapterId: string,
    title: string,
    index: number | null
): BreadcrumbItem {
    return new BreadcrumbItem(
        createUuid(),
        title,
        createDashboardProjectDetailPath(projectExternalId, externalChapterId),
        index !== null ? generateSectionNumbering(index) : null
    );
}

export function createDashboardParagraphBreadcrumbItem(
    projectExternalId: string,
    chapterExternalId: string,
    externalId: string,
    title: string,
    indexes: number[] = []
): BreadcrumbItem {
    return new BreadcrumbItem(
        createUuid(),
        title,
        createDashboardParagraphDetailPath(projectExternalId, chapterExternalId, externalId),
        indexes ? generateSectionNumbering(...indexes) : null
    );
}

export function createDashboardSubParagraphBreadcrumbItem(
    title: string,
    chapterIndex: number,
    paragraphIndex: number,
    subParagraphIndex: number
) {
    return new BreadcrumbItem(
        createUuid(),
        title,
        null,
        generateSectionNumbering(chapterIndex, paragraphIndex, subParagraphIndex)
    );
}

export function createBreadcrumbItemWithTitleOnly(title: string): BreadcrumbItem {
    return new BreadcrumbItem(createUuid(), title);
}

export function createCollectionFromProjectBreadcrumb(projectBreadcrumb: ProjectBreadcrumb): Array<BreadcrumbItem> {
    return projectBreadcrumb.parts.map((part: string) => createBreadcrumbItemWithTitleOnly(part));
}
