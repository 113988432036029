import React, { VFC } from 'react';
import Heading from '../../../../shared/heading/Heading';
import type { DetailArticle } from '../../../../../model/Article';
import { formatLongDay } from '../../../../../helper/dateTimeHelper';
import styles from '../ArticleDetail.module.scss';

type Props = {
    article: DetailArticle;
};

const ArticleDetailHeader: VFC<Props> = ({ article }) => {
    const { title, author, publishedAt } = article;

    return (
        <header className={styles.header}>
            <Heading tag="h1" className={styles.title}>
                {title}
            </Heading>
            <div className={styles.meta}>
                <strong className={styles.author}>{author.name.full}</strong>
                {author.profession && (
                    <>
                        <span> - </span>
                        <span className="article-header__profession">{article.author.profession}</span>
                    </>
                )}
                {publishedAt && (
                    <>
                        &nbsp;&nbsp;
                        <time dateTime={publishedAt} className={styles.publishedAt}>
                            {formatLongDay(publishedAt)}
                        </time>
                    </>
                )}
            </div>
        </header>
    );
};

export default ArticleDetailHeader;
