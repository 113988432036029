import * as React from 'react';
import createClassName from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export enum ButtonStyles {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    link = 'link',
}

export enum ButtonTypes {
    submit = 'submit',
    button = 'button',
    reset = 'reset',
    link = 'link',
}

// @deprecated use ButtonStyles enum instead
export const PRIMARY = ButtonStyles.primary;

// @deprecated use ButtonStyles enum instead
export const LINK = ButtonStyles.link;

// @deprecated use ButtonStyles enum instead
export const SUBMIT = ButtonTypes.submit;

// @deprecated use ButtonStyles enum instead
export const BUTTON = ButtonTypes.button;

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style' | 'type'> & {
    style?: ButtonStyles | null;
    type?: ButtonTypes;
    to?: string;
    isSmall?: boolean;
    isDisabled?: boolean;
    icon?: string | ReactNode | null;
    isOutlineBordered?: boolean;
    isSharpBordered?: boolean;
    isFull?: boolean;
    isCleared?: boolean;
    children?: React.ReactNode;
    additionalClassName?: string;
    isDeflated?: boolean;
    isTextHighlighted?: boolean;
    isEffectless?: boolean;
    align?: 'left' | 'center' | 'right';
    [x: string]: any;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            style = null,
            type = ButtonTypes.button,
            to,
            isSmall = false,
            isDisabled = false,
            isOutlineBordered = false,
            isSharpBordered = false,
            isFull = false,
            isCleared = false,
            icon = null,
            children,
            additionalClassName,
            isDeflated,
            isTextHighlighted,
            isEffectless = false,
            align = 'center',
            ...otherProps
        }: Props,
        ref
    ) => {
        const hasIcon = !!icon;
        const iconAsString: string = typeof icon === 'string' ? icon : '';
        const iconAsSvg = typeof icon === 'object' ? icon : null;

        const className: string = createClassName(
            'button',
            {
                'button--primary': style === ButtonStyles.primary,
                'button--secondary': style === ButtonStyles.secondary,
                'button--tertiary': style === ButtonStyles.tertiary,
                'button--link': style === ButtonStyles.link,
                'button--small': isSmall,
                [`icon icon-${iconAsString}`]: hasIcon && iconAsString,
                'button--icon-only': hasIcon && React.Children.count(children) === 0,
                'button--outline-bordered': isOutlineBordered,
                'button--sharp-bordered': isSharpBordered,
                'button--full': isFull,
                'button--cleared': isCleared,
                'button--deflated': isDeflated,
                'button--text-highlighted': isTextHighlighted,
                'button--is-effectless': isEffectless,
                'button--align-left': align === 'left',
                'button--align-center': align === 'center',
                'button--align-right': align === 'right',
            },
            additionalClassName
        );

        if (type === ButtonTypes.link) {
            if (!to) {
                throw new Error("Please provide a 'to' if you want to make a link button");
            }

            return (
                // @ts-ignore hard to support multiple elements when using a forward ref
                <Link {...otherProps} to={to} className={className}>
                    {!!iconAsSvg && iconAsSvg}
                    {children}
                </Link>
            );
        }

        return (
            <button {...otherProps} type={type} className={className} disabled={isDisabled} ref={ref}>
                {!!iconAsSvg && iconAsSvg}
                {children}
            </button>
        );
    }
);

export default Button;
