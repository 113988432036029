import React from 'react';
import FormGroup from '../form/FormGroup';
import BookmarkTag from '../../../model/BookmarkTag';
import Loader from '../Loader';
import Button, { ButtonStyles, ButtonTypes } from '../buttons/Button';
import useManageBookmarkFormValues from './hooks/useManageBookmarkFormValues';
import CreatableMultiSelect from '../form/select/CreatableMultiSelect';
import { DefaultOptionType } from '../form/select/Select';

export type OnFormValidHandler = (externalTagIds: number[]) => void;

export type OnAddNewHandler = (label: string) => void;

type Props = {
    onFormValid: OnFormValidHandler;
    onAddNew: OnAddNewHandler;
    heading?: string;
    onCancel: () => void;
    tags: BookmarkTag[];
    currentTags?: BookmarkTag[];
    ctaText?: string;
    autoFocusTags?: boolean;
};

const BookmarkForm: React.FC<Props> = ({
    onFormValid,
    onAddNew,
    tags,
    currentTags = [],
    heading = 'Sla op als favoriet',
    onCancel,
    ctaText = 'Sla op als favoriet',
    autoFocusTags = false,
}) => {
    const { setFieldValue, isSubmitting, tagOptions, values, handleSubmit } = useManageBookmarkFormValues(
        currentTags,
        tags,
        onFormValid
    );

    return (
        <form onSubmit={handleSubmit}>
            <h4 className="heading">{heading}</h4>
            <FormGroup>
                <CreatableMultiSelect
                    placeholder="Kies of creëer een map"
                    autoFocus={autoFocusTags}
                    options={tagOptions}
                    value={values.tags}
                    disabled={isSubmitting}
                    onCreateOption={onAddNew}
                    onChange={(selectedOptions: DefaultOptionType[]) => {
                        setFieldValue('tags', selectedOptions);
                    }}
                />
            </FormGroup>
            {isSubmitting ? (
                <Loader />
            ) : (
                <div>
                    <Button type={ButtonTypes.submit} style={ButtonStyles.primary} isSmall data-track-id="bookmark">
                        {ctaText}
                    </Button>
                    <Button style={ButtonStyles.link} onClick={() => onCancel()} isSmall>
                        Annuleren
                    </Button>
                </div>
            )}
        </form>
    );
};

export default BookmarkForm;
