import { ActionType } from 'redux-promise-middleware';
import type { Action } from '../actions/factory';
import { FETCH_SETTINGS } from '../actions/types';
import { TenantType } from '../constants/settings';

export type SettingsReducerState = {
    context: {
        tenant: TenantType | null | undefined;
    };
    featureFlags: {
        favoritesEnabled: boolean;
        newsletterEnabled: boolean;
        anonymousFeedbackEnabled: boolean;
        restrictedCommentsEnabled: boolean;
        soundingBoardEnabled: boolean;
    };
} | null;

export default function (currentState: SettingsReducerState = null, action: Action) {
    switch (action.type) {
        case `${FETCH_SETTINGS}_${ActionType.Fulfilled}`:
            return createSettingsOutputFromApiData(action.payload.data);

        default:
            return currentState;
    }
}

function createSettingsOutputFromApiData(data: any) {
    if (!data) return null;

    return {
        context: {
            tenant: data.context.tenant,
        },
        featureFlags: {
            favoritesEnabled: data.feature_flags.favorites_enabled,
            newsletterEnabled: data.feature_flags.favorites_enabled,
            anonymousFeedbackEnabled: data.feature_flags.anonymous_feedback_enabled,
            restrictedCommentsEnabled: data.feature_flags.restricted_comments_enabled,
            soundingBoardEnabled: data.feature_flags.sounding_board_enabled,
        },
    };
}
