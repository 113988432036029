// @ts-nocheck react-select elements cause problems with ts
import React, { useMemo } from 'react';
import CreatableReactSelect, { Props as CreatableReactSelectProps } from 'react-select/creatable';
import createStyles from './utility/stylesFactory';
import { OptionTypeBase } from 'react-select';
import { DefaultOptionType } from './Select';

type Props<OptionType extends OptionTypeBase = DefaultOptionType> = Omit<
    CreatableReactSelectProps<OptionType, true>,
    'styles' | 'isMulti'
>;

const CreatableMultiSelect: React.FC<Props> = <OptionType extends OptionTypeBase = DefaultOptionType>(props: Props) => {
    const {
        noOptionsMessage = () => 'Geen resultaten',
        formatCreateLabel = (inputValue: string) => `Nieuwe optie '${inputValue}' toevoegen`,
        ...otherProps
    } = props;

    const styles = useMemo(() => createStyles<OptionType, true>(), []);

    return (
        <CreatableReactSelect<OptionType, true>
            {...otherProps}
            noOptionsMessage={noOptionsMessage}
            isMulti
            styles={styles}
        />
    );
};

export default CreatableMultiSelect;
