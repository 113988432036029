import React from 'react';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardContent from '../shared/containers/DashboardContent';
import HeadingGroup from '../shared/headings/HeadingGroup';

export default function Unauthorized() {
    return (
        <DashboardContent>
            <HeadingGroup>
                <DashboardContentHeading isHeading={true}>Niet geautoriseerd</DashboardContentHeading>
            </HeadingGroup>
            <div className="alert alert--danger">
                <span className="alert__content">
                    Je bent niet geautoriseerd om gebruik te maken van deze functionaliteit. Mocht je vinden dat dit
                    niet klopt, neem dan contact op met een beheerder.
                </span>
            </div>
        </DashboardContent>
    );
}
