import ProjectDetailParagraph from '../ProjectDetailParagraph';
import { createUuid } from '../../../utility/idGenerator';

export function createProjectDetailParagraphCollectionFromApiInput(apiInput: any): Array<ProjectDetailParagraph> {
    return apiInput.map(
        (data: any) =>
            new ProjectDetailParagraph(
                createUuid(),
                data.id,
                data.title,
                data.index,
                data.in_chapters,
                data.comments.total_count,
                data.comments.total_answered_count
            )
    );
}

export function createProjectDetailParagraphFromAddParagraphActionPayload(
    payload: any,
    id: string | undefined | null,
    index: number
): ProjectDetailParagraph {
    return new ProjectDetailParagraph(id ? id : createUuid(), null, payload.title, index, [], 0, 0);
}
