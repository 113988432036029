import type { OnClickCallback } from '../../../ggz/searchResults/components/SearchResult';
import { useDispatch } from 'react-redux';
import { createSetLastSearchedItemAction } from '../../../../../actions/projectActionFactory';

export default function useSaveClickedResultForFutureReference(onLastSearchedItemSaved: OnClickCallback) {
    const dispatch = useDispatch();

    const onResultClick: OnClickCallback = (result) => {
        const action = createSetLastSearchedItemAction(result.type, result.id, result.matchedQueryTerms);

        dispatch(action);

        onLastSearchedItemSaved(result);
    };

    return { onResultClick };
}
