import { useEffect, useState } from 'react';
import { PaginatedCollectionType } from '../model/collection/paginatedCollection';
import { Page } from '../model/Page';
import { logError } from '../utility/Logger';
import { toast } from 'react-toastify';
import { findAllForPage } from '../repository/pageRepository';

type OnErrorCallback = (error: any) => void;

type Filters = {
    page: number;
    standardId?: string;
    perPage?: number;
    includeUnpublished?: boolean;
};

export default function useRefetchPagesOnFilterChange(
    { page, standardId, perPage, includeUnpublished }: Filters,
    enabled: boolean = true,
    onError?: OnErrorCallback
) {
    const [contentPages, setContentPages] = useState<PaginatedCollectionType<Page> | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchCurrentPage = async () => {
        setIsFetching(true);
        setContentPages((currentState) => {
            if (!currentState) {
                return null;
            }

            return {
                ...currentState,
                results: [],
            };
        });

        try {
            const contentPages = await findAllForPage(
                page,
                standardId || null,
                perPage || null,
                includeUnpublished || false
            );

            setContentPages(contentPages);
        } catch (error) {
            logError(error);

            toast.error("Er is iets foutgegaan bij het ophalen van de pagina's. Probeer het later nog eens!");

            if (onError) {
                onError(error);
            }
        }
    };

    useEffect(() => {
        if (!enabled) {
            return;
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchCurrentPage();
    }, [page, standardId, enabled]);

    return { fetchCurrentPage, isFetching, contentPages };
}
