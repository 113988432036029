import * as React from 'react';
import { useProjectDetailHeaderHeight } from '../../../../../hooks/useProjectDetailHeaderHeight';
import type { onChangeStickyStatusType } from '../../../../../hooks/useProjectDetailHeaderHeight';

type ContextValue = {
    projectDetailHeaderHeight: number;
    onProjectDetailHeaderStickyStatusChange: onChangeStickyStatusType;
};

const ProjectDetailContext = React.createContext<ContextValue>({
    projectDetailHeaderHeight: 0,
    onProjectDetailHeaderStickyStatusChange: () => {},
});

type ProviderProps = {
    children: React.ReactNode;
};

export const ProjectDetailContextProvider = ({ children }: ProviderProps) => {
    const { headerHeight: projectDetailHeaderHeight, onChangeStickyStatus: onProjectDetailHeaderStickyStatusChange } =
        useProjectDetailHeaderHeight();

    // keep track of calculated header heights in context api to easily use in different child components
    const value = {
        projectDetailHeaderHeight,
        onProjectDetailHeaderStickyStatusChange,
    };

    return <ProjectDetailContext.Provider value={value}>{children}</ProjectDetailContext.Provider>;
};

export const useProjectDetailContext = () => {
    const context = React.useContext(ProjectDetailContext);

    if (!context) {
        throw new Error('useProjectDetailContext must be used within a ProjectDetailContextProvider');
    }

    return context;
};
