export default class BranchOverviewItem {
    private readonly _id: string;
    private readonly _title: string;
    private readonly _shortTitle: string | null;
    private readonly _createdAt: string;

    constructor(id: string, title: string, shortTitle: string | null, createdAt: string) {
        this._id = id;
        this._title = title;
        this._shortTitle = shortTitle;
        this._createdAt = createdAt;
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get shortTitle(): string | null {
        return this._shortTitle;
    }
    get createdAt(): string {
        return this._createdAt;
    }

    clone(): BranchOverviewItem {
        return new BranchOverviewItem(this._id, this._title, this._shortTitle, this._createdAt);
    }
}
