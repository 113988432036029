// KEEP THESE IN LINE WITH BACKEND Voters!!!

// project
export const PROJECT_START = 'project__start';
export const PROJECT_UPDATE = 'project__update_project';
export const PROJECT_UPDATE_CONTENTS = 'project__update_project_contents';
export const PROJECT_PUBLISH = 'project__publish';
export const PROJECT_CLOSE = 'project__close';
export const PROJECT_DUPLICATE = 'project__duplicate';
export const PROJECT_VIEW_OVERVIEW = 'project__view_overview';
export const ADD_WORKING_GROUP_MEMBER = 'project__add_working_group_member';
export const REMOVE_WORKING_GROUP_MEMBER = 'project__remove_working_group_member';
export const ADD_SOUNDING_BOARD_MEMBER = 'project__add_sounding_board_member';
export const REMOVE_SOUNDING_BOARD_MEMBER = 'project__remove_sounding_board_member';
export const ADD_EXTERNAL_PARTY_MEMBER = 'project__add_external_party_member';
export const REMOVE_EXTERNAL_PARTY_MEMBER = 'project__remove_external_party_member';
export const PROJECT_REQUEST_FEEDBACK_FROM_WORKING_GROUP_MEMBERS = 'project__feedback_from_working_group_members';

export const POJECT_TOGGLE_WORKING_GROUP_MEMBER_WRITE = 'project__toggle_working_group_member_write';
export const PROJECT_REQUEST_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS = 'project__feedback_from_external_party_members';
export const PROJECT_REMIND_FEEDBACK_FROM_EXTERNAL_PARTY_MEMBERS =
    'project__remind_external_party_members_of_feedback_request';
export const PROJECT_REQUEST_FEEDBACK_FROM_SOUNDING_BOARD_MEMBERS = 'project__feedback_from_sounding_board_members';
export const PROJECT_END_CONSULTATION_PHASE = 'project__end_consultation_phase';
export const PROJECT_ADD_CHAPTER = 'project__add_chapter';
export const PROJECT_MOVE_CHAPTER = 'project__move_chapter';
export const PROJECT_REMOVE_CHAPTER = 'project__remove_chapter';
export const PROJECT_ADD_SIDE_PRODUCT = 'project__add_side_product';
export const PROJECT_UPDATE_SIDE_PRODUCT = 'project__update_side_product';
export const PROJECT_REMOVE_SIDE_PRODUCT = 'project__remove_side_product';
export const PROJECT_MOVE_SIDE_PRODUCT = 'project__move_side_product';
export const PROJECT_ADD_LINK = 'project__add_link';
export const PROJECT_UPDATE_LINK = 'project__update_link';
export const PROJECT_REMOVE_LINK = 'project__remove_link';
export const PROJECT_POST_COMMENTS = 'project__post_comment';
export const PROJECT_VIEW_COMMENTS = 'project__view_comments';
export const PROJECT_VIEW_COMMENT_CONCEPT_REACTION = 'project__view_comment_concept_reaction';
export const PROJECT_EDIT_COMMENT_CONCEPT_REACTION = 'project__edit_comment_concept_reaction';
export const PROJECT_POST_COMMENT_REACTION = 'project__post_comment_reaction';
export const PROJECT_VIEW_OTHER_PEOPLES_COMMENTS = 'project__view_other_peoples_comments';
export const PROJECT_VIEW_COMMENT_COUNTS = 'project__view_comment_counts';
export const VIEW_FEEDBACK_STATUS = 'project__view_feedback_status';
export const VIEW_AUTHORIZATION_STATUS = 'project__view_authorization_status';
export const VIEW_SELECTED_PEOPLES_AUTHORIZATION_STATUS = 'project__view_selected_peoples_authorization_status';
export const REQUEST_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS =
    'project__request_authorization_from_external_party_members';
export const REMIND_AUTHORIZATION_FROM_EXTERNAL_PARTY_MEMBERS =
    'project__remind_external_party_members_of_pending_authorization';
export const PROJECT_UPDATE_PATIENT_INTRODUCTION = 'project__update_patient_introduction';
export const PROJECT_GENERATE_CONCEPT_PDF = 'project__generate-concept-pdf';
export const PROJECT_GENERATE_COMMENT_OVERVIEW = 'project__export_comments';
export const ARCHIVE_COMMENT = 'project__archive_comment';
export const PROJECT_VIEW_HISTORY = 'project__view-history';
export const PROJECT_ARCHIVE_SELECTED_COMMENTS = 'project__archive_selected_comments';
export const PROJECT_EDIT_STATUS = 'project__edit_status';
export const PROJECT_TOGGLE_COMMENT_REACTION_VISIBILITY_OVERRIDDEN =
    'project__toggle_comment_reaction_visibility_overridden';
export const PROJECT_ADD_SUB_PARAGRAPH = 'project__add_sub_paragraph';

// user
export const USER_OVERVIEW = 'user__overview';
export const USER_CREATE = 'user__create';
export const USER_UPDATE = 'user__update';

// branch
export const BRANCH_ACCESS = 'branch__access';

// chapter
export const CHAPTER_UPDATE = 'chapter__update';
export const CHAPTER_ADD_PARAGRAPH = 'chapter__add_paragraph';
export const CHAPTER_MOVE_PARAGRAPH = 'chapter__move_paragraph';
export const CHAPTER_REMOVE_PARAGRAPH = 'chapter__remove_paragraph';
export const CHAPTER_TOGGLE_SEARCHABLE = 'chapter__toggle_searchable';

// paragraph
export const PARAGRAPH_UPDATE = 'paragraph__update';
export const PARAGRAPH_REMOVE_SUB_PARAGRAPH = 'paragraph__remove_sub_paragraph';
export const PARAGRAPH_MOVE_SUB_PARAGRAPH = 'paragraph__move_sub_paragraph';
export const PARAGRAPH_ADD_SUB_PARAGRAPH = 'paragraph__add_sub_paragraph';

// sub-paragraph
export const SUB_PARAGRAPH_UPDATE = 'sub_paragraph__update';

// article
export const ARTICLE_UPDATE = 'article__update';
export const ARTICLE_CREATE = 'article__create';
export const ARTICLE_OVERVIEW_PUBLISHED = 'article__overview_published';
export const ARTICLE_OVERVIEW_UNPUBLISHED = 'article__overview_unpublished';

// side product categories
export const SIDE_PRODUCT_CATEGORIES_OVERVIEW = 'side_product_tool__index';
export const SIDE_PRODUCT_CATEGORIES_CREATE = 'side_product_tool__create';
export const SIDE_PRODUCT_CATEGORIES_UPDATE = 'side_product_tool__update';

// standard
export const STANDARD_VIEW_OVERVIEW_PUBLISHED = 'standard__view_overview_published';
export const STANDARD_ARCHIVE = 'standard__archive';
export const STANDARD_START_REVISION = 'standard__start_revision';

// event
export const EVENT_VIEW_OVERVIEW = 'event__view-overview';

// page
export const PAGE_OVERVIEW_UNPUBLISHED = 'page__overview_unpublished';
export const PAGE_CREATE = 'page__create';
export const PAGE_UPDATE = 'page__update';
export const PAGE_DELETE = 'page__delete';
export const PAGE_DETAIL_PUBLISHED = 'page__detail_published';
export const PAGE_DETAIL_UNPUBLISHED = 'page__detail_unpublished';

// faq
export const FAQ_ITEM_CREATE = 'faq_item__create';
export const FAQ_ITEM_UPDATE = 'faq_item__update';
export const FAQ_ITEM_DELETE = 'faq_item__delete';
export const FAQ_ITEM_DETAIL = 'faq_item__detail';
export const FAQ_ITEM_INDEX = 'faq_item__index';

// reference
export const REFERENCE_CREATE = 'reference__create';
export const REFERENCE_UPDATE = 'reference__update';
export const REFERENCE_LIST = 'reference__list';
