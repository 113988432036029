import React from 'react';
import Heading from '../../../../shared/heading/Heading';
import styles from './Spotlight.module.scss';
import classNames from 'classnames';
import spotlightImage from '../../../../../../images/skilz/visual.png';
import spotlightImageHres from '../../../../../../images/skilz/visual@2x.png';
import spotlightImageWebP from '../../../../../../images/skilz/visual.webp';
import spotlightImageHresWepP from '../../../../../../images/skilz/visual@2x.webp';
import spotlightMobileImageHresWepP from '../../../../../../images/skilz/visual-mobile@2x.webp';
import spotlightMobileImageWepP from '../../../../../../images/skilz/visual-mobile.webp';
import { breakpoints } from '../../../../../constants/breakpoints';

const Spotlight = () => {
    return (
        <article className={styles.wrapper}>
            <div className={classNames('container', styles.layout)}>
                <div>
                    <Heading tag="h1" className={styles.title}>
                        Richtlijnen voor de langdurige zorg
                    </Heading>
                    <p className={styles.description}>
                        Ontwikkeld voor én samen met zorgprofessionals, cliënten en naasten. Zo werken we samen aan het
                        verbeteren van de kwaliteit voor de langdurige zorg.
                    </p>
                </div>
                <picture className={styles.imageHolder}>
                    <source
                        media={`(max-width: ${breakpoints.md}px)`}
                        type="image/webp"
                        srcSet={`${spotlightMobileImageWepP} 1x, ${spotlightMobileImageHresWepP} 2x`}
                    />
                    <source type="image/webp" srcSet={`${spotlightImageWebP} 1x, ${spotlightImageHresWepP} 2x`} />
                    <source type="image/png" srcSet={`${spotlightImage} 1x, ${spotlightImageHres} 2x`} />
                    <img src={spotlightImage} alt="Richtlijnen voor de langdurige zorg" width="502" height="431" />
                </picture>
            </div>
        </article>
    );
};

export default Spotlight;
