import React from 'react';
import Spotlight from './components/Spotlight';
import { Helmet } from 'react-helmet';
import { getDefaultSeoTitle } from '../../../../helper/seoHelper';
import ProjectOverview from '../../shared/projectOverview/ProjectOverview';
import LatestArticles from '../latestArticles/LatestArticles';
import DirectiveOverview from '../projectOverview/components/DirectiveOverview';
import NewsletterCta from '../newsletterCta/NewsletterCta';

const Home = () => {
    return (
        <main>
            <Helmet>
                <title>{getDefaultSeoTitle()}</title>
            </Helmet>
            <Spotlight />
            <ProjectOverview>
                <DirectiveOverview />
                <NewsletterCta />
            </ProjectOverview>
            <LatestArticles />
        </main>
    );
};

export default Home;
