import { useState, useEffect } from 'react';

const DISPLAY_TIMEOUT = 5000; // seconds

export default function useDisplayModalWithADelay() {
    const [isReadyToDisplay, setIsReadyToDisplay] = useState<boolean>(false);

    useEffect(() => {
        const handle = setTimeout(() => setIsReadyToDisplay(true), DISPLAY_TIMEOUT);

        return () => {
            clearTimeout(handle);
        };
    }, []);

    return { isReadyToDisplay };
}
