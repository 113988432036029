import React from 'react';
import HeaderLogo from './components/HeaderLogo';
import HeaderLoadingQueueLoader from './components/HeaderLoadingQueueLoader';
import UserMenu from './components/UserMenu';
import MainMenu from './components/MainMenu';
import TopMenu from './components/TopMenu';
import HeaderSearch from '../headerSearch/HeaderSearch';
import Header from './Header';
import BottomMenu from './components/BottomMenu';
import SearchIcon from '../../../../../icons/ggz/search.svg';

const HeaderBottom = () => (
    <>
        <BottomMenu />
        <UserMenu />
    </>
);

const DefaultHeader = () => (
    <Header theme="light" bottomBarChildren={<HeaderBottom />}>
        <div className="layout layout--align-middle">
            <div className="layout__item layout__item--shrink">
                <HeaderLogo />
            </div>
            <div className="layout__item layout__item--grow">
                <div className="layout layout--align-right layout--align-middle">
                    <div className="layout__item layout__item--shrink">
                        <HeaderLoadingQueueLoader />
                    </div>
                    <div className="layout__item layout__item--shrink hidden hidden--until-lg">
                        <TopMenu />
                    </div>
                    <div className="layout__item layout__item--shrink">
                        <HeaderSearch
                            icon={<SearchIcon className="icon--color-primary form__field-icon button__icon" />}
                        />
                    </div>
                    <div className="layout__item layout__item--shrink hidden--from-lg">
                        <MainMenu />
                    </div>
                </div>
            </div>
        </div>
    </Header>
);

export default DefaultHeader;
