import { get } from '../api/client/apiClient';
import { createSideProductCategoryDetailsApiPath } from '../routing/apiUrlGenerator';
import { SideProductCategoryDetails } from '../model/sideProductOverview/SideProductOverview';
import { useState } from 'react';
import useOnMount from './useOnMount';
import { logError } from '../utility/Logger';

export default function useFetchSideProductsCategoryDetails() {
    const [categoryDetails, setCategoryDetails] = useState<SideProductCategoryDetails[] | null>(null);

    useOnMount(() => {
        fetchCategoryDetails()
            .then((data) => setCategoryDetails(data.results))
            .catch((error) => logError(error));
    });

    return { categoryDetails };
}

async function fetchCategoryDetails(): Promise<{ results: SideProductCategoryDetails[] }> {
    const response = await get(createSideProductCategoryDetailsApiPath());

    return await response.data;
}
