import * as React from 'react';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';
import { PROJECT_POST_COMMENT_REACTION, PROJECT_VIEW_COMMENT_COUNTS } from '../../../../security/SecurityAttribute';
import { SecuritySubject } from '../../../../security/SecuritySubjectType';
import CommentCounterCloud from './CommentCounterCloud';
import createClassName from 'classnames';
import { VFC } from 'react';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import { IoChatboxOutline } from 'react-icons/io5';

type Props = {
    count: number;
    answeredCount: number;
    showWhenNoComments?: boolean;
    externalProjectId: string;
    alternateContent?: React.ReactNode;
    isMuted?: boolean;
};

const CommentCounter: VFC<Props> = ({
    count,
    answeredCount,
    showWhenNoComments = false,
    externalProjectId,
    alternateContent,
    isMuted = false,
}) => {
    const { isGGZ, isSKILZ } = useCurrentTenant();
    if (count === 0 && !showWhenNoComments) {
        return null;
    }

    const unansweredCount: number = count - answeredCount;

    const containerClassName = createClassName('comment-counter', {
        'comment-counter--is-muted': isMuted,
    });

    return (
        <>
            <SecurityThreshold
                requiredAttributes={PROJECT_VIEW_COMMENT_COUNTS}
                subjectType={SecuritySubject.project}
                subjectId={externalProjectId}
                alternateContent={alternateContent}
            >
                <div className={containerClassName}>
                    {isGGZ && <CommentCounterCloud />}
                    {isSKILZ && <IoChatboxOutline className="icon icon--md comment-counter__cloud-path" />}
                    <span className="comment-counter__total-count" aria-label="Totaal aantal reacties">
                        {count}
                    </span>
                    {unansweredCount > 0 && (
                        <SecurityThreshold
                            requiredAttributes={PROJECT_POST_COMMENT_REACTION}
                            subjectType={SecuritySubject.project}
                            subjectId={externalProjectId}
                        >
                            <span
                                className="comment-counter__unanswered-count"
                                aria-label="Onbeantwoord aantal reacties"
                            >
                                {unansweredCount}
                            </span>
                        </SecurityThreshold>
                    )}
                </div>
            </SecurityThreshold>
        </>
    );
};

export default CommentCounter;
