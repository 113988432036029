import { useRef, useState } from 'react';
import type { FormData } from '../../../frontend/shared/searchForm/SearchForm';
import { useHistory } from 'react-router-dom';
import { createSearchPath } from '../../../../routing/urlGenerator';
import { breakpoints } from '../../../../constants/breakpoints';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

export default function useExpandSearchFieldOnSubmitButtonClick() {
    const history = useHistory();
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const containerElementRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(containerElementRef, () => setExpanded(false));

    const onSearchSubmit = (formData: FormData) => {
        const query = formData.query || '';

        // on small devices there is no room to expand field in that case we navigate to search result page without query
        const isSmallDevice = window.matchMedia(`(max-width: ${breakpoints.md - 1}px)`).matches;

        // expand search field instead of submitting the form
        if (!isExpanded && !isSmallDevice) {
            setExpanded(true);

            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }

            return;
        }

        // regular search submit
        navigateToSearchResults(query);

        setExpanded(false);
    };

    const navigateToSearchResults = (query: string) => {
        history.push(createSearchPath(query));
    };

    return { isExpanded, onSearchSubmit, containerElementRef, searchInputRef };
}
