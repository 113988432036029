import { createUuid } from '../../../utility/idGenerator';
import ProjectIndex from '../ProjectIndex';
import ProjectIndexChapter from '../ProjectIndexChapter';
import ProjectIndexParagraph from '../ProjectIndexParagraph';
import ProjectIndexSubParagraph from '../ProjectIndexSubParagraph';

function _createProjectIndexChapterCollectionFromApiInput(apiInput: Array<any>): Array<ProjectIndexChapter> {
    return apiInput.map((item) => {
        return new ProjectIndexChapter(
            createUuid(),
            item.id,
            item.title,
            _createProjectIndexParagraphCollectionFromApiInput(item.paragraphs),
            item._path
        );
    });
}

function _createProjectIndexParagraphCollectionFromApiInput(apiInput: Array<any>): Array<ProjectIndexParagraph> {
    return apiInput.map((item) => {
        return new ProjectIndexParagraph(
            createUuid(),
            item.id,
            item.title,
            _createProjectIndexSubParagraphCollectionFromApiInput(item.sub_paragraphs),
            item._path
        );
    });
}

function _createProjectIndexSubParagraphCollectionFromApiInput(apiInput: Array<any>): Array<ProjectIndexSubParagraph> {
    return apiInput.map((item) => {
        return new ProjectIndexSubParagraph(createUuid(), item.id, item.title, item._path);
    });
}

export function createProjectIndexFromApiInput(apiInput: any): ProjectIndex {
    return new ProjectIndex(
        createUuid(),
        apiInput.id,
        apiInput.title,
        _createProjectIndexChapterCollectionFromApiInput(apiInput.chapters),
        apiInput._path,
        apiInput._type_slug,
        apiInput._slug,
        apiInput.file
    );
}
