import { ProjectSideProductToolType, ProjectSideProductType } from '../../../../../model/ProjectSideProduct';
import { ProjectDetailLinkToolType, ProjectDetailLinkType } from '../../../../../model/projectDetail/ProjectDetailLink';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';

export function getCategorySideProducts(
    projectDetails: ProjectDetail,
    sideProductTypes: ProjectSideProductType[],
    linkTypes: ProjectDetailLinkType[]
) {
    const categorySideProducts = {
        [ProjectSideProductToolType.workCard]: [],
        [ProjectSideProductToolType.patientToolkit]: [],
        [ProjectSideProductToolType.caseCard]: [],
        [ProjectSideProductToolType.app]: [],
        [ProjectSideProductToolType.video]: [],
        [ProjectSideProductToolType.processInformation]: [],
        [ProjectSideProductToolType.bottlenecks]: [],
        [ProjectSideProductToolType.literatureReview]: [],
        [ProjectSideProductToolType.informationCard]: [],
        [ProjectSideProductToolType.summaryCard]: [],
        [ProjectSideProductToolType.practiceCard]: [],
        [ProjectSideProductToolType.tool]: [],
        [ProjectSideProductToolType.usefulLinks]: [],
        [ProjectSideProductToolType.other]: [],
    };

    const applySideProducts = projectDetails.getSideProductsByType(sideProductTypes);
    const applyLinks = projectDetails.getLinksByType(linkTypes);

    applySideProducts.forEach((sideProduct) =>
        // @ts-ignore unsure how to fix this error
        categorySideProducts[sideProduct.toolType].push(sideProduct)
    );

    applyLinks.forEach((link) => {
            if (link.toolType === ProjectDetailLinkToolType.decisionAid) {
                // @ts-ignore unsure how to fix this error
                categorySideProducts[ProjectSideProductToolType.app].push(link);
            } else if (link.toolType === ProjectDetailLinkToolType.usefulLinks) {
                // @ts-ignore unsure how to fix this error
                categorySideProducts[ProjectSideProductToolType.usefulLinks].push(link);
            } else {
                // @ts-ignore unsure how to fix this error
                categorySideProducts[ProjectSideProductToolType.other].push(link);
            }
        }
    );

    return { categorySideProducts };
}
