import React, { VFC } from 'react';
import { Link } from 'react-router-dom';
import styles from './BackButton.module.scss';
import { IoArrowBack } from 'react-icons/io5';

type Props = {
    to: string;
    text: string;
    additionalClassNames?: string;
    [x: string]: any;
};
const BackButton: VFC<Props> = ({ to, text, ...otherProps }) => {
    return (
        <Link to={to} className={styles.link} aria-hidden {...otherProps}>
            <IoArrowBack className={styles.arrowIcon} />
            <span>{text}</span>
        </Link>
    );
};

export default BackButton;
