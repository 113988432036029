import { ActionType } from 'redux-promise-middleware';
import type { Action } from '../actions/factory';
import { createSideProductOverviewItemCollectionFromApiData } from '../model/sideProductOverview/factory/sideProductFactory';
import { FETCH_SIDE_PRODUCT_OVERVIEW } from '../actions/types';
import { SideProductOverview } from '../model/sideProductOverview/SideProductOverview';

export type SideProductOverviewReducerState = SideProductOverview | null;

export default function (
    currentState: SideProductOverviewReducerState = null,
    action: Action
): SideProductOverviewReducerState {
    switch (action.type) {
        case `${FETCH_SIDE_PRODUCT_OVERVIEW}_${ActionType.Fulfilled}`:
            return createSideProductOverviewItemCollectionFromApiData(
                // @ts-ignore -> Flow does not know payload contents
                action.payload.data
            );

        default:
            return currentState;
    }
}
