import OverviewUser from '../OverviewUser';
import PaginatedCollection from '../collection/paginatedCollection';

function _createOverviewUserFromApiInput(apiInput: any): OverviewUser {
    return new OverviewUser(
        apiInput.id,
        apiInput.roles,
        apiInput.name.first_name,
        apiInput.name.preposition,
        apiInput.name.last_name,
        apiInput.profession,
        apiInput.email
    );
}

export function createOverviewUserCollectionFromApiInput(apiInput: any): PaginatedCollection<OverviewUser> {
    const results = apiInput.results.map((apiInputItem: any) => _createOverviewUserFromApiInput(apiInputItem));

    return new PaginatedCollection(
        results,
        apiInput.current_page,
        apiInput.total_no_of_pages,
        apiInput.no_of_results_per_page,
        apiInput.total_no_of_results
    );
}
