import { useDispatch } from 'react-redux';
import { createDuplicateProjectAction } from '../../../../actions/projectActionFactory';
import ProjectOverviewItem from '../../../../model/projectOverview/ProjectOverviewItem';

export default function useDispatchDuplicateProjectActionOnConfirm(item: ProjectOverviewItem) {
    const dispatch = useDispatch();

    const onConfirm = () => {
        if (!item.externalId) {
            throw new Error('Expecting this project to have an external id at this point');
        }

        dispatch(createDuplicateProjectAction(item.id, item.externalId, item.title));
    };

    return { onConfirm };
}
