import React from 'react';
import { NavLink } from 'react-router-dom';
import { createArticleOverviewPath, createExplainStandardTypesPagePath } from '../../../../routing/urlGenerator';

const TopMenu = () => (
    <nav className="header__top-menu">
        <NavLink to={createExplainStandardTypesPagePath()} activeClassName="is-active">
            Over zorgstandaarden
        </NavLink>
        <NavLink to={createArticleOverviewPath()} activeClassName="is-active">
            Actueel
        </NavLink>
    </nav>
);

export default TopMenu;
