export default class BookmarkTrigger {
    _bookmarkedId: string;
    _title: string;
    _url: string;

    constructor(bookmarkedId: string, title: string, url: string) {
        this._bookmarkedId = bookmarkedId;
        this._title = title;
        this._url = url;
    }

    get bookmarkedId() {
        return this._bookmarkedId;
    }

    get title() {
        return this._title;
    }

    get url() {
        return this._url;
    }
}
