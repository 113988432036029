import * as React from 'react';
import ProjectOverviewItemModel from '../../../model/projectOverview/ProjectOverviewItem';
import DashboardIslandHeader from '../shared/island/DashboardIslandHeader';
import DashboardIslandAction from '../shared/island/DashboardIslandAction';
import DashboardIsland from '../shared/island/DashboardIsland';
import ProjectOverviewItem from './components/ProjectOverviewItem';
import List from '../shared/list/List';
import { ReactNode } from 'react';

type Props = {
    items: Array<ProjectOverviewItemModel>;
    title: string;
    children?: ReactNode;
};

const ProjectOverview = ({ items, title, children }: Props) => {
    const persistedItems = items.filter(({ externalId }) => !!externalId);

    return (
        <DashboardIsland>
            <DashboardIslandHeader heading={title}>
                {children && <DashboardIslandAction>{children}</DashboardIslandAction>}
            </DashboardIslandHeader>
            {persistedItems.length === 0 ? (
                'Geen resultaten'
            ) : (
                <List>
                    {persistedItems.map((item) => (
                        <ProjectOverviewItem key={item.id} item={item} />
                    ))}
                </List>
            )}
        </DashboardIsland>
    );
};

export default ProjectOverview;
