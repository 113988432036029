import React from 'react';
import redraft from 'redraft';
import Link from './components/Link';
import LinkInformation from './components/LinkInformation';
import Atomic from './components/Atomic';
import {
    ENTITY_TYPE_LINK,
    ENTITY_TYPE_IMAGE,
    ENTITY_TYPE_DEFINITION,
    ENTITY_TYPE_REFERENCE,
    ENTITY_TYPE_VIDEO,
    ENTITY_TYPE_TABLE,
    ENTITY_TYPE_HIGHLIGHT,
    ENTITY_TYPE_FILE_LINK,
} from '../editor/entity/type';
import Image from './components/Image';
import { logError } from '../../../utility/Logger';
import { applySoftNewlines } from '../../../helper/enterHelper';
import Definition from './components/Definition';
import Reference from './components/Reference';
import Video from './components/Video';
import Heading from '../heading/Heading';
import Table from '../editor/components/Table';
import HighlightBlock from '../editor/components/HighlightBlock';

type Props = {
    raw: any;
    followLink?: boolean;
};

const EditorContent = ({ raw, followLink = true }: Props) => {
    const rendered = redraft(raw, {
        inline: {
            BOLD: (children: React.ReactChild[], { key }: { key: string }) => <strong key={key}>{children}</strong>, // eslint-disable-line react/display-name
            ITALIC: (children: React.ReactChild[], { key }: { key: string }) => <em key={key}>{children}</em>, // eslint-disable-line react/display-name
        },

        blocks: {
            unstyled: (children: React.ReactChild[]) =>
                children.map((childChildren, index) => <p key={index}>{applySoftNewlines(childChildren)}</p>),
            atomic: (children: React.ReactChild[], { keys, data }: { keys: string[]; data: any }) => {
                return children.map((_child, i) => (
                    <Atomic key={keys[i]} {...data[i]}>
                        {children}
                    </Atomic>
                ));
            },
            blockquote: (children: React.ReactChild[][]) =>
                children.map((childChildren, index) => (
                    <blockquote key={index}>{childChildren.map((child) => applySoftNewlines(child))}</blockquote>
                )),
            'header-two': (children: React.ReactChild[], { keys }: { keys: string[] }) => (
                <Heading tag="h2" variant="h3" key={keys[keys.length - 1]}>
                    {children}
                </Heading>
            ),
            'header-three': (children: React.ReactChild[], { keys }: { keys: string[] }) => (
                <Heading tag="h3" variant="h4" key={keys[keys.length - 1]}>
                    {children}
                </Heading>
            ),
            'unordered-list-item': (
                children: React.ReactChild[],
                { depth, keys }: { depth: number; keys: string[] } // eslint-disable-line react/display-name
            ) => (
                <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
                    {children.map((child, index) => (
                        <li key={index}>{applySoftNewlines(child)}</li>
                    ))}
                </ul>
            ),
            'ordered-list-item': (
                children: React.ReactChild[],
                { depth, keys }: { depth: number; keys: string[] } // eslint-disable-line react/display-name
            ) => (
                <ol key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
                    {children.map((child, index) => (
                        <li key={index}>{applySoftNewlines(child)}</li>
                    ))}
                </ol>
            ),
        },

        entities: {
            [ENTITY_TYPE_LINK]: (
                children: React.ReactChild[],
                data: { href: string; external: boolean },
                { key }: { key: string }
            ) => {
                // eslint-disable-line react/display-name
                if (followLink) {
                    return (
                        <Link key={key} href={data.href} external={data.external}>
                            {children}
                        </Link>
                    );
                }

                return (
                    <LinkInformation key={key} data={data}>
                        {children}
                    </LinkInformation>
                );
            },

            [ENTITY_TYPE_FILE_LINK]: (
                children: React.ReactChild[],
                data: { href: string; file: string },
                { key }: { key: string }
            ) => {
                // eslint-disable-line react/display-name
                if (followLink) {
                    return (
                        <Link key={key} className="link link--file" file={data.file}>
                            {children}
                        </Link>
                    );
                }

                return (
                    <LinkInformation key={key} data={data}>
                        {children}
                    </LinkInformation>
                );
            },

            [ENTITY_TYPE_IMAGE]: function (
                children: React.ReactChild[],
                data: {
                    src: string | null;
                    title: string;
                    originalFilePath: string;
                },
                { key }: { key: string }
            ) {
                // eslint-disable-line react/display-name
                if (!data.src) {
                    return null;
                }

                return (
                    <Image key={key} src={data.src} originalSrc={data.originalFilePath} title={data.title}>
                        {children}
                    </Image>
                );
            },

            [ENTITY_TYPE_VIDEO]: function (
                _children: React.ReactChild[],
                data: { code: string | null },
                { key }: { key: string }
            ) {
                if (!data.code) {
                    return null;
                }

                return <Video key={key} code={data.code} />;
            },

            [ENTITY_TYPE_TABLE]: function (
                _children: React.ReactChild[],
                data: { sanitizedHTML: string | null },
                { key }: { key: string }
            ) {
                if (!data.sanitizedHTML) return null;

                return <Table key={key} html={data.sanitizedHTML} />;
            },

            [ENTITY_TYPE_DEFINITION]: function (
                children: React.ReactChild[],
                data: { definition: any; link: any },
                { key }: { key: string }
            ) {
                // eslint-disable-line react/display-name
                // @todo find a fix for warning of wrongly nested elements
                return (
                    <Definition key={key} definition={data.definition} link={data.link}>
                        {children}
                    </Definition>
                );
            },

            [ENTITY_TYPE_REFERENCE]: function (
                _children: React.ReactChild[],
                data: { referenceId: string },
                { key }: { key: string }
            ) {
                // eslint-disable-line react/display-name
                const referenceId = data.referenceId;

                return <Reference key={key} id={referenceId} />;
            },

            [ENTITY_TYPE_HIGHLIGHT]: function (children: React.ReactChild[], _data: {}, { key }: { key: string }) {
                return <HighlightBlock key={key}>{children}</HighlightBlock>;
            },
        },
    });

    if (!rendered) {
        logError(new Error('raw content could not be rendered by redract'), {
            content: raw,
        });

        return null;
    }

    return <div className="editor-content">{rendered}</div>;
};

export default EditorContent;
