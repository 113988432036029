export default class ProjectIndexSubParagraph {
    _id: string;
    _externalId: string;
    _title: string;
    _path: string;

    constructor(id: string, externalId: string, title: string, path: string) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get path() {
        return this._path;
    }
}
