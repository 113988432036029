import React, { ReactNode } from 'react';
import createClassName from 'classnames';
import { FC } from 'react';

type Props = {
    theme: 'light' | 'dark';
    divider?: boolean;
    children: ReactNode;
    bottomBarChildren?: ReactNode;
};

const Header: FC<Props> = ({ theme, divider, children, bottomBarChildren }) => {
    const wrapperClassName = createClassName('header', {
        'header--dark': theme === 'dark',
        'header--divider': divider,
    });

    return (
        <header className={wrapperClassName}>
            <div className="header__top">{children}</div>
            {bottomBarChildren && <div className="header__bottom">{bottomBarChildren}</div>}
        </header>
    );
};

export default Header;
