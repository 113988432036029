// editors within the same name space allow copy/pasting of content between them
import { UserPlatformRole } from '../../../../model/User';

export type EditorSettings = {
    editable: boolean;
    plugins: EditorPlugins;
};

export type EditorPlugins = {
    bold: boolean;
    italic: boolean;
    lists: boolean;
    links: boolean;
    internalLinks: boolean;
    fileLinks: boolean;
    quotes: boolean;
    media: boolean;
    definitions: boolean;
    references: boolean;
    highlights: boolean;
    comments: boolean;
    wordCount: boolean;
};

export const editorNamespaces = {
    EDITOR_PROJECT_DETAIL: 'projectDetail',
    EDIT_PATIENT_INTRODUCTION: 'editPatientIntroduction',
};

export const RESTRICT_ALL_PLUGIN_SETTINGS = {
    bold: false,
    italic: false,
    lists: false,
    links: false,
    internalLinks: false,
    fileLinks: false,
    quotes: false,
    media: false,
    definitions: false,
    references: false,
    highlights: false,
    comments: false,
    wordCount: false,
};

export const ALLOW_ALL_PLUGIN_SETTINGS = {
    bold: true,
    italic: true,
    lists: true,
    links: true,
    internalLinks: true,
    fileLinks: true,
    quotes: true,
    media: true,
    definitions: true,
    references: true,
    highlights: true,
    comments: true,
    wordCount: true,
};

export const DEFAULT_EDITOR_SETTINGS: EditorSettings = {
    editable: true,
    plugins: {
        ...RESTRICT_ALL_PLUGIN_SETTINGS,
    },
};

export const editorSettings = {
    [editorNamespaces.EDITOR_PROJECT_DETAIL]: {
        editable: true,
        plugins: {
            ...RESTRICT_ALL_PLUGIN_SETTINGS,
            internalLinks: true,
            fileLinks: true,
            quotes: true,
            media: true,
            definitions: true,
            references: true,
            highlights: true,
            comments: true,
            wordCount: true,
        },
    },
    [editorNamespaces.EDIT_PATIENT_INTRODUCTION]: {
        editable: true,
        plugins: {
            ...RESTRICT_ALL_PLUGIN_SETTINGS,
            bold: true,
            italic: true,
            lists: true,
            links: true,
            internalLinks: true,
            fileLinks: true,
            quotes: true,
            media: true,
        },
    },
};

// editing rights may vary depending on which phase a project is in for working group members
export const getProjectEditorSettingsByUserPlatformRole = (
    role?: UserPlatformRole,
    overwritingPlugins?: Partial<EditorPlugins>
) => {
    switch (role) {
        case UserPlatformRole.SuperAdmin:
        case UserPlatformRole.Admin:
        case UserPlatformRole.WorkingGroupMember:
            return {
                editable: true,
                plugins: {
                    ...ALLOW_ALL_PLUGIN_SETTINGS,
                    ...overwritingPlugins,
                },
            };
        default:
            return {
                editable: false,
                plugins: {
                    ...RESTRICT_ALL_PLUGIN_SETTINGS,
                    ...overwritingPlugins,
                },
            };
    }
};
