import ProjectBreadcrumb from '../ProjectBreadcrumb';
import { createUuid } from '../../utility/idGenerator';

export function createProjectBreadcrumbFromApiInput(apiInput: any): ProjectBreadcrumb {
    return new ProjectBreadcrumb(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.parts,
        apiInput.indexes,
        apiInput.type,
        apiInput.project_id,
        apiInput.chapter_id,
        apiInput.paragraph_id,
        apiInput.sub_paragraph_id,
        apiInput.path,
        apiInput.closed
    );
}

export function createProjectBreadcrumbCollectionFromApiInput(apiInput: Array<any>): Array<ProjectBreadcrumb> {
    return apiInput.map((apiInputItem: any) => createProjectBreadcrumbFromApiInput(apiInputItem));
}
