import React from 'react';

const CommentCounterCloud = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M9.24 22.977l3.78-3.74H22a2.5 2.5 0 0 0 2.5-2.5V3A2.5 2.5 0 0 0 22 .5H3A2.5 2.5 0 0 0 .5 3v13.737a2.5 2.5 0 0 0 2.5 2.5h2.546l3.693 3.74z"
            fill="none"
            fillRule="evenodd"
            className="comment-counter__cloud-path"
        />
    </svg>
);

export default CommentCounterCloud;
