import { DetailArticle } from '../model/Article';
import { useState, useEffect } from 'react';
import { logError } from '../utility/Logger';
import { getOneWithId } from '../repository/articleRepository';
import { useParams } from 'react-router-dom';

type RouteParams = {
    id: string;
};

export default function useFetchArticleDetailsOnIdChange() {
    const [article, setArticle] = useState<DetailArticle | null>(null);
    const { id } = useParams<RouteParams>();

    useEffect(() => {
        getOneWithId(parseInt(id), false)
            .then((article) => setArticle(article))
            .catch((error) => logError(error));
    }, [id]);

    return { article };
}
