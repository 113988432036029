import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { isDevelopmentEnvironment } from '../helper/environmentHelper';
import { applyMiddleware, compose } from 'redux';
import errorCatchingMiddleware from './errorCatchingMiddleware';
import handleMiddlewareError from '../handler/middlewareErrorHandler';
import {
    PUBLISH_PROJECT,
    FETCH_PROJECT_DETAILS,
    FETCH_PARAGRAPH_DETAILS,
    PROJECT_EMBED_CHAPTER,
    PROJECT_EMBED_PARAGRAPH,
    FORGOT_PASSWORD,
    ADD_USER,
    ADD_BRANCH,
} from '../actions/types';

/**
 * @returns {Function}
 */
export function createMiddlewareChain() {
    const middlewares = [
        errorCatchingMiddleware(handleMiddlewareError, {
            excludeActionTypes: [
                PUBLISH_PROJECT,
                FETCH_PROJECT_DETAILS,
                FETCH_PARAGRAPH_DETAILS,
                PROJECT_EMBED_CHAPTER,
                PROJECT_EMBED_PARAGRAPH,
                FORGOT_PASSWORD,
                ADD_USER,
                ADD_BRANCH,
            ],
        }),
        thunkMiddleware,
        promiseMiddleware,
    ];

    // add access for Redux dev-tools extensions (https://github.com/zalmoxisus/redux-devtools-extension)
    const enhancedComposer =
        isDevelopmentEnvironment() && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose;

    return enhancedComposer(applyMiddleware(...middlewares));
}
