import User from '../User';
import { createBookmarkFromUserApiData, createBookmarkTagFromApiData } from './bookmarkFactory';

export function createUserFromApiData(apiData: any): User {
    return new User(
        apiData.id,
        apiData.email,
        apiData.roles,
        apiData.name.first_name,
        apiData.name.preposition,
        apiData.name.last_name,
        apiData.profession,
        apiData.bookmarks.map((bookmarkApiData: any) => createBookmarkFromUserApiData(bookmarkApiData)),
        apiData.bookmarkTags.map((bookmarkTagApiData: any) => createBookmarkTagFromApiData(bookmarkTagApiData)),
        apiData.platform_roles,
        apiData.last_login_at
    );
}
