import type { Cloneable } from '../model/contracts';

export type ItemIdType = string | number | null;

export default class LoadingQueueItem implements Cloneable<LoadingQueueItem> {
    _type: string;
    _id: ItemIdType;

    constructor(type: string, id: ItemIdType = null) {
        this._type = type;
        this._id = id;
    }

    get type() {
        return this._type;
    }

    get id() {
        return this._id;
    }

    clone() {
        return new LoadingQueueItem(this._type, this._id);
    }
}
