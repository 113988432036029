import { FETCH_PROJECT_CHAPTER_DETAILS } from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { createProjectChapterDetailFromApiInput } from '../model/projectChapterDetail/factory/projectChapterDetailFactory';
import ProjectChapterDetail from '../model/projectChapterDetail/ProjectChapterDetail';

import type { Action } from '../actions/factory';

export type ProjectChapterDetailsReducerState = ProjectChapterDetail | undefined | null;

export default function (
    currentState: ProjectChapterDetailsReducerState = null,
    action: Action
): ProjectChapterDetailsReducerState {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case `${FETCH_PROJECT_CHAPTER_DETAILS}_${ActionType.Fulfilled}`:
            // $ExpectError -> Flow does not know payload contents
            return createProjectChapterDetailFromApiInput(action.payload.data);

        default:
            return currentState;
    }
}
