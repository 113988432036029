import React from 'react';
import ErrorNotificationPage from '../../page/ErrorNotificationPage';

const OfflineErrorNotificationPage = () => (
    <ErrorNotificationPage
        title="De pagina kon niet geladen worden"
        description="De pagina kon niet geladen worden vanwege een ontbrekende of instabiele internetverbinding. Ververs de pagina om het nog een keer te proberen."
    />
);

export default OfflineErrorNotificationPage;
