import React from 'react';
import classNameFactory from 'classnames';

type Props = {
    sizeSmall?: boolean;
};

const Loader: React.FC<Props> = ({ sizeSmall = false }) => {
    const className = classNameFactory('loader', {
        'loader--small': sizeSmall,
    });

    return (
        <div className={className}>
            <div className="loader-rect loader-rect1">&nbsp;</div>
            <div className="loader-rect loader-rect2">&nbsp;</div>
            <div className="loader-rect loader-rect3">&nbsp;</div>
            <div className="loader-rect loader-rect4">&nbsp;</div>
            <div className="loader-rect loader-rect5">&nbsp;</div>
        </div>
    );
};

export default Loader;
