import { useDispatch } from 'react-redux';
import { OnValidSubmitHandler } from './useHandleCreateUserFormState';
import { createAddUserAction } from '../../../../actions/userActionFactory';
import { Dispatch } from '../../../../actions/factory';
import { extractFormErrorsFromResponseError } from '../../../../helper/formErrorHelper';

export default function usePersistUserOnValidSubmit(onDone: () => void): OnValidSubmitHandler {
    const dispatch = useDispatch<Dispatch>();

    return async (values) => {
        const action = createAddUserAction(
            values.firstName,
            values.preposition,
            values.lastName,
            values.profession,
            values.email,
            values.globalRole,
            values.personalMessage
        );

        try {
            await dispatch(action);

            onDone();

            return;
        } catch (error: any) {
            const validationErrors = extractFormErrorsFromResponseError(error);

            if (!validationErrors) {
                throw error;
            }

            return validationErrors;
        }
    };
}
