import checkImagesAreLoaded from 'imagesloaded';
import { useEffect, useRef } from 'react';
import Scroll from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { useProjectDetailContext } from '../../../ggz/projectDetail/components/ProjectDetailContext';
import ProjectChapterDetail from '../../../../../model/projectChapterDetail/ProjectChapterDetail';

const ADDITIONAL_SCROLL_OFFSET = 20;

const scrollToAnchor = (anchor: string, scrollOffset: number) => {
    // first make sure the anchor exists before trying to go there. (if it doesn't this
    // results in a console warning)
    if (!document.querySelector(`[name='${anchor}']`)) {
        return;
    }

    Scroll.scroller.scrollTo(anchor, {
        smooth: true,
        offset: -scrollOffset,
    });
};

export function useScrollToChapterPartOnChapterChange(chapterDetails: ProjectChapterDetail | null) {
    const chapterContentContainerRef = useRef<HTMLDivElement | null>(null);

    const { pathname } = useLocation();

    const { projectDetailHeaderHeight } = useProjectDetailContext();

    const scrollOffset = projectDetailHeaderHeight + ADDITIONAL_SCROLL_OFFSET;

    useEffect(() => {
        if (!chapterContentContainerRef.current || !chapterDetails) {
            return;
        }

        // the scroll to anchor functionality is only reliable when all components
        // on the page that take space, are loaded.
        checkImagesAreLoaded(chapterContentContainerRef.current).on('always', () =>
            scrollToAnchor(pathname, scrollOffset)
        );
    }, [chapterContentContainerRef.current, chapterDetails]);

    return { chapterContentContainerRef };
}
