import React from 'react';
import PaginatedCollection from '../../../model/collection/paginatedCollection';
import Pagination from 'react-js-pagination';
import type { PaginatedCollectionType } from '../../../model/collection/paginatedCollection';
import ArrowLeft from '../../../../../icons/ggz/arrow-left.svg';
import ArrowRight from '../../../../../icons/ggz/arrow-right.svg';

export type OnPageChangeCallback = (newPage: number) => void;

type Props = {
    collection: PaginatedCollection | PaginatedCollectionType;
    currentPage: number;
    onPageChange: OnPageChangeCallback;
};

const CollectionPagination = ({ collection, currentPage, onPageChange }: Props) => {
    if (collection.totalNumberOfPages <= 1) {
        return null;
    }

    return (
        <Pagination
            activePage={currentPage}
            itemsCountPerPage={collection.numberOfResultsPerPage}
            totalItemsCount={collection.totalNumberOfResults}
            pageRangeDisplayed={5}
            onChange={onPageChange}
            activeLinkClass="is-active"
            itemClass="pagination__item"
            linkClass="pagination__link"
            linkClassFirst="pagination__first"
            linkClassLast="pagination__last"
            linkClassPrev="pagination__prev"
            linkClassNext="pagination__next"
            prevPageText={<ArrowLeft />}
            nextPageText={<ArrowRight />}
            hideFirstLastPages={true}
        />
    );
};

export default CollectionPagination;
