import React, { FC } from 'react';
import { createLoginPath } from '../../../../routing/urlGenerator';
import AccountIcon from '../../../../../../icons/ggz/account.svg';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import createClassName from 'classnames';
import { createDashboardEntryPath } from '../../../../routing/dashboardUrlGenerator';

type Props = {
    isSecondary?: boolean;
    onClick?: any;
};

const LoginButton: FC<Props> = ({ isSecondary = false, onClick }) => {
    const user = useCurrentUser();
    const path = user ? createDashboardEntryPath() : createLoginPath();

    const classNames = createClassName('header__navigation-user-trigger', {
        'header__navigation-user-trigger--secondary': isSecondary,
    });

    return (
        <button onClick={onClick} className={classNames}>
            <a href={path}>
                <AccountIcon />
                <span className="header__navigation-user-text">{user ? user.firstName : 'Inloggen'}</span>
            </a>
        </button>
    );
};

export default LoginButton;
