import React, { VFC } from 'react';
import ForgotPasswordWrapper from './components/ForgotPasswordWrapper';
import ForgotPasswordForm from '../../shared/forgotPassword/ForgotPasswordForm';

const ForgotPassword: VFC = () => (
    <ForgotPasswordWrapper>
        <h1>Wachtwoord vergeten?</h1>
        <p>
            Lukt het niet om in te loggen of wil je je wachtwoord wijzigen? Vul hieronder je e-mailadres in. Je ontvangt
            op dit adres een e-mail met daarin een link om je wachtwoord opnieuw in te stellen.
        </p>
        <ForgotPasswordForm placeholder="" allowLabels />
    </ForgotPasswordWrapper>
);

export default ForgotPassword;
