import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { createFrontendEntryPath, createLoginPath } from '../../../../routing/urlGenerator';
import Logo from '../../../../../images/skilz/skilz-logo.svg';
import styles from './Header.module.scss';
import HeaderSearch from '../../../shared/headerSearch/HeaderSearch';
import NavItems from './components/NavItems';
import MobileNav from './components/MobileNav';
import createClassName from 'classnames';
import { IoPersonOutline, IoSearchOutline } from 'react-icons/io5';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { createDashboardEntryPath } from '../../../../routing/dashboardUrlGenerator';
import classNames from 'classnames';

const Header: FC = () => {
    const user = useCurrentUser();
    const path = user ? createDashboardEntryPath() : createLoginPath();

    return (
        <header className={styles.header}>
            <div className="container">
                <div className={styles.content}>
                    <div className={styles.logoWrapper}>
                        <Link to={createFrontendEntryPath()}>
                            <Logo className={styles.logo} />
                        </Link>
                    </div>
                    <nav className={styles.navigation}>
                        <NavItems />
                    </nav>
                    <div className={styles.login}>
                        <a href={path}>
                            <IoPersonOutline className={classNames(styles.icon, 'icon--color-quaternary')} />
                        </a>
                    </div>
                    <div className={styles.search}>
                        <HeaderSearch
                            icon={
                                <IoSearchOutline
                                    className={createClassName('icon--color-quaternary form__field-icon', styles.icon)}
                                />
                            }
                            placeholder="Zoek op richtlijn of trefwoord"
                        />
                    </div>
                    <MobileNav />
                </div>
            </div>
        </header>
    );
};

export default Header;
