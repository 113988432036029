import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import { Dropdown } from 'semantic-ui-react';
import DuplicateProjectThreshold from './components/DuplicateProjectThreshold';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import useDispatchDuplicateProjectActionOnConfirm from './hooks/useDispatchDuplicateProjectActionOnConfirm';
import ProjectOverviewItem from '../../../model/projectOverview/ProjectOverviewItem';

type Props = {
    item: ProjectOverviewItem;
};

const DuplicateProject = ({ item }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const { onConfirm } = useDispatchDuplicateProjectActionOnConfirm(item);

    if (!item.externalId) {
        return null;
    }

    return (
        <DuplicateProjectThreshold externalId={item.externalId}>
            {showModal ? (
                <ConfirmationModal title="Weet je het zeker?" onConfirm={onConfirm} onDecline={() => doHideModal()}>
                    <p>Deze actie kan niet meer ongedaan gemaakt worden.</p>
                </ConfirmationModal>
            ) : (
                <Dropdown.Item text="Project dupliceren" onClick={() => doShowModal()} className="icon icon-cancel" />
            )}
        </DuplicateProjectThreshold>
    );
};

export default DuplicateProject;
