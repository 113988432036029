import queryString from 'qs';
import { serialize } from 'object-to-formdata';
import { isObject } from 'lodash';
import type { RequestBody } from '../client/apiClient';

export type FormattingOptions = {
    removeEmptyArrays?: boolean;
};

const DEFAULT_FORMATTING_OPTIONS = {
    removeEmptyArrays: false,
};

function removeEmptyArraysFromObject(values: RequestBody): RequestBody {
    const filteredObject: RequestBody = {};

    Object.keys(values).forEach((key) => {
        const value = values[key];

        if (Array.isArray(value) && value.length === 0) {
            return;
        }

        if (isObject(value)) {
            // $ExpectError -> flow does not know value is of type RequestBody here
            filteredObject[key] = removeEmptyArraysFromObject(value as RequestBody);
        } else {
            filteredObject[key] = value;
        }
    });

    return filteredObject;
}

export function formatBodyAsFormData(values: RequestBody, options: FormattingOptions | null = null): FormData {
    // $ExpectError
    const normalizedOptions: FormattingOptions = {
        ...DEFAULT_FORMATTING_OPTIONS,
        ...(options || {}),
    };

    if (normalizedOptions.removeEmptyArrays) {
        values = removeEmptyArraysFromObject(values);
    }

    return serialize(values, {
        indices: true,
    });
}

export function formatBodyAsQueryString(values: RequestBody): string {
    return Object.keys(values).length > 0 ? queryString.stringify(values) : '';
}
