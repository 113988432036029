import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import createClassName from 'classnames';
import BreadcrumbItemModel from '../../../../model/BreadcrumbItem';
import Tooltip from '../../Tooltip';

type Props = {
    item: BreadcrumbItemModel;
    openLinkInNewTab?: boolean;
    inClosedProject?: boolean;
};

const BreadcrumbItem: React.FC<Props> = ({ item, openLinkInNewTab = false, inClosedProject = false }) => {
    const routeMatch = useRouteMatch();

    const isActive = routeMatch.url === item.path;

    const className: string = createClassName('breadcrumb-list__item-text', {
        ['breadcrumb-list__item-text--active']: isActive,
    });

    const renderClosedProjectContent = () => (
        <span className={className}>
            <del>{item.titleWithNumbering}</del>
        </span>
    );

    const renderNonLinkContent = () => <span className={className}>{item.titleWithNumbering}</span>;

    const renderLinkContent = () => {
        const target = openLinkInNewTab ? '_blank' : '';

        if (!item.path) {
            throw Error('Link item should contain a path value at this point');
        }

        return (
            <Link to={item.path} className={className} target={target}>
                {item.titleWithNumbering}
            </Link>
        );
    };

    const renderContent = () => {
        if (inClosedProject) {
            return renderClosedProjectContent();
        } else if (item.path === null || isActive) {
            return renderNonLinkContent();
        }

        return renderLinkContent();
    };

    const tooltipContent = inClosedProject ? `${item.titleWithNumbering} (project gesloten)` : item.titleWithNumbering;

    return <Tooltip content={tooltipContent}>{renderContent()}</Tooltip>;
};

export default BreadcrumbItem;
