import * as React from 'react';
import Scroll from 'react-scroll';
import { NavLink, useHistory } from 'react-router-dom';
import classNameFactory from 'classnames';
import { useProjectDetailContext } from '../../../ggz/projectDetail/components/ProjectDetailContext';
import NestedCounterList from '../../../../shared/nestedCounterList/NestedCounterList';
import { IndexStyles } from './ProjectIndexList';

export const LEVEL_CHAPTER = 'chapter';
export const LEVEL_PARAGRAPH = 'paragraph';
export const LEVEL_SUB_PARAGRAPH = 'sub-paragraph';

export type OnSetActiveCallback = (anchor: string, level: string) => void;

type Props = {
    title: string;
    path: string;
    level: 'chapter' | 'paragraph' | 'sub-paragraph';
    isAnchorLink?: boolean;
    children?: React.ReactNode;
    onSetActive?: OnSetActiveCallback;
    onClick: () => void;
    style: IndexStyles;
};

const ADDITIONAL_SCROLL_OFFSET = 20;

const ProjectIndexItem = ({
    title,
    path,
    level,
    isAnchorLink = false,
    children,
    onSetActive,
    onClick: onClickHandler,
    style,
}: Props) => {
    const classnames = classNameFactory('project-index__item', {
        'project-index__item-level--chapter': level === LEVEL_CHAPTER,
        'project-index__item-level--paragraph': level === LEVEL_PARAGRAPH,
        'project-index__item-level--sub-paragraph': level === LEVEL_SUB_PARAGRAPH,
        'project-index__item--ggz': style === IndexStyles.GGZ,
        'project-index__item--skilz': style === IndexStyles.SKILZ,
    });

    const history = useHistory();
    const { projectDetailHeaderHeight } = useProjectDetailContext();

    const scrollOffset = projectDetailHeaderHeight + ADDITIONAL_SCROLL_OFFSET;

    const handleActive = (to: string) => {
        if (onSetActive) {
            onSetActive(to, level);
        }
    };

    const onClick = (path: string, replaceState = false) => {
        if (replaceState) {
            // when you are navigating within chapters, we don't want a new url to be pushed to the history for every
            // anchor click, as the user would have to click back multiple times to get to the previous chapter.
            history.replace(path);
        }

        if (onClickHandler) {
            onClickHandler();
        }
    };

    /*
     * Anchor links will scroll to target and use a spy element to set active state based on scroll position
     * Links to other pages will use the route path to detect active state
     */
    const renderLink = () => {
        if (isAnchorLink) {
            return (
                <Scroll.Link
                    href={path}
                    spy={true}
                    offset={-scrollOffset}
                    to={path}
                    smooth={true}
                    isDynamic={true}
                    activeClass="is-active"
                    onSetActive={handleActive}
                    onClick={() => onClick(path, true)}
                    data-track-id="index-sub-title"
                >
                    {title}
                </Scroll.Link>
            );
        }

        return (
            <NavLink
                to={path}
                activeClassName="is-active"
                exact
                onClick={() => onClick(path)}
                data-track-id="index-title"
            >
                {title}
            </NavLink>
        );
    };

    return (
        <li className={classnames}>
            {renderLink()}
            {children && <NestedCounterList>{children}</NestedCounterList>}
        </li>
    );
};

export default ProjectIndexItem;
