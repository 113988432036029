import { applyNotActiveAsRevisionProjectSelector } from '../../../../model/standardOverview/selectors';
import useFetchStandardAllOverviewItems from '../../../../hooks/useFetchStandardAllOverviewItems';
import type { OnFormValidHandler } from '../../startRevisionProjectForm/StartRevisionProjectForm';
import { useDispatch } from 'react-redux';
import { createStartRevisionProjectAction } from '../../../../actions/projectActionFactory';

export default function useHandleStartRevisionProjectForm(doHideModal: () => void) {
    const dispatch = useDispatch();

    const { archivedItems, items, isLoading: isLoadingStandardOverviewItems } = useFetchStandardAllOverviewItems();

    let standardOverviewItems: any[] = [];

    if (items) {
        standardOverviewItems = standardOverviewItems.concat(items);
    }

    if (archivedItems) {
        standardOverviewItems = standardOverviewItems.concat(archivedItems);
    }

    const standardOverviewItemChoices = standardOverviewItems
        ? applyNotActiveAsRevisionProjectSelector(standardOverviewItems)
        : [];

    const onFormValid: OnFormValidHandler = (standardId) => {
        doHideModal();

        dispatch(createStartRevisionProjectAction(standardId));
    };

    return {
        standardOverviewItemChoices,
        isLoadingStandardOverviewItems,
        onFormValid,
    };
}
