import React, { ReactNode } from 'react';
import styles from './ProjectOverview.module.scss';

type Props = {
    children: ReactNode;
};

const ProjectOverview = ({ children }: Props) => {
    return (
        <section className="section section--light">
            <div className={styles.container}>{children}</div>
        </section>
    );
};

export default ProjectOverview;
