import React, { FC } from 'react';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import Loader from '../../../shared/Loader';
import useStandardSearchFilterParams from '../../ggz/searchResults/hooks/useStandardSearchFilterParams';
import useFetchSearchResultsOnQueryOrFilterChange from '../../ggz/searchResults/hooks/useFetchSearchResultsOnQueryOrFilterChange';
import BackToStandardLink from '../../ggz/searchResults/components/BackToStandardLink';
import AmountOfStandardResults from '../../ggz/searchResults/components/AmountOfStandardResults';
import NoResults from '../../ggz/searchResults/components/NoResults';
import SearchResult from '../../ggz/searchResults/components/SearchResult';
import useTrackVisitedPageTypeOnChange from '../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../constants/pageTypes';
import useScrollToTopOnResultChange from '../../ggz/searchResults/hooks/useScrollToTopOnResultChange';
import CollectionPagination from '../CollectionPagination';
import useNavigateToNextPageOnPageChange from '../../ggz/searchResults/hooks/useNavigateToNextPageOnPageChange';
import { TenantType } from '../../../../constants/settings';
import NestedResult from '../../skilz/searchResults/components/NestedResult';

type Props = {
    tenant?: TenantType;
};

const SearchResults: FC<Props> = ({ tenant = TenantType.GGZ }) => {
    const { details: projectDetails } = useProjectDetails();

    const { query, page } = useStandardSearchFilterParams();

    const { searchResults, resetSearchResults } = useFetchSearchResultsOnQueryOrFilterChange(
        query,
        page,
        projectDetails
    );

    useTrackVisitedPageTypeOnChange(pageType.projectSearch, { query, page }, [query, page]);

    useScrollToTopOnResultChange(searchResults);

    const { onPageChange } = useNavigateToNextPageOnPageChange(projectDetails, query, resetSearchResults);

    if (!projectDetails || searchResults === null) {
        return <Loader />;
    }

    return (
        <section>
            <header className="search-results__header">
                <BackToStandardLink projectDetails={projectDetails} />
                {searchResults && <AmountOfStandardResults searchResults={searchResults} />}
            </header>
            {searchResults && (
                <>
                    {searchResults.hasResults() ? (
                        searchResults.map((result) => {
                            if (tenant === TenantType.SKILZ) {
                                return <NestedResult key={result.id} result={result} />;
                            }

                            return <SearchResult key={result.id} result={result} />;
                        })
                    ) : (
                        <NoResults />
                    )}
                    <CollectionPagination collection={searchResults} currentPage={page} onPageChange={onPageChange} />
                </>
            )}
        </section>
    );
};

export default SearchResults;
