import ParagraphDetailProject from './ParagraphDetailProject';
import type { Cloneable } from '../contracts';

export default class ParagraphDetailChapter implements Cloneable<ParagraphDetailChapter> {
    _id: string;
    _externalId: string;
    _title: string;
    _project: ParagraphDetailProject;
    _inProjects: Array<string>;
    _index: number;

    constructor(
        id: string,
        externalId: string,
        title: string,
        project: ParagraphDetailProject,
        inProjects: Array<string>,
        index: number
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._project = project;
        this._inProjects = inProjects;
        this._index = index;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    get project(): ParagraphDetailProject {
        return this._project;
    }

    get inProjects(): Array<string> {
        return this._inProjects;
    }

    get index() {
        return this._index;
    }

    isBeingReused(): boolean {
        return this._inProjects.length > 1;
    }

    merge(incomingChapter: ParagraphDetailChapter): void {
        this._title = incomingChapter.title;
        this._inProjects = incomingChapter.inProjects;
        this._project.merge(incomingChapter.project);
        this._inProjects = [...incomingChapter.inProjects];
    }

    clone(): ParagraphDetailChapter {
        return new ParagraphDetailChapter(
            this._id,
            this._externalId,
            this._title,
            this._project.clone(),
            this._inProjects,
            this._index
        );
    }
}
