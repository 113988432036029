import React, { FC } from 'react';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';
import Heading from '../../../../shared/heading/Heading';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getProjectTypeTitleSingular } from '../../../../../constants/types';
import { TenantType } from '../../../../../constants/settings';
import NestedResult from './NestedResult';
import styles from './SearchResultOverview.module.scss';
import ResultSectionDivider from './ResultSectionDivider';
import NoResults from '../../../shared/searchResults/components/NoResults';

type Props = {
    nestedResults: PaginatedCollection<ProjectSearchItem>;
    toplevelResults: PaginatedCollection<ProjectSearchItem>;
    isLoading: boolean;
};

const SearchResultOverview: FC<Props> = ({ nestedResults, toplevelResults, isLoading }) => {

    if (!toplevelResults.results?.length && !nestedResults.results?.length && !isLoading) return <NoResults/>;

    const toplevelResultsPrefix =
        toplevelResults.totalNumberOfResults === 1
            ? '1 richtlijn'
            : `${toplevelResults.totalNumberOfResults} richtlijnen`;

    const nestedResultsPrefix =
        nestedResults.totalNumberOfResults === 1
            ? '1 overig resultaat'
            : `${nestedResults.totalNumberOfResults} overige resultaten`;

    return (
        <div>
            {toplevelResults.results.length > 0 && (
                <>
                    <ResultSectionDivider>{toplevelResultsPrefix}</ResultSectionDivider>
                    <ul className={styles.list}>
                        {toplevelResults.map((result) => (
                            <li key={result.id}>
                                <span className={styles.projectType}>
                                    {getProjectTypeTitleSingular(TenantType.SKILZ, result.projectType)}
                                </span>
                                <Heading tag="h2" variant="h3">
                                    <Link to={result.path} className={styles.title}>
                                        {parse(result.title)}
                                    </Link>
                                </Heading>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {nestedResults.results.length > 0 && (
                <>
                    <ResultSectionDivider>{nestedResultsPrefix}</ResultSectionDivider>
                    {nestedResults.map((result) => (
                        <NestedResult
                            key={result.id}
                            result={result}
                            breadcrumbItems={[
                                getProjectTypeTitleSingular(TenantType.SKILZ, result.projectType),
                                ...result.breadcrumbTitles,
                            ]}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default SearchResultOverview;
