import React from 'react';
import styles from './NewsletterCta.module.scss';
import useHandleNewsletterSubscriptionAnswer from '../../ggz/subscribeToNewsletter/hooks/useHandleNewsletterSubscriptionAnswer';
import Heading from '../../../shared/heading/Heading';
import FormGroup from '../../../shared/form/FormGroup';
import FormErrorMessage from '../../../shared/form/FormErrorMessage';
import useNewsletterSubscriptionFormHandlers from '../../ggz/subscribeToNewsletter/hooks/useNewsletterSubscriptionFormHandlers';
import Button, { ButtonStyles, ButtonTypes } from '../primitives/button/Button';
import createClassName from 'classnames';

const NewsletterCta = () => {
    const { onValidSubmit } = useHandleNewsletterSubscriptionAnswer(undefined, undefined, true);
    const { handleChange, handleSubmit, handleBlur, touched, errors, values, isPending } =
        useNewsletterSubscriptionFormHandlers(onValidSubmit);

    const classNames = createClassName('form__field', styles.formField);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <Heading tag="h4" flatten className={styles.heading}>
                    Wil jij op de hoogte blijven van richtlijnen in de langdurige zorg?
                </Heading>
                <p>Vul je e-mailadres in en wij sturen je updates</p>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className={styles.formGroup}>
                            <input
                                id="email"
                                name="email"
                                placeholder="e-mailadres"
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                                className={classNames}
                                disabled={isPending}
                            />
                            <Button
                                type={ButtonTypes.Submit}
                                style={ButtonStyles.Primary}
                                data-track-id={'newsletter-subscribe'}
                                disabled={isPending}
                                isOutline
                            >
                                Aanmelden
                            </Button>
                        </div>
                    </FormGroup>
                    {touched.email && !!errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                </form>
            </div>
        </div>
    );
};

export default NewsletterCta;
