import React from 'react';
import { Link } from 'react-router-dom';
import { createDisclaimerPagePath, createPrivacyAndCookiesPagePath } from '../../../../routing/urlGenerator';
import styles from './Footer.module.scss';
import Logo from '../../../../../images/skilz/skilz-logo.svg';
import Heading from '../../../shared/heading/Heading';
import createClassName from 'classnames';
import { IoLogoLinkedin } from 'react-icons/io5';

const Footer = () => {
    return (
        <footer>
            <div className={styles.top}>
                <div className={createClassName('container', styles.topContainer)}>
                    <div className={styles.logoWrapper}>
                        <Logo className={styles.logo} />
                    </div>
                    <div className={styles.contactColumn}>
                        <div>
                            <Heading tag="h6" className={styles.heading} flatten>
                                Telefonische contact
                            </Heading>
                            <a
                                href="tel:0610614836"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link link--secondary"
                                data-track-id="footer_telephone_click"
                            >
                                06 10 614 836
                            </a>
                            <p>
                                Bereikbaar op ma, di en do
                                <br />
                                tussen 9.00 en 12.00 uur
                            </p>
                        </div>
                        <div>
                            <Heading tag="h6" className={styles.heading} flatten>
                                LinkedIn
                            </Heading>
                            <a
                                href="https://www.linkedin.com/company/skilz-nu/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={createClassName('link link--secondary', styles.linkedin)}
                            >
                                <IoLogoLinkedin /> skilz-nu
                            </a>
                            <br />
                            <br />
                            <Heading tag="h6" className={styles.heading} flatten>
                                E-mail
                            </Heading>
                            <a
                                href="mailto:vraag@richtlijnenlangdurigezorg.nl"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link link--secondary"
                                data-track-id="footer_mail_to_click"
                            >
                                vraag@richtlijnenlangdurigezorg.nl
                            </a>
                        </div>
                        <p className={styles.footnote}>
                            Voor meer informatie over SKILZ:{' '}
                            <a
                                href="https://www.skilz.nu"
                                className="link link--secondary"
                                data-track-id="footer_website_click"
                            >
                                www.skilz.nu
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className="container">
                    <nav aria-label="Voorwaarden en privacybeleid" className={styles.navigation}>
                        <Link to={createDisclaimerPagePath()}>Algemene voorwaarden</Link>
                        <Link to={createPrivacyAndCookiesPagePath()}>Privacy- en cookiebeleid</Link>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
