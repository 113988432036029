import { logDebug } from '../../utility/Logger';

type ExecuteCallback = () => Promise<any>;

type QueueItem = {
    executeCallback: ExecuteCallback;
    resolve: Function;
    reject: Function;
};

const queue: Array<QueueItem> = [];

let isStarted = false;

export function add(executeCallback: ExecuteCallback): Promise<any> {
    logDebug('request queue', 'add: ', 'new length', queue.length + 1);

    return new Promise((resolve, reject) => {
        queue.push({
            executeCallback: executeCallback,
            resolve,
            reject,
        });

        startIfNotAlreadyStarted();
    });
}

function start(): void {
    const noOfItems: number = queue.length;

    if (noOfItems === 0) {
        isStarted = false;

        logDebug('request queue', 'finished');

        return;
    }

    const firstQueueItem: QueueItem | undefined = queue.shift();

    if (!firstQueueItem) {
        throw new Error('Expect there to be an item at this point');
    }

    logDebug('request queue', 'item start', 1, 'of', noOfItems);

    firstQueueItem
        .executeCallback()
        .then((...args) => firstQueueItem.resolve(...args))
        .catch((error) => firstQueueItem.reject(error))
        .finally(() => {
            logDebug('request queue', 'item done: ', 'new length', queue.length);

            start();
        });
}

function startIfNotAlreadyStarted(): void {
    if (isStarted) {
        return;
    }

    isStarted = true;

    start();
}
