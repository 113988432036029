import React from 'react';
import Page from '../../../shared/page/Page';
import Heading from '../../../../shared/heading/Heading';

const DisclaimerPage = () => {
    return (
        <Page title="Algemene Voorwaarden">
            <p>
                De inhoud van het platform Richtlijnen Langdurige Zorg is zorgvuldig samengesteld. Wij streven ernaar om
                zo actueel mogelijke informatie te geven. Desondanks kan het voorkomen dat er onjuistheden voorkomen of
                informatie verouderd is. Alle teksten, beelden en andere inhoud zijn eigendom van SKILZ of van een
                afzonderlijke beroepsvereniging. Zonder toestemming van SKILZ is het niet toegestaan gepubliceerde
                teksten of delen daarvan over te nemen, te (laten) publiceren of op een andere manier openbaar te maken
                of te verveelvoudigen zonder bronvermelding.
            </p>
            <p>
                SKILZ is op geen enkele manier aansprakelijk voor eventuele schade die direct of indirect het gevolg is
                van, of in verband te brengen is met de informatie die beschikbaar is op{' '}
                <a href="https://www.richtlijnenlangdurigezorg.nl/" target="_blank">
                    www.richtlijnenlangdurigezorg.nl
                </a>
                .
            </p>
            <Heading tag="h2" variant="h3">
                Vragen?
            </Heading>
            <p>
                Heb je een vraag over onze algemene voorwaarden? Stuur dan een e-mail naar{' '}
                <a href="vraag@richtlijnenlangdurigezorg.nl">vraag@richtlijnenlangdurigezorg.nl</a>
            </p>
            <Heading tag="h2" variant="h3">
                Ontwerp en technische realisatie
            </Heading>
            <p>Freshheads</p>
        </Page>
    );
};

export default DisclaimerPage;
