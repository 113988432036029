import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFetchStandardAllOverviewItemsAction } from '../actions/standardActionFactory';
import type { GlobalState } from '../store/types';
import { StandardOverviewItem } from '../model/standardOverview/StandardOverviewItem';
import { applyArchivedStandardSelector, applyPublishedStandardSelector } from '../model/standardOverview/selectors';
import { sortByField } from '../helper/sortingHelper';
import { StandardOverviewReducerState } from '../reducers/standardOverviewReducer';

type ReduxSuppliedProps = {
    archivedItems: Array<StandardOverviewItem> | null;
    items: Array<StandardOverviewItem> | null;
    allItems: StandardOverviewReducerState;
};

export default function useFetchStandardAllOverviewItems() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);

        // @ts-ignore don't know how to fix this...
        dispatch(createFetchStandardAllOverviewItemsAction()).finally(() => setIsLoading(false));
    }, []);

    const { archivedItems, items, allItems } = useSelector<GlobalState, ReduxSuppliedProps>(
        ({ standardAllOverviewItems }) => {
            return {
                archivedItems: standardAllOverviewItems ? applyArchivedStandardSelector(standardAllOverviewItems) : [],
                items: standardAllOverviewItems ? applyPublishedStandardSelector(standardAllOverviewItems) : [],
                allItems: standardAllOverviewItems,
            };
        }
    );

    const sortedArchivedItems = archivedItems
        ? sortByField<StandardOverviewItem, string>(archivedItems, 'title', (value) => value.toLowerCase())
        : [];

    const sortedItems = items
        ? sortByField<StandardOverviewItem, string>(items, 'title', (value) => value.toLowerCase())
        : [];

    return {
        archivedItems: sortedArchivedItems,
        items: sortedItems,
        allItems,
        isLoading,
    };
}
