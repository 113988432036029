import React, { FC } from 'react';
import useHandleCreateBranchFormState, { OnValidSubmitHandler } from '../hooks/useHandleCreateBranchFormState';
import FormErrorMessage from '../../../shared/form/FormErrorMessage';
import FormGroup from '../../../shared/form/FormGroup';

type Props = {
    onValidSubmit: OnValidSubmitHandler;
};

const CreateBranchForm: FC<Props> = ({ onValidSubmit }) => {
    const { values, errors, touched, handleBlur, handleSubmit, handleChange, isSubmitting } =
        useHandleCreateBranchFormState(onValidSubmit);

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup hasErrors={touched.title && !!errors.title}>
                <label htmlFor="title">Partijnaam</label>
                <input
                    type="text"
                    value={values.title}
                    name="title"
                    className="form__field"
                    onChange={handleChange}
                    id="title"
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                />
                {touched.title && errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
            </FormGroup>
            <FormGroup hasErrors={touched.shortTitle && !!errors.shortTitle}>
                <label htmlFor="shortTitle">Afkorting</label>
                <input
                    type="text"
                    value={values.shortTitle}
                    name="shortTitle"
                    className="form__field"
                    onChange={handleChange}
                    id="shortTitle"
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                />
                {touched.shortTitle && errors.shortTitle && <FormErrorMessage>{errors.shortTitle}</FormErrorMessage>}
            </FormGroup>

            <button type="submit" className="button button--primary" disabled={isSubmitting}>
                Aanmaken
            </button>
        </form>
    );
};

export default CreateBranchForm;
