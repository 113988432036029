import React, { FC } from 'react';
import styles from './SearchResultOverview.module.scss';

type Props = {
    children: string;
};

const ResultSectionDivider: FC<Props> = ({ children }) => {
    return (
        <div className={styles.divider}>
            <span className={styles.line} />
            <div className={styles.textContainer}>{children}</div>
            <span className={styles.line} />
        </div>
    );
};

export default ResultSectionDivider;
