import React, { ReactNode } from 'react';
import styles from './NewsletterSubscriptionPopup.module.scss';
import ModalCloseButton from '../../../../shared/modal/components/ModalCloseButton';

type Props = {
    onDecline: () => void;
    children: ReactNode;
};

// this is a variant used for A/B testing
const NewsletterSubscriptionPopup = ({ onDecline, children }: Props) => {
    return (
        <div className={styles.wrapper}>
            <ModalCloseButton onClick={onDecline} />
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default NewsletterSubscriptionPopup;
