import { useState } from 'react';

export type onChangeStickyStatusType = (isSticky: boolean) => void;

const DEFAULT_HEADER_HEIGHT = 68;

export function useProjectDetailHeaderHeight() {
    const [headerHeight, setHeaderHeight] = useState<number>(DEFAULT_HEADER_HEIGHT);

    const [didCalculation, setDidCalculation] = useState<boolean>(false);

    const calculateHeight = () => {
        const projectDetailHeaderElement = document.querySelector('.js-sticky-project-detail-header') as HTMLDivElement;

        if (projectDetailHeaderElement) {
            const newHeight = projectDetailHeaderElement.offsetHeight;

            if (newHeight !== headerHeight) {
                setHeaderHeight(newHeight);
            }

            setDidCalculation(true);
        }
    };

    const onChangeStickyStatus: onChangeStickyStatusType = (isSticky) => {
        if (isSticky && didCalculation === false) {
            // wait for sticky element to become available in DOM
            setTimeout(() => calculateHeight(), 10);
        }
    };

    return { headerHeight, onChangeStickyStatus };
}
