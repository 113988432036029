import ParagraphDetail from '../ParagraphDetail';
import { createUuid } from '../../../utility/idGenerator';
import { createParagraphDetailChapterFromApiInput } from './paragraphDetailChapterFactory';
import { createParagraphDetailSubParagraphCollectionFromApiInput } from './paragraphDetailSubParagraphFactory';
import { createParagraphDetailLastUpdatedInformationFromApiInput } from './paragraphDetailLastUpdatedInformationFactory';

export function createParagraphDetailFromApiInput(apiInput: any): ParagraphDetail {
    return new ParagraphDetail(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.content,
        createParagraphDetailChapterFromApiInput(apiInput.chapter),
        createParagraphDetailSubParagraphCollectionFromApiInput(apiInput.sub_paragraphs),
        createParagraphDetailLastUpdatedInformationFromApiInput(apiInput.recursive_last_updated_information),
        createParagraphDetailLastUpdatedInformationFromApiInput(apiInput.last_updated_information),
        apiInput.in_chapters,
        apiInput.index,
        apiInput.comments.count,
        apiInput.comments.answered_count,
        apiInput.contentType
    );
}
