import cookieStorage from 'js-cookie';
import { logError } from './Logger';
import { isString } from 'lodash';

export function getBooleanFromCookieStorage(key: string, logOnError = false): boolean | void {
    const value = getFromCookieStorage(key, null, logOnError);

    if (isString(value)) {
        return value === 'true';
    }

    return undefined;
}

export function getFromCookieStorage(key: string, defaultValue = null, logOnError = false): unknown {
    try {
        const value = cookieStorage.get(key);

        if (value === undefined) {
            return defaultValue;
        }

        return value;
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }

        return defaultValue;
    }
}

export function writeBooleanToCookieStorage(
    key: string,
    value: boolean,
    expireLengthInDays: number,
    logOnError = false
): boolean {
    return writeToCookieStorage(key, value === true ? 'true' : 'false', logOnError, expireLengthInDays);
}

export function writeToCookieStorage(key: string, value: string, logOnError = false, expires: number): boolean {
    try {
        cookieStorage.set(key, value, { expires });

        return true;
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return false;
}
