import { useDispatch } from 'react-redux';
import { createStartRevisionStandardAction } from '../../../../actions/standardActionFactory';

export default function useDispatchStartRevisionStandardActionOnConfirm(standardId: string) {
    const dispatch = useDispatch();

    const onConfirm = () => {
        if (!standardId) {
            throw new Error('Expecting this project to have an external id at this point');
        }

        dispatch(createStartRevisionStandardAction(standardId));
    };

    return { onConfirm };
}
