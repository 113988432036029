import { useCallback, useState } from 'react';

export default function useShowHideModal(startValue = false) {
    const [showModal, setShowModal] = useState<boolean>(startValue);

    const doShowModal = useCallback(() => {
        setShowModal(true);
    }, [setShowModal]);

    const doHideModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    return { showModal, doShowModal, doHideModal };
}
