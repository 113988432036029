import React, { Fragment } from 'react';
import Modal from '../../modal/Modal';
import { findBreadcrumbById } from '../../../../repository/projectBreadcrumbRepository';
import { toast } from 'react-toastify';
import { logError } from '../../../../utility/Logger';
import ProjectBreadcrumb from '../../../../model/ProjectBreadcrumb';
import Loader from '../../Loader';
import Breadcrumb from '../../breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../breadcrumb/components/BreadcrumbItem';
import BreadcrumbItemModel from '../../../../model/BreadcrumbItem';
import { createCollectionFromProjectBreadcrumb } from '../../../../model/factory/breadcrumbItemFactory';
import { isString } from 'lodash';

type Props = {
    data: Record<string, any>;
    onDecline: () => void;
};

type DisplayValue = ProjectBreadcrumb | false | string | null;

type State = {
    displayValue: DisplayValue;
};

class LinkInformationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            displayValue: null,
        };
    }

    componentDidMount() {
        this._determineDisplayValue();
    }

    _determineDisplayValue() {
        const { data } = this.props;

        if (typeof data.breadcrumbId !== 'undefined' && data.breadcrumbId) {
            this._fetchBreadcrumbItem(data.breadcrumbId);
        } else if (data.href) {
            this._setDisplayValue(data.href);
        } else {
            this._setDisplayValue(false);
        }
    }

    _fetchBreadcrumbItem(id: string) {
        return findBreadcrumbById(id)
            .then((breadcrumb: ProjectBreadcrumb) => this._setDisplayValue(breadcrumb))
            .catch((error: Error) => {
                logError(error, {
                    action: `Fetch breacrumb with id: '${id}'`,
                });

                toast.error('De link kon niet opgehaald worden. Probeer het later nog eens');

                this._setDisplayValue(false);
            });
    }

    _setDisplayValue(displayValue: DisplayValue): void {
        this.setState((currentState: State) => ({
            ...currentState,
            displayValue,
        }));
    }

    _renderContent() {
        const { displayValue } = this.state;

        if (displayValue === null) {
            return <Loader />;
        } else if (displayValue === false) {
            return <p>Geen gegevens over de link gevonden</p>;
        } else if (displayValue instanceof ProjectBreadcrumb) {
            return (
                <Fragment>
                    <p>Deze interne link gaat, na publicatie, naar het volgende onderdeel:</p>
                    <Breadcrumb>
                        {createCollectionFromProjectBreadcrumb(displayValue).map((item: BreadcrumbItemModel) => (
                            <BreadcrumbItem key={item.id} item={item} />
                        ))}
                    </Breadcrumb>
                </Fragment>
            );
        } else if (isString(displayValue)) {
            return (
                <Fragment>
                    <p>Deze link gaat, na publicatie, naar de volgende externe website:</p>
                    <a href={displayValue} target="_blank" rel="noopener noreferrer">
                        {displayValue}
                    </a>
                </Fragment>
            );
        }

        throw new Error('Invalid breadcrumb state');
    }

    render() {
        const { onDecline } = this.props;

        return (
            <Modal title="Link informatie" onDecline={onDecline}>
                {this._renderContent()}
            </Modal>
        );
    }
}

export default LinkInformationModal;
