import * as React from 'react';
import { Link } from 'react-router-dom';
import ProjectOverviewItem from '../../../../model/projectOverview/ProjectOverviewItem';
import { createDashboardProjectDetailPath } from '../../../../routing/dashboardUrlGenerator';

type Props = {
    item: ProjectOverviewItem;
};

const ProjectOverviewItemTitle = ({ item }: Props) => (
    <h2 className="heading">
        {item.externalId ? (
            <Link to={createDashboardProjectDetailPath(item.externalId)} className="link list__heading-link">
                {item.shortTitle ?? item.title}
            </Link>
        ) : (
            item.shortTitle ?? item.title
        )}
    </h2>
);

export default ProjectOverviewItemTitle;
