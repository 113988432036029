import React from 'react';
import { Link } from 'react-router-dom';
import { createFrontendEntryPath } from '../../../../routing/urlGenerator';
import GGZLogo from '../../../../../images/ggz/ggz-logo.svg';
import SKILZLogo from '../../../../../images/skilz/skilz-logo.svg';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';

const HeaderLogo = () => {
    const { isSKILZ, isGGZ } = useCurrentTenant();

    return (
        <Link to={createFrontendEntryPath()} className="header__logo-wrapper">
            {isGGZ && <GGZLogo className="header__logo--ggz" />}
            {isSKILZ && <SKILZLogo className="header__logo--skilz" />}
        </Link>
    );
};

export default HeaderLogo;
