import type { Action, Thunk } from './factory';
import * as apiClient from '../api/client/apiClient';
import { createAction } from './factory';
import { ADD_BRANCH, FETCH_BRANCH_OVERVIEW } from './types';
import { toast } from 'react-toastify';
import { createAddBranchApiPath, createDashboardBranchOverviewApiPath } from '../routing/apiUrlGenerator';

export function createFetchBranchOverviewItemsAction(): Action {
    const promise: Promise<any> = apiClient.get(createDashboardBranchOverviewApiPath());

    return createAction(FETCH_BRANCH_OVERVIEW, promise);
}

export function createAddBranchAction(title: string, shortTitle: string | null): Thunk {
    return (dispatch) => {
        const url = createAddBranchApiPath(),
            promise: Promise<any> = apiClient.post(url, {
                title,
                shortTitle,
            }),
            action: Action = createAction(ADD_BRANCH, promise);

        return dispatch(action).then((response: Object) => {
            if (response instanceof Error) {
                return;
            }

            toast.success(`${title} ${shortTitle ? `(${shortTitle})` : ''} is aangemaakt als partij`);

            return dispatch(createFetchBranchOverviewItemsAction());
        });
    };
}
