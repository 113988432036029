import React from 'react';
import ListItem from '../../shared/list/component/ListItem';
import ListHeading from '../../shared/list/component/ListHeading';
import ListAction from '../../shared/list/component/ListAction';
import { Faq } from '../../../../model/Faq';
import { ReactNode } from 'react';
import Heading from '../../../shared/heading/Heading';

type Props = {
    faqItem: Faq;
    children: ReactNode;
};

const FaqOverviewListItem = ({ faqItem, children }: Props) => (
    <ListItem>
        <ListHeading>
            <Heading tag="h2">{faqItem.title}</Heading>
        </ListHeading>
        <ListAction>{children}</ListAction>
    </ListItem>
);

export default FaqOverviewListItem;
