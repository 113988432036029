import * as React from 'react';
import Page from './Page';

type Props = {
    title: string;
    description: string;
    children?: React.ReactNode;
};

const ErrorNotificationPage = ({ title, description, children }: Props) => {
    return (
        <Page title={title}>
            <p>{description}</p>
            <a href="#" onClick={() => location.reload()} className="button button--primary">
                Ververs de pagina
            </a>
            {children}
        </Page>
    );
};

export default ErrorNotificationPage;
