export default class ReferenceOverviewItem {
    private readonly _externalId: number;
    private readonly _description: string;
    private readonly _archived: boolean;

    constructor(externalId: number, description: string, archived: boolean) {
        this._externalId = externalId;
        this._description = description;
        this._archived = archived;
    }

    get externalId(): number {
        return this._externalId;
    }

    get description(): string {
        return this._description;
    }

    get archived(): boolean {
        return this._archived;
    }

    clone(): ReferenceOverviewItem {
        return new ReferenceOverviewItem(this._externalId, this._description, this._archived);
    }
}
