import { useEffect } from 'react';
import ProjectChapterDetailParagraph from '../../../../../model/projectChapterDetail/ProjectChapterDetailParagraph';
import { useSelector } from 'react-redux';
import type { GlobalState } from '../../../../../store/types';
import { ProjectSearchItemType } from '../../../../../model/ProjectSearchItem';

// @ts-ignore Somehow Typescript does not detect this module
import Marker from 'mark.js/dist/mark';

export default function useMarkMatchedSearchTerms(paragraph: ProjectChapterDetailParagraph) {
    const wrapperId = `chapter-detail-paragraph-${paragraph.externalId}`;

    const matchedSearchTerms = useSelector<GlobalState, Array<string>>(({ lastSearchedItem }) =>
        lastSearchedItem &&
        lastSearchedItem.type === ProjectSearchItemType.Paragraph &&
        paragraph.externalId === lastSearchedItem.id
            ? lastSearchedItem.matchedSearchTerms
            : []
    );

    // convert to string to be able to compare on non-object level in useEffect below
    const matchedSearchTermsAsString = matchedSearchTerms.join('');

    useEffect(() => {
        // prevent setting markers when there are no matched search terms
        if (matchedSearchTerms.length === 0) {
            return;
        }

        const marker = new Marker(`#${wrapperId}`);

        marker.mark(matchedSearchTerms, {
            element: 'em',
            className: 'text--marked',
        });
    }, [matchedSearchTermsAsString]);

    return { wrapperId };
}
