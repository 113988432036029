import React from 'react';
import ProjectChapterDetailSubParagraph from '../../../../../model/projectChapterDetail/ProjectChapterDetailSubParagraph';
import createDispatchBookmarkTriggerOnClickComponent from '../../../../../hoc/BookmarkDispatcher';
import BookmarkTrigger, { BookmarkSubject } from '../../../../shared/bookmark/BookmarkTrigger';
import BookmarkTriggerModel from '../../../../../model/BookmarkTrigger';
import Scroll from 'react-scroll';
import AnonymousFeedback, { ANONYMOUS_FEEDBACK_TYPE_SUBPARAGRAPH } from '../../anonymousFeedback/AnonymousFeedback';
import { connect } from 'react-redux';
import { ProjectSearchItemType } from '../../../../../model/ProjectSearchItem';
import type { GlobalState } from '../../../../../store/types';
import Marker from 'mark.js';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';
import ContentRender from '../../../../shared/lexicalRender/ContentRender';

const AutoDispatchingBookmarkTrigger = createDispatchBookmarkTriggerOnClickComponent(BookmarkTrigger);

type OwnProps = {
    subParagraph: ProjectChapterDetailSubParagraph;
    paragraphIndex: number;
    chapterIndex: number;
};

type ReduxSuppliedProps = {
    matchedSearchTerms: Array<string>;
};

type CombinedProps = {} & OwnProps & ReduxSuppliedProps;

class ChapterDetailSubParagraph extends React.Component<CombinedProps> {
    _marker: Marker;

    constructor(props: CombinedProps) {
        super(props);

        this._marker = new Marker(`#${this.generateWrapperId()}`);
    }

    generateWrapperId() {
        const { subParagraph } = this.props;

        return `chapter-detail-subparagraph-${subParagraph.externalId}`;
    }

    componentDidMount() {
        this._markEmphasizedWordsIfRequired();
        this._scrollIntoViewTheFirstEmphasizedWord();
    }

    _scrollIntoViewTheFirstEmphasizedWord() {
        const section = document.getElementById(this.generateWrapperId());

        if (!section) {
            return;
        }

        const elements = section.getElementsByClassName('text--marked');

        if (elements.length === 0) {
            return;
        }

        elements[0].scrollIntoView();
    }

    componentDidUpdate() {
        this._markEmphasizedWordsIfRequired();
    }

    _markEmphasizedWordsIfRequired() {
        const { matchedSearchTerms = [] } = this.props;

        if (matchedSearchTerms.length === 0) {
            return;
        }

        this._marker.mark(matchedSearchTerms, {
            element: 'em',
            className: 'text--marked',
        });
    }

    render() {
        const { subParagraph, chapterIndex, paragraphIndex } = this.props;

        const numbering = generateSectionNumbering(chapterIndex, paragraphIndex, subParagraph.index);
        const title = `${numbering} ${subParagraph.title}`;

        const bookmark = new BookmarkTriggerModel(
            subParagraph.externalId,
            title,
            window.location.origin + subParagraph.path
        );

        return (
            <Scroll.Element
                key={subParagraph.id}
                name={subParagraph.path}
                className="chapter-content"
                id={this.generateWrapperId()}
            >
                <h3 className="heading heading--sub-paragraph heading--includes-actions">
                    <span>{title}</span>
                    <span className="heading__content-action-holder">
                        {/* @ts-ignore types cannot be read for component as it is not part of Typescript */}
                        <AutoDispatchingBookmarkTrigger
                            className="heading__content-action"
                            bookmarkedId={bookmark.bookmarkedId}
                            href={bookmark.url}
                            title={title}
                            type={BookmarkSubject.subParagraph}
                        />
                        <AnonymousFeedback
                            target={{
                                type: ANONYMOUS_FEEDBACK_TYPE_SUBPARAGRAPH,
                                id: subParagraph.externalId,
                            }}
                        />
                    </span>
                </h3>
                <ContentRender content={subParagraph.content} contentType={subParagraph.contentType} />
            </Scroll.Element>
        );
    }
}

const withGlobalStateAccess = connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>(
    ({ lastSearchedItem }: GlobalState, props: OwnProps): ReduxSuppliedProps => {
        const { subParagraph } = props;

        if (
            lastSearchedItem &&
            lastSearchedItem.type === ProjectSearchItemType.SubParagraph &&
            subParagraph.externalId === lastSearchedItem.id
        ) {
            return {
                matchedSearchTerms: lastSearchedItem.matchedSearchTerms,
            };
        }

        return {
            matchedSearchTerms: [],
        };
    }
);

export default withGlobalStateAccess(ChapterDetailSubParagraph);
