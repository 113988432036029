import useTrackVisitedPageTypeOnChange from '../../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../../constants/pageTypes';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';

export default function useTrackVisitedPageTypeForProjectDetail(projectDetails: ProjectDetail | null) {
    const enabled = !!projectDetails;

    useTrackVisitedPageTypeOnChange(
        pageType.standardDetail,
        {
            type: projectDetails ? projectDetails.type : null,
            title: projectDetails ? projectDetails.title : null,
        },
        [projectDetails ? projectDetails.externalId : null],
        enabled
    );
}
