import * as React from 'react';
import { composeSeoTitle } from '../../../../helper/seoHelper';
import { Helmet } from 'react-helmet';
import Heading from '../../../shared/heading/Heading';
import useScrollToTopOnMount from '../../../../hooks/useScrollToTopOnMount';

type Props = {
    title: string;
    children: React.ReactNode;
};

const Page = ({ title, children }: Props) => {
    useScrollToTopOnMount();

    return (
        <>
            <Helmet>
                <title>{composeSeoTitle(null, title)}</title>
            </Helmet>
            <main className="section section--light">
                <div className="container">
                    <div className="layout layout--align-center">
                        <article className="layout__item layout__item--lg-10 layout__item--xl-8">
                            <Heading tag="h1" className="text--allow-hyphens">
                                {title}
                            </Heading>
                            {/* This is not editor content but we thread it the same */}
                            <div className="editor-content">{children}</div>
                        </article>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Page;
