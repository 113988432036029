import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import type { GlobalState } from '../../../../store/types';
import type { DispatchProps } from '../../../../actions/factory';
import type { ProjectDetailsReducerState } from '../../../../reducers/projectDetailsReducer';
import useTrackVisitedPageTypeForProjectDetail from '../../ggz/projectDetailRedirectToFirstChapter/hooks/useTrackVisitedPageTypeForProjectDetail';

type OwnProps = {};

type ReduxSuppliedProps = {
    projectDetails: ProjectDetailsReducerState;
};

type CombinedProps = {} & OwnProps & ReduxSuppliedProps & DispatchProps;

type RouteParams = {
    type: string;
    slug: string;
};

const ProjectDetailRedirectToFirstChapter = ({ projectDetails }: CombinedProps) => {
    if (!projectDetails) {
        return null;
    }

    const { type: currentProjectTypeSlug, slug: currentProjectSlug } = useParams<RouteParams>();

    useTrackVisitedPageTypeForProjectDetail(projectDetails);

    // check if the supplied route params match the current project details in
    // store, to make sure we do not redirect to the wrong first chapter
    if (
        !currentProjectTypeSlug ||
        !currentProjectSlug ||
        currentProjectTypeSlug !== projectDetails.typeSlug ||
        currentProjectSlug !== projectDetails.slug
    ) {
        return null;
    }

    const firstChapter = projectDetails.getFirstChapter();

    if (!firstChapter) {
        return null;
    }

    const path = firstChapter.path;

    if (!path) {
        return null;
    }

    return <Redirect to={path} />;
};

const withGlobalStateAccess = connect<ReduxSuppliedProps, {}, OwnProps, GlobalState>(
    (globalState: GlobalState): ReduxSuppliedProps => ({
        projectDetails: globalState.projectDetails,
    })
);

// @ts-ignore -> don't know how to fix this -> migrate to useSelector and useDispatch
export default withGlobalStateAccess(ProjectDetailRedirectToFirstChapter);
