import React, { FC, ReactNode } from 'react';
import createClassName from 'classnames';
import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

export enum ButtonTypes {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset',
    Link = 'link',
    External = 'external',
}

export enum ButtonStyles {
    Primary,
    Secondary,
}

export enum SizeOptions {
    Default,
    Small,
}

type Props = {
    children?: ReactNode;
    icon?: ReactNode;
    style?: ButtonStyles;
    type?: ButtonTypes;
    isDisabled?: boolean;
    isOutline?: boolean;
    isCleared?: boolean;
    to?: string;
    href?: string;
    additionalClassName?: string;
    size?: SizeOptions;
    [x: string]: any;
};

const Button: FC<Props> = ({
    children,
    icon,
    style = ButtonStyles.Primary,
    type = ButtonTypes.Button,
    isDisabled = false,
    isOutline = false,
    isCleared = false,
    to,
    href,
    additionalClassName,
    size = SizeOptions.Default,
    ...otherProps
}) => {
    const classNames = createClassName(
        styles.button,
        {
            [styles.isDisabled]: isDisabled,
            [styles.isPrimary]: style === ButtonStyles.Primary,
            [styles.isSecondary]: style === ButtonStyles.Secondary,
            [styles.isCleared]: isCleared,
            [styles.isSizeSmall]: size === SizeOptions.Small,
            [styles.isDisabled]: isDisabled,
            [styles.isOutline]: isOutline,
        },
        additionalClassName
    );

    if (type === ButtonTypes.Link) {
        if (!to) {
            throw new Error("Please provide a 'to' if you want to make a link button");
        }

        return (
            <Link to={to} className={classNames} {...otherProps}>
                {icon && icon}
                {children}
            </Link>
        );
    }

    if (type === ButtonTypes.External) {
        if (!href) {
            throw new Error("Please provide a 'href' if you want to make a external link button");
        }

        return (
            <a href={href} target="_blank" rel="noreferrer noopener" className={classNames} {...otherProps}>
                {icon && icon}
                {children}
            </a>
        );
    }

    return (
        <button type={type} className={classNames} disabled={isDisabled} {...otherProps}>
            {icon && icon}
            {children}
        </button>
    );
};

export default Button;
