import React, { VFC } from 'react';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import { IoPencilOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Button from '../../../shared/buttons/Button';

type Props = {
    path?: string;
    onClick?: () => void;
};

const EditLinkButton: VFC<Props> = ({ path = '#', onClick }) => {
    const { isGGZ } = useCurrentTenant();

    if (isGGZ) {
        return (
            <Button onClick={onClick} isCleared>
                <Link to={path} className="button button--cleared button--icon-only icon icon-pencil" />
            </Button>
        );
    }

    return (
        <Button onClick={onClick} isCleared>
            <Link to={path} className="button button--cleared button--icon-only">
                <IoPencilOutline className="icon icon--md" />
            </Link>
        </Button>
    );
};

export default EditLinkButton;
