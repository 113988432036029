import * as React from 'react';
import List from '../../shared/list/List';
import ListItem from '../../shared/list/component/ListItem';

type Props = {
    children: React.ReactNode;
};

const ArticleOverviewList = ({ children }: Props) => (
    <List>
        {React.Children.map(children, (child, index) => {
            // @ts-ignore -> Somehow typescript does not know about key property
            const key = child?.key || index;

            return <ListItem key={key}>{child}</ListItem>;
        })}
    </List>
);

export default ArticleOverviewList;
