import { useSelector } from 'react-redux';
import type { GlobalState } from '../store/types';
import ProjectDetail from '../model/projectDetail/ProjectDetail';

type ReduxSuppliedProps = {
    details: ProjectDetail | null;
};

export function useProjectDetails() {
    const { details } = useSelector<GlobalState, ReduxSuppliedProps>(({ projectDetails }) => ({
        details: projectDetails,
    }));

    return { details };
}
