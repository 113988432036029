import ProjectMember from './ProjectMember';
import type { Cloneable } from '../contracts';

class ExternalPartyMember extends ProjectMember implements Cloneable<ExternalPartyMember> {
    _feedbackStatus: string | null;
    _authorization: string | null;
    _authorizationRemark: string | null;
    _authorizedAt: string | null;

    constructor(
        id: string,
        externalUserId: string,
        name: string,
        branch: string,
        feedbackStatus: string | null,
        authorization: string | null,
        authorizationRemark: string | null,
        authorizedAt: string | null
    ) {
        super(id, externalUserId, name, branch);
        this._feedbackStatus = feedbackStatus;
        this._authorization = authorization;
        this._authorizationRemark = authorizationRemark;
        this._authorizedAt = authorizedAt;
    }

    get feedbackStatus(): string | null {
        return this._feedbackStatus;
    }

    get authorization(): string | null {
        return this._authorization;
    }

    get authorizationRemark(): string | null {
        return this._authorizationRemark;
    }

    get authorizedAt(): string | null {
        return this._authorizedAt;
    }

    clone(): ExternalPartyMember {
        return new ExternalPartyMember(
            this._id,
            this._externalUserId,
            this._name,
            this._branch,
            this._feedbackStatus,
            this._authorization,
            this._authorizationRemark,
            this._authorizedAt
        );
    }
}

export default ExternalPartyMember;
