import React from 'react';
import SearchForm from '../../frontend/shared/searchForm/SearchForm';
import useExpandSearchFieldOnSubmitButtonClick from './hooks/useExpandSearchFieldOnSubmitButtonClick';
import createClassName from 'classnames';

type Props = {
    icon: JSX.Element;
    placeholder?: string;
};

const HeaderSearch = ({ icon, placeholder }: Props) => {
    const { isExpanded, onSearchSubmit, containerElementRef, searchInputRef } =
        useExpandSearchFieldOnSubmitButtonClick();

    const formClassName = createClassName('search-form--header search-form--expandable form--inline-action', {
        'is-expanded': isExpanded,
    });

    return (
        <div ref={containerElementRef}>
            <SearchForm
                ref={searchInputRef}
                onSubmit={onSearchSubmit}
                className={formClassName}
                placeholder={placeholder ?? 'Zoek op standaard of trefwoord'}
                formFieldType="primary"
                size="small"
                buttonClassName="button button--cleared button--icon-only"
                validateBeforeSubmit={isExpanded}
                icon={icon}
            />
        </div>
    );
};

export default HeaderSearch;
