import { Page } from '../../../../model/Page';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STATUS_CODE_NOT_FOUND } from '../../../../constants/statusCode';
import { logError } from '../../../../utility/Logger';
import { toast } from 'react-toastify';
import { createDashboardPageOverviewPath } from '../../../../routing/dashboardUrlGenerator';
import { deletePageFromServer } from '../../../../repository/pageRepository';

export default function useDeletePageOnConfirmation(page: Page, hideConfirmationModal: () => void, onDone: () => void) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const history = useHistory();

    const onConfirm = async () => {
        setIsDeleting(true);

        try {
            await deletePageFromServer(page.id);

            toast.success(`Pagina '${page.title}' is verwijderd`);
        } catch (error: any) {
            if (!error.response && error.response.status !== STATUS_CODE_NOT_FOUND) {
                logError(error);
            }

            toast.error('Er is iets foutgegaan bij het verwijderen van de pagina. Probeer het later nog eens!');
        }

        setIsDeleting(false);

        hideConfirmationModal();

        history.push(createDashboardPageOverviewPath());

        onDone();
    };

    return { onConfirm, isDeleting };
}
