import React from 'react';
import ChapterHeader from './component/ChapterHeader';
import ChapterDetailParagraph from '../../ggz/chapterDetailParagraph/ChapterDetailParagraph';
import { Link } from 'react-router-dom';
import useFetchChapterDetailsOnSlugChange from '../../../../hooks/useFetchChapterDetailsOnSlugChange';
import { useScrollToChapterPartOnChapterChange } from './hooks/useScrollToChapterPartOnChapterChange';
import useTrackVisitedPageTypeForChapterDetail from './hooks/useTrackVisitedPageTypeForChapterDetail';
import ChapterDetailMeta from './component/ChapterDetailMeta';

// @todo: shared component?
const ChapterDetail = () => {
    const { details, nextChapterPath } = useFetchChapterDetailsOnSlugChange();

    const { chapterContentContainerRef } = useScrollToChapterPartOnChapterChange(details);

    useTrackVisitedPageTypeForChapterDetail(details);

    if (!details) {
        return null;
    }

    return (
        <div ref={chapterContentContainerRef}>
            <ChapterDetailMeta details={details} />
            <ChapterHeader details={details} />
            {details.paragraphs.map((paragraph) => (
                <ChapterDetailParagraph
                    paragraph={paragraph}
                    chapterIndex={details.index}
                    key={paragraph.externalId}
                    noOfParagraphsInChapter={details.paragraphs.length}
                />
            ))}
            {nextChapterPath && (
                <div className="text-center">
                    <Link to={nextChapterPath} className="button button--primary" data-track-id="next-chapter">
                        Volgende hoofdstuk
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ChapterDetail;
