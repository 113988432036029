import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import { StandardOverviewItem } from '../../../model/standardOverview/StandardOverviewItem';
import StartRevisionStandardThreshold from './components/StartRevisionStandardThreshold';
import useDispatchStartRevisionStandardActionOnConfirm from './hooks/useDispatchStartRevisionStandardActionOnConfirm';
import useShowModalOnKeyBindingPress from '../../../hooks/useShowModalOnKeyBindingPress';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import { Dropdown } from 'semantic-ui-react';
import useCurrentTenant from '../../../hooks/useCurrentTenant';

type Props = {
    item: StandardOverviewItem;
};

const StartRevisionStandard = ({ item }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);
    const { isGGZ } = useCurrentTenant();

    const { onConfirm } = useDispatchStartRevisionStandardActionOnConfirm(item.externalId);

    if (!item.externalId) {
        return null;
    }

    useShowModalOnKeyBindingPress(doShowModal);

    return (
        <>
            <StartRevisionStandardThreshold externalId={item.externalId}>
                {showModal ? (
                    <ConfirmationModal
                        title="Herzien als project"
                        onConfirm={onConfirm}
                        onDecline={() => doHideModal()}
                        confirmButtonText={'Herzien als project'}
                        declineButtonText={'Annuleren'}
                    >
                        <p>Met deze actie ga je deze {isGGZ ? 'standaard' : 'richtlijn'} herzien als project.</p>
                    </ConfirmationModal>
                ) : (
                    <Dropdown.Item
                        text="Herzien als project"
                        onClick={() => doShowModal()}
                        className="icon icon-cancel"
                    />
                )}
            </StartRevisionStandardThreshold>
        </>
    );
};

export default StartRevisionStandard;
