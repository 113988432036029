import { convertFromRaw, ContentState, RawDraftContentState } from 'draft-js';
import { logError } from '../../../../utility/Logger';
import { isString, isObject, isArray } from 'lodash';

function _transformString(content?: string | null): ContentState | undefined | null {
    if (!content || !isString(content)) {
        return null;
    }

    return ContentState.createFromText(content);
}

function _transformJson(content?: string | null): ContentState | undefined | null {
    if (!content || !isString(content)) {
        return null;
    }

    try {
        const parsedContent = JSON.parse(content) as RawDraftContentState;

        // fixes legacy where by default an empty array was inserted
        if (isArray(parsedContent) || !isObject(parsedContent)) {
            logError(new Error('Content is parsed from json, but cannot be used for DraftJs editor. Supplied: '), {
                content,
            });

            return null;
        }

        return convertFromRaw(parsedContent);
    } catch (error) {
        logError(new Error('Content is not valid JSON. Message when transforming: '), { content, error });

        return null;
    }
}

export default {
    transformString: _transformString,
    transformJson: _transformJson,
};
