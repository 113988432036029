import React from 'react';
import Page from '../../../shared/page/Page';

const ContactPage = () => {
    return (
        <Page title="Contact">
            <p>
                Voor vragen over platform Richtlijnen Langdurige Zorg kun je contact opnemen via{' '}
                <a data-track-id="contact_page_mail_to_click" href="mailto:vraag@richtlijnenlangdurigezorg.nl">
                    vraag@richtlijnenlangdurigezorg.nl
                </a>
                . De Stichting KwaliteitsImpuls Langdurige Zorg (SKILZ) is als initiatiefnemer de organisatie achter het
                platform. Wij nemen binnen twee werkdagen contact met je op.
            </p>
            <p>
                Heb je suggesties of ideeën voor het platform, dan kun je ons dat ook laten weten via{' '}
                <a data-track-id="contact_page_mail_to_click" href="mailto:vraag@richtlijnenlangdurigezorg.nl">
                    vraag@richtlijnenlangdurigezorg.nl
                </a>
                .
            </p>
            <p>
                Telefonisch zijn we bereikbaar op maandag, dinsdag en donderdag tussen 9:00 en 12:00 uur via{' '}
                <a
                    data-track-id="contact_page_telephone_click"
                    href="tel:0610614836"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    06 10 614 836
                </a>
                .
            </p>
            <br />
        </Page>
    );
};

export default ContactPage;
