import React, { ReactElement } from 'react';
import createClassName from 'classnames';
import BreadcrumbItemSeperator from './components/BreadcrumbItemSeperator';

type Props = {
    small?: boolean;
    children: ReactElement[];
};

const Breadcrumb = ({ small = false, children }: Props) => {
    if (React.Children.count(children) === 0) {
        return null;
    }

    const className: string = createClassName('breadcrumb-list', {
        'breadcrumb-list--small': small,
    });

    return (
        <ul className={className}>
            {React.Children.map(children, (child, index) => {
                const isLast = index === React.Children.count(children) - 1;

                return (
                    <>
                        <li key={child.key} className="breadcrumb-list__item">
                            {child}
                        </li>
                        {!isLast && <BreadcrumbItemSeperator />}
                    </>
                );
            })}
        </ul>
    );
};

export default Breadcrumb;
