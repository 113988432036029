import { logError } from './Logger';

export function getFromSessionStorage(key: string, logOnError = false): string | undefined | null {
    try {
        return sessionStorage.getItem(key);
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return null;
}

export function writeToSessionStorage(key: string, value: string, logOnError = false): boolean {
    try {
        sessionStorage.setItem(key, value);

        return true;
    } catch (error) {
        if (logOnError) {
            logError(error, {
                requestedKey: key,
            });
        }
    }

    return false;
}
