import { useEffect } from 'react';
import mousetrap from 'mousetrap';

export default function useShowStartRevisionModalOnKeyBindingPress(doShowModal: () => void) {
    useEffect(() => {
        mousetrap.bind(['r'], () => doShowModal());

        return () => {
            mousetrap.unbind(['r']);
        };
    });
}
