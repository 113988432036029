import React, { FC, Fragment, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createStore } from '../../../store/storeFactory';
import { getBrowserHistory, initialize as initializeHistory } from '../../../routing/historyManager';
import { initializeSentryLogging } from '../../../utility/Logger';

initializeSentryLogging();

type Props = {
    children: ReactNode;
    containerEl: HTMLElement;
};

const EntryWrapper: FC<Props> = ({ children, containerEl }) => {
    if (!containerEl) return null;

    const isLoggedIn = containerEl.getAttribute('data-loggedin') === 'true';
    const initialState = { authenticated: isLoggedIn };
    const store = createStore(initialState);

    initializeHistory();
    const history = getBrowserHistory();

    return (
        <Fragment>
            <Provider store={store}>
                <Router history={history}>
                    <Fragment>{children}</Fragment>
                </Router>
            </Provider>
        </Fragment>
    );
};

export default EntryWrapper;
