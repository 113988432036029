import * as React from 'react';
import type { OverviewArticle } from '../../../../model/Article';
import { formatLongDay } from '../../../../helper/dateTimeHelper';
import Heading from '../../../shared/heading/Heading';
import ListAction from '../../shared/list/component/ListAction';
import ListHeading from '../../shared/list/component/ListHeading';
import ListLabel from '../../shared/list/component/ListLabel';

type Props = {
    article: OverviewArticle;
    children: React.ReactNode;
};

const ArticleOverviewListItem = ({ article, children }: Props) => {
    const publishedAt = article.publishedAt ? formatLongDay(article.publishedAt) : null;

    return (
        <>
            {publishedAt && <ListLabel>{publishedAt}</ListLabel>}
            <ListHeading>
                <Heading tag="h2">{article.title}</Heading>
            </ListHeading>
            <ListAction>{children}</ListAction>
        </>
    );
};

export default ArticleOverviewListItem;
