import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import StartRevisionProjectForm from '../startRevisionProjectForm/StartRevisionProjectForm';
import useHandleStartRevisionProjectForm from './hooks/useHandleStartRevisionProjectForm';
import useShowStartRevisionModalOnKeyBindingPress from './hooks/useShowStartRevisionModalOnKeyBindingPress';
import StartRevisionProjectModal from './components/StartRevisionProjectModal';
import StartRevisionProjectButton from './components/StartRevisionProjectButton';
import StartProjectThreshold from '../startNewProject/components/StartProjectThreshold';

const StartRevisionProject = () => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const { standardOverviewItemChoices, isLoadingStandardOverviewItems, onFormValid } =
        useHandleStartRevisionProjectForm(doHideModal);

    useShowStartRevisionModalOnKeyBindingPress(doShowModal);

    return (
        <StartProjectThreshold>
            {showModal ? (
                <StartRevisionProjectModal onDecline={() => doHideModal()}>
                    <StartRevisionProjectForm
                        standardOverviewItemChoices={standardOverviewItemChoices}
                        onFormValid={onFormValid}
                        onCancel={() => doHideModal()}
                        showLoader={isLoadingStandardOverviewItems}
                    />
                </StartRevisionProjectModal>
            ) : (
                <StartRevisionProjectButton onClick={() => doShowModal()} />
            )}
        </StartProjectThreshold>
    );
};

export default StartRevisionProject;
