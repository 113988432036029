import { OnPageChangeCallback } from '../../../frontend/shared/CollectionPagination';
import { useHistory } from 'react-router-dom';
import { createDashboardUsersOverviewPath } from '../../../../routing/dashboardUrlGenerator';

export default function useNavigateToNewPageOnChange(): OnPageChangeCallback {
    const history = useHistory();

    return (newPage) => {
        const newPath = createDashboardUsersOverviewPath(newPage.toString());

        history.push(newPath);
    };
}
