import React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import Loader from '../../shared/Loader';
import Button, { BUTTON } from '../../shared/buttons/Button';
import { Faq } from '../../../model/Faq';
import useDeleteFaqItemOnConfirmation from './hooks/useDeleteFaqItemOnConfirmation';

type Props = {
    faqItem: Faq;
    onDone: () => any;
};

const DeletePage = ({ faqItem, onDone }: Props) => {
    const { showModal, doHideModal, doShowModal } = useShowHideModal();
    const { onConfirm, isDeleting } = useDeleteFaqItemOnConfirmation(faqItem, doHideModal, onDone);

    return (
        <>
            {isDeleting ? (
                <Loader sizeSmall />
            ) : (
                <Button type={BUTTON} icon="cancel" onClick={() => doShowModal()} isCleared></Button>
            )}
            {showModal && (
                <ConfirmationModal title="Weet je het zeker?/" onConfirm={onConfirm} onDecline={doHideModal}>
                    <p>
                        Weet je zeker dat je artikel &apos;{faqItem.title}&apos; wilt verwijderen? Deze wijziging kan
                        niet ongedaan gemaakt worden.
                    </p>
                </ConfirmationModal>
            )}
        </>
    );
};

export default DeletePage;
