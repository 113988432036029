import React from 'react';
import { ProjectTabIdentifier, resolveLinkInformationForTab } from '../../../../../resolver/projectDetailTabsResolver';
import Tab from '../../../../shared/tabs/components/Tab';
import { useHistory } from 'react-router-dom';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import styles from '../../../../shared/tabs/Tabs.module.scss';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';

type Props = {
    project: ProjectDetail;
    identifier: ProjectTabIdentifier;
    currentIdentifier: ProjectTabIdentifier | null;
};

const ProjectDetailTab: React.FC<Props> = ({ project, identifier, currentIdentifier }) => {
    const history = useHistory();
    const { tenant } = useCurrentTenant();

    if (!tenant) return null;

    const linkInformation = resolveLinkInformationForTab(
        identifier,
        tenant,
        project.type,
        project.typeSlug,
        project.slug
    );

    const trackingData = {
        identifier,
        project: project.title,
    };

    const isActive = currentIdentifier === identifier;

    return (
        <Tab
            isActive={isActive}
            onClick={() => history.push(linkInformation.path)}
            to={linkInformation.path}
            trackId="project_tab_click"
            trackData={trackingData}
        >
            <span className={styles.title}>{linkInformation.title}</span>
        </Tab>
    );
};

export default ProjectDetailTab;
