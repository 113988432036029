import * as React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import { Dropdown } from 'semantic-ui-react';
import { StandardOverviewItem as StandardOverviewItemModel } from '../../../model/standardOverview/StandardOverviewItem.d';
import RecommendedStandardsForm from './RecommendedStandardsForm';
import Modal from '../../shared/modal/Modal';

type Props = {
    currentStandard: StandardOverviewItemModel;
    publishedStandards: StandardOverviewItemModel[];
};

const RecommendedStandards = ({ currentStandard, publishedStandards }: Props) => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const publishedStandardsOptions = publishedStandards.map((item) => ({
        value: item.externalId,
        label: item.title,
    }));

    return (
        <>
            {showModal ? (
                <Modal title={currentStandard.title} onDecline={() => doHideModal()}>
                    <div style={{ marginBottom: '70px' }}>
                        <RecommendedStandardsForm
                            currentStandard={currentStandard}
                            publishedStandardsOptions={publishedStandardsOptions}
                            doHideModal={doHideModal}
                        />
                    </div>
                </Modal>
            ) : (
                <Dropdown.Item
                    text="Aanbevolen standaarden"
                    onClick={() => doShowModal()}
                    className="icon icon-pencil"
                />
            )}
        </>
    );
};

export default RecommendedStandards;
