import * as React from 'react';
import classNameFactory from 'classnames';

export const MAIN_CLASS_NAME = 'form__group';
export const ERROR_CLASS_NAME = 'has-error';

type Props = {
    children: React.ReactNode;
    hasErrors?: boolean;
    isFlat?: boolean;
    additionalClassName?: string;
};

const FormGroup = ({ children, hasErrors = false, isFlat = false, additionalClassName }: Props) => {
    const className = classNameFactory(
        MAIN_CLASS_NAME,
        {
            [ERROR_CLASS_NAME]: hasErrors,
            ['form__group--flat']: isFlat,
        },
        additionalClassName
    );

    if (React.Children.count(children) === 0) {
        return null;
    }

    return <div className={className}>{children}</div>;
};

export default FormGroup;
