import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import type { GlobalState } from '../store/types';
import ProjectChapterDetail from '../model/projectChapterDetail/ProjectChapterDetail';
import { createFetchChapterDetails } from '../actions/projectActionFactory';

type Params = {
    type: string;
    projectSlug: string;
    chapterSlug: string;
};

export default function useFetchChapterDetailsOnSlugChange() {
    const dispatch = useDispatch();

    const { type: projectTypeSlug, projectSlug, chapterSlug } = useParams<Params>();

    if (!projectTypeSlug || !projectSlug || !chapterSlug) {
        throw new Error('Expecting all route params to be available');
    }

    useEffect(() => {
        dispatch(createFetchChapterDetails(projectTypeSlug, projectSlug, chapterSlug));
    }, [projectTypeSlug, projectSlug, chapterSlug]);

    const location = useLocation();

    const details = useSelector<GlobalState, ProjectChapterDetail | null>(({ chapterDetails }) => {
        if (!chapterDetails) {
            return null;
        }

        if (!chapterDetails.containsPath(location.pathname)) {
            return null;
        }

        return chapterDetails;
    });

    const nextChapterPath = useSelector<GlobalState, string | null>(({ projectIndex }) =>
        projectIndex && details ? projectIndex.getNextChapterPath(details.externalId) : null
    );

    return { details, nextChapterPath };
}
