import React from 'react';
import FormErrorMessage from '../../../../shared/form/FormErrorMessage';
import FormGroup from '../../../../shared/form/FormGroup';
import { OnValidSubmitHandler } from '../hooks/useHandleNewsletterSubscriptionAnswer';
import useNewsletterSubscriptionFormHandlers from '../hooks/useNewsletterSubscriptionFormHandlers';
import SubscribeSubmitButton from './SubscribeSubmitButton';

export type FormValues = {
    email: string;
};

type Props = {
    onValidSubmit: OnValidSubmitHandler;
    highlightButton?: boolean; // temporary prop for A/B test NewsletterSubscriptionPopup
    trackingData?: string; // temporary prop for A/B test NewsletterSubscriptionPopup
};

const NewsletterSubscriptionForm: React.FC<Props> = ({ onValidSubmit, highlightButton, trackingData }) => {
    const { handleChange, handleSubmit, handleBlur, touched, errors, values, isPending } =
        useNewsletterSubscriptionFormHandlers(onValidSubmit);

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <div className="newsletter-form">
                    <input
                        id="email"
                        name="email"
                        placeholder="e-mailadres"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        className="newsletter-form__input"
                        disabled={isPending}
                    />
                    <SubscribeSubmitButton
                        isPending={isPending}
                        isHighlighted={highlightButton}
                        trackingData={trackingData}
                    />
                </div>
            </FormGroup>
            {touched.email && !!errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
        </form>
    );
};

export default NewsletterSubscriptionForm;
