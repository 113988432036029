import React, { VFC } from 'react';
import { PermissionRequirementCallback } from '../../../security/types';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { BRANCH_ACCESS } from '../../../security/SecurityAttribute';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import DashboardContent from '../shared/containers/DashboardContent';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../helper/seoHelper';
import HeadingGroup from '../shared/headings/HeadingGroup';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import CreateBranch from '../createBranch/CreateBranch';
import DashboardIsland from '../shared/island/DashboardIsland';
import { useFetchBranchOverviewItems } from '../../../hooks/useFetchBranchOverviewItems';
import BranchOverviewList from './components/BranchOverviewList';
import BranchOverviewListItem from './components/BranchOverviewListItem';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import { userMenuTextItems } from '../../../translations/translations';
import { TenantType } from '../../../constants/settings';

const BranchOverview: VFC = () => {
    const { branches } = useFetchBranchOverviewItems();
    const { tenant } = useCurrentTenant();

    if (!branches) {
        return null;
    }

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{composeSeoTitle(tenant, userMenuTextItems[tenant as TenantType].branches)}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading>
                        {userMenuTextItems[tenant as TenantType].branches}
                    </DashboardContentHeading>
                    <CreateBranch />
                </HeadingGroup>
                <DashboardIsland>
                    <BranchOverviewList>
                        {branches.map((branch) => (
                            <BranchOverviewListItem key={branch.id} branch={branch} />
                        ))}
                    </BranchOverviewList>
                </DashboardIsland>
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(BRANCH_ACCESS));
};

export default BranchOverview;
