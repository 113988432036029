import { StandardOverviewItem } from './StandardOverviewItem';

export const applyNotActiveAsRevisionProjectSelector = (
    allStandardOverviewItems: Array<StandardOverviewItem>
): Array<StandardOverviewItem> => {
    return allStandardOverviewItems.filter((item: StandardOverviewItem) => item.activeRevisionProjectId === null);
};

export const applyArchivedStandardSelector = (
    items: Array<StandardOverviewItem>
): Array<StandardOverviewItem> => items.filter((item) => item.archivedAt);

export const applyPublishedStandardSelector = (
    items: Array<StandardOverviewItem>
): Array<StandardOverviewItem> => items.filter((item) => !item.archivedAt);
