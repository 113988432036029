import router from '../router';

const inMemoryCache: {
    [routeName: string]: string;
} = {};

/*
 * to be able to generate a route without filling-in the placeholders, we need to extract the placeholder names
 * from the route information. If we use Routing.generate the placeholders are url encoded, which makes them
 * not usable with React Router.
 */
export function extractPathWithPlaceholdersFromRoute(routeName: string): string {
    if (typeof inMemoryCache[routeName] !== 'undefined') {
        return inMemoryCache[routeName];
    }

    const route = router.getRoute(routeName);

    if (!route) {
        throw new Error(`No route found with name: '${routeName}'`);
    }

    const pathParts = route.tokens.map((tokenInfo) => {
        const type = tokenInfo[0];
        const lastIndex = tokenInfo.length - 1;
        const value = tokenInfo[lastIndex];
        const isOptional =
            route.defaults && typeof route.defaults[value] !== 'undefined'
                ? route.defaults[value] === null || route.defaults[value] === ''
                : false;

        let pathPart;

        switch (type) {
            case 'variable':
                pathPart = `/:${value}${isOptional ? '?' : ''}`;
                break;

            case 'text':
                pathPart = value;
                break;

            default:
                throw new Error(`Type ${type} not supported`);
        }

        return pathPart;
    });

    inMemoryCache[routeName] = pathParts.reverse().join('');

    return inMemoryCache[routeName];
}
