import { OnFormValidHandler } from '../StartRevisionProjectForm';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { StandardOverviewItem } from '../../../../model/standardOverview/StandardOverviewItem';
import { DefaultOptionType } from '../../../shared/form/select/Select';
import { getProjectTypeTitleSingular } from '../../../../constants/types';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';

export type FormValues = {
    standardId: string | null;
};

export default function useHandleStartRevisionProjectFormValues(
    onFormValid: OnFormValidHandler,
    standardOverviewItemChoices: StandardOverviewItem[]
) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { tenant } = useCurrentTenant();

    if (!tenant) return;

    const { handleSubmit, setFieldValue, handleBlur, values, touched, errors } = useFormik<FormValues>({
        initialValues: {
            standardId: null,
        },
        onSubmit: () => {
            if (!values.standardId) {
                return;
            }

            setIsSubmitting(true);

            onFormValid(values.standardId);
        },
        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (!values.standardId) {
                errors.standardId = 'Dit veld is verplicht!';
            }

            return errors;
        },
    });

    const options: DefaultOptionType[] = standardOverviewItemChoices.map((item) => ({
        value: item.externalId, //here
        label: `${item.shortTitle ?? item.title} - ${getProjectTypeTitleSingular(tenant, item.type)}${
            item.archivedAt ? ' (gearchiveerd)' : ''
        }`,
    }));

    const currentStandardIdOption = options.filter((option) => option.value === values.standardId);

    return {
        isSubmitting,
        handleSubmit,
        setFieldValue,
        handleBlur,
        touched,
        errors,
        currentStandardIdOption,
        options,
    };
}
