import ProjectChapterDetail from '../ProjectChapterDetail';
import ProjectChapterDetailProject from '../ProjectChapterDetailProject';
import ProjectChapterDetailParagraph from '../ProjectChapterDetailParagraph';
import ProjectChapterDetailSubParagraph from '../ProjectChapterDetailSubParagraph';
import { createUuid } from '../../../utility/idGenerator';

function _createProjectChapterDetailProjectFromApiInput(apiInput: any): ProjectChapterDetailProject {
    return new ProjectChapterDetailProject(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.short_title,
        apiInput.type,
        apiInput._path
    );
}

function _createProjectChapterDetailSubParagraphCollectionFromApiInput(
    apiInput: Array<any>
): Array<ProjectChapterDetailSubParagraph> {
    return apiInput.map(
        (item) =>
            new ProjectChapterDetailSubParagraph(
                createUuid(),
                item.id,
                item.title,
                item.index,
                item.content,
                item.contentType,
                item.position,
                item._path
            )
    );
}

function _createProjectChapterDetailParagraphCollectionFromApiInput(
    apiInput: Array<any>
): Array<ProjectChapterDetailParagraph> {
    return apiInput.map(
        (item) =>
            new ProjectChapterDetailParagraph(
                createUuid(),
                item.id,
                item.title,
                item.index,
                item.content,
                item.contentType,
                item._path,
                _createProjectChapterDetailSubParagraphCollectionFromApiInput(item.sub_paragraphs)
            )
    );
}

export function createProjectChapterDetailFromApiInput(apiInput: any): ProjectChapterDetail {
    return new ProjectChapterDetail(
        createUuid(),
        apiInput.id,
        apiInput.title,
        apiInput.index,
        apiInput._path,
        _createProjectChapterDetailProjectFromApiInput(apiInput.project),
        _createProjectChapterDetailParagraphCollectionFromApiInput(apiInput.paragraphs),
        apiInput.authorized_at,
        apiInput.reviewed_at
    );
}
