import React, { MouseEventHandler } from 'react';
import LinkInformationModal from './LinkInformationModal';

type Props = {
    data: Record<string, any>;
    children: React.ReactNode;
};

type State = {
    displayModal: boolean;
};

class LinkInformation extends React.Component<Props, State> {
    state = {
        displayModal: false,
    };

    _onLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();

        this._displayModal();
    };

    _displayModal() {
        this.setState((currentState: State) => ({
            ...currentState,
            displayModal: true,
        }));
    }

    _hideModal() {
        this.setState((currentState: State) => ({
            ...currentState,
            displayModal: false,
        }));
    }

    _onModalDecline = () => {
        this._hideModal();
    };

    render() {
        const { data, children } = this.props;
        const { displayModal } = this.state;

        return (
            <React.Fragment>
                <a href="#" onClick={this._onLinkClick}>
                    {children}
                </a>
                {displayModal ? <LinkInformationModal data={data} onDecline={this._onModalDecline} /> : null}
            </React.Fragment>
        );
    }
}

export default LinkInformation;
