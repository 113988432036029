import React from 'react';
import ListLabel from '../../shared/list/component/ListLabel';
import { getProjectTypeTitleSingular } from '../../../../constants/types';
import StandardOverviewItemHeading from './StandardOverviewItemHeading';
import StandardOverviewItemPublicationDate from './StandardOverviewItemPublicationDate';
import ListItem from '../../shared/list/component/ListItem';
import { StandardOverviewItem as StandardOverviewItemModel } from '../../../../model/standardOverview/StandardOverviewItem.d';
import ListAction from '../../shared/list/component/ListAction';
import { Dropdown } from 'semantic-ui-react';
import ArchiveStandard from '../../archiveStandard/ArchiveStandard';
import StartRevisionStandard from '../../startRevisionStandard/StartRevisionStandard';
import { formatDate } from '../../../../helper/dateTimeHelper';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';
import RecommendedStandards from '../../recommendedStandards/RecommendedStandards';

type Props = {
    currentStandard: StandardOverviewItemModel;
    standards: StandardOverviewItemModel[];
};

const StandardOverviewItem = ({ currentStandard, standards }: Props) => {
    const { tenant, isGGZ } = useCurrentTenant();

    if (!tenant) return null;

    return (
        <ListItem key={currentStandard.externalId}>
            <ListLabel>{getProjectTypeTitleSingular(tenant, currentStandard.type)}</ListLabel>
            <StandardOverviewItemHeading item={currentStandard} />
            <StandardOverviewItemPublicationDate item={currentStandard} />
            {currentStandard.externalId &&
                (!currentStandard.archivedAt ||
                    (!currentStandard.activeRevisionProjectId && currentStandard.publicationOverviewItems)) && (
                    <ListAction>
                        <Dropdown icon={false} className="list__menu icon-kebab" text="">
                            <Dropdown.Menu>
                                {currentStandard.archivedAt || !isGGZ ? null : (
                                    <RecommendedStandards
                                        currentStandard={currentStandard}
                                        publishedStandards={standards}
                                    />
                                )}
                                {currentStandard.archivedAt ? null : <ArchiveStandard item={currentStandard} />}
                                {currentStandard.activeRevisionProjectId ? null : (
                                    <StartRevisionStandard item={currentStandard} />
                                )}
                                {currentStandard.publicationOverviewItems
                                    ? currentStandard.publicationOverviewItems.map((publicationOverviewItem, index) => {
                                          return (
                                              <Dropdown.Item
                                                  key={index}
                                                  text={'PDF ' + formatDate(publicationOverviewItem.publishedAt)}
                                                  href={publicationOverviewItem.pdfFile}
                                                  target="_blank"
                                                  className="icon icon-pdf"
                                              />
                                          );
                                      })
                                    : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </ListAction>
                )}
        </ListItem>
    );
};

export default StandardOverviewItem;
