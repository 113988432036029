import ReferenceOverviewItem from '../components/dashboard/referenceOverview/ReferenceOverviewItem';
import { FETCH_REFERENCE_OVERVIEW } from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { Action } from '../actions/factory';
import { createReferenceOverviewItemCollectionFromApiData } from '../components/dashboard/referenceOverview/factory/referenceOverviewItemFactory';
import PaginatedCollection from '../model/collection/paginatedCollection';

export type ReferenceOverviewReducerState = PaginatedCollection<ReferenceOverviewItem> | null;

export default function referenceOverviewReducer(
    currentState: ReferenceOverviewReducerState = null,
    action: Action
): ReferenceOverviewReducerState {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case `${FETCH_REFERENCE_OVERVIEW}_${ActionType.Fulfilled}`:
            // @ts-ignore -> typescript does not know action contents
            return createReferenceOverviewItemCollectionFromApiData(action.payload.data);

        default:
            return currentState;
    }
}
