import { TenantType } from '../constants/settings';

export const userMenuTextItems = {
    [TenantType.GGZ]: {
        users: 'Gebruikers',
        branches: 'Partijen',
        projects: 'Projecten',
        standards: 'Standaarden',
        references: 'Referenties',
        articles: 'Artikelen',
        sideProductCategories: 'Hulpmiddelen',
        pages: "Pagina's",
        faq: 'Veelgestelde vragen',
    },
    [TenantType.SKILZ]: {
        users: 'Gebruikers',
        branches: 'Organisaties',
        projects: 'In ontwikkeling',
        standards: 'Richtlijnen',
        references: 'Referenties',
        articles: 'Artikelen',
        sideProductCategories: 'Hulpmiddelen',
        pages: "Pagina's",
        faq: 'Veelgestelde vragen',
    },
};

export const projectsTabTextItems = {
    [TenantType.GGZ]: {
        projects: 'Projecten',
        newProjects: 'Nieuwe projecten',
        newProject: 'Nieuw project',
        newProjectFromStandard: 'Nieuw project vanuit standaard',
        revisions: 'Herzieningen',
    },
    [TenantType.SKILZ]: {
        projects: 'In ontwikkeling',
        newProjects: 'Richtlijnen in ontwikkeling',
        newProject: 'Nieuwe richtlijn',
        newProjectFromStandard: 'Nieuw project vanuit bestaande richtlijn',
        revisions: 'Herzieningen',
    },
};

export const standardTabTextItems = {
    [TenantType.GGZ]: {
        standards: 'Standaarden',
        publishedStandards: 'Gepubliceerde standaarden',
        archivedStandards: 'Gearchiveerde standaarden',
    },
    [TenantType.SKILZ]: {
        standards: 'Richtlijnen',
        publishedStandards: 'Publicaties',
        archivedStandards: 'Archief',
    },
};
