// @ts-nocheck react-select elements cause problems with ts
import React, { useMemo } from 'react';
import ReactSelect, { OptionTypeBase, Props as ReactSelectProps } from 'react-select';
import createStyles from './utility/stylesFactory';

export interface DefaultOptionType extends OptionTypeBase {
    value: string;
    label: string;
}

type Props = Omit<ReactSelectProps, 'styles'>;

const Select: React.FC<Props> = <
    OptionType extends OptionTypeBase = DefaultOptionType,
    IsMulti extends boolean = false
>(
    props: Props
) => {
    const styles = useMemo(() => createStyles<OptionType, IsMulti>(), []);

    return <ReactSelect<OptionType, IsMulti> {...props} styles={styles} />;
};

export default Select;
