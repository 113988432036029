export function sortByField<T = any, FV = string, FVR = string>(
    entityCollection: Array<T>,
    field: string,
    normalizeValueCallback?: (value: FV) => FVR
): Array<T> {
    return entityCollection.sort((firstEntity: T, secondEntity: T) => {
        // @ts-ignore -> Typescript does not know about field in generic type
        const firstValue: FV = firstEntity[field];
        const normalizedFirstValue: FV | FVR = normalizeValueCallback ? normalizeValueCallback(firstValue) : firstValue;

        // @ts-ignore -> Typescript does not know about field in generic type
        const secondValue: FV = secondEntity[field];
        const normalizedSecondValue: FV | FVR = normalizeValueCallback
            ? normalizeValueCallback(secondValue)
            : secondValue;

        if (normalizedFirstValue > normalizedSecondValue) {
            return 1;
        }

        if (normalizedFirstValue < normalizedSecondValue) {
            return -1;
        }

        return 0;
    });
}
