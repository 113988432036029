import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { logError } from '../utility/Logger';
import { findAllForPage } from '../repository/articleRepository';
import { OverviewArticle } from '../model/Article';
import { PaginatedCollectionType } from '../model/collection/paginatedCollection';

type OnErrorCallback = (error: any) => void;

type Filters = {
    page: number;
    standardId?: string;
    perPage?: number;
    includeUnpublished?: boolean;
};

export default function useFetchArticlesOnFilterChange(
    { page, standardId, perPage, includeUnpublished }: Filters,
    enabled: boolean = true,
    onError?: OnErrorCallback
) {
    const [articles, setArticles] = useState<PaginatedCollectionType<OverviewArticle> | null>(null);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchCurrentPage = async () => {
        setIsFetching(true);
        setArticles((currentState) => {
            if (!currentState) {
                return null;
            }

            // when we already have results in state, and the page changes, don't throw
            // away all data we have, but only the results. We leave to counts for
            // the rendering of the pagination and the correct loading state in the
            // component.
            return {
                ...currentState,
                results: [],
            };
        });

        try {
            const articles = await findAllForPage(
                page,
                standardId || null,
                perPage || null,
                includeUnpublished || false
            );

            setArticles(articles);
        } catch (error) {
            logError(error);

            toast.error('Er is iets foutgegaan bij het ophalen van de artikelen. Probeer het later nog eens!');

            if (onError) {
                onError(error);
            }
        }

        setIsFetching(false);
    };

    useEffect(() => {
        if (!enabled) {
            return;
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchCurrentPage();
    }, [page, standardId, enabled]);

    return { articles, refetchCurrentPage: fetchCurrentPage, isFetching };
}
