import ProjectIndexChapter from './ProjectIndexChapter';

export default class ProjectIndex {
    _id: string;
    _externalId: string;
    _title: string;
    _chapters: Array<ProjectIndexChapter>;
    _path: string;
    _typeSlug: string;
    _slug: string;
    _file: string | null;

    constructor(
        id: string,
        externalId: string,
        title: string,
        chapters: Array<ProjectIndexChapter>,
        path: string,
        typeSlug: string,
        slug: string,
        file: string | null
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._chapters = chapters;
        this._path = path;
        this._typeSlug = typeSlug;
        this._slug = slug;
        this._file = file;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get chapters() {
        return this._chapters;
    }

    get path() {
        return this._path;
    }

    get typeSlug() {
        return this._typeSlug;
    }

    get slug() {
        return this._slug;
    }

    get file() {
        return this._file;
    }

    getNextChapterPath(currentChapterExternalId: string): string | null {
        const currentChapterIndex = this._chapters.findIndex(
            (projectIndexChapter) => projectIndexChapter.externalId === currentChapterExternalId
        );

        if (currentChapterIndex === -1) {
            return null;
        }

        const nextChapterIndex = currentChapterIndex + 1;

        return typeof this._chapters[nextChapterIndex] !== 'undefined' ? this._chapters[nextChapterIndex].path : null;
    }
}
