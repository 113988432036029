import type { Cloneable } from '../contracts';

// Beware! Keep in line with backend setup in LinkToolType.php
export enum ProjectDetailLinkToolType {
    decisionAid = 'decisionAid',
    other = 'other',
    usefulLinks = 'usefulLinks', // SKILZ
}

export const ggzProjectDetailLinkToolTypeLabels = {
    [ProjectDetailLinkToolType.usefulLinks]: 'Handige links',
    [ProjectDetailLinkToolType.decisionAid]: 'Keuzehulpen',
    [ProjectDetailLinkToolType.other]: 'Overig',
};

// Beware! Keep in line with backend setup in LinkType.php
export enum ProjectDetailLinkType {
    notProfessionalReference = 'notProfessionalReference',
    relatedStandards = 'relatedStandards',
    additionalLinks = 'additionalLinks',
    apply = 'apply',
    transparency = 'transparency',
}

export const ProjectDetailLinkTypeValues = {
    [ProjectDetailLinkType.notProfessionalReference]: 'Voor patiënten » Patiënteninformatie',
    [ProjectDetailLinkType.apply]: 'Hulpmiddelen',
    [ProjectDetailLinkType.relatedStandards]: 'Aanvullend » Gerelateerde standaarden',
    [ProjectDetailLinkType.additionalLinks]: 'Aanvullend » Handige links',
};

export const skilzProjectDetailLinkTypeValues = {
    [ProjectDetailLinkType.notProfessionalReference]: 'Voor cliënten',
    [ProjectDetailLinkType.apply]: 'In de praktijk',
};

export default class ProjectDetailLink implements Cloneable<ProjectDetailLink> {
    _id: string;
    _title: string;
    _url: string;
    _type: ProjectDetailLinkType;
    _description: string | null;
    _highlightText: string | null;
    _toolType: ProjectDetailLinkToolType;

    constructor(
        id: string,
        title: string,
        url: string,
        type: ProjectDetailLinkType,
        description: string | null,
        highlightText: string | null,
        toolType: ProjectDetailLinkToolType
    ) {
        this._id = id;
        this._title = title;
        this._url = url;
        this._type = type;
        this._description = description;
        this._highlightText = highlightText;
        this._toolType = toolType;
    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get url() {
        return this._url;
    }

    set url(url: string) {
        this._url = url;
    }

    get type(): ProjectDetailLinkType {
        return this._type;
    }

    set type(type: ProjectDetailLinkType) {
        this._type = type;
    }

    get description() {
        return this._description;
    }

    set description(description: string | null) {
        this._description = description;
    }

    get highlightText() {
        return this._highlightText;
    }

    set highlightText(highlightText: string | null) {
        this._highlightText = highlightText;
    }

    get toolType() {
        return this._toolType;
    }

    set toolType(toolType: ProjectDetailLinkToolType) {
        this._toolType = toolType;
    }

    clone(): ProjectDetailLink {
        return new ProjectDetailLink(
            this._id,
            this._title,
            this._url,
            this._type,
            this._description,
            this._highlightText,
            this._toolType
        );
    }
}
