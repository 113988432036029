import PaginatedCollection from '../collection/paginatedCollection';
import ProjectSearchItem from '../ProjectSearchItem';

export type ProjectSearchResultsResponse = {
    toplevelResults: PaginatedCollection<ProjectSearchItem>;
    sideProductResults: PaginatedCollection<ProjectSearchItem>;
    nestedResults: PaginatedCollection<ProjectSearchItem>;
};

function _createSearchResultItemFromApiInput(apiInput: any): ProjectSearchItem {
    return new ProjectSearchItem(
        apiInput.id,
        apiInput.project_id,
        apiInput.project_type,
        apiInput.type,
        apiInput.indexes,
        apiInput.title,
        apiInput.description,
        apiInput.path,
        apiInput.breadcrumb_titles,
        apiInput.matched_query_terms
    );
}

export function _createProjectSearchItemCollectionFromApiInput(apiInput: any): PaginatedCollection<ProjectSearchItem> {
    if (!apiInput) {
        return new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0);
    }

    return new PaginatedCollection<ProjectSearchItem>(
        apiInput.results.map((apiInputItem: any) => _createSearchResultItemFromApiInput(apiInputItem)),
        apiInput.current_page,
        apiInput.total_no_of_pages,
        apiInput.no_of_results_per_page,
        apiInput.total_no_of_results
    );
}

export function createProjectSearchResultsFromApiInput(apiInput: any): ProjectSearchResultsResponse {
    return {
        toplevelResults: _createProjectSearchItemCollectionFromApiInput(apiInput.toplevelResults),
        sideProductResults: _createProjectSearchItemCollectionFromApiInput(apiInput.sideProductResults),
        nestedResults: _createProjectSearchItemCollectionFromApiInput(apiInput.nestedResults),
    };
}
