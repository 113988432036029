import {
    FETCH_PROJECT_DETAILS,
    ADD_CHAPTER_TO_PROJECT,
    CLEAR_PROJECT_DETAILS,
    UPDATE_PROJECT,
    UPDATE_PROJECT_CHAPTER,
    MOVE_PROJECT_CHAPTER,
    ADD_PARAGRAPH_TO_CHAPTER,
    PROJECT_EMBED_CHAPTER,
    PROJECT_EMBED_PARAGRAPH,
    MOVE_PROJECT_PARAGRAPH,
    PROJECT_REMOVE_CHAPTER,
    PROJECT_REMOVE_PARAGRAPH,
    PROJECT_ADD_SIDE_PRODUCT,
    PROJECT_UPDATE_SIDE_PRODUCT,
    PROJECT_REMOVE_SIDE_PRODUCT,
    MARK_CHAPTER_SEARCHABLE,
    MARK_CHAPTER_NOT_SEARCHABLE,
    PUBLISH_PROJECT,
    ADD_WORKING_GROUP_MEMBER_TO_PROJECT,
    REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT,
    PROJECT_POST_COMMENT,
    PROJECT_REACT_ON_COMMENT,
    ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT,
    REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT,
    PROJECT_ADD_LINK,
    PROJECT_REMOVE_LINK,
    PROJECT_UPDATE_PATIENT_INTRODUCTION,
    PROJECT_UPDATE_LINK,
} from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import {
    createExternalPartyMemberFromPendingAddActionPayload,
    createProjectDetailFromApiInput,
    createWorkingGroupMemberFromPendingAddActionPayload,
} from '../model/projectDetail/factory/projectDetailFactory';
import { createProjectDetailChapterFromAddChapterToProjectPendingActionPayload } from '../model/projectDetail/factory/projectDetailChapterFactory';
import { createProjectDetailParagraphFromAddParagraphActionPayload } from '../model/projectDetail/factory/projectDetailParagraphFactory';
import { createProjectSideProductPendingActionPayload } from '../model/projectDetail/factory/projectSideProductFactory';
import { extractPath } from '../helper/objectPathHelper';
import ProjectDetail from '../model/projectDetail/ProjectDetail';
import type { Action } from '../actions/factory';
import ProjectDetailChapter from '../model/projectDetail/ProjectDetailChapter';
import ProjectDetailParagraph from '../model/projectDetail/ProjectDetailParagraph';
import { createProjectDetailLinkFromPendingActionPayload } from '../model/projectDetail/factory/projectDetailLinkFactory';
import { ExistingSubject } from '../context/comments/CommentSubjectContext';
import ParagraphDetail from '../model/paragraphDetail/ParagraphDetail';
import ParagraphDetailSubParagraph from '../model/paragraphDetail/ParagraphDetailSubParagraph';

export type ProjectDetailsReducerState = ProjectDetail | null;

const RESET_STATE: ProjectDetailsReducerState = null;

function _handleAddChapterToProjectPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone(),
        id = extractPath('meta.id', action, null),
        index = newState._chapters.length;

    newState.chapters.push(
        createProjectDetailChapterFromAddChapterToProjectPendingActionPayload(action.payload, id, index)
    );

    return newState;
}

function _handleProjectEmbedChapterRejectedAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const id = action.meta.id;

    newState.removeChapterWithId(id);

    return newState;
}

function _handleUpdateProjectPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { title } = action.payload;

    newState.title = title;

    return newState;
}

function _handleUpdateProjectChapterPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterId = action.payload.chapterId;
    const chapter = newState.getChapterById(chapterId);

    if (!chapter) {
        throw new Error(`Cannot find just updated chapter with id: ${chapterId}`);
    }

    const { title } = action.payload;

    chapter.title = title;

    return newState;
}

function _handleAddParagraphToChapterPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone(),
        chapterId = action.payload.chapterId,
        chapter = newState.getChapterById(chapterId);

    if (!chapter) {
        throw new Error(`Cannot find chapter ${chapterId} to add a paragraph`);
    }

    const id = extractPath('meta.id', action, null);
    const index = chapter._paragraphs.length;

    chapter.paragraphs.push(createProjectDetailParagraphFromAddParagraphActionPayload(action.payload, id, index));

    return newState;
}

function _handleMarkChapterSearchablePendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterId = action.payload.externalChapterId;
    const chapter = newState.getChapterByExternalId(chapterId);

    if (!chapter) {
        throw new Error(`Cannot find chapter ${chapterId} to add a paragraph`);
    }

    chapter.searchable = true;

    return newState;
}
function _handleMarkChapterNotSearchablePendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterId = action.payload.externalChapterId;
    const chapter = newState.getChapterByExternalId(chapterId);

    if (!chapter) {
        throw new Error(`Cannot find chapter ${chapterId} to add a paragraph`);
    }

    chapter.searchable = false;

    return newState;
}

function _handleRemoveWorkingGroupMemberPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const externalUserId = action.payload.externalUserId;

    newState.removeWorkingGroupMemberWithExternalUserId(externalUserId);

    return newState;
}

function _handleRemoveExternalPartyMemberPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const externalUserId = action.payload.externalUserId;

    newState.removeExternalPartyMemberWithExternalUserId(externalUserId);

    return newState;
}

function _handleProjectPostCommentPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const externalProjectId: string = action.meta.externalProjectId;

    if (currentState.externalId !== externalProjectId) {
        return currentState;
    }

    const subject = action.meta.subject as ExistingSubject;

    if (!(subject instanceof ParagraphDetail) && !(subject instanceof ParagraphDetailSubParagraph)) {
        // no counts to update in this projection

        return currentState;
    }

    const newState = currentState.clone();

    const chapter = newState.getChapterByExternalId(action.meta.externalChapterId);

    if (!chapter) {
        return currentState;
    }

    const paragraph = chapter.getParagraphByExternalId(action.meta.externalParagraphId);

    if (!paragraph) {
        return currentState;
    }

    chapter.incrementCommentCount();
    paragraph.incrementCommentCount();

    return newState;
}

function _handleProjectReactOnCommentPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const isConcept = action.payload.concept;

    if (isConcept) {
        return currentState;
    }

    const subject = action.meta.subject as ExistingSubject;

    if (!(subject instanceof ParagraphDetail) && !(subject instanceof ParagraphDetailSubParagraph)) {
        // no counts to update in this projection

        return currentState;
    }

    const newState: ProjectDetail = currentState.clone();
    const externalProjectId: string = action.meta.externalProjectId;

    if (currentState.externalId !== externalProjectId) {
        return currentState;
    }

    const externalChapterId: string = action.meta.externalChapterId;
    const chapter: ProjectDetailChapter | undefined | null = newState.getChapterByExternalId(externalChapterId);

    if (!chapter) {
        return currentState;
    }

    const externalParagraphId: string = action.meta.externalParagraphId;
    const paragraph: ProjectDetailParagraph | undefined | null = chapter.getParagraphByExternalId(externalParagraphId);

    if (!paragraph) {
        return currentState;
    }

    chapter.incrementAnsweredCommentCount();
    paragraph.incrementAnsweredCommentCount();

    return newState;
}
function _handleProjectUpdatePatientInformationPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState: ProjectDetail = currentState.clone();

    newState.patientIntroduction = action.payload.introduction;

    return newState;
}

function _handleAddWorkingGroupMemberToProjectPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.workingGroupMembers.push(createWorkingGroupMemberFromPendingAddActionPayload(action.payload));

    return newState;
}

function _handleAddExternalPartyMemberToProjectPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.externalPartyMembers.push(createExternalPartyMemberFromPendingAddActionPayload(action.payload));

    return newState;
}

function _handleProjectEmbedParagraphRejectedAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterId = action.meta.chapterId;
    const chapter = newState.getChapterById(chapterId);

    if (!chapter) {
        throw new Error(`Cannot find chapter ${chapterId} to add a paragraph`);
    }

    const id = extractPath('meta.id', action, null);

    chapter.removeParagraphWithId(id);

    return newState;
}

function _handleMoveProjectChapterPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { chapterId, newIndex } = action.payload;

    newState.moveChapterToIndex(chapterId, newIndex);

    return newState;
}

function _handleProjectRemoveChapterPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterIdToBeRemoved = action.payload.chapterId;

    newState.removeChapterWithId(chapterIdToBeRemoved);

    return newState;
}

function _handleProjectRemoveParagraphPendingACtion(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const { chapterId: idOfChapterWhereTheParagraphIsIn, paragraphId: idOfParagraphThatIsToBeRemoved } = action.payload;

    const chapter = newState.getChapterById(idOfChapterWhereTheParagraphIsIn);

    if (!chapter) {
        console.error('Trying to remove a paragraph in a non existing chapter: ', idOfChapterWhereTheParagraphIsIn);

        return currentState;
    }

    chapter.removeParagraphWithId(idOfParagraphThatIsToBeRemoved);

    return newState;
}

function _handleMoveProjectParagraphPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const chapterId = action.payload.chapterId;
    const chapter = newState.getChapterById(chapterId);

    if (!chapter) {
        console.error(`Cannot find chapter ${chapterId} supplied in action:`, action);

        return currentState;
    }

    const paragraphId = action.payload.paragraphId;
    const newIndex = action.payload.newIndex;

    chapter.moveParagraphToIndex(paragraphId, newIndex);

    return newState;
}

function _handleAddSideProductPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.sideProducts.push(createProjectSideProductPendingActionPayload(action.payload));

    return newState;
}

function _handleProjectAddLinkPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.links.push(createProjectDetailLinkFromPendingActionPayload(action.payload));

    return newState;
}

function _handleUpdateSideProductPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const sideProduct = newState.sideProducts.find((cursorSideProduct) => cursorSideProduct.id === action.meta.id);

    if (!sideProduct) {
        return currentState;
    }

    const { payload } = action;

    sideProduct.title = payload.title;
    sideProduct.filename = payload.file;
    sideProduct.type = payload.type;
    sideProduct.description = payload.description;

    return newState;
}

function _handleProjectUpdateLinkPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    const link = newState.links.find((cursorLink) => cursorLink.id === action.meta.id);

    if (!link) {
        return currentState;
    }

    const payload = action.payload;

    link.title = payload.title;
    link.url = payload.url;
    link.type = payload.type;
    link.description = payload.description;

    return newState;
}

function _handleProjectAddLinkRejectedAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.removeLinkWithId(action.meta.id);

    return newState;
}

function _handleProjectRemoveLinkPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.removeLinkWithId(action.meta.id);

    return newState;
}

function _handleRemoveSideProductPendingAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    if (!(currentState instanceof ProjectDetail)) {
        return currentState;
    }

    const newState = currentState.clone();
    const sideProductId = action.payload.sideProductId;

    const sideProduct = currentState.getSideProductById(sideProductId);

    if (!sideProduct) {
        console.error(`Cannot find sideProduct with id ${sideProductId} supplied in action:`, action);

        return currentState;
    }

    newState.removeSideProductById(sideProductId);

    return newState;
}

function _handleFetchProjectDetailsFulfilledAction(
    currentState: ProjectDetailsReducerState,
    action: Action
): ProjectDetailsReducerState {
    const incomingProjectDetails = createProjectDetailFromApiInput(
        // @ts-ignore -> typescript does not know what the response is
        action.payload.data.result
    );

    // when this is the first fetch return everything that comes back from API or if its another project
    if (!(currentState instanceof ProjectDetail) || currentState.externalId !== incomingProjectDetails.externalId) {
        return incomingProjectDetails;
    }

    // maintain current client-side id
    incomingProjectDetails.id = currentState.id;

    // merge incoming chapters data but maintain current client-side id to be able to hold reference to existing items
    incomingProjectDetails.chapters.forEach((incomingChapter) => {
        // $ExpectError
        const currentChapter = currentState.chapters.find(
            (currentChapter) => currentChapter.externalId === incomingChapter.externalId
        );

        if (currentChapter) {
            incomingChapter.id = currentChapter.id;

            incomingChapter.paragraphs.forEach((incomingParagraph) => {
                // @ts-ignore -> somehow Typescript does not recognize that currentChapter is there
                const currentParagraph = currentChapter.paragraphs.find(
                    (currentParagraph) => currentParagraph.externalId === incomingParagraph.externalId
                );

                if (currentParagraph) {
                    incomingParagraph.id = currentParagraph.id;
                }
            });
        }
    });

    return incomingProjectDetails;
}

export default function (
    currentState: ProjectDetailsReducerState = RESET_STATE,
    action: Action
): ProjectDetailsReducerState {
    switch (action.type) {
        case `${FETCH_PROJECT_DETAILS}_${ActionType.Fulfilled}`:
            return _handleFetchProjectDetailsFulfilledAction(currentState, action);

        case `${ADD_CHAPTER_TO_PROJECT}_${ActionType.Pending}`:
        case `${PROJECT_EMBED_CHAPTER}_${ActionType.Pending}`:
            return _handleAddChapterToProjectPendingAction(currentState, action);

        case `${PROJECT_EMBED_CHAPTER}_${ActionType.Rejected}`:
            return _handleProjectEmbedChapterRejectedAction(currentState, action);

        case `${UPDATE_PROJECT}_${ActionType.Pending}`:
            return _handleUpdateProjectPendingAction(currentState, action);

        case `${UPDATE_PROJECT_CHAPTER}_${ActionType.Pending}`:
            return _handleUpdateProjectChapterPendingAction(currentState, action);

        case `${MOVE_PROJECT_CHAPTER}_${ActionType.Pending}`:
            return _handleMoveProjectChapterPendingAction(currentState, action);

        case `${PROJECT_REMOVE_CHAPTER}_${ActionType.Pending}`:
            return _handleProjectRemoveChapterPendingAction(currentState, action);

        case `${PROJECT_REMOVE_PARAGRAPH}_${ActionType.Pending}`:
            return _handleProjectRemoveParagraphPendingACtion(currentState, action);

        case `${MOVE_PROJECT_PARAGRAPH}_${ActionType.Pending}`:
            return _handleMoveProjectParagraphPendingAction(currentState, action);

        case `${ADD_PARAGRAPH_TO_CHAPTER}_${ActionType.Pending}`:
        case `${PROJECT_EMBED_PARAGRAPH}_${ActionType.Pending}`:
            return _handleAddParagraphToChapterPendingAction(currentState, action);

        case `${PROJECT_EMBED_PARAGRAPH}_${ActionType.Rejected}`:
            return _handleProjectEmbedParagraphRejectedAction(currentState, action);

        case `${PROJECT_ADD_SIDE_PRODUCT}_${ActionType.Pending}`:
            return _handleAddSideProductPendingAction(currentState, action);

        case `${PROJECT_UPDATE_SIDE_PRODUCT}_${ActionType.Pending}`:
            return _handleUpdateSideProductPendingAction(currentState, action);

        case `${PROJECT_REMOVE_SIDE_PRODUCT}_${ActionType.Pending}`:
            return _handleRemoveSideProductPendingAction(currentState, action);

        case `${PROJECT_ADD_LINK}_${ActionType.Pending}`:
            return _handleProjectAddLinkPendingAction(currentState, action);

        case `${PROJECT_UPDATE_LINK}_${ActionType.Pending}`:
            return _handleProjectUpdateLinkPendingAction(currentState, action);

        case `${PROJECT_ADD_LINK}_${ActionType.Rejected}`:
            return _handleProjectAddLinkRejectedAction(currentState, action);

        case `${PROJECT_REMOVE_LINK}_${ActionType.Pending}`:
            return _handleProjectRemoveLinkPendingAction(currentState, action);

        case `${MARK_CHAPTER_SEARCHABLE}_${ActionType.Pending}`:
            return _handleMarkChapterSearchablePendingAction(currentState, action);

        case `${MARK_CHAPTER_NOT_SEARCHABLE}_${ActionType.Pending}`:
            return _handleMarkChapterNotSearchablePendingAction(currentState, action);

        case `${ADD_WORKING_GROUP_MEMBER_TO_PROJECT}_${ActionType.Pending}`:
            return _handleAddWorkingGroupMemberToProjectPendingAction(currentState, action);

        case `${REMOVE_WORKING_GROUP_MEMBER_FROM_PROJECT}_${ActionType.Pending}`:
            return _handleRemoveWorkingGroupMemberPendingAction(currentState, action);

        case `${ADD_EXTERNAL_PARTY_MEMBER_TO_PROJECT}_${ActionType.Pending}`:
            return _handleAddExternalPartyMemberToProjectPendingAction(currentState, action);

        case `${REMOVE_EXTERNAL_PARTY_MEMBER_FROM_PROJECT}_${ActionType.Pending}`:
            return _handleRemoveExternalPartyMemberPendingAction(currentState, action);

        case `${PROJECT_POST_COMMENT}_${ActionType.Pending}`:
            return _handleProjectPostCommentPendingAction(currentState, action);

        case `${PROJECT_REACT_ON_COMMENT}_${ActionType.Pending}`:
            return _handleProjectReactOnCommentPendingAction(currentState, action);

        case CLEAR_PROJECT_DETAILS:
            return RESET_STATE;

        case `${PUBLISH_PROJECT}_${ActionType.Fulfilled}`:
        case `${PUBLISH_PROJECT}_${ActionType.Rejected}`:
            // force new retrieval of project details with closed state, that makes sure it is no longer accessible
            return RESET_STATE;

        case `${PROJECT_UPDATE_PATIENT_INTRODUCTION}_${ActionType.Pending}`:
            return _handleProjectUpdatePatientInformationPendingAction(currentState, action);

        default:
            return currentState;
    }
}
