import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';

export const createUuid = () => uuidv4();

// Beware! Keep inline with backend setup!
export const generateCompositeKey = (...parts: Array<string | null>) => {
    const normalizedParts = parts.map((part) => part || '0');

    normalizedParts.sort();

    return md5(normalizedParts.join('\0'));
};
