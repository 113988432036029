import ProjectDetail from '../ProjectDetail';
import { createUuid } from '../../../utility/idGenerator';
import { createProjectDetailChapterFromApiInput } from './projectDetailChapterFactory';
import WorkingGroupMember from '../WorkingGroupMember';
import ExternalPartyMember from '../ExternalPartyMember';
import SoundingBoardMember from '../SoundingBoardMember';
import { createProjectDetailLinkCollectionFromApiInput } from './projectDetailLinkFactory';
import { createProjectSideProductFromApiInput } from './projectSideProductFactory';

function _createWorkingGroupMemberFromApiInput(apiInput: any): WorkingGroupMember {
    return new WorkingGroupMember(createUuid(), apiInput.user_id, apiInput.name, apiInput.branch);
}

export function createWorkingGroupMemberFromPendingAddActionPayload(payload: any): WorkingGroupMember {
    return new WorkingGroupMember(createUuid(), payload.externalUserId, payload.name, payload.branch);
}

function _createSoundingBoardMemberFromApiInput(apiInput: any): SoundingBoardMember {
    return new SoundingBoardMember(createUuid(), apiInput.user_id, apiInput.name, apiInput.branch);
}

function _createExternalPartyMemberFromApiInput(apiInput: any): ExternalPartyMember {
    return new ExternalPartyMember(
        createUuid(),
        apiInput.user_id,
        apiInput.name,
        apiInput.branch,
        apiInput.feedback_status,
        apiInput.authorization,
        apiInput.authorization_remark,
        apiInput.authorized_at
    );
}

export function createExternalPartyMemberFromPendingAddActionPayload(payload: any): ExternalPartyMember {
    return new ExternalPartyMember(
        createUuid(),
        payload.externalUserId,
        payload.name,
        payload.branch,
        payload.feedbackStatus,
        payload.authorization,
        payload.authorizationRemark,
        payload.authorizedAt
    );
}

export function createProjectDetailFromApiInput(apiInput: any): ProjectDetail {
    return new ProjectDetail(
        createUuid(),
        apiInput.id,
        apiInput.standard_id,
        apiInput.title,
        apiInput.short_title,
        apiInput.type,
        apiInput.chapters.map((chapterInput: any) => createProjectDetailChapterFromApiInput(chapterInput)),
        apiInput._type_slug,
        apiInput._slug,
        apiInput.authorizedAt,
        apiInput.side_products.map((product: any) => createProjectSideProductFromApiInput(product)),
        createProjectDetailLinkCollectionFromApiInput(apiInput.links),
        apiInput.created_at,
        apiInput.revision,
        apiInput.is_closed,
        apiInput.working_group.map((member: any) => _createWorkingGroupMemberFromApiInput(member)),
        apiInput.external_party_members.map((member: any) => _createExternalPartyMemberFromApiInput(member)),
        apiInput.sounding_board_members.map((member: any) => _createSoundingBoardMemberFromApiInput(member)),
        apiInput.reviewed_at,
        apiInput.consultation_phase_started_at,
        apiInput.consultation_phase_ended_at,
        apiInput.is_in_consultation_phase,
        apiInput.pdf_file,
        apiInput.patient_introduction,
        apiInput.patient_introduction_content_type,
        apiInput.parties_authorizing,
        apiInput.parties_not_authorizing,
        apiInput.parties_not_objecting,
        apiInput.previous_publication_project_id,
        apiInput.article_count,
        apiInput.tags,
        apiInput.status,
        apiInput.recommended_standards,
        apiInput.working_group_member_allowed_write,
        apiInput.template_id
    );
}
