import Heading from '../../../../shared/heading/Heading';
import styles from './DirectiveOverviewList.module.scss';
import DirectiveOverviewListItem from './DirectiveOverviewListItem';
import React, { useEffect, useState } from 'react';
import useFetchStandardOverviewItems from '../../../../../hooks/useFetchStandardOverviewItems';
import DirectiveOverviewList from './DirectiveOverviewList';
import { skilzProjectOwnerTypes } from '../../../../../constants/types';
import FormikCheckbox from '../../../../shared/form/formikCheckbox/FormikCheckbox';
import { Formik, FormikProps } from 'formik';
import useFetchProjectOverviewPreviews from '../../../../../hooks/useFetchProjectOverviewPreviews';
import { formatTriggerDateTime } from '../../../../dashboard/eventHistoryOverview/utility/dateFormatter';
import { SkilzProjectStatusType, skilzProjectStatusTypeLabels } from '../../../../../model/SkilzProjectStatusType';

const filterTypeLabels = {
    ...skilzProjectOwnerTypes,
    inDevelopment: 'In ontwikkeling',
};

const DirectiveOverview = () => {
    const { items: publishedDirectives } = useFetchStandardOverviewItems();
    const { previewItems: directivesInProgress } = useFetchProjectOverviewPreviews();
    const initialValues: { [key: string]: boolean } = {};

    Object.keys(skilzProjectOwnerTypes).forEach((type) => {
        initialValues[type as string] = true;
    });

    initialValues.inDevelopment = true;

    const [appliedFilters, setAppliedFilters] = useState(initialValues);

    if (!publishedDirectives) {
        return null;
    }

    return (
        <>
            <Heading tag="h2" variant="h2" style="primary">
                Richtlijnen
            </Heading>
            <p className={styles.description}>
                {publishedDirectives.length !== 0
                    ? 'Hieronder vind je richtlijnen en handreikingen voor de langdurige zorg ontwikkeld vanuit vier organisaties: Stichting Kwaliteitsimpuls Langdurige Zorg (SKILZ), Samenwerkingsverband Infectiepreventie Richtlijnen (SRI), Nederlandse Vereniging Artsen Verstandelijk Gehandicapten (NVAVG) en Associatie van Academische Werkplaatsen Verstandelijke Beperkingen (AAW VB). Ook vind je hieronder de richtlijnen die nog in ontwikkeling zijn. Je kan de knoppen hieronder gebruiken om deze lijst te filteren.'
                    : 'Er zijn nog geen richtlijnen gepubliceerd'}
            </p>
            <Formik initialValues={initialValues} onSubmit={(values) => setAppliedFilters(values)}>
                {(formikProps) => (
                    <>
                        <OnFilterChangeListener formikProps={formikProps} />
                        <form onSubmit={formikProps.handleSubmit}>
                            <div className={styles.checkboxGroup}>
                                {Object.keys(appliedFilters).map((key) => (
                                    <div className={styles.toggleCheckboxWrapper} key={key}>
                                        <FormikCheckbox
                                            key={key}
                                            name={key}
                                            id={key}
                                            label={filterTypeLabels[key as keyof typeof filterTypeLabels]}
                                            isToggle
                                        />
                                    </div>
                                ))}
                            </div>
                        </form>
                    </>
                )}
            </Formik>
            <DirectiveOverviewList>
                {publishedDirectives
                    .filter(item => appliedFilters[item.owner])
                    .map((item) => {
                        return (
                            <DirectiveOverviewListItem
                                key={item.externalId}
                                title={item.shortTitle ?? item.title}
                                link={item.path}
                                subProperties={[
                                    skilzProjectOwnerTypes[item.owner],
                                    `Geautoriseerd op ${formatTriggerDateTime(item.authorizedAt || '')}`,
                                ]}
                            />
                        );
                    })
                }
                {directivesInProgress
                    ?.filter(item => appliedFilters[item.template_id] && appliedFilters.inDevelopment)
                    .map((item) => {
                        return (
                            <DirectiveOverviewListItem
                                key={item.id}
                                title={item.short_title ?? item.title}
                                subProperties={[
                                    skilzProjectOwnerTypes[item.template_id],
                                    'In ontwikkeling',
                                    skilzProjectStatusTypeLabels[item.status as SkilzProjectStatusType] || '',
                                ]}
                            />
                        );
                    })
                }
            </DirectiveOverviewList>
        </>
    );
};

type OnFilterChangeListenerProps = {
    formikProps: FormikProps<any>;
};

const OnFilterChangeListener = ({ formikProps }: OnFilterChangeListenerProps) => {
    const { initialValues, values, submitForm, handleChange } = formikProps;
    const [lastValues, updateState] = useState(values);

    useEffect(() => {
        const valuesEqualLastValues = lastValues === values;
        const valuesEqualInitialValues = values === initialValues;

        if (!valuesEqualLastValues) {
            updateState(values);
        }

        if (!valuesEqualLastValues && !valuesEqualInitialValues) {
            submitForm();
        }
    }, [lastValues, values, initialValues, handleChange]);

    return null;
};

export default DirectiveOverview;
