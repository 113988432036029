import BookmarkTag from './BookmarkTag';
import BookmarkSimple from './BookmarkSimple';
import type { Cloneable } from './contracts';

export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';

export const userRoles: {
    [x: string]: string;
} = {
    ROLE_SUPER_ADMIN: 'Administrator',
    ROLE_ADMIN: 'Beheerder',
    ROLE_USER: 'Gebruiker',
};

// Beware! Keep inline with 'UserPlatformRole'
export enum UserPlatformRole {
    SuperAdmin = 'super_admin',
    Admin = 'admin',
    WorkingGroupMember = 'working_group_member',
    ExternalPartyMember = 'external_party_member',
    SoundingBoardMember = 'sounding_board_member',
    User = 'user',
}

export default class User implements Cloneable<User> {
    _externalId: string;
    _email: string;
    _roles: Array<string>;
    _firstName: string;
    _preposition: string | undefined | null;
    _lastName: string;
    _profession: string | undefined | null;
    _bookmarks: Array<BookmarkSimple>;
    _bookmarkTags: Array<BookmarkTag>;
    _platformRoles: UserPlatformRole[];
    _lastLoginAt: string;

    constructor(
        externalId: string,
        email: string,
        roles: Array<string>,
        firstName: string,
        preposition: string | undefined | null,
        lastName: string,
        profession: string | undefined | null,
        bookmarks: Array<BookmarkSimple>,
        bookmarkTags: Array<BookmarkTag>,
        platformRoles: UserPlatformRole[],
        lastLoginAt: string
    ) {
        this._externalId = externalId;
        this._email = email;
        this._roles = roles;
        this._firstName = firstName;
        this._preposition = preposition;
        this._lastName = lastName;
        this._profession = profession;
        this._bookmarks = bookmarks;
        this._bookmarkTags = bookmarkTags;
        this._platformRoles = platformRoles;
        this._lastLoginAt = lastLoginAt;
    }

    get externalId(): string {
        return this._externalId;
    }

    get email(): string {
        return this._email;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(firstName: string) {
        this._firstName = firstName;
    }

    get preposition(): string | undefined | null {
        return this._preposition;
    }

    set preposition(preposition: string | undefined | null) {
        this._preposition = preposition;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(lastName: string) {
        this._lastName = lastName;
    }

    get fullName(): string {
        return this._firstName + (this._preposition ? ' ' + this._preposition : '') + ' ' + this._lastName;
    }

    get lastLoginAt() {
        return this._lastLoginAt;
    }

    get roles(): Array<string> {
        return this._roles;
    }

    get profession(): string | undefined | null {
        return this._profession;
    }

    set profession(profession: string | undefined | null) {
        this._profession = profession;
    }

    get bookmarks(): Array<BookmarkSimple> {
        return this._bookmarks;
    }

    set bookmarks(bookmarks: Array<BookmarkSimple>) {
        this._bookmarks = bookmarks;
    }

    get bookmarkTags(): Array<BookmarkTag> {
        return this._bookmarkTags;
    }

    addBookmarkTag(bookmarkTag: BookmarkTag): void {
        this._bookmarkTags.push(bookmarkTag);
    }

    findBookmarkTag(id: string): BookmarkTag | undefined | null {
        return this._bookmarkTags.find((bookmarkTag) => bookmarkTag.id === id);
    }

    findBookmarkTagWithExternalId(externalId: number): BookmarkTag | undefined | null {
        return this._bookmarkTags.find((bookmarkTag) => bookmarkTag.externalId === externalId);
    }

    hasPlatformRole(platformRole: UserPlatformRole) {
        return this._platformRoles.includes(platformRole);
    }

    hasOneOfPlatformRoles(...platformRoles: UserPlatformRole[]) {
        for (let i = 0, l = platformRoles.length; i < l; i++) {
            if (this._platformRoles.includes(platformRoles[i])) {
                return true;
            }
        }

        return false;
    }

    hasRole(role: string) {
        return this._roles.indexOf(role) > -1; // eslint-disable-line no-magic-numbers
    }

    hasRoles(roles: Array<string>): boolean {
        for (let i = 0, l = roles.length; i < l; i++) {
            const role = roles[i];

            if (!this.hasRole(role)) {
                return false;
            }
        }

        return true;
    }

    hasOneOfRoles(roles: Array<string>): boolean {
        for (let i = 0, l = roles.length; i < l; i++) {
            const role = roles[i];

            if (this.hasRole(role)) {
                return true;
            }
        }

        return false;
    }

    hasSuperAdminRole(): boolean {
        return this.hasRole(ROLE_SUPER_ADMIN) || this.hasRole(ROLE_ADMIN);
    }

    hasBookmark(bookmarkedId: string): boolean {
        return this._bookmarks.filter((bookmark) => bookmark.bookmarkedId === bookmarkedId).length > 0;
    }

    findBookmark(bookmarkedId: string): BookmarkSimple | undefined | null {
        return this._bookmarks.find((bookmark) => bookmark.bookmarkedId === bookmarkedId);
    }

    removeBookmark(externalBookmarkId: number): void {
        this._bookmarks = this._bookmarks.filter((bookmark) => bookmark.externalId !== externalBookmarkId);
    }

    removeBookmarkTag(externalId: number): void {
        this._bookmarkTags = this._bookmarkTags.filter((bookmarkTag) => bookmarkTag.externalId !== externalId);
    }

    removeBookmarkTagWithId(id: string): void {
        this._bookmarkTags = this._bookmarkTags.filter((bookmarkTag) => bookmarkTag.id !== id);
    }

    clone(): User {
        return new User(
            this._externalId,
            this._email,
            this._roles,
            this._firstName,
            this._preposition,
            this._lastName,
            this._profession,
            this._bookmarks.map((bookmark) => bookmark.clone()),
            this._bookmarkTags.map((bookmarkTag) => bookmarkTag.clone()),
            [...this._platformRoles],
            this._lastLoginAt
        );
    }
}

export type UserSummary = {
    id: string;
    name: {
        firstName: string;
        preposition: string | null;
        lastName: string;
    };
    profession: string | null;
};
