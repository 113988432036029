import isPromise from 'is-promise';
import type { Action, Store } from '../actions/factory';
import { extractPath } from '../helper/objectPathHelper';

type Options = {
    excludeActionTypes: string[];
};

export type ErrorHandler = (error: Error, store: Store, action: Action) => void;

const DEFAULT_OPTIONS = { excludeActionTypes: [] };

export default function errorCatchingMiddleware(handler: ErrorHandler, options: Options = DEFAULT_OPTIONS) {
    return (store: Store) => (next: (a: Action) => any) => (action: Action) => {
        const actionIsExcluded: boolean = options.excludeActionTypes.includes(action.type); // eslint-disable-line no-magic-numbers

        if (actionIsExcluded) {
            return next(action);
        }

        const promise: any = extractPath('payload.promise', action);

        if (promise && isPromise(promise)) {
            return next(action).catch((error: Error) => {
                handler(error, store, action);

                return error;
            });
        }

        try {
            return next(action);
        } catch (error: any) {
            handler(error, store, action);
        }
    };
}
