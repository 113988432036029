import React from 'react';
import { NavLink } from 'react-router-dom';
import { createSideProductsPagePath, createStandardsOverviewPagePath } from '../../../../routing/urlGenerator';
import ListIcon from '../../../../../../icons/ggz/list.svg';
import SideProductsIcon from '../../../../../../icons/ggz/side-products.svg';
import ForTeachersIcon from '../../../../../../icons/ggz/for-teachers.svg';
import { createForTeachersPagePath } from '../../../../routing/ggzUrlGenerator';

const BottomMenu = () => (
    <nav className="header__bottom-menu">
        <NavLink
            to={createStandardsOverviewPagePath}
            activeClassName="is-active"
            className="link"
            data-track-id="bottom_menu_link_click"
        >
            <ListIcon className="link__icon" />
            <span>Zorgstandaarden A-Z</span>
        </NavLink>
        <NavLink to={createSideProductsPagePath} activeClassName="is-active" data-track-id="bottom_menu_link_click">
            <SideProductsIcon className="link__icon" />
            <span>Hulpmiddelen</span>
        </NavLink>
        <NavLink to={createForTeachersPagePath} activeClassName="is-active" data-track-id="bottom_menu_link_click">
            <ForTeachersIcon className="link__icon" />
            <span>Voor docenten</span>
        </NavLink>
    </nav>
);

export default BottomMenu;
