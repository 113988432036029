import React, { FC } from 'react';
import { BRANCH_ACCESS } from '../../../security/SecurityAttribute';
import SecurityThreshold from '../../shared/security/SecurityThreshold';
import useShowHideModal from '../../../hooks/useShowHideModal';
import Modal from '../../shared/modal/Modal';
import Button, { PRIMARY } from '../../shared/buttons/Button';
import CreateBranchForm from './components/CreateBranchForm';
import usePersistBranchOnValidSubmit from './hooks/usePersistBranchOnValidSubmit';
import useCurrentTenant from '../../../hooks/useCurrentTenant';

const CreateBranch: FC = () => {
    const { isGGZ, isSKILZ } = useCurrentTenant();
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const onValidSubmit = usePersistBranchOnValidSubmit(doHideModal);

    return (
        <>
            <SecurityThreshold requiredAttributes={BRANCH_ACCESS}>
                <Button onClick={doShowModal} style={PRIMARY} icon="plus" isSmall>
                    {isGGZ && 'Partij aanmaken'}
                    {isSKILZ && 'Organisatie aanmaken'}
                </Button>
            </SecurityThreshold>
            {showModal && (
                <Modal title={isGGZ ? 'Partij aanmaken' : 'Organisatie aanmaken'} onDecline={doHideModal}>
                    <CreateBranchForm onValidSubmit={onValidSubmit} />
                </Modal>
            )}
        </>
    );
};

export default CreateBranch;
