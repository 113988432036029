import React, { MouseEventHandler } from 'react';
import ProjectSearchItem, { ProjectSearchItemType } from '../../../../../model/ProjectSearchItem';
import parse from 'html-react-parser';
import TruncateMarkup from 'react-truncate-markup';
import useNavigateToResultAfterSearchResultIsSaved from '../../../shared/searchResults/hooks/useNavigateToResultAfterSearchResultIsSaved';
import useSaveClickedResultForFutureReference from '../../../shared/searchResults/hooks/useSaveClickedResultForFutureReference';
import Breadcrumb from '../../../../shared/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../shared/breadcrumb/components/BreadcrumbItem';
import { createBreadcrumbItemWithTitleOnly } from '../../../../../model/factory/breadcrumbItemFactory';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';
import Heading from '../../../../shared/heading/Heading';

export type OnClickCallback = (result: ProjectSearchItem) => void;

type Props = {
    result: ProjectSearchItem;
    breadcrumbItems?: Array<string>;
};

const searchItemTypesWithIndex = [
    ProjectSearchItemType.Project,
    ProjectSearchItemType.Chapter,
    ProjectSearchItemType.Paragraph,
    ProjectSearchItemType.SubParagraph,
];

const SearchResult = ({ result, breadcrumbItems = [] }: Props) => {
    const { onLastSearchResultSaved } = useNavigateToResultAfterSearchResultIsSaved();

    const { onResultClick } = useSaveClickedResultForFutureReference(onLastSearchResultSaved);

    const onClick: MouseEventHandler = (event) => {
        event.preventDefault();

        onResultClick(result);
    };

    const titlePrefix = searchItemTypesWithIndex.includes(result.type)
        ? `${generateSectionNumbering(...result.indexes)}.`
        : '';

    return (
        <article className="search-result">
            <a href={result.path} onClick={onClick} className="search-result__anchor">
                <Heading tag={'h3'} variant={'h4'} className="search-result__title">
                    {titlePrefix} {parse(result.title)}
                </Heading>
                {breadcrumbItems.length > 0 && (
                    <div className="search-result__breadcrumb-list">
                        <Breadcrumb small={true}>
                            {breadcrumbItems
                                .map((title) => createBreadcrumbItemWithTitleOnly(title))
                                .map((item) => (
                                    <BreadcrumbItem key={item.id} item={item} />
                                ))}
                        </Breadcrumb>
                    </div>
                )}
                {result.description && (
                    <TruncateMarkup lines={3}>
                        <p className="search-result__description">{parse(result.description)}</p>
                    </TruncateMarkup>
                )}
            </a>
        </article>
    );
};

export default SearchResult;
