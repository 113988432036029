import React, { useEffect, useState } from 'react';
import ExpandedContentBlock from '../../../../shared/editorContent/components/ExpandedContentBlock';
import { findReferenceMatchingId } from '../../../../../repository/referenceRepository';
import Reference from '../../../../../model/Reference';
import Loader from '../../../../shared/Loader';
import { logError } from '../../../../../utility/Logger';
import EditButton from './EditButton';
import { useGetProjectEditorSettingsForUser } from '../../../../../hooks/useGetProjectEditorSettingsForUser';

type Props = {
    referenceId: string;
    isEditor?: boolean;
};

const ReferenceComponent = ({ referenceId, isEditor = false }: Props) => {
    const [showContent, setShowContent] = useState(false);
    const [reference, setReference] = useState<Reference | null>(null);
    const editorSettings = useGetProjectEditorSettingsForUser();

    useEffect(() => {
        if (!showContent || reference) return;

        const fetchReference = async () => {
            return await findReferenceMatchingId(parseInt(referenceId));
        };

        fetchReference()
            .then((data) => setReference(data))
            .catch((error) => logError(error));
    }, [showContent]);

    const onLinkClick = (event: any) => {
        event.preventDefault();
        setShowContent(!showContent);
    };

    return (
        <>
            <a href="#" className="reference" onClick={onLinkClick} />
            {showContent && (
                <ExpandedContentBlock
                    title="Referentie"
                    content={reference?.description}
                    onClose={() => setShowContent(false)}
                    actionButtons={
                        !reference?.archived && isEditor && editorSettings?.plugins.references ? (
                            <EditButton externalId={reference?.externalId.toString()} />
                        ) : null
                    }
                    warning={
                        reference?.archived && isEditor
                            ? 'Deze referentie is gearchiveerd en kan niet meer worden bewerkt'
                            : ''
                    }
                >
                    {reference ? null : <Loader sizeSmall />}
                </ExpandedContentBlock>
            )}
        </>
    );
};

export default ReferenceComponent;
