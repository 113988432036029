import { createInMemoryCache } from '@freshheads/javascript-essentials/build/cache/factory/inMemoryCacheFactory';
import { ProjectPreview } from '../model/projectOverview/ProjectPreview';
import { get } from '../api/client/apiClient';
import { createFetchProjectOverviewPreviews } from '../routing/apiUrlGenerator';

const MAX_CACHE_LIFETIME = 300; // 5 minutes

const cache = createInMemoryCache<Array<ProjectPreview>>('project_preview_list', MAX_CACHE_LIFETIME);

export const fetchProjectOverviewPreviews = async (): Promise<Array<ProjectPreview>> =>
    await cache.getOrCreate('items', async () => {
        const response = await get(createFetchProjectOverviewPreviews());

        return await response.data;
    });
