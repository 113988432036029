const ENVIRONMENT_DEVELOPMENT = 'dev';
const ENVIRONMENT_PRODUCTION = 'production';
const ENVIRONMENT_STAGING = 'staging';
const ENVIRONMENT_TEST = 'test';

const CUSTOMER_ENVIRONMENTS: Array<string> = [ENVIRONMENT_STAGING, ENVIRONMENT_PRODUCTION];

const INTERNAL_ENVIRONMENTS: Array<string> = [ENVIRONMENT_DEVELOPMENT, ENVIRONMENT_TEST];

// $ExpectError -> FLow doesn\'t know this environment variable exists
const CURRENT_ENVIRONMENT: string = process.env.NODE_ENV || 'production';

export function isDevelopmentEnvironment(): boolean {
    return CURRENT_ENVIRONMENT === ENVIRONMENT_DEVELOPMENT;
}

export function isProductionEnvironment(): boolean {
    return CURRENT_ENVIRONMENT === ENVIRONMENT_PRODUCTION;
}

export function isCustomerEnvironment(): boolean {
    return CUSTOMER_ENVIRONMENTS.indexOf(CURRENT_ENVIRONMENT) !== -1; // eslint-disable-line no-magic-numbers
}

export function isInternalEnvironment(): boolean {
    return INTERNAL_ENVIRONMENTS.indexOf(CURRENT_ENVIRONMENT) !== -1; // eslint-disable-line no-magic-numbers
}
