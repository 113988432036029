import React, { VFC } from 'react';
import FormGroup from '../../../shared/form/FormGroup';
import FormErrorMessage from '../../../shared/form/FormErrorMessage';
import { userRoles } from '../../../../model/User';
import useHandleCreateUserFormState, { OnValidSubmitHandler } from '../hooks/useHandleCreateUserFormState';

type Props = {
    onValidSubmit: OnValidSubmitHandler;
};

const CreateUserForm: VFC<Props> = ({ onValidSubmit }) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange, isSubmitting } =
        useHandleCreateUserFormState(onValidSubmit);

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <label className="form__label">Naam</label>
            <div className="layout">
                <div className="layout__item layout__item--xs-5">
                    <FormGroup hasErrors={touched.firstName && !!errors.firstName}>
                        <input
                            type="text"
                            value={values.firstName}
                            name="firstName"
                            placeholder="Voornaam"
                            className="form__field"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                        />
                        {touched.firstName && errors.firstName && (
                            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                        )}
                    </FormGroup>
                </div>
                <div className="layout__item layout__item--xs-2 layout__item--skinny-sm">
                    <FormGroup hasErrors={touched.preposition && !!errors.preposition}>
                        <input
                            type="text"
                            value={values.preposition}
                            name="preposition"
                            placeholder="t.v."
                            className="form__field"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                        />
                        {touched.preposition && errors.preposition && (
                            <FormErrorMessage>{errors.preposition}</FormErrorMessage>
                        )}
                    </FormGroup>
                </div>
                <div className="layout__item layout__item--xs-5">
                    <FormGroup hasErrors={touched.lastName && !!errors.lastName}>
                        <input
                            type="text"
                            value={values.lastName}
                            name="lastName"
                            placeholder="Achternaam"
                            className="form__field"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                        />
                        {touched.lastName && errors.lastName && <FormErrorMessage>{errors.lastName}</FormErrorMessage>}
                    </FormGroup>
                </div>
            </div>
            <FormGroup hasErrors={touched.profession && !!errors.profession}>
                <label htmlFor="profession">Functie</label>
                <input
                    type="text"
                    value={values.profession}
                    name="profession"
                    className="form__field"
                    onChange={handleChange}
                    id="profession"
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                />
                {touched.profession && errors.profession && <FormErrorMessage>{errors.profession}</FormErrorMessage>}
            </FormGroup>
            <FormGroup hasErrors={touched.email && !!errors.email}>
                <label htmlFor="email">E-mail</label>
                <input
                    type="email"
                    value={values.email}
                    name="email"
                    className="form__field"
                    onChange={handleChange}
                    id="email"
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                />
                {touched.email && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
            </FormGroup>
            <FormGroup hasErrors={touched.globalRole && !!errors.globalRole}>
                <label htmlFor="globalRole">Rol</label>
                <select
                    value={values.globalRole}
                    id="globalRole"
                    name="globalRole"
                    className="form-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                >
                    {Object.keys(userRoles).map((roleKey) => {
                        return (
                            <option value={roleKey} key={roleKey}>
                                {userRoles[roleKey]}
                            </option>
                        );
                    })}
                </select>
                {touched.globalRole && errors.globalRole && <FormErrorMessage>{errors.globalRole}</FormErrorMessage>}
            </FormGroup>
            <FormGroup hasErrors={touched.personalMessage && !!errors.personalMessage}>
                <label htmlFor="personalMessage">Persoonlijk bericht</label>
                <textarea
                    value={values.personalMessage}
                    name="personalMessage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="personalMessage"
                    className="form__field"
                    disabled={isSubmitting}
                />
                {touched.personalMessage && errors.personalMessage && (
                    <FormErrorMessage>{errors.personalMessage}</FormErrorMessage>
                )}
            </FormGroup>

            <button type="submit" className="button button--primary" disabled={isSubmitting}>
                Aanmaken
            </button>
        </form>
    );
};

export default CreateUserForm;
