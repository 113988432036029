import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import createClassName from 'classnames';

type Props = {
    heading: string;
    headingIcon?: string;
    children?: ReactNode;
};

const DashboardIslandHeader: React.FC<Props> = ({ heading, headingIcon, children }) => {
    const className = createClassName('dashboard__island-heading', headingIcon);

    return (
        <header className="dashboard__island-header">
            <h1 className={className}>{heading}</h1>
            {children}
        </header>
    );
};

DashboardIslandHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    headingIcon: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DashboardIslandHeader;
