// KEEP IN LINE WITH BACKEND GGZ\Application\Security\Resolver\SecuritySubjectResolver

export enum SecuritySubject {
    standard = 'standard',
    project = 'project',
    chapter = 'chapter',
    paragraph = 'paragraph',
    subParagraph = 'sub_paragraph',
    branch = 'branch',
    page = 'page',
    faqItem = 'faq_item',
}
