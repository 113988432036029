import * as React from 'react';
import Modal, { ModalSize } from './Modal';

type Props = {
    isOpen?: boolean;
    title: string;
    onConfirm: Function;
    onDecline: Function;
    confirmButtonText?: string;
    declineButtonText?: string;
    children?: React.ReactNode;
};

const ConfirmationModal = ({ isOpen = true, onDecline, title, children, onConfirm, confirmButtonText = 'Ja', declineButtonText = 'Nee' }: Props) => {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal onDecline={() => onDecline()} title={title} modalSize={ModalSize.Small}>
            {children}
            <button className="button button--primary" onClick={() => onConfirm()}>{confirmButtonText}</button>
            <button className="button button--default" onClick={() => onDecline()}>{declineButtonText}</button>
        </Modal>
    );
};

export default ConfirmationModal;
