import { useState, useEffect, DependencyList } from 'react';

export default function useDisplayAfterTimeout(length: number, deps: DependencyList = []) {
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const handle = setTimeout(() => setVisible(true), length);

        return () => {
            clearTimeout(handle);
        };
    }, deps);

    return { visible };
}
