import { extractPathWithPlaceholdersFromRoute } from './utilities/pathWithPlaceholdersExtractor';
import {
    CONTACT_PAGE_SLUG,
    DISCLAIMER_PAGE_SLUG,
    EXPLAIN_STANDARD_TYPES_SLUG,
    PRIVACY_COOKIES_PAGE_SLUG,
} from '../constants/pageSlugs';
import router from './router';
import { createQueryString } from './utilities/queryStringGenerator';
import { isString } from 'lodash';

export function generatePath(path: string, params: any = {}): string {
    return router.generate(path, params);
}

export function createFrontendEntryPath(): string {
    return '/';
}

export function createForgotPasswordPath(): string {
    return generatePath('frontend_forgot_password');
}

export function createResetPasswordPath(token: string | undefined | null = null): string {
    return generatePath('reset_password', {
        token: token,
    });
}

export function createProjectDetailPath(
    type: string | undefined | null = null,
    slug: string | undefined | null = null
): string {
    if (type && slug) {
        return generatePath('frontend_project_detail', { type, slug });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_project_detail');
}

export function createProjectDetailTabPath(
    type: string | undefined | null = null,
    slug: string | undefined | null = null,
    tab: string | undefined | null = null,
    queryParams?: {}
): string {
    if (type && slug && tab) {
        const queryString = queryParams ? createQueryString(queryParams) : '';

        return generatePath('frontend_project_detail_tab', { type, slug, tab }) + queryString;
    }

    return extractPathWithPlaceholdersFromRoute('frontend_project_detail_tab');
}

export function createProjectSearchPath(
    type: string | undefined | null = null,
    slug: string | undefined | null = null,
    query: string | undefined | null = null,
    page: number | undefined | null = null
): string {
    if (type && slug && query) {
        return generatePath('frontend_search_within_project_detail', {
            type,
            slug,
            query,
            page,
        });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_search_within_project_detail');
}

export function createSearchPath(query: string | undefined | null = null, page = 1): string {
    if (isString(query)) {
        return generatePath('frontend_search_detail', {
            query,
            page,
        });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_search_detail');
}

export function createChapterDetailPath(
    type: string | undefined | null = null,
    projectSlug: string | undefined | null = null,
    chapterSlug: string | undefined | null = null
): string {
    if (type && projectSlug && chapterSlug) {
        return generatePath('frontend_chapter_detail', {
            type,
            projectSlug,
            chapterSlug,
        });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_chapter_detail');
}

export function createParagraphDetailPath(
    type: string | null = null,
    projectSlug: string | null = null,
    chapterSlug: string | null = null,
    paragraphSlug: string | null = null
): string {
    if (type && projectSlug && chapterSlug && paragraphSlug) {
        return generatePath('frontend_paragraph_detail', {
            type,
            projectSlug,
            chapterSlug,
            paragraphSlug,
        });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_paragraph_detail');
}

export function createSubParagraphDetailPath(
    type: string | null = null,
    projectSlug: string | null = null,
    chapterSlug: string | null = null,
    paragraphSlug: string | null = null,
    subParagraphSlug: string | null = null
): string {
    if (type && projectSlug && chapterSlug && paragraphSlug && subParagraphSlug) {
        return generatePath('frontend_subparagraph_detail', {
            type,
            projectSlug,
            chapterSlug,
            paragraphSlug,
            subParagraphSlug,
        });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_subparagraph_detail');
}

export function createArticleOverviewPath(page?: number) {
    if (page) {
        return generatePath('frontend_article_overview', { page });
    }

    return generatePath('frontend_article_overview');
}

export function createArticleDetailPath(id?: number | null, slug?: string | null): string {
    if (id && slug) {
        return generatePath('frontend_article_detail', { id, slug });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_article_detail');
}

export function createPageDetailPath(slug?: string) {
    if (slug) {
        return generatePath('frontend_page_detail', { slug });
    }

    return extractPathWithPlaceholdersFromRoute('frontend_page_detail');
}

export function createLoginPath(targetPath: string | undefined | null = null, showDefaultErrorMessage = false): string {
    return generatePath('login', {
        // eslint-disable-next-line camelcase
        _target_path: targetPath,
        // eslint-disable-next-line camelcase
        show_default_error_message: showDefaultErrorMessage,
    });
}

export function createLogoutPath(): string {
    return generatePath('logout');
}

export function createPagePath(slug: string): string {
    return generatePath('frontend_page', { slug });
}

export function createSideProductsOverviewPath(slug: string): string {
    return generatePath('frontend_side_products_overview', { slug });
}

// path is only used to redirect to overview page
export function createStandardsPagePath(): string {
    return generatePath('frontend_standards');
}

export function createStandardsOverviewPagePath(): string {
    return generatePath('frontend_standards_overview');
}

export function createSideProductsPagePath(): string {
    return generatePath('frontend_side_products');
}

export function createContactPagePath(): string {
    return createPagePath(CONTACT_PAGE_SLUG);
}

export function createExplainStandardTypesPagePath(): string {
    return createPagePath(EXPLAIN_STANDARD_TYPES_SLUG);
}

export function createDisclaimerPagePath(): string {
    return createPagePath(DISCLAIMER_PAGE_SLUG);
}

export function createPrivacyAndCookiesPagePath(): string {
    return createPagePath(PRIVACY_COOKIES_PAGE_SLUG);
}

export function createUserRegistrationPath(): string {
    return generatePath('register');
}
