import React, { ReactNode } from 'react';
import ListHeading from '../../shared/list/component/ListHeading';
import Heading from '../../../shared/heading/Heading';
import { SideProductCategoryDetails } from '../../../../model/sideProductOverview/SideProductOverview';
import ListAction from '../../shared/list/component/ListAction';
import ListItem from '../../shared/list/component/ListItem';

type Props = {
    category: SideProductCategoryDetails;
    children: ReactNode;
};

const CategoryListOverviewItem = ({ category, children }: Props) => (
    <ListItem>
        <ListHeading>
            <Heading tag="h2">{category.title}</Heading>
        </ListHeading>
        <ListAction>{children}</ListAction>
    </ListItem>
);

export default CategoryListOverviewItem;
