import ProjectDetailChapter from '../../../model/projectDetail/ProjectDetailChapter';

export function createAddChapterToProjectConstraintSet(): any {
    return {
        title: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}

export function createAddParagraphConstraintSet(): any {
    return {
        title: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}

export function createAddProjectMemberFormConstraintSet(): any {
    return {
        user: {
            presence: {
                allowEmpty: false,
            },
        },
        branch: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}

export function createPublishProjectConstraintSet(
    chapters: Array<ProjectDetailChapter>,
    generateChapterAuthorizedAtKey: Function,
    generateChapterReviewedAtKey: Function
): any {
    const publishProjectChaptersConstraintSet = chapters.reduce(
        (accumulator, currentChapter) => ({
            ...accumulator,
            [generateChapterAuthorizedAtKey(currentChapter)]: {
                presence: {
                    allowEmpty: false,
                },
            },
            [generateChapterReviewedAtKey(currentChapter)]: {
                presence: {
                    allowEmpty: false,
                },
            },
        }),
        {}
    );

    const publishProjectConstraintSet = {
        authorizedAt: {
            presence: {
                allowEmpty: false,
            },
        },
        reviewedAt: {
            presence: {
                allowEmpty: false,
            },
        },
    };

    const authorizingPartiesConstraintSet = {
        partiesAuthorizing: {
            presence: {
                allowEmpty: false,
            },
            editorStateIsNotEmpty: true,
        },
    };

    return {
        ...publishProjectConstraintSet,
        // $ExpectError
        ...publishProjectChaptersConstraintSet,
        ...authorizingPartiesConstraintSet,
    };
}

export function createAskFeedbackFromWorkingGroupMembersFormConstraintSet(): any {
    return {
        text: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}

export function createAnonymousFeedbackConstraintSet(): any {
    return {
        feedback: {
            presence: {
                allowEmpty: false,
            },
        },
        email: {
            email: {
                message: '^Vul een geldig e-mailadres in',
            },
        },
    };
}

export function createChapterUpdateConstraintSet(): any {
    return {
        title: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}
