import React from 'react';
import useHandleProjectFormEvents, { FormValues } from './hooks/useHandleProjectFormEvents';
import FormGroup from '../../shared/form/FormGroup';
import Button, { PRIMARY, SUBMIT } from '../../shared/buttons/Button';
import FormActions from '../../shared/form/FormActions';
import {
    ProjectTag,
    projectTagsLabels,
    skilzProjectTypesSingular,
    ggzProjectOwnerTypes,
    skilzProjectOwnerTypes, ggzProjectTypesSingularDropdown,
} from '../../../constants/types';
import FormErrorMessage from '../../shared/form/FormErrorMessage';
import Loader from '../../shared/Loader';
import FormText from '../../shared/form/FormText';
import Select from '../../shared/form/select/Select';
import useCurrentTenant from '../../../hooks/useCurrentTenant';

export type OnFormValidHandler = (formValues: FormValues) => Promise<boolean>;

type Props = {
    onFormValid: OnFormValidHandler;
    onDone: () => void;
    initialValues?: FormValues;
};

const ProjectForm: React.FC<Props> = ({ onFormValid, onDone, initialValues }) => {
    const { tenant, isGGZ, isSKILZ } = useCurrentTenant();
    const { handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors, submitPending } =
        useHandleProjectFormEvents(onFormValid, onDone, initialValues, tenant);

    const projectTypeLabels = isGGZ ? ggzProjectTypesSingularDropdown : skilzProjectTypesSingular;
    const projectOwnerTypeLabels = isGGZ ? ggzProjectOwnerTypes : skilzProjectOwnerTypes;

    const isUpdate = !!initialValues;

    if (submitPending) {
        return <Loader />;
    }

    const tagsOptions = Object.keys(projectTagsLabels).map((projectTagsKey) => ({
        value: projectTagsKey,
        label: projectTagsLabels[projectTagsKey as ProjectTag],
    }));

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup hasErrors={!!touched.title && !!errors.title}>
                <label htmlFor="title" className="form__label">
                    Titel
                </label>
                <input
                    id="title"
                    type="text"
                    name="title"
                    className="form__field"
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoFocus={!isUpdate}
                />
            </FormGroup>
            {touched.title && errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}

            <FormGroup hasErrors={!!touched.shortTitle && !!errors.shortTitle}>
                <label htmlFor="shortTitle" className="form__label">
                    Beknopte titel
                </label>
                <input
                    id="shortTitle"
                    type="text"
                    name="shortTitle"
                    className="form__field"
                    value={values.shortTitle}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {isGGZ && (
                    <FormText>
                        Als ingevuld, dan wordt deze beknopte titel gebruikt in het standaarden overzicht op de
                        homepage.
                    </FormText>
                )}
            </FormGroup>
            {touched.shortTitle && errors.shortTitle && <FormErrorMessage>{errors.shortTitle}</FormErrorMessage>}

            {!isUpdate && (
                <FormGroup hasErrors={!!touched.type && !!errors.type}>
                    <label htmlFor="type" className="form__label">
                        Type
                    </label>
                    <select
                        id="type"
                        name="type"
                        className="form-select"
                        value={values.type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        <option key="empty" value="">
                            Selecteer een type
                        </option>

                        {Object.keys(projectTypeLabels).map((key: string) => (
                            <option key={key} value={key}>
                                {projectTypeLabels[key]}
                            </option>
                        ))}
                    </select>
                    {touched.type && errors.type && <FormErrorMessage>{errors.type}</FormErrorMessage>}
                </FormGroup>
            )}

            {isGGZ && (
                <FormGroup hasErrors={!!touched.tags && !!errors.tags}>
                    <label htmlFor="tags" className="form__label">
                        Tags
                    </label>
                    <Select
                        name="tags"
                        id="tags"
                        menuPortalTarget={document.body}
                        value={
                            values.tags
                                ? values.tags.map((value) => ({
                                      value,
                                      label: projectTagsLabels[value as ProjectTag],
                                  }))
                                : []
                        }
                        onChange={(newValue: [{ value: string; label: string }]) => {
                            const selected = (newValue as [{ value: string; label: string }]).map(({ value }) => value);
                            setFieldValue('tags', selected);
                        }}
                        options={tagsOptions}
                        isMulti
                    />
                    {errors.tags && touched.tags && <FormErrorMessage>{errors.tags}</FormErrorMessage>}
                </FormGroup>
            )}

            {!isUpdate && isSKILZ && (
                <FormGroup hasErrors={!!touched.templateId && !!errors.templateId}>
                    <label htmlFor="templateId" className="form__label">
                        Eigenaar
                    </label>
                    <select
                        id="templateId"
                        name="templateId"
                        className="form-select"
                        value={values.templateId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        <option key="empty" value="">
                            Selecteer een eigenaar
                        </option>

                        {Object.keys(projectOwnerTypeLabels).map((key: string) => (
                            <option key={key} value={key}>
                                {projectOwnerTypeLabels[key]}
                            </option>
                        ))}
                    </select>
                    {touched.templateId && errors.templateId && (
                        <FormErrorMessage>{errors.templateId}</FormErrorMessage>
                    )}
                </FormGroup>
            )}

            <FormActions alignRight>
                <Button type={SUBMIT} style={PRIMARY}>
                    {isUpdate ? 'Bewerken' : 'Maak aan'}
                </Button>
            </FormActions>
        </form>
    );
};

export default ProjectForm;
