import React from 'react';
import { Link } from 'react-router-dom';
import { createDashboardArticleCreatePath } from '../../../../routing/dashboardUrlGenerator';

const CreateArticleLink = () => (
    <Link to={createDashboardArticleCreatePath()} className="button button--primary button--small icon-plus">
        Artikel toevoegen
    </Link>
);

export default CreateArticleLink;
