import {
    CHANGE_CURRENT_USER_AUTHENTICATION_STATUS,
    CHECK_IS_GRANTED,
    FETCH_CURRENT_USER,
    FETCH_PROJECT_DETAILS,
    FETCH_PROJECT_OVERVIEW,
    FETCH_PARAGRAPH_DETAILS,
    FETCH_STANDARD_ALL_OVERVIEW,
} from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { extractPath } from '../helper/objectPathHelper';
import { generateSecurityEntryKey } from '../security/helpers/securityHelpers';

import type { SecurityVoteCollection } from '../security/types';
import type { Action } from '../actions/factory';

export type AuthorizationReducerState = SecurityVoteCollection;

const RESET_STATE: SecurityVoteCollection = {};

function _handleIsGrantedFulfilledAction(currentState: SecurityVoteCollection, action: Action): SecurityVoteCollection {
    const { normalizedAttributes, subjectType, subjectId } = action.meta,
        newState: SecurityVoteCollection = { ...currentState },
        key: string = generateSecurityEntryKey(normalizedAttributes, subjectType, subjectId);

    newState[key] = extractPath('data.vote', action.payload, false);

    return newState;
}

export default function authorizationReducer(
    currentState: SecurityVoteCollection = RESET_STATE,
    action: Action
): SecurityVoteCollection {
    switch (action.type) {
        case `${CHECK_IS_GRANTED}_${ActionType.Fulfilled}`:
            return _handleIsGrantedFulfilledAction(currentState, action);

        case `${FETCH_CURRENT_USER}_${ActionType.Fulfilled}`:
        case `${FETCH_PROJECT_DETAILS}_${ActionType.Fulfilled}`:
        case `${FETCH_PROJECT_OVERVIEW}_${ActionType.Fulfilled}`:
        case `${FETCH_PARAGRAPH_DETAILS}_${ActionType.Fulfilled}`:
        case `${FETCH_STANDARD_ALL_OVERVIEW}_${ActionType.Fulfilled}`:
            return {
                ...currentState,
                ...extractPath('payload.data.security_votes', action, []),
            };

        case CHANGE_CURRENT_USER_AUTHENTICATION_STATUS:
            return RESET_STATE;

        default:
            return currentState;
    }
}
