import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const Atomic = (props: Props) => {
    return <div>{props.children}</div>;
};

export default Atomic;
