import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFetchStandardOverviewItemsAction } from '../actions/standardActionFactory';
import type { GlobalState } from '../store/types';
import { StandardOverviewItem } from '../model/standardOverview/StandardOverviewItem';
import useOnMount from './useOnMount';
import { sortByField } from '../helper/sortingHelper';

export default function useFetchStandardOverviewItems(): {
    items: StandardOverviewItem[] | null;
    isLoading: boolean;
} {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useDispatch<Promise<void>>();

    useOnMount(() => {
        setIsLoading(true);

        // @ts-ignore don't know how to fix this...
        dispatch(createFetchStandardOverviewItemsAction()).finally(() => setIsLoading(false));
    });

    const items = useSelector<GlobalState, StandardOverviewItem[] | null>(({ standardOverviewItems }) => {
        if (!standardOverviewItems) {
            return null;
        }

        return sortByField<StandardOverviewItem, string>(standardOverviewItems, 'title', (value) =>
            value.toLowerCase()
        );
    });

    return { items, isLoading };
}
