import React, { VFC } from 'react';
import { Link } from 'react-router-dom';
import { createArticleDetailPath } from '../../../../../routing/urlGenerator';
import { formatLongDay } from '../../../../../helper/dateTimeHelper';
import Heading from '../../../../shared/heading/Heading';
import { OverviewArticle } from '../../../../../model/Article';
import TruncateMarkup from 'react-truncate-markup';
import styles from './ArticleItem.module.scss';
import createClassName from 'classnames';

export enum LayoutOptions {
    Landscape,
    Portrait,
}
type Props = {
    article: OverviewArticle;
    layout: LayoutOptions;
};

const ArticleItem: VFC<Props> = ({ article, layout }) => {
    const itemClassName = createClassName(styles.item, {
        [styles.hasLandscapeLayout]: layout === LayoutOptions.Landscape,
        [styles.hasPortraitLayout]: layout === LayoutOptions.Portrait,
    });

    return (
        <article>
            <Link to={createArticleDetailPath(article.id, article.slug)} className={itemClassName}>
                <figure className={styles.imageHolder}>
                    {article.imageUrl && (
                        <img
                            src={article.imageUrl}
                            alt={article.title}
                            width="300"
                            height="300"
                            loading="lazy"
                            className={styles.image}
                        />
                    )}
                </figure>
                <div>
                    {article.publishedAt && (
                        <time dateTime={article.publishedAt} className={styles.date}>
                            {formatLongDay(article.publishedAt)}
                        </time>
                    )}
                    <Heading tag="h3" variant="h4" className={styles.title}>
                        {article.title}
                    </Heading>
                    <TruncateMarkup lines={3}>
                        <p>{article.description}</p>
                    </TruncateMarkup>
                </div>
            </Link>
        </article>
    );
};

export default ArticleItem;
