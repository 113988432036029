import { formatShortestReadableDay, formatTime } from '../../../../helper/dateTimeHelper';
import { isToday, isYesterday } from 'date-fns';

export const formatGroupDay = (dateAsString: string) => {
    const date = new Date(dateAsString);

    if (isToday(date)) {
        return 'Vandaag';
    }

    if (isYesterday(date)) {
        return 'Gisteren';
    }

    return formatShortestReadableDay(date);
};

export const formatTriggerDateTime = (dateTime: string) => {
    const dateTimeAsDateObject = new Date(dateTime);

    if (isToday(dateTimeAsDateObject)) {
        return formatTime(dateTimeAsDateObject);
    }

    return formatShortestReadableDay(dateTimeAsDateObject);
};

export const formatItemTimeAndDate = (dateTime: string) => {
    const dateTimeAsDateObject = new Date(dateTime);

    const timeFormatted = formatTime(dateTimeAsDateObject);
    const dateFormatted = formatShortestReadableDay(dateTimeAsDateObject);

    return `${timeFormatted}  -  ${dateFormatted}`;
};
