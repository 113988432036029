import { isString } from 'lodash';
import { logError } from './Logger';

export function transformJsonToObject(jsonString: string | null): any | null {
    if (!jsonString || !isString(jsonString)) {
        logError(new Error('Expecting a json string'), { jsonString });

        return null;
    }

    try {
        return JSON.parse(jsonString);
    } catch (error) {
        logError(error, {
            message: 'Content cannot be json stringified',
            jsonString,
        });

        return null;
    }
}
