import type { Cloneable } from '../contracts';
import { ContentType } from '../../constants/content';

export default class ParagraphDetailProject implements Cloneable<ParagraphDetailProject> {
    private readonly _id: string;
    private readonly _externalId: string;
    private _title: string;
    private _shortTitle: string | null;
    private _type: string;
    private readonly _contentType: ContentType | undefined;
    private _commentCount: number;
    private _answeredCommentCount: number;

    constructor(
        id: string,
        externalId: string,
        title: string,
        shortTitle: string | null,
        type: string,
        contentType: ContentType | undefined,
        commentCount: number,
        answeredCommentCount: number
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._shortTitle = shortTitle;
        this._type = type;
        this._contentType = contentType;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    get shortTitle() {
        return this._shortTitle;
    }

    get type(): string {
        return this._type;
    }

    get contentType(): ContentType | undefined {
        return this._contentType;
    }

    get commentCount() {
        return this._commentCount;
    }

    incrementCommentCount() {
        this._commentCount++;
    }

    get answeredCommentCount() {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount() {
        this._answeredCommentCount++;
    }

    merge(incomingProject: ParagraphDetailProject): void {
        this._title = incomingProject.title;
        this._shortTitle = incomingProject.shortTitle;
        this._type = incomingProject.type;
        this._commentCount = incomingProject.commentCount;
        this._answeredCommentCount = incomingProject.answeredCommentCount;
    }

    clone(): ParagraphDetailProject {
        return new ParagraphDetailProject(
            this._id,
            this._externalId,
            this._title,
            this._shortTitle,
            this._type,
            this._contentType,
            this._commentCount,
            this._answeredCommentCount
        );
    }
}
