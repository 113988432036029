import React from 'react';
import { formatDate } from '../../../../../helper/dateTimeHelper';
import ProjectDetailChapter from '../../../../../model/projectDetail/ProjectDetailChapter';
import styles from '../ProjectAccountability.module.scss';

type Props = {
    chapters: Array<ProjectDetailChapter>;
};

const ProjectChapterAccountability = ({ chapters }: Props) => {
    return (
        <div className={styles.island}>
            <table className="table table--responsive-sm">
                <thead>
                    <tr>
                        <th>Hoofdstuk</th>
                        <th className="text--right">Geautoriseerd op</th>
                    </tr>
                </thead>
                <tbody>
                    {chapters.map((chapter) => (
                        <tr key={chapter.externalId}>
                            <td>{chapter.title}</td>
                            <td className="text--right">
                                {chapter.authorizedAt ? formatDate(chapter.authorizedAt) : 'Onbekend'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProjectChapterAccountability;
