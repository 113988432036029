import React from 'react';
import LinkedInIcon from '../../../../../../../icons/ggz/linkedin.svg';
import useDisplayAfterTimeout from '../../../../../hooks/useDisplayAfterTimeout';
import SubscribeToNewsletter from '../../subscribeToNewsletter/SubscribeToNewsletter';
import Heading from '../../../../shared/heading/Heading';
import styles from './ContactTeaser.module.scss';

const DISPLAY_TIMEOUT = 500; // half a second

const ContactTeaser: React.FC = () => {
    const { visible } = useDisplayAfterTimeout(DISPLAY_TIMEOUT);

    if (!visible) {
        return null;
    }

    return (
        <section className="section section--light">
            <div className="container">
                <div className={styles.newsletterSubscriptionTeaser}>
                    <Heading tag="h3" flatten>
                        Wil je op de hoogte blijven van nieuws over de zorgstandaarden?
                    </Heading>
                    <Heading tag="h4" weight="thin">
                        Geef je, net als duizenden andere ggz-professionals, op voor de 2-wekelijkse nieuwsbrief van
                        Akwa GGZ.
                    </Heading>
                    <div className={styles.actions}>
                        <SubscribeToNewsletter />
                        <div className={styles.actionGroup}>
                            <span>of</span>
                            <a
                                href="https://www.linkedin.com/company/akwa-ggz/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button button--primary"
                            >
                                <LinkedInIcon />
                            </a>
                            <span>volg Akwa GGZ op LinkedIn</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactTeaser;
