import { toast } from 'react-toastify';

export type Notification = {
    type: string;
    message: string;
};

export const showServerNotifications = (notifications: Notification[]) => {
    return notifications.forEach((notification) => {
        // @ts-ignore typescript does not know that type is supported type of toast
        toast[notification.type](notification.message);
    });
};
