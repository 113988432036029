import { createUuid } from '../../../utility/idGenerator';
import ProjectOverviewItem from '../ProjectOverviewItem';

export function createProjectOverviewItemCollectionFromApiData(apiData: any): Array<ProjectOverviewItem> {
    return apiData.map((data: any) => createProjectOverviewItemFromApiData(data));
}

export function createProjectOverviewItemFromStartProjectPendingActionPayload(payload: any): ProjectOverviewItem {
    return new ProjectOverviewItem(
        createUuid(),
        null,
        payload.type,
        payload.shortTitle,
        payload.title,
        payload.revision,
        null,
        0,
        0
    );
}

export function createProjectOverviewItemFromApiData(apiData: any): ProjectOverviewItem {
    return new ProjectOverviewItem(
        createUuid(),
        apiData.id,
        apiData.type,
        apiData.title,
        apiData.short_title,
        apiData.revision,
        apiData._path,
        apiData.comments.total_count,
        apiData.comments.total_answered_count
    );
}
