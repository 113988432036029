import * as React from 'react';
import { HTMLAttributes } from 'react';
import createClassName from 'classnames';
import { dataApplicationTag, DomElementApplication } from '../../../../utility/domElementApplicationsUtilities';

type Props = HTMLAttributes<HTMLDivElement> & {
    isBlock?: boolean;
};

const DashboardContentHolder = ({
    isBlock = false,
    className: additionalClassName,
    children,
    ...otherProps
}: Props) => {
    const className = createClassName(
        'dashboard__content-holder',
        {
            'dashboard__content-holder--block': isBlock,
        },
        additionalClassName
    );

    const appliedProps = {
        ...otherProps,
        className,
        [dataApplicationTag]: DomElementApplication.ScrollableContainer,
    };

    return <div {...appliedProps}>{children}</div>;
};

export default DashboardContentHolder;
