import React from 'react';
import useShowHideModal from '../../../hooks/useShowHideModal';
import CreateUserForm from './components/CreateUserForm';
import Modal from '../../shared/modal/Modal';
import { USER_CREATE } from '../../../security/SecurityAttribute';
import SecurityThreshold from '../../shared/security/SecurityThreshold';
import usePersistUserOnValidSubmit from './hooks/usePersistUserOnValidSubmit';
import CreateUserButton from './components/CreateUserButton';

const CreateUser: React.FC = () => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);

    const onValidSubmit = usePersistUserOnValidSubmit(() => doHideModal());

    if (showModal) {
        return (
            <Modal title="Gebruiker aanmaken" onDecline={() => doHideModal()}>
                <CreateUserForm onValidSubmit={onValidSubmit} />
            </Modal>
        );
    }

    return (
        <SecurityThreshold requiredAttributes={USER_CREATE}>
            <CreateUserButton onClick={() => doShowModal()} />
        </SecurityThreshold>
    );
};

export default CreateUser;
