import type { DetailArticle, OverviewArticle } from '../model/Article';
import { get as fetch, doDelete, post } from '../api/client/apiClient';
import { createInMemoryCache } from '@freshheads/javascript-essentials/build/cache/factory/inMemoryCacheFactory';
import type { PaginatedCollectionType } from '../model/collection/paginatedCollection';
import {
    createArticleCreateApiPath,
    createArticleDeleteApiPath,
    createArticleDetailApiPath,
    createArticleOverviewApiPath,
    createArticleSpotlightApiPath,
    createArticleUpdateApiPath,
} from '../routing/apiUrlGenerator';

const MAX_CACHE_LIFETIME = 300; // 5 minutes

const pageCache = createInMemoryCache<PaginatedCollectionType<OverviewArticle>>(
    'articles_per_page',
    MAX_CACHE_LIFETIME
);

const spotlightCache = createInMemoryCache<Array<OverviewArticle>>('articles_spotlight', MAX_CACHE_LIFETIME);

export const getOneWithId = async (id: number, isEdit: boolean): Promise<DetailArticle> => {
    const response = await fetch(createArticleDetailApiPath(id, isEdit));

    return await response.data;
};

export const findAllForSpotlight = async (numberOfItems: number): Promise<Array<OverviewArticle>> =>
    await spotlightCache.getOrCreate('only', async () => {
        const response = await fetch(createArticleSpotlightApiPath(numberOfItems));

        const data = await response.data;

        return data.results;
    });

export const findAllForPage = async (
    page: number,
    standardId: string | null,
    perPage: number | null,
    includeUnpublished: boolean
): Promise<PaginatedCollectionType<OverviewArticle>> => {
    const cacheKey = `${page}_${standardId}_${perPage}`;

    return await pageCache.getOrCreate(cacheKey, async () => {
        const response = await fetch(createArticleOverviewApiPath(page, standardId, perPage, includeUnpublished));

        return await response.data;
    });
};

export async function pushArticleUpdateToServer(
    id: number,
    title: string,
    description: string,
    publishedAt: string | null,
    standardId: string | null,
    standardIntroduction: string | null,
    image: any | null
): Promise<void> {
    const url = createArticleUpdateApiPath(id);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publishedAt', publishedAt || '');
    formData.append('standardId', standardId || '');
    formData.append('standardIntroduction', standardIntroduction || '');
    formData.append('image', image);

    const response = await post(url, formData);

    pageCache.clear();

    await response.data;
}

export async function persistNewArticleToServer(
    title: string,
    description: string,
    publishedAt: string | null,
    standardId: string | null,
    standardIntroduction: string | null,
    image: any | null
): Promise<number> {
    const url = createArticleCreateApiPath();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publishedAt', publishedAt || '');
    formData.append('standardId', standardId || '');
    formData.append('standardIntroduction', standardIntroduction || '');
    formData.append('image', image);

    const response = await post(url, formData);

    const { id } = await response.data;

    pageCache.clear();

    return id;
}

export async function deleteArticleFromServer(id: number) {
    await doDelete(createArticleDeleteApiPath(id));

    pageCache.clear();
}
