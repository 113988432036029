import { useState } from 'react';
import useOnMount from './useOnMount';
import { findAllForSpotlight } from '../repository/articleRepository';
import type { OverviewArticle } from '../model/Article';
import { logError } from '../utility/Logger';

export default function useFetchSpotlightArticles(numberOfItems: number) {
    const [articles, setArticles] = useState<Array<OverviewArticle> | null>(null);

    useOnMount(() => {
        findAllForSpotlight(numberOfItems)
            .then((articles) => setArticles(articles))
            .catch((error) => logError(error));
    });

    return { articles };
}
