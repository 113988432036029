// keep in line with types in AuthorizationType.php
export const AUTHORIZATION_REQUEST_PENDING = 'PENDING';
export const AUTHORIZATION_APPROVED = 'APPROVED';
export const AUTHORIZATION_UNAPPROVED = 'UNAPPROVED';
export const AUTHORIZATION_ABSTAINED = 'ABSTAINED';

export type AuthorizationStatusType = 'PENDING' | 'APPROVED' | 'UNAPPROVED' | 'ABSTAINED';

export const authorizationStatusTypeLabels = {
    [AUTHORIZATION_REQUEST_PENDING]: 'In afwachting',
    [AUTHORIZATION_APPROVED]: 'Akkoord',
    [AUTHORIZATION_UNAPPROVED]: 'Afgewezen',
    [AUTHORIZATION_ABSTAINED]: 'Geen bezwaar',
};
