import { useDispatch } from 'react-redux';
import { createStandardArchiveAction } from '../../../../actions/standardActionFactory';
import ProjectOverviewItem from '../../../../model/projectOverview/ProjectOverviewItem';

export default function useDispatchArchiveStandardActionOnConfirm(item: ProjectOverviewItem) {
    const dispatch = useDispatch();

    const onConfirm = () => {
        if (!item.externalId) {
            throw new Error('Expecting this project to have an external id at this point');
        }

        dispatch(createStandardArchiveAction(item.id, item.externalId, item.title));
    };

    return { onConfirm };
}
