import { createBrowserHistory } from 'history';

let history;

export function initialize() {
    history = createBrowserHistory();
}

export function getBrowserHistory() {
    if (!history) {
        throw new Error('Call initialize() first to initialize the browser history');
    }

    return history;
}
