import React from 'react';

type Props = {
    isArticle?: boolean;
    children: React.ReactNode;
};

const DashboardContent = ({ isArticle = false, children }: Props) => {
    const tagName = isArticle ? 'article' : 'div';

    return React.createElement(tagName, { className: 'dashboard__content' }, children);
};

export default DashboardContent;
