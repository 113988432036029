import React, { FC } from 'react';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../helper/seoHelper';
import DashboardContent from '../shared/containers/DashboardContent';
import HeadingGroup from '../shared/headings/HeadingGroup';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardIsland from '../shared/island/DashboardIsland';
import useFetchSideProductsCategoryDetails from '../../../hooks/useFetchSideProductCategoryDetails';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import { userMenuTextItems } from '../../../translations/translations';
import { TenantType } from '../../../constants/settings';
import List from '../shared/list/List';
import CategoryListOverviewItem from './components/CategoryListOverviewItem';
import { Link } from 'react-router-dom';
import { PermissionRequirementCallback } from '../../../security/types';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import { SIDE_PRODUCT_CATEGORIES_CREATE } from '../../../security/SecurityAttribute';
import { compose } from 'redux';
import requireAuthenticationStatus from '../../../hoc/RequireAuthenticatedStatus';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import { createDashboardSideProductCategoriesUpdatePath } from '../../../routing/dashboardUrlGenerator';

const SideProductCategoriesOverview: FC = () => {
    const { tenant } = useCurrentTenant();
    const { categoryDetails } = useFetchSideProductsCategoryDetails();

    if (!categoryDetails) return null;

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>
                        {composeSeoTitle(tenant, userMenuTextItems[tenant as TenantType].sideProductCategories)}
                    </title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading>Hulpmiddelen</DashboardContentHeading>
                </HeadingGroup>
                <DashboardIsland>
                    <List>
                        {categoryDetails.map((category) => (
                            <CategoryListOverviewItem key={category.id} category={category}>
                                <Link
                                    to={createDashboardSideProductCategoriesUpdatePath(category.id)}
                                    className="button button--cleared button--icon-only icon icon-pencil"
                                />
                            </CategoryListOverviewItem>
                        ))}
                    </List>
                </DashboardIsland>
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(SIDE_PRODUCT_CATEGORIES_CREATE));
};

export default compose(
    requireAuthenticationStatus,
    requireAuthorizedPermissions(getRequiredPermissions)
)(SideProductCategoriesOverview);
