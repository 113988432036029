// Keep inline with backend setup!
export enum ProjectSearchItemType {
    Project = 'project',
    ProjectLink = 'project_link',
    ProjectSideProduct = 'project_side_product',
    Chapter = 'chapter',
    Paragraph = 'paragraph',
    SubParagraph = 'sub_paragraph',
}

export const ProjectSearchItemTypeLabels = {
    [ProjectSearchItemType.Project]: 'Project',
    [ProjectSearchItemType.ProjectLink]: '',
    [ProjectSearchItemType.ProjectSideProduct]: 'Hulpmiddel',
    [ProjectSearchItemType.Chapter]: 'Hoofdstuk',
    [ProjectSearchItemType.Paragraph]: 'Paragraaf',
    [ProjectSearchItemType.SubParagraph]: 'Subparagraaf',
};

export default class ProjectSearchItem {
    _id: string;
    _projectId: string;
    _projectType: string;
    _type: ProjectSearchItemType;
    _indexes: number[];
    _title: string;
    _description: string | undefined | null;
    _path: string;
    _breadcrumbTitles: string[];
    _matchedQueryTerms: string[];

    constructor(
        id: string,
        projectId: string,
        projectType: string,
        type: ProjectSearchItemType,
        indexes: number[],
        title: string,
        description: string | undefined | null,
        path: string,
        breadcrumbTitles: string[],
        matchedQueryTerms: Array<string>
    ) {
        this._id = id;
        this._projectId = projectId;
        this._projectType = projectType;
        this._indexes = indexes;
        this._type = type;
        this._title = title;
        this._description = description;
        this._path = path;
        this._breadcrumbTitles = breadcrumbTitles;
        this._matchedQueryTerms = matchedQueryTerms;
    }

    get id() {
        return this._id;
    }

    get projectId() {
        return this._projectId;
    }

    get projectType() {
        return this._projectType;
    }

    get type() {
        return this._type;
    }

    get indexes() {
        return this._indexes;
    }

    get title() {
        return this._title;
    }

    get description() {
        return this._description;
    }

    get path() {
        return this._path;
    }

    get breadcrumbTitles() {
        return this._breadcrumbTitles;
    }

    get matchedQueryTerms() {
        return this._matchedQueryTerms;
    }
}
