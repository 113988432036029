import useScrollToTopOnLocationChange from '../../../../../hooks/useScrollToTopOnLocationChange';
import { createChapterDetailPath, createProjectSearchPath } from '../../../../../routing/urlGenerator';
import { matchPath } from 'react-router-dom';

const scrollToTopIfExactMatchedPaths = [createChapterDetailPath, createProjectSearchPath];

export default function useScrollToTopOnChapterChangeOrSwitchToSearch() {
    useScrollToTopOnLocationChange((location) =>
        scrollToTopIfExactMatchedPaths.some((createPath): boolean => {
            const match = matchPath(location.pathname, createPath());

            return match ? match.isExact : false;
        })
    );
}
