export default class OverviewUser {
    _externalId: string;
    _roles: Array<string>;
    _firstName: string;
    _preposition: string | undefined | null;
    _lastName: string;
    _profession: string | undefined | null;
    _email: string;

    constructor(
        externalId: string,
        roles: Array<string>,
        firstName: string,
        preposition: string | undefined | null,
        lastName: string,
        profession: string | undefined | null,
        email: string
    ) {
        this._externalId = externalId;
        this._roles = roles;
        this._firstName = firstName;
        this._preposition = preposition;
        this._lastName = lastName;
        this._profession = profession;
        this._email = email;
    }

    get externalId() {
        return this._externalId;
    }

    get roles() {
        return this._roles;
    }

    get firstName() {
        return this._firstName;
    }

    get preposition() {
        return this._preposition;
    }

    get lastName() {
        return this._lastName;
    }

    get fullLastName(): string {
        return (this._preposition ? this._preposition + ' ' : '') + this._lastName;
    }

    get profession() {
        return this._profession;
    }

    get fullName(): string {
        return `${this._firstName} ${this._preposition ? this._preposition + ' ' : ''}${this._lastName}`;
    }

    get email() {
        return this._email;
    }
}
