import React from 'react';
import ProjectDetailLink, { ProjectDetailLinkType } from '../../../../../model/projectDetail/ProjectDetailLink';
import Heading from '../../../../shared/heading/Heading';
import ArrowDownIcon from '../../../../../../../icons/ggz/arrow-down.svg';
import LinkChainIcon from '../../../../../../../icons/ggz/embedden.svg';
import DocumentIcon from '../../../../../../../icons/ggz/document.svg';
import createClassName from 'classnames';
import useCurrentTenant from '../../../../../hooks/useCurrentTenant';
import { IoLinkOutline, IoReaderOutline } from 'react-icons/io5';

type Props = {
    link: ProjectDetailLink;
};

const LinkOverviewItem = ({ link }: Props) => {
    const { isSKILZ, isGGZ } = useCurrentTenant();
    const iconHolderClassname = createClassName('side-product-item__icon-holder', {
        'side-product-item__icon-holder--with-highlight-text': !!link.highlightText,
    });

    return (
        <div className="side-product-item side-product-item--link">
            <div className={iconHolderClassname}>
                {link.type === ProjectDetailLinkType.relatedStandards && isSKILZ && (
                    <IoReaderOutline className="icon" />
                )}
                {link.type === ProjectDetailLinkType.relatedStandards && isGGZ && <DocumentIcon className="icon" />}
                {link.type !== ProjectDetailLinkType.relatedStandards && isSKILZ && <IoLinkOutline className="icon" />}
                {link.type !== ProjectDetailLinkType.relatedStandards && isGGZ && <LinkChainIcon className="icon" />}
            </div>
            <div className="side-product-item__content">
                {link.highlightText && (
                    <span className="side-product-item__highlight-text">
                        {isGGZ && <ArrowDownIcon className="side-product-item__highlight-text-icon" />}
                        {link.highlightText}
                    </span>
                )}
                <Heading tag="h4" className="side-product-item__title" weight="semi-bold">
                    <a
                        href={link.url}
                        className="link link--primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-track-id="project-link"
                        data-track-data={JSON.stringify({ type: link.type })}
                    >
                        {link.title}
                    </a>
                </Heading>
                {link.description && <p className="side-product-item__description">{link.description}</p>}
            </div>
        </div>
    );
};

export default LinkOverviewItem;
