import { matchPath, useLocation } from 'react-router-dom';
import { createProjectSearchPath } from '../../../../../routing/urlGenerator';

type RouteParams = {
    query: string;
};

export default function useExtractSearchQueryFromUrlForSearchForm() {
    const location = useLocation();

    const match = matchPath<RouteParams>(location.pathname, createProjectSearchPath());

    let query = '';

    if (match && match.isExact === true) {
        query = match.params.query || '';
    }

    return { query };
}
