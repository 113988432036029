import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import createClassName from 'classnames';
import styles from '../AddOrCreateReferencePlugin.module.scss';
import { createDashboardUpdateReferencePath } from '../../../../../routing/dashboardUrlGenerator';

type Props = {
    externalId?: string;
};

const EditButton = ({ externalId }: Props) => {
    const [clicked, setIsClicked] = useState(false);

    // forces a page refresh for the changes to take effect
    useEffect(() => {
        window.onfocus = () => {
            if (clicked) {
                window.location.reload();
                setIsClicked(false);
            }
        };

        return () => {
            window.onfocus = null;
        };
    }, [clicked]);

    return (
        <Link
            to={createDashboardUpdateReferencePath(externalId)}
            className={createClassName(styles.editButton, 'icon icon-pencil')}
            target="_blank"
            onClick={() => setIsClicked(true)}
        />
    );
};

export default EditButton;
