import * as React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    isColumn?: boolean;
};

const ListHeading = ({ children, isColumn = false }: Props) => {
    const classnames = createClassName('list__heading', {
        'list__heading--column': isColumn,
    });

    return <div className={classnames}>{children}</div>;
};

export default ListHeading;
