import { getLastVisitedProjectDetailPath } from '../storage/lastVisitedProjectDetailPathStorage';
import { createProjectDetailPath } from '../../../../../routing/urlGenerator';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';

export function resolvePathBackToStandard(projectDetails: ProjectDetail) {
    const lastIndexPosition = getLastVisitedProjectDetailPath();

    return lastIndexPosition
        ? lastIndexPosition
        : createProjectDetailPath(projectDetails.typeSlug, projectDetails.slug);
}
