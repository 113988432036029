import ProjectMember from './ProjectMember';
import type { Cloneable } from '../contracts';

class SoundingBoardMember extends ProjectMember implements Cloneable<SoundingBoardMember> {
    clone(): SoundingBoardMember {
        return new SoundingBoardMember(this._id, this._externalUserId, this._name, this._branch);
    }
}

export default SoundingBoardMember;
