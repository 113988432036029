import FormGroup from '../../../../shared/form/FormGroup';
import styles from './StickyNewsletterCta.module.scss';
import Button, { ButtonStyles, ButtonTypes } from '../../primitives/button/Button';
import React, { useState } from 'react';
import Heading from '../../../../shared/heading/Heading';
import useHandleNewsletterSubscriptionAnswer from '../../../ggz/subscribeToNewsletter/hooks/useHandleNewsletterSubscriptionAnswer';
import useNewsletterSubscriptionFormHandlers from '../../../ggz/subscribeToNewsletter/hooks/useNewsletterSubscriptionFormHandlers';
import createClassName from 'classnames';
import CloseIcon from '../../../../../../../icons/skilz/close.svg';
import ShareIcon from '../../../../../../../icons/skilz/share.svg';
import { getBooleanFromCookieStorage, writeBooleanToCookieStorage } from '../../../../../utility/cookieStorageHelper';

const COOKIE_NAME = 'newsletter-subscription-closed';
const COOKIE_EXPIRATION = 30;

const StickyNewsletterCta = () => {
    const cookieValue = getBooleanFromCookieStorage(COOKIE_NAME);
    const [showCta, setShowCta] = useState<boolean>(!cookieValue || true);
    const { onValidSubmit } = useHandleNewsletterSubscriptionAnswer(undefined, undefined, true);

    const onClose = () => {
        setShowCta(false);
        writeBooleanToCookieStorage(COOKIE_NAME, true, COOKIE_EXPIRATION);
    };

    const { handleChange, handleSubmit, handleBlur, values, isPending } = useNewsletterSubscriptionFormHandlers(
        onValidSubmit,
        onClose
    );

    const classNames = createClassName('form__field', styles.formField);

    if (!showCta || cookieValue) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <Button isCleared className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </Button>
                <Heading tag="h4" flatten className={styles.heading}>
                    Op de hoogte blijven van onze richtlijnen?
                </Heading>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className={styles.formGroup}>
                            <input
                                id="email"
                                name="email"
                                placeholder="E-mailadres"
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                                className={classNames}
                                disabled={isPending}
                            />
                            <Button
                                type={ButtonTypes.Submit}
                                style={ButtonStyles.Primary}
                                disabled={isPending}
                                additionalClassName={styles.submitButton}
                            >
                                <ShareIcon />
                            </Button>
                        </div>
                    </FormGroup>
                </form>
            </div>
        </div>
    );
};

export default StickyNewsletterCta;
