import { FormikErrors } from 'formik';
import { FormValues } from '../components/NewsletterSubscriptionForm';
import { checkIsValidEmail } from '../../../../../utility/validationUtilities';

export const validateInput = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
        errors.email = 'Dit veld is verplicht';
    } else if (!checkIsValidEmail(values.email)) {
        errors.email = 'Vul een geldig e-mailadres in';
    }

    return errors;
};
