import { useSelector } from 'react-redux';
import { GlobalState } from '../store/types';
import { SettingsReducerState } from '../reducers/settingsReducer';
import { TenantType } from '../constants/settings';
import { createSelector } from 'reselect';

const getMemoizedCurrentTenant = createSelector(
    (state: GlobalState) => state.settings,
    (settings: SettingsReducerState) => {
        const tenant = settings?.context.tenant;

        const isSKILZ = tenant === TenantType.SKILZ;
        const isGGZ = tenant === TenantType.GGZ;

        return { tenant, isSKILZ, isGGZ };
    }
);

export default function useCurrentTenant() {
    return useSelector(getMemoizedCurrentTenant);
}
