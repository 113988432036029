/* For both GGZ and SKILZ we use the same types technically, though we differentiate by using different labels
(i.e. 'GENERIC_MODULE' is 'Standaard' (before: 'Generieke module') for GGZ and 'Handreiking' for SKILZ, though in technical terms, we treat them the same.
We do this to prevent potential technical problems and issues with TypeScript

EDIT 30-10-2023: type GENERIC_MODULE ('Generieke module') no longer exists within GGZ's process. We keep the type for existing projects, but treat them as STANDARD */

import { TenantType } from './settings';

export const PROJECT_TYPE_STANDARD = 'STANDARD'; /* Standaard || NOT IN USE */
export const PROJECT_TYPE_DIRECTIVE = 'DIRECTIVE'; /* Richtlijn || Richtlijn */
export const PROJECT_TYPE_GENERIC_MODULE = 'GENERIC_MODULE'; /* Standaard || Handreiking */

export type ProjectType = 'STANDARD' | 'DIRECTIVE' | 'GENERIC_MODULE';

type TypesDictionary = {
    [x: string]: string;
};

/* GGZ labels */
export const ggzProjectTypesSingular: TypesDictionary = {
    [PROJECT_TYPE_STANDARD]: 'Zorgstandaard',
    [PROJECT_TYPE_DIRECTIVE]: 'Richtlijn',
    [PROJECT_TYPE_GENERIC_MODULE]: 'Zorgstandaard',
};

export const ggzProjectTypesSingularDropdown: TypesDictionary = {
    [PROJECT_TYPE_STANDARD]: 'Zorgstandaard',
    [PROJECT_TYPE_DIRECTIVE]: 'Richtlijn',
};

const ggzProjectTypesPlural: TypesDictionary = {
    [PROJECT_TYPE_STANDARD]: 'Zorgstandaarden',
    [PROJECT_TYPE_DIRECTIVE]: 'Richtlijnen',
    [PROJECT_TYPE_GENERIC_MODULE]: 'Zorgstandaarden',
};

export const ggzProjectOwnerTypes: TypesDictionary = {
    default: 'GGZ Standaarden',
};

export const projectTypesFilterCategories: TypesDictionary = {
    [PROJECT_TYPE_STANDARD]: 'Aandoeningen',
    [PROJECT_TYPE_DIRECTIVE]: 'Aandoeningen',
    [PROJECT_TYPE_GENERIC_MODULE]: "Overkoepelende thema's", // @todo: check if still needed
};

// Beware! Make sure these match the server side setup in ProjectTag.php
export enum ProjectTag {
    intensiveCare = 'intensive_care',
    recoveryThemes = 'recovery_themes',
    childrenAndYoungPeople = 'children_and_young_people',
    lvbOrZb = 'lvb_or_zb',
    elderly = 'elderly',
    somaticsAndPsychiatry = 'somatics_and_psychiatry',
    addiction = 'addiction',
    careByEpa = 'care_by_epa',
}

export const projectTagsLabels = {
    [ProjectTag.intensiveCare]: 'Acute zorg',
    [ProjectTag.recoveryThemes]: 'Herstelthema’s',
    [ProjectTag.childrenAndYoungPeople]: 'Kinderen en jongeren',
    [ProjectTag.lvbOrZb]: 'LVB of ZB',
    [ProjectTag.elderly]: 'Ouderen',
    [ProjectTag.somaticsAndPsychiatry]: 'Somatiek & psychiatrie',
    [ProjectTag.addiction]: 'Verslaving',
    [ProjectTag.careByEpa]: 'Zorg bij EPA',
};

export function getProjectTagByLabel(label: string): ProjectTag | null {
    const projectTagsValues = Object.values(projectTagsLabels);
    const projectTagsKeys = Object.keys(projectTagsLabels);

    return (projectTagsKeys[projectTagsValues.indexOf(label)] as ProjectTag) || null;
}

/* SKILZ labels */
export const skilzProjectTypesSingular: TypesDictionary = {
    [PROJECT_TYPE_DIRECTIVE]: 'Richtlijn',
    [PROJECT_TYPE_GENERIC_MODULE]: 'Handreiking',
};

const skilzProjectTypesPlural: TypesDictionary = {
    [PROJECT_TYPE_DIRECTIVE]: 'Richtlijnen',
    [PROJECT_TYPE_GENERIC_MODULE]: 'Handreikingen',
};

export const skilzProjectOwnerTypes: TypesDictionary = {
    default: 'SKILZ',
    sri: 'SRI',
    aawvb: 'AAW VB',
    nvavg: 'NVAVG',
};

export function getProjectTypeTitleSingular(tenant: TenantType, type: string): string {
    if (tenant === TenantType.GGZ) {
        return ggzProjectTypesSingular[type];
    }

    return skilzProjectTypesSingular[type];
}

export function getProjectTypeTitlePlural(tenant: TenantType, type: string): string {
    if (tenant === TenantType.GGZ) {
        return ggzProjectTypesPlural[type];
    }

    return skilzProjectTypesPlural[type];
}
