import * as React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

const Tooltip: React.FC<TippyProps> = ({ children, placement = 'bottom', theme = 'primary', ...otherProps }) => (
    <Tippy {...otherProps} placement={placement} theme={theme}>
        {children}
    </Tippy>
);

export default Tooltip;
