import * as React from 'react';
import { PROJECT_CLOSE } from '../../../../security/SecurityAttribute';
import { SecuritySubject } from '../../../../security/SecuritySubjectType';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';

type Props = {
    children: React.ReactNode;
    externalId: string;
};

const CloseProjectThreshold = ({ children, externalId }: Props) => (
    <SecurityThreshold requiredAttributes={PROJECT_CLOSE} subjectType={SecuritySubject.project} subjectId={externalId}>
        {children}
    </SecurityThreshold>
);

export default CloseProjectThreshold;
