import React from 'react';
import { Page } from '../../../model/Page';
import useShowHideModal from '../../../hooks/useShowHideModal';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import useDeletePageOnConfirmation from './hooks/useDeletePageOnConfirmation';
import Loader from '../../shared/Loader';
import Button, { BUTTON } from '../../shared/buttons/Button';

type Props = {
    page: Page;
    onDone: () => any;
};

const DeletePage = ({ page, onDone }: Props) => {
    const { showModal, doHideModal, doShowModal } = useShowHideModal();
    const { onConfirm, isDeleting } = useDeletePageOnConfirmation(page, doHideModal, onDone);

    return (
        <>
            {isDeleting ? (
                <Loader sizeSmall />
            ) : (
                <Button type={BUTTON} icon="cancel" onClick={() => doShowModal()} isCleared></Button>
            )}
            {showModal && (
                <ConfirmationModal title="Weet je het zeker?/" onConfirm={onConfirm} onDecline={doHideModal}>
                    <p>
                        Weet je zeker dat je artikel &apos;{page.title}&apos; wilt verwijderen? Deze wijziging kan niet
                        ongedaan gemaakt worden.
                    </p>
                </ConfirmationModal>
            )}
        </>
    );
};

export default DeletePage;
