import type { GlobalState } from '../store/types';
import type { Store as ReduxStore } from 'redux';

// @see https://blog.callstack.io/type-checking-react-and-redux-thunk-with-flow-part-2-206ce5f6e705
// @see https://stackoverflow.com/questions/41792811/how-are-you-supposed-to-flowtype-redux-thunk
// @see https://flow.org/en/docs/react/redux/

type Payload = {
    data: Promise<any> | undefined | null | Payload;
    [x: string]: any;
};

export type Action = {
    type: string;
    payload: Payload;
    meta: any;
};

export type Store = ReduxStore<GlobalState, Action>;
export type Dispatch = (action: Action | Thunk) => any;
export type GetState = () => GlobalState;
export type Thunk = (dispatch: Dispatch, getState: GetState) => any;

export type DispatchProps = {
    dispatch: Dispatch;
};

export function createAction(
    type: string,
    promise: Promise<any> | undefined | null = null,
    data: any = {},
    meta: any = {}
): Action {
    return {
        type,
        payload: {
            data: data,
            promise: promise,
        },
        meta,
    };
}
