import ProjectBreadcrumb from './ProjectBreadcrumb';
import BookmarkTag from './BookmarkTag';
import type { Cloneable } from './contracts';

export default class Bookmark implements Cloneable<Bookmark> {
    _id: string;
    _externalId: number;
    _bookmarkedId: string;
    _tags: Array<BookmarkTag>;
    _createdAt: string;
    _breadcrumb: ProjectBreadcrumb;

    constructor(
        id: string,
        externalId: number,
        bookmarkedId: string,
        tags: Array<BookmarkTag>,
        createdAt: string,
        breadcrumb: ProjectBreadcrumb
    ) {
        this._id = id;
        this._externalId = externalId;
        this._bookmarkedId = bookmarkedId;
        this._tags = tags;
        this._createdAt = createdAt;
        this._breadcrumb = breadcrumb;
    }

    get id() {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get externalId() {
        return this._externalId;
    }

    get bookmarkedId() {
        return this._bookmarkedId;
    }

    removeTagWithExternalId(externalId: number): void {
        this._tags = this._tags.filter((bookmarkTag) => bookmarkTag.externalId !== externalId);
    }

    get tags() {
        return this._tags;
    }

    getExternalTagIds(): Array<number | undefined | null> {
        return this._tags.map((tag) => tag.externalId);
    }

    hasTagWithExternalId(externalId: number): boolean {
        return this._tags.findIndex((tag) => tag.externalId === externalId) !== -1; // eslint-disable-line no-magic-numbers
    }

    get createdAt() {
        return this._createdAt;
    }

    get breadcrumb() {
        return this._breadcrumb;
    }

    clone(): Bookmark {
        return new Bookmark(
            this._id,
            this._externalId,
            this._bookmarkedId,
            this._tags.map((tag) => tag.clone()),
            this._createdAt,
            this._breadcrumb
        );
    }
}
