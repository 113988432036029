const firstName = {
    presence: {
        allowEmpty: false,
    },
    length: {
        maximum: 100,
        message: '^Een voornaam langer dan 100 tekens is niet toegestaan',
    },
};

const preposition = {
    length: {
        maximum: 50,
        message: '^Een tussenvoegsel langer dan 50 tekens is niet toegestaan',
    },
};

const lastName = {
    presence: {
        allowEmpty: false,
    },
    length: {
        maximum: 150,
        message: '^Een achternaam langer dan 150 tekens is niet toegestaan',
    },
};

const profession = {
    length: {
        maximum: 200,
        message: '^Een functie langer dan 200 tekens is niet toegestaan',
    },
};

const password = {
    presence: {
        allowEmpty: false,
    },
    length: {
        minimum: 8,
        message: '^Een wachtwoord moet uit minimaal 8 karakters bestaan',
    },
};

const email = {
    presence: {
        allowEmpty: false,
    },
    email: {
        message: '^Vul een geldig e-mailadres in',
    },
    emailDoesNotExists: true,
};

export function createUserRegistrationConstraintSet(): any {
    return {
        firstName,
        preposition,
        lastName,
        email,
        password,
        agreement: {
            presence: {
                allowEmpty: false,
            },
            inclusion: {
                within: [true],
                message: '^Om door te gaan moet u de algemene voorwaarden accepteren',
            },
        },
        privacy: {
            presence: {
                allowEmpty: false,
            },
            inclusion: {
                within: [true],
                message: '^Om door te gaan moet u ons privacybeleid accepteren',
            },
        },
    };
}

export function createUserEditPasswordConstraintSet(): any {
    return {
        newPassword: password,
        newPasswordConfirmation: {
            equality: {
                attribute: 'newPassword',
                message: '^De ingevulde wachtwoorden komen niet overeen',
            },
        },
    };
}

export function createUserEditProfileConstraintSet(): any {
    return {
        firstName,
        preposition,
        lastName,
        profession,
    };
}

export function createReferenceConstraintSet(): any {
    return {
        description: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}

export function createChangeEmailConstraintSet(): any {
    return { email };
}

export function createTagFormConstraintSet(): any {
    return {
        title: {
            presence: {
                allowEmpty: false,
            },
        },
    };
}
