import React, { VFC } from 'react';
import FormGroup from '../../../shared/form/FormGroup';
import useForgotPasswordHandler from './useForgotPasswordHandler';
import createClassname from 'classnames';
import Loader from '../../../shared/Loader';
import FormErrorMessage from '../../../shared/form/FormErrorMessage';

type Props = {
    allowLabels: boolean;
    placeholder?: string;
};

const ForgotPasswordForm: VFC<Props> = ({ allowLabels, placeholder = 'E-mail' }) => {
    const { value, onSubmit, loading, onChange, errors } = useForgotPasswordHandler();

    const formClassName = createClassname('form', {
        'form--disable-field-labels': allowLabels === false,
    });

    return (
        <form onSubmit={onSubmit} className={formClassName}>
            <FormGroup hasErrors={errors && errors.email.length > 0}>
                <label className="form__label" htmlFor="email">
                    E-mail
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="form__field"
                    placeholder={placeholder}
                    value={value}
                    disabled={loading}
                    onChange={(event) => onChange(event.target.value)}
                    autoFocus={true}
                    required={true}
                />
                {errors &&
                    errors.email.map((error, index) => {
                        return <FormErrorMessage key={index}>{error}</FormErrorMessage>;
                    })}
            </FormGroup>
            {loading ? (
                <div className="text-center">
                    <Loader sizeSmall={true} />
                </div>
            ) : (
                <button type="submit" className="button button--primary">
                    Verzend
                </button>
            )}
        </form>
    );
};

export default ForgotPasswordForm;
