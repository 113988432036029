import { FormValues } from '../hooks/useHandleCreateBranchFormState';
import { FormikErrors } from 'formik';
import { FormErrorCollectionType } from '../../../../lib/forms/model/FormState';

export const validateBranchInput = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.title) {
        errors.title = 'Dit veld is verplicht';
    }

    return errors;
};

export const extractFormErrorsFromBackendValidationErrors = (
    backendValidationErrors: FormErrorCollectionType,
    values: FormValues
): FormikErrors<FormValues> => {
    const fields = Object.keys(values) as Array<keyof FormValues>;

    const newErrors: FormikErrors<FormValues> = {};

    fields.forEach((field) => {
        if (backendValidationErrors[field] !== undefined && backendValidationErrors[field].length > 0) {
            newErrors[field] = backendValidationErrors[field][0];
        }
    });

    return newErrors;
};
