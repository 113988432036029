import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateObject } from '../../../../validation/validator';
import { createUserEditPasswordConstraintSet } from '../../../../validation/constraint/Factory/UserConstraintSetFactory';
import FormGroup from '../../../shared/form/FormGroup';
import FormErrorMessage from '../../../shared/form/FormErrorMessage';
import Loader from '../../../shared/Loader';

class ResetPasswordForm extends Component {
    constructor() {
        super();

        this.state = {
            newPassword: '',
            newPasswordConfirmation: '',
            errors: {
                newPassword: [],
                newPasswordConfirmation: [],
            },
            locked: false,
        };

        this._onSubmit = this._onSubmit.bind(this);
    }

    onSubmitSuccess() {
        this.setState({
            locked: false,
            newPassword: '',
            newPasswordConfirmation: '',
        });
    }

    /**
     * @returns {Object}
     *
     * @private
     */
    _extractCurrentInputFromState() {
        var input = {
            ...this.state,
        };

        delete input.errors;
        delete input.locked;

        return input;
    }

    /**
     * @returns {Promise}
     *
     * @private
     */
    _validate() {
        var input = this._extractCurrentInputFromState();

        return validateObject(input, createUserEditPasswordConstraintSet()).then((result) => {
            this.setState({
                errors: result.errorMessages,
            });

            return result.containsErrors;
        });
    }

    /**
     * @param {String} field
     * @param {Event} event
     *
     * @private
     */
    _onFieldChange(field, event) {
        var value = event.target.value;

        this.setState({
            [field]: value,
        });
    }

    /**
     * @param {Event} event
     * @private
     */
    _onSubmit(event) {
        event.preventDefault();

        this._validate().then((containsErrors) => {
            if (!containsErrors) {
                this.setState({ locked: true });

                this.props.onSubmit(this._extractCurrentInputFromState().newPassword);
            }
        });
    }

    /**
     * @param {String} fieldName
     *
     * @returns {Array|null}
     *
     * @private
     */
    _renderFormFieldErrorMessages(fieldName) {
        var { errors } = this.state;

        if (!errors[fieldName]) {
            return null;
        }

        return errors[fieldName].map((error, index) => {
            return <FormErrorMessage key={index}>{error}</FormErrorMessage>;
        });
    }

    /**
     * @param {String} fieldName
     * @param {String|null=} label
     * @param {String|null=} placeholder
     * @param {String=} type
     * @param {Boolean=} autoFocus
     *
     * @returns {String}
     *
     * @private
     */
    _renderTextFieldGroup(fieldName, label = null, placeholder = null, type = 'text', autoFocus = false) {
        var { errors, locked } = this.state;

        return (
            <FormGroup hasErrors={errors[fieldName].length > 0}>
                {label ? (
                    <label className="form__label" htmlFor={fieldName}>
                        {label}
                    </label>
                ) : null}
                <input
                    type={type}
                    name={fieldName}
                    id={fieldName}
                    className="form__field"
                    placeholder={placeholder}
                    value={this.state[fieldName]}
                    disabled={locked}
                    onChange={this._onFieldChange.bind(this, fieldName)}
                    autoFocus={autoFocus}
                />
                {this._renderFormFieldErrorMessages(fieldName)}
            </FormGroup>
        );
    }

    /**
     * @returns {String}
     *
     * @private
     */
    _renderSubmit() {
        if (this.state.locked) {
            return <Loader />;
        }

        return (
            <button type="submit" className="button button--primary">
                Verander wachtwoord
            </button>
        );
    }

    render() {
        return (
            <form onSubmit={this._onSubmit} noValidate>
                {this._renderTextFieldGroup('newPassword', null, 'Wachtwoord', 'password', true)}
                {this._renderTextFieldGroup('newPasswordConfirmation', null, 'Bevestig wachtwoord', 'password')}
                <div className="form__group form__help">Je wachtwoord moet uit minimaal 8 tekens bestaan.</div>
                {this._renderSubmit()}
            </form>
        );
    }
}

ResetPasswordForm.propTypes = {
    token: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
