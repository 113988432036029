import { GlobalState } from './../../../../store/types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../Loader';

type ReduxSuppliedProps = {
    hasItems: boolean;
};

const HeaderLoadingQueueLoader: React.FC<ReduxSuppliedProps> = ({ hasItems }) => {
    if (!hasItems) {
        return null;
    }

    return <Loader />;
};

/**
 * @param {Object} globalState
 *
 * @returns {Object}
 *
 * @private
 */
function _mapGlobalStateToComponentState(globalState: GlobalState) {
    return {
        hasItems: globalState.loadingQueue.count() > 0,
    };
}

export default connect<ReduxSuppliedProps, {}, {}, GlobalState>(_mapGlobalStateToComponentState)(
    HeaderLoadingQueueLoader
);
