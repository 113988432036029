import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    createAddBookmarkAction,
    createRemoveBookmarkAction,
    createAddBookmarkTagAction,
} from '../actions/userActionFactory';
import { createLoginPath } from '../routing/urlGenerator';
import User from '../model/User';
import BookmarkForm from '../components/shared/bookmarkForm/BookmarkForm';
import { createUuid } from '../utility/idGenerator';
import Popover from '../components/shared/Popover';
import { BookmarkSubject } from '../components/shared/bookmark/BookmarkTrigger';

/**
 * @param {BookmarkTrigger} BookmarkTrigger
 *
 * @returns {BookmarkDispatcher}
 */
export default function createDispatchBookmarkTriggerOnClickComponent(BookmarkTrigger) {
    class BookmarkDispatcher extends Component {
        /**
         * @param {Object} props
         */
        constructor(props) {
            super(props);

            this.state = {
                marked: false,
                isLoading: false,
                showPopover: false,
            };

            this._bookmarkExternalId = null;

            // context bindings
            this._onClick = this._onClick.bind(this);
            this._onPopoverDecline = this._onPopoverDecline.bind(this);
            this._onFormValid = this._onFormValid.bind(this);
            this._onFormCancel = this._onFormCancel.bind(this);
            this._onAddNew = this._onAddNew.bind(this);
        }

        /**
         * @inheritDoc
         */
        componentDidMount() {
            this._setInitialBookmarkStatus();
        }

        /**
         * @param {Object} nextProps
         */
        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { currentUser } = this.props;

            if (!currentUser || !nextProps.currentUser) {
                return;
            }

            if (currentUser.bookmarks.length === nextProps.currentUser.bookmarks.length) {
                return;
            }

            const bookmark = nextProps.currentUser.findBookmark(nextProps.bookmarkedId),
                hasBookmark = typeof bookmark !== 'undefined';

            if (hasBookmark) {
                this._bookmarkExternalId = bookmark.externalId;
            }

            // only update state when bookmark is found and not the same as current state
            if (this.state.marked !== hasBookmark) {
                this.setState({
                    marked: hasBookmark,
                });
            }
        }

        /**
         * @private
         */
        _setInitialBookmarkStatus() {
            const { currentUser, authenticated, bookmarkedId } = this.props;

            if (!authenticated || !(currentUser instanceof User)) {
                return;
            }

            const bookmark = currentUser.findBookmark(bookmarkedId);

            if (bookmark) {
                this._bookmarkExternalId = bookmark.externalId;

                this.setState({
                    marked: true,
                });
            }
        }

        /**
         * @param {Number[]} externalTagIds
         *
         * @private
         */
        _addBookmarkToCurrentUser(externalTagIds) {
            const { dispatch, bookmarkedId, title } = this.props;

            const action = createAddBookmarkAction(bookmarkedId, title, externalTagIds);

            this.setState({ isLoading: true });

            dispatch(action).then(() => this.setState({ isLoading: false }));
        }

        /**
         * @private
         */
        _removeBookmarkFromCurrentUser() {
            const { dispatch, title } = this.props;
            const action = createRemoveBookmarkAction(this._bookmarkExternalId, title);

            this.setState({ isLoading: true });

            dispatch(action).then(() => this.setState({ isLoading: false }));
        }

        /**
         * @private
         */
        _redirectUserToLoginPage() {
            const { href } = this.props;

            // @todo after user login add auto create favorite
            window.location.href = createLoginPath(href, true);
        }

        /**
         * @private
         */
        _onClick() {
            const { authenticated, currentUser, bookmarkedId } = this.props;

            if (!authenticated || currentUser === null) {
                this._redirectUserToLoginPage();

                return;
            }

            if (currentUser.hasBookmark(bookmarkedId) === false) {
                this._showPopover();
            } else {
                this._removeBookmarkFromCurrentUser();
            }
        }

        _showPopover() {
            this.setState((currentState) => {
                return { ...currentState, showPopover: true };
            });
        }

        /**
         * @param {Function} callback
         *
         * @private
         */
        _hidePopover(callback = null) {
            this.setState(
                (currentState) => {
                    return { ...currentState, showPopover: false };
                },
                () => {
                    if (callback) {
                        callback();
                    }
                }
            );
        }

        /**
         * @private
         */
        _onFormCancel() {
            this._hidePopover();
        }

        /**
         * @private
         */
        _onPopoverDecline() {
            this._hidePopover();
        }

        /**
         * @param {Number[]} externalTagIds
         *
         * @private
         */
        _onFormValid(externalTagIds) {
            this._hidePopover(() => this._addBookmarkToCurrentUser(externalTagIds));
        }

        /**
         * @param {String} title
         *
         * @private
         */
        _onAddNew(title) {
            const { dispatch } = this.props;

            dispatch(createAddBookmarkTagAction(title));
        }

        /**
         * @returns {String}
         */
        render() {
            const { currentUser, popoverClassName, title, className, bookmarkTriggerLabel, type } = this.props;
            const { marked, isLoading, showPopover } = this.state;

            return (
                <Popover
                    visible={showPopover}
                    placement="right"
                    onHide={this._onPopoverDecline}
                    className={popoverClassName}
                    content={
                        <BookmarkForm
                            key={createUuid()}
                            onFormValid={this._onFormValid}
                            onCancel={this._onFormCancel}
                            onAddNew={this._onAddNew}
                            tags={currentUser instanceof User ? currentUser.bookmarkTags : []}
                        />
                    }
                >
                    {/* wrap in span because tippy only supports react child components if the ref is forwarded which is not easy in a HOC */}
                    <span tabIndex="0" style={{ display: 'inline-flex' }}>
                        <BookmarkTrigger
                            title={title}
                            className={className}
                            marked={marked}
                            isLoading={isLoading}
                            onClick={this._onClick}
                            label={bookmarkTriggerLabel}
                            type={type}
                        />
                    </span>
                </Popover>
            );
        }
    }

    BookmarkDispatcher.propTypes = {
        authenticated: PropTypes.bool.isRequired,
        currentUser: PropTypes.instanceOf(User),
        dispatch: PropTypes.func.isRequired,
        bookmarkedId: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        className: PropTypes.string,
        popoverClassName: PropTypes.string,
        bookmarkTriggerLabel: PropTypes.string,
        type: PropTypes.oneOf(Object.keys(BookmarkSubject)).isRequired,
    };

    function _mapGlobalStateToComponentProps(globalState) {
        return {
            authenticated: globalState.authenticated,
            currentUser: globalState.currentUser,
        };
    }

    return connect(_mapGlobalStateToComponentProps)(BookmarkDispatcher);
}
