import { OnValidSubmitCallback } from '../RecommendedStandardsForm';
import { logError } from '../../../../utility/Logger';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { createAddRecommendedStandardsAction } from '../../../../actions/standardActionFactory';
import { StandardOverviewItem } from '../../../../model/standardOverview/StandardOverviewItem.d';

export default function useSubmitRecommendedStandardsFormValuesToServer(standardOverviewItem: StandardOverviewItem) {
    const dispatch = useDispatch();

    const onValidSubmit: OnValidSubmitCallback = async (values) => {
        // @ts-ignore
        const valuesAsArray = values ? Object.keys(values).map((key) => values[key].value) : [];

        try {
            await dispatch(createAddRecommendedStandardsAction(standardOverviewItem.externalId, valuesAsArray));

            return true;
        } catch (error) {
            logError(error);

            toast.error(
                'Er is iets fout gegaan bij het opslaan van de aanbevolen standaarden. Probeer het later nog eens!'
            );

            return false;
        }
    };

    return { onValidSubmit };
}
