import React from 'react';
import { useProjectDetails } from '../../../../hooks/useProjectDetails';
import Heading from '../../../shared/heading/Heading';
import ProjectAccountabilitySummary from './components/ProjectAccountabilitySummary';
import ProjectChapterAccountability from './components/ProjectChapterAccountability';
import ProjectAuthorizingParties from './components/ProjectAuthorizingParties';
import ProjectStatusSideProductOverview from './components/ProjectStatusSideProductOverview';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';

const ProjectAccountability = () => {
    const { details } = useProjectDetails();
    const { isSKILZ } = useCurrentTenant();

    if (!details) {
        return null;
    }

    return (
        <article>
            <Heading tag="h2" variant="h3">
                Status van de {isSKILZ ? 'richtlijn' : 'standaard'}
            </Heading>
            <ProjectStatusSideProductOverview projectDetail={details} />
            <ProjectAccountabilitySummary authorizedAt={details.authorizedAt} reviewedAt={details.reviewedAt} />
            <Heading tag="h2" variant="h3">
                Status per hoofdstuk
            </Heading>
            <ProjectChapterAccountability chapters={details.chapters} />
            {details.partiesAuthorizing ? (
                <ProjectAuthorizingParties
                    partiesAuthorizing={details.partiesAuthorizing}
                    partiesNotAuthorizing={details.partiesNotAuthorizing}
                    partiesNotObjecting={details.partiesNotObjecting}
                />
            ) : null}
        </article>
    );
};

export default ProjectAccountability;
