import React, { ReactNode } from 'react';
import Button from '../../../shared/buttons/Button';
import PlusIcon from '../../../../../../icons/ggz/plus.svg';
import { AnimatePresence, motion } from 'framer-motion';
import useShowHide from '../../../../hooks/useShowHide';
import classnames from './PageToggleItem.module.scss';
import createClassName from 'classnames';
import Heading from '../../../shared/heading/Heading';

type Props = {
    children: ReactNode;
    title: string;
};

const PageToggleItem: React.FC<Props> = ({ children, title }) => {
    const { toggle, visible } = useShowHide(false);

    const iconClassname = createClassName(classnames.icon, {
        [classnames.isOpen]: visible,
    });

    return (
        <>
            <Button isCleared onClick={() => toggle()} additionalClassName={classnames.toggle}>
                <PlusIcon className={iconClassname} />
                <Heading tag="h2" variant="h4" className="heading--toggle" flatten>
                    {title}
                </Heading>
            </Button>
            {/* @ts-ignore - issue with create react app */}
            <AnimatePresence>
                {visible && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={classnames.content}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default PageToggleItem;
