import React, { FC } from 'react';
import { useProjectDetails } from '../../../../../hooks/useProjectDetails';
import ProjectDetailPublicationMainContent from '../../../shared/projectDetail/ProjectDetailPublicationMainContent';
import { Route, Switch } from 'react-router-dom';
import {
    createChapterDetailPath,
    createParagraphDetailPath,
    createProjectDetailPath,
    createProjectSearchPath,
    createSubParagraphDetailPath,
} from '../../../../../routing/urlGenerator';
import ProjectDetailRedirectToFirstChapter from '../../../shared/projectDetail/ProjectDetailRedirectToFirstChapter';
import ChapterDetail from '../../../shared/chapterDetail/ChapterDetail';
import ProjectDetailSidebar from '../../../shared/projectDetail/ProjectDetailSidebar';
import useSidebarMobileVisibilityHandlers from '../../../shared/projectDetail/hooks/useSidebarMobileVisibilityHandlers';
import ProjectIndex from '../../../shared/projectIndex/ProjectIndex';
import { IndexStyles } from '../../../shared/projectIndex/components/ProjectIndexList';
import LayoutProjectViewMobileActions from '../../../shared/projectDetail/components/LayoutProjectViewMobileActions';
import { IoSearchOutline } from 'react-icons/io5';
import SearchForm, { SearchIconPlacement } from '../../../shared/searchForm/SearchForm';
import useNavigateToProjectSearchOnFormSubmit from '../../../shared/projectDetail/hooks/useNavigateToProjectSearchOnFormSubmit';
import useExtractSearchQueryFromUrlForSearchForm from '../../../shared/projectDetail/hooks/useExtractSearchQueryFromUrlForSearchForm';
import SearchResults from '../../../shared/searchResults/SearchResults';
import useScrollToTopOnChapterChangeOrSwitchToSearch from '../../../shared/projectDetail/hooks/useScrollToTopOnChapterChangeOrMoveToSearch';
import { TenantType } from '../../../../../constants/settings';

const ProjectDetailDirectivePublication: FC = () => {
    const { details: projectDetails } = useProjectDetails();

    const {
        sidebarIsVisibleOnMobile,
        hideSidebarOnMobile,
        toggleSidebarVisibilityState,
        onTriggerSearchClick,
        onTriggerProjectIndexClick,
        searchInputRef,
    } = useSidebarMobileVisibilityHandlers();

    const { onSearchSubmit } = useNavigateToProjectSearchOnFormSubmit(projectDetails, hideSidebarOnMobile);

    useScrollToTopOnChapterChangeOrSwitchToSearch();

    const { query } = useExtractSearchQueryFromUrlForSearchForm();

    const placeholder =
        projectDetails && projectDetails?.title.length > 10
            ? `Zoeken binnen ${projectDetails?.title.slice(0, 10).toLowerCase()}...`
            : `Zoeken binnen ${projectDetails?.title.toLowerCase()}`;

    return (
        <>
            <LayoutProjectViewMobileActions
                onTriggerProjectIndexClick={onTriggerProjectIndexClick}
                onTriggerSearchClick={onTriggerSearchClick}
            />
            <div className="layout-project-view">
                <ProjectDetailSidebar visibleOnMobile={sidebarIsVisibleOnMobile}>
                    <ProjectIndex
                        onProjectIndexItemClick={() => hideSidebarOnMobile()}
                        onProjectIndexCloseClick={toggleSidebarVisibilityState}
                        indexStyle={IndexStyles.SKILZ}
                    >
                        {projectDetails && (
                            <SearchForm
                                className="form--inline-action project-index__search"
                                buttonClassName="button--cleared button--icon-only"
                                placeholder={placeholder}
                                onSubmit={onSearchSubmit}
                                formFieldType="solid"
                                size="small"
                                query={query}
                                ref={searchInputRef}
                                searchIconPlacement={SearchIconPlacement.field}
                                icon={<IoSearchOutline className="form__field-icon" />}
                            />
                        )}
                    </ProjectIndex>
                </ProjectDetailSidebar>
                <ProjectDetailPublicationMainContent>
                    <Switch>
                        <Route exact path={createProjectDetailPath()} component={ProjectDetailRedirectToFirstChapter} />
                        <Route
                            exact
                            path={createProjectSearchPath()}
                            render={() => <SearchResults tenant={TenantType.SKILZ} />}
                        />
                        <Route
                            exact
                            path={[
                                createChapterDetailPath(),
                                createParagraphDetailPath(),
                                createSubParagraphDetailPath(),
                            ]}
                            component={ChapterDetail}
                        />
                    </Switch>
                </ProjectDetailPublicationMainContent>
            </div>
        </>
    );
};

export default ProjectDetailDirectivePublication;
