import { SideProductOverview, SideProductOverviewItemType } from '../SideProductOverview';

export function createSideProductOverviewItemCollectionFromApiData(apiData: SideProductOverview): SideProductOverview {
    return {
        all: apiData.all.map((data) => {
            return createSideProductOverviewItemFromApiInput(data);
        }),
        publishedRecently: apiData.publishedRecently.map((data) => {
            return createSideProductOverviewItemFromApiInput(data);
        }),
        highlighted: apiData.highlighted.map((data) => {
            return createSideProductOverviewItemFromApiInput(data);
        }),
    };
}

export function createSideProductOverviewItemFromApiInput(apiInput: any): SideProductOverviewItemType {
    return {
        id: apiInput.id,
        title: apiInput.title,
        type: apiInput.type,
        toolType: apiInput.toolType,
        filename: apiInput.filename,
        path: apiInput.path,
        description: apiInput.description,
        highlightText: apiInput.highlightText,
        resourceThumbnailUrl: apiInput.resourceThumbnailUrl,
        resourceUri: apiInput.resourceUri,
        resourceDurationInSeconds: apiInput.resourceDurationInSeconds,
        publishedAt: apiInput.publishedAt,
        breadcrumbs: apiInput.breadcrumbs,
    };
}
