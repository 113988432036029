import jsonStringToDraftEditorStateTransformer from './jsonStringToDraftEditorStateTransformer';
import stringToDraftEditorStateTransformer from './stringToDraftEditorStateTransformer';
import { EditorState } from 'draft-js';

export function checkJsonEditorStatesAreTheSame(
    first: EditorState,
    second: EditorState,
    checkOnStringLevel = false
): boolean {
    // for performance optimization (not having to tranform back to json to compare on string level), knowing
    // that the editor content is immutable, we can do an instance check with === to check for changes.

    if (first.getCurrentContent() === second.getCurrentContent()) {
        return true;
    }

    if (!checkOnStringLevel) {
        return false;
    }

    const { reverseTransform: transformToString } = jsonStringToDraftEditorStateTransformer;

    const firstContentStateAsString = transformToString(first);
    const secondContentStateAsString = transformToString(second);

    return firstContentStateAsString === secondContentStateAsString;
}

export function checkStringEditorStatesAreTheSame(
    first: EditorState,
    second: EditorState,
    checkOnStringLevel = false
): boolean {
    if (first.getCurrentContent() === second.getCurrentContent()) {
        return true;
    }

    if (!checkOnStringLevel) {
        return false;
    }

    // at this point we know there has been a change, but we need to make sure that it is not a change that was
    // reversed (which in memory results in a different instance of the editor content).

    const { reverseTransform: transformToString } = stringToDraftEditorStateTransformer;

    const firstAsString = transformToString(first);
    const secondAsString = transformToString(second);

    return firstAsString === secondAsString;
}

export function checkEditorStateIsEmpty(value: EditorState) {
    const valueAsString = (stringToDraftEditorStateTransformer.reverseTransform(value) || '').trim();

    return valueAsString === '';
}
