import { useEffect, useState } from 'react';
import PaginatedCollection from '../../../../../model/collection/paginatedCollection';
import { findProjectSearchItemsMatching } from '../../../../../repository/projectSearchItemRepository';
import { logError } from '../../../../../utility/Logger';
import ProjectSearchItem from '../../../../../model/ProjectSearchItem';

export default function useFetchSearchResultsOnFilterChange(query: string, page: number) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [toplevelResults, setToplevelResults] = useState<PaginatedCollection<ProjectSearchItem>>(
        new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0)
    );

    const [sideProductResults, setSideProductResults] = useState<PaginatedCollection<ProjectSearchItem>>(
        new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0)
    );

    const [nestedResults, setNestedResults] = useState<PaginatedCollection<ProjectSearchItem>>(
        new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0)
    );

    const resetData = () => {
        setNestedResults(new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0));
        setSideProductResults(new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0));
        setToplevelResults(new PaginatedCollection<ProjectSearchItem>([], 1, 0, 0, 0));
    };

    useEffect(() => {
        resetData();

        if (query.length === 0) {
            return;
        }

        setIsLoading(true);

        findProjectSearchItemsMatching(query, page)
            .then((results) => {
                setToplevelResults(results.toplevelResults);
                setSideProductResults(results.sideProductResults);
                setNestedResults(results.nestedResults);
            })
            .catch((error) => {
                logError(error);
            })
            .finally(() => setIsLoading(false));
    }, [query, page]);

    return { toplevelResults, sideProductResults, nestedResults, isLoading };
}
