import ProjectChapterDetailParagraph from './ProjectChapterDetailParagraph';
import ProjectChapterDetailProject from './ProjectChapterDetailProject';

export default class ProjectChapterDetail {
    _id: string;
    _externalId: string;
    _title: string;
    _index: number;
    _path: string;
    _project: ProjectChapterDetailProject;
    _paragraphs: Array<ProjectChapterDetailParagraph>;
    _authorizedAt: string | undefined | null;
    _reviewedAt: string | undefined | null;

    constructor(
        id: string,
        externalId: string,
        title: string,
        index: number,
        path: string,
        project: ProjectChapterDetailProject,
        paragraphs: Array<ProjectChapterDetailParagraph>,
        authorizedAt?: string | null,
        reviewedAt?: string | null
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._index = index;
        this._path = path;
        this._project = project;
        this._paragraphs = paragraphs;
        this._authorizedAt = authorizedAt;
        this._reviewedAt = reviewedAt;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get index() {
        return this._index;
    }

    get path() {
        return this._path;
    }

    containsPath(path: string) {
        if (this._path === path) {
            return true;
        }

        return this._paragraphs.findIndex((paragraph) => paragraph.containsPath(path)) !== -1; // eslint-disable-line no-magic-numbers
    }

    get project() {
        return this._project;
    }

    get paragraphs() {
        return this._paragraphs;
    }

    get authorizedAt(): string | undefined | null {
        return this._authorizedAt;
    }

    get reviewedAt(): string | undefined | null {
        return this._reviewedAt;
    }
}
