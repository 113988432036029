import type { Cloneable } from './contracts';

// Beware! Make sure these match the server side setup in SideProductType.php
export enum ProjectSideProductType {
    default = 'default',
    summaryCard = 'summaryCard',
    patientTool = 'patientTool',
    apply = 'apply',
    transparency = 'transparency',
    status = 'status',
}

// order matters! 'Aanvullend' should be first as it is the default value for GGZ
export const projectSideProductTypeLabels = {
    [ProjectSideProductType.default]: 'Aanvullend » Aanvullende documenten',
    [ProjectSideProductType.summaryCard]: 'Samenvattingskaart',
    [ProjectSideProductType.apply]: 'Hulpmiddelen',
    [ProjectSideProductType.patientTool]: 'Voor patiënten » Hulpmiddelen',
    [ProjectSideProductType.status]: 'Status » Documenten',
};

// order matters! 'In de praktijk' should be first as it is the default value for SKILZ
export const skilzProjectSideProductTypeLabels = {
    [ProjectSideProductType.apply]: 'In de praktijk',
    [ProjectSideProductType.patientTool]: 'Voor cliënten',
    [ProjectSideProductType.transparency]: 'Bijlagen',
    [ProjectSideProductType.status]: 'Status',
    [ProjectSideProductType.summaryCard]: 'Samenvattingskaart',
};

// Beware! Make sure these match the server side setup in SideProductToolType.php
export enum ProjectSideProductToolType {
    workCard = 'workCard',
    patientToolkit = 'patientToolkit',
    caseCard = 'caseCard',
    video = 'video',
    other = 'other',

    // SKILZ
    processInformation = 'processInformation',
    bottlenecks = 'bottlenecks',
    literatureReview = 'literatureReview',
    tool = 'tool',
    informationCard = 'informationCard',
    summaryCard = 'summaryCard',
    practiceCard = 'practiceCard',
    usefulLinks = 'usefulLinks',
}

export const projectSideProductCategoryGroupLabels = {
    [ProjectSideProductToolType.workCard]: 'Werkkaarten',
    [ProjectSideProductToolType.patientToolkit]: 'Keuzehulpen',
    [ProjectSideProductToolType.caseCard]: 'Casussen',
    [ProjectSideProductToolType.video]: "Video's & webinars",
};

export const projectSideProductToolTypeLabels = {
    [ProjectSideProductToolType.workCard]: 'Werkkaarten',
    [ProjectSideProductToolType.patientToolkit]: 'Keuzehulpen',
    [ProjectSideProductToolType.caseCard]: 'Casussen',
    [ProjectSideProductToolType.video]: "Video's & webinars",
    [ProjectSideProductToolType.other]: 'Overig',

    // SKILZ
    [ProjectSideProductToolType.summaryCard]: 'Samenvattingskaart',
    [ProjectSideProductToolType.practiceCard]: 'Praktijkkaart',
    [ProjectSideProductToolType.tool]: 'Hulpmiddelen',
    [ProjectSideProductToolType.informationCard]: 'Informatiekaart',
    [ProjectSideProductToolType.processInformation]: 'Procesinformatie',
    [ProjectSideProductToolType.bottlenecks]: 'Knelpunten',
    [ProjectSideProductToolType.literatureReview]: 'Literatuuronderzoek',
    [ProjectSideProductToolType.usefulLinks]: 'Handige links',
};

// List below is used for the GGZ dashboard

export const ggzProjectSideProductToolTypeApplyLabels = {
    [ProjectSideProductToolType.workCard]: 'Werkkaarten',
    [ProjectSideProductToolType.patientToolkit]: 'Keuzehulpen',
    [ProjectSideProductToolType.caseCard]: 'Casussen',
    [ProjectSideProductToolType.video]: "Video's & webinars",
    [ProjectSideProductToolType.other]: 'Overig', // default value (determineInitialValues in ProjectSideProductForm)
};

// Lists below are used for the SKILZ dashboard

export const skilzProjectSideProductToolTypeApplyLabels = {
    [ProjectSideProductToolType.summaryCard]: 'Samenvattingskaart',
    [ProjectSideProductToolType.practiceCard]: 'Praktijkkaart',
    [ProjectSideProductToolType.tool]: 'Hulpmiddelen',
    [ProjectSideProductToolType.other]: 'Overig', // default value (determineInitialValues in ProjectSideProductForm)
};

export const skilzProjectSideProductToolTypePatientToolLabels = {
    [ProjectSideProductToolType.informationCard]: 'Informatiekaart',
    [ProjectSideProductToolType.tool]: 'Hulpmiddelen',
    [ProjectSideProductToolType.other]: 'Overig', // default value (determineInitialValues in ProjectSideProductForm)
};

export const skilzProjectSideProductToolTypeTransparencyLabels = {
    [ProjectSideProductToolType.processInformation]: 'Procesinformatie',
    [ProjectSideProductToolType.bottlenecks]: 'Knelpunten',
    [ProjectSideProductToolType.literatureReview]: 'Literatuuronderzoek',
    [ProjectSideProductToolType.other]: 'Overig', // default value (determineInitialValues in ProjectSideProductForm)
};

export const projectSideProductCategorySlugLabels = {
    [ProjectSideProductToolType.workCard]: 'werkkaarten',
    [ProjectSideProductToolType.caseCard]: 'casussen',
    [ProjectSideProductToolType.patientToolkit]: 'keuzehulpen',
    [ProjectSideProductToolType.video]: 'videos',
    [ProjectSideProductToolType.other]: 'overig',
    // SKILZ
    [ProjectSideProductToolType.summaryCard]: 'samenvattingskaart',
    [ProjectSideProductToolType.practiceCard]: 'praktijkkaart',
    [ProjectSideProductToolType.informationCard]: 'informatiekaart',
    [ProjectSideProductToolType.tool]: 'hulpmiddelen',
    [ProjectSideProductToolType.processInformation]: 'proces-informatie',
    [ProjectSideProductToolType.bottlenecks]: 'knelpunten',
    [ProjectSideProductToolType.literatureReview]: 'literatuuronderzoek',
    [ProjectSideProductToolType.usefulLinks]: 'handige-links',
};

export default class ProjectSideProduct implements Cloneable<ProjectSideProduct> {
    _id: string;
    _title: string;
    _type: ProjectSideProductType;
    _toolType: ProjectSideProductToolType;
    _filename: string | undefined | null;
    _resourceUri: string | null;
    _resourceThumbnailUrl: string | null;
    _resourceDurationInSeconds: number | null;
    _path: string | undefined | null;
    _description: string | null;
    _highlightText: string | null;
    _visibleOnOverview: boolean | null;
    _breadcrumbs: string[] | null;

    constructor(
        id: string,
        title: string,
        type: ProjectSideProductType,
        toolType: ProjectSideProductToolType,
        filename: string | undefined | null,
        resourceUri: string | null,
        resourceThumbnailUrl: string | null,
        resourceDurationInSeconds: number | null,
        path: string | undefined | null,
        description: string | null,
        highlightText: string | null,
        visibleOnOverview: boolean | null,
        breadcrumbs: string[] | null
    ) {
        this._id = id;
        this._title = title;
        this._type = type;
        this._toolType = toolType;
        this._filename = filename;
        this._resourceUri = resourceUri;
        this._resourceThumbnailUrl = resourceThumbnailUrl;
        this._resourceDurationInSeconds = resourceDurationInSeconds;
        this._path = path;
        this._description = description;
        this._highlightText = highlightText;
        this._visibleOnOverview = visibleOnOverview;
        this._breadcrumbs = breadcrumbs;
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get type(): ProjectSideProductType {
        return this._type;
    }

    set type(type: ProjectSideProductType) {
        this._type = type;
    }

    get toolType(): ProjectSideProductToolType {
        return this._toolType;
    }

    set toolType(toolType: ProjectSideProductToolType) {
        this._toolType = toolType;
    }

    get filename(): string | undefined | null {
        return this._filename;
    }

    set filename(filename: string | undefined | null) {
        this._filename = filename;
    }

    set resourceUri(resourceUri: string | null) {
        this._resourceUri = resourceUri;
    }

    set resourceThumbnailUrl(resourceThumbnailUrl: string | null) {
        this._resourceThumbnailUrl = resourceThumbnailUrl;
    }

    set resourceDurationInSeconds(resourceDurationInSeconds: number | null) {
        this._resourceDurationInSeconds = resourceDurationInSeconds;
    }

    set breadcrumbs(breadcrumbs: string[] | null) {
        this._breadcrumbs = breadcrumbs;
    }

    get path(): string | undefined | null {
        return this._path;
    }

    get description() {
        return this._description;
    }

    set description(description: string | null) {
        this._description = description;
    }

    get highlightText() {
        return this._highlightText;
    }

    set highlightText(highlightText: string | null) {
        this._highlightText = highlightText;
    }

    get visibleOnOverview() {
        return this._visibleOnOverview;
    }

    set visibleOnOverview(visibleOnOverview: boolean | null) {
        this._visibleOnOverview = visibleOnOverview;
    }

    get resourceUri() {
        return this._resourceUri;
    }

    get resourceThumbnailUrl() {
        return this._resourceThumbnailUrl;
    }

    get resourceDurationInSeconds() {
        return this._resourceDurationInSeconds;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }

    clone(): ProjectSideProduct {
        return new ProjectSideProduct(
            this._id,
            this._title,
            this._type,
            this._toolType,
            this._filename,
            this._resourceUri,
            this._resourceThumbnailUrl,
            this._resourceDurationInSeconds,
            this._path,
            this._description,
            this._highlightText,
            this._visibleOnOverview,
            this._breadcrumbs
        );
    }
}
