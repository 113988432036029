import { toast } from 'react-toastify';
import { post } from '../../../../../api/client/apiClient';
import { logError } from '../../../../../utility/Logger';
import { createSubscribeToNewsletterApiPath } from '../../../../../routing/apiUrlGenerator';

const skilzSuccesMessage =
    "Hartelijk dank voor je inschrijving! Je kunt op elk moment weer uitschrijven via de 'unsubscribe'-link onderaan elke nieuwsbrief.";
const ggzSuccesMessage =
    "Hartelijk dank voor je inschrijving! Binnen 2 weken ontvang je de nieuwsbrief per e-mail. Je kunt op elk moment weer uitschrijven via de 'unsubscribe'-link onderaan elke nieuwsbrief.";

export const subscribeToNewsletter = async (email: string, isSKILZ?: boolean): Promise<boolean> => {
    try {
        await post(createSubscribeToNewsletterApiPath(), {
            email,
        });

        toast.success(isSKILZ ? skilzSuccesMessage : ggzSuccesMessage, {
            autoClose: 10000,
        });

        return true;
    } catch (error) {
        logError(error, {
            action: 'Push newsletter to subscription server',
        });

        toast.error('Er is iets mis gegaan bij het inschrijven. Probeer het later nog eens.');

        return false;
    }
};
