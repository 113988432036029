import * as React from 'react';
import createClassName from 'classnames';
import Tippy, { TippyProps } from '@tippyjs/react';

type Props = {
    isLarge?: boolean;
} & TippyProps;

const Popover = ({ content, children, isLarge = false, className = '', ...otherProps }: Props) => {
    const composedClassName = createClassName(className, {
        'popover--large': isLarge,
    });

    // When using decide if you want to control the visible state or pass a trigger prop with 'click'
    // We can't set a default because of warning when both become active: https://github.com/atomiks/tippyjs-react#visible-boolean-controlled-mode
    return (
        <Tippy
            content={content}
            interactive={true}
            appendTo={document.body} // breaks accessibility for interactivity but otherwise styles from parent will be inherited
            theme="brand-secondary popover"
            maxWidth="80vw"
            className={composedClassName}
            {...otherProps}
        >
            {children}
        </Tippy>
    );
};

export default Popover;
