import * as React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    isVertical?: boolean;
    isLarge?: boolean;
    alignItems?: 'vertically' | 'horizontally';
};

const HeadingGroup = ({ children, isVertical = false, isLarge = false, alignItems }: Props) => {
    const classname = createClassName('heading-group', {
        'heading-group--is-vertical': isVertical,
        'heading-group--is-large': isLarge,
        'heading-group--align-vertically': alignItems === 'vertically',
        'heading-group--align-horizontally': alignItems === 'horizontally',
    });

    return <div className={classname}>{children}</div>;
};

export default HeadingGroup;
