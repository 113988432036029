import React, { FC, MouseEventHandler } from 'react';
import parse from 'html-react-parser';
import Breadcrumb from '../../../../shared/breadcrumb/Breadcrumb';
import { createBreadcrumbItemWithTitleOnly } from '../../../../../model/factory/breadcrumbItemFactory';
import BreadcrumbItem from '../../../../shared/breadcrumb/components/BreadcrumbItem';
import TruncateMarkup from 'react-truncate-markup';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';
import ProjectSearchItem, { ProjectSearchItemType } from '../../../../../model/ProjectSearchItem';
import useSaveClickedResultForFutureReference from '../../../shared/searchResults/hooks/useSaveClickedResultForFutureReference';
import useNavigateToResultAfterSearchResultIsSaved from '../../../shared/searchResults/hooks/useNavigateToResultAfterSearchResultIsSaved';
import Heading from '../../../../shared/heading/Heading';
import styles from './SearchResultOverview.module.scss';

type Props = {
    result: ProjectSearchItem;
    breadcrumbItems?: Array<string>;
};

const searchItemTypesWithIndex = [
    ProjectSearchItemType.Project,
    ProjectSearchItemType.Chapter,
    ProjectSearchItemType.Paragraph,
    ProjectSearchItemType.SubParagraph,
];

const NestedResult: FC<Props> = ({ result, breadcrumbItems = [] }) => {
    const { onLastSearchResultSaved } = useNavigateToResultAfterSearchResultIsSaved();
    const { onResultClick } = useSaveClickedResultForFutureReference(onLastSearchResultSaved);

    const onClick: MouseEventHandler = (event) => {
        event.preventDefault();

        onResultClick(result);
    };

    const titlePrefix = searchItemTypesWithIndex.includes(result.type)
        ? `${generateSectionNumbering(...result.indexes)}.`
        : '';

    return (
        <article className={styles.nestedResultWrapper}>
            <a href={result.path} onClick={onClick} className={styles.nestedResult}>
                {breadcrumbItems.length > 0 && (
                    <div>
                        <Breadcrumb>
                            {breadcrumbItems
                                .map((title) => createBreadcrumbItemWithTitleOnly(title))
                                .map((item) => (
                                    <BreadcrumbItem key={item.id} item={item} />
                                ))}
                        </Breadcrumb>
                    </div>
                )}
                <Heading tag="h2" variant="h5" className={styles.title} flatten>
                    {titlePrefix} {parse(result.title)}
                </Heading>
                {result.description && (
                    <TruncateMarkup lines={3}>
                        <p className={styles.description}>{parse(result.description)}</p>
                    </TruncateMarkup>
                )}
            </a>
        </article>
    );
};

export default NestedResult;
