import { Cloneable } from '../contracts';

export default class ParagraphDetailLastUpdatedInformation implements Cloneable<ParagraphDetailLastUpdatedInformation> {
    private readonly _lastUpdatedAt: string;
    private readonly _lastUpdatedBy: string | null;

    constructor(lastUpdatedAt: string, lastUpdatedBy: string | null) {
        this._lastUpdatedAt = lastUpdatedAt;
        this._lastUpdatedBy = lastUpdatedBy;
    }

    get lastUpdatedAt() {
        return this._lastUpdatedAt;
    }

    get lastUpdatedBy() {
        return this._lastUpdatedBy;
    }

    clone(): ParagraphDetailLastUpdatedInformation {
        return new ParagraphDetailLastUpdatedInformation(this._lastUpdatedAt, this._lastUpdatedBy);
    }
}
