import ProjectDetailLink from '../ProjectDetailLink';

const createProjectDetailLinkFromApiInput = (apiInput: any): ProjectDetailLink =>
    new ProjectDetailLink(
        apiInput.id,
        apiInput.title,
        apiInput.url,
        apiInput.type,
        apiInput.description,
        apiInput.highlight_text,
        apiInput.tool_type
    );

export const createProjectDetailLinkFromPendingActionPayload = ({
    id,
    title,
    url,
    type,
    description,
    highlightText,
    toolType,
}: any) => new ProjectDetailLink(id, title, url, type, description, highlightText, toolType);

export const createProjectDetailLinkCollectionFromApiInput = (apiInput: Array<any>): Array<ProjectDetailLink> =>
    apiInput.map((apiInputItem) => createProjectDetailLinkFromApiInput(apiInputItem));
