import React from 'react';
import NewsletterSubscriptionModal from './components/NewsletterSubscriptionModal';
import useHandleNewsletterSubscriptionAnswer from './hooks/useHandleNewsletterSubscriptionAnswer';
import useHandleNewsletterModalVisibility from './hooks/useHandleNewsletterModalVisibility';
import NewsletterSubscriptionForm from './components/NewsletterSubscriptionForm';
import NewsletterSubscriptionPopup from './components/NewsletterSubscriptionPopup';
import Heading from '../../../shared/heading/Heading';
// import Button, { ButtonStyles } from '../../../shared/buttons/Button';

type Props = {
    type?: 'modal' | 'mini-popup';
};

const SubscribeToNewsletter: React.FC<Props> = ({ type = 'footer' }) => {
    const { visible, hideModal } = useHandleNewsletterModalVisibility(!!type);

    const { onValidSubmit, onDecline } = useHandleNewsletterSubscriptionAnswer(hideModal, type);

    if (!visible && type !== 'footer') {
        return null;
    }

    const renderForm = () => (
        <NewsletterSubscriptionForm
            onValidSubmit={onValidSubmit}
            trackingData={`newsletter-subscribe-${type}`}
            highlightButton={type === 'mini-popup'}
        />
    );

    if (type === 'modal') {
        return (
            <NewsletterSubscriptionModal
                onDecline={onDecline}
                // title={'Verbeter de herstelgerichte zorg'}
                title={'Wil jij op de hoogte blijven van de zorgstandaarden?'}
            >
                <p>
                    Geef je, net als duizenden andere ggz-professionals, op voor de 2-wekelijkse nieuwsbrief van Akwa
                    GGZ.
                    {/*Geef je mening en vul de enquête in 10 min. in. Je maakt kans op een boekenbon.*/}
                </p>
                {renderForm()}
                {/*<a href={'https://evaluatie-zorgstandaarden.nl/'} target="_blank" rel="noreferrer">*/}
                {/*    <Button style={ButtonStyles.primary}>Invullen of meer weten</Button>*/}
                {/*</a>*/}
            </NewsletterSubscriptionModal>
        );
    }

    if (type === 'mini-popup') {
        return (
            <NewsletterSubscriptionPopup onDecline={onDecline}>
                <Heading tag="h4" flatten>
                    Wil jij op de hoogte blijven van de zorg­standaarden?
                </Heading>
                <p>
                    Geef je, net als duizenden andere ggz-professionals, op voor de 2-wekelijkse nieuwsbrief van Akwa
                    GGZ.
                </p>
                {renderForm()}
            </NewsletterSubscriptionPopup>
        );
    }

    return renderForm();
};

export default SubscribeToNewsletter;
