import React from 'react';
import { Link } from 'react-router-dom';
import { createDisclaimerPagePath, createPrivacyAndCookiesPagePath } from '../../../../routing/urlGenerator';
import useDisplayAfterTimeout from '../../../../hooks/useDisplayAfterTimeout';

const DISPLAY_TIMEOUT = 500; // half a seconds

const Footer = () => {
    const { visible } = useDisplayAfterTimeout(DISPLAY_TIMEOUT);

    if (!visible) {
        return null;
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="layout">
                    <div className="layout__item layout__item--md-4">
                        <h4 className="footer__heading">GGZ Standaarden</h4>
                        <p>Deze kennisbank is ontwikkeld door en in beheer van Akwa GGZ.</p>
                    </div>
                    <div className="layout__item layout__item--md-4">
                        <h4 className="footer__heading">Contact</h4>
                        <address className="footer__contact">
                            <a href="tel:0303079270" className="footer__link" target="_blank" rel="noopener noreferrer">
                                030-307 92 70
                            </a>
                            <a
                                href="mailto:ggzstandaarden@akwaggz.nl"
                                className="footer__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ggzstandaarden@akwaggz.nl
                            </a>
                        </address>
                    </div>
                    <div className="layout__item layout__item--md-4">
                        <h4 className="footer__heading">Kijk ook op</h4>
                        <a href="https://akwaggz.nl" className="footer__link" target="_blank" rel="noopener noreferrer">
                            akwaggz.nl
                        </a>
                        <a
                            href="https://ggzdataportaal.nl"
                            className="footer__link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ggzdataportaal.nl
                        </a>
                        <a
                            href="https://ggznetwerken.nl"
                            className="footer__link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ggznetwerken.nl
                        </a>
                    </div>
                </div>
                <nav role="navigation" className="footer__navigation">
                    <Link to={createDisclaimerPagePath()}>Algemene voorwaarden</Link>
                    <Link to={createPrivacyAndCookiesPagePath()}>Privacy- en cookiebeleid</Link>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
