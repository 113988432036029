import {
    FETCH_CURRENT_USER,
    EDIT_PROFILE,
    ADD_BOOKMARK,
    REMOVE_BOOKMARK,
    ADD_BOOKMARK_TAG,
    EDIT_BOOKMARK_TAG,
    DELETE_BOOKMARK_TAG,
    GET_BOOKMARKS,
    CHANGE_CURRENT_USER_AUTHENTICATION_STATUS,
} from '../actions/types';
import { ActionType } from 'redux-promise-middleware';
import { createUserFromApiData } from '../model/factory/userFactory';
import {
    createBookmarkFromUserApiData,
    createBookmarkSimpleFromAddBookmarkFulfilledAction,
    createBookmarkTagFromAddBookmarkTagPendingAction,
} from '../model/factory/bookmarkFactory';
import User from '../model/User';
import type { Action } from '../actions/factory';

export type CurrentUserReducerState = User | null;

function _handleEditProfilePendingAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const payload: {
            [x: string]: any;
        } = action.payload,
        newState = currentState.clone();

    newState.firstName = payload.firstName;
    newState.preposition = payload.preposition;
    newState.lastName = payload.lastName;
    newState.profession = payload.profession;

    return newState;
}

function _handleAddBookMarkFulfilledAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.bookmarks.push(createBookmarkSimpleFromAddBookmarkFulfilledAction(action));

    return newState;
}

function _handleRemoveBookMarkPendingAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const meta = action.meta,
        newState = currentState.clone();

    newState.removeBookmark(meta.externalId);

    return newState;
}

function _handeGetCurrentUserBookmarksFulfilledAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const bookmarks = action.payload.data,
        newState = currentState.clone();

    // @ts-ignore -> typescript does not know the action contents
    newState.bookmarks = bookmarks.map((bookmark) => createBookmarkFromUserApiData(bookmark));

    return newState;
}

function _handleAddBookmarkTagPending(currentState: CurrentUserReducerState, action: Action) {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.addBookmarkTag(createBookmarkTagFromAddBookmarkTagPendingAction(action));

    return newState;
}

function _handleAddBookmarkTagFullfilled(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    // @ts-ignore -> typescript does not know the action contents
    const externalId = action.payload.data.id;

    const id = action.meta.id,
        newState = currentState.clone();

    const bookmarkTag = newState.findBookmarkTag(id);

    if (!bookmarkTag) {
        return currentState;
    }

    bookmarkTag.externalId = externalId;

    return newState;
}

function _handleAddBookmarkTagRejected(currentState: CurrentUserReducerState, action: Action): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const newState = currentState.clone(),
        idToDelete = action.meta.id;

    newState.removeBookmarkTagWithId(idToDelete);

    return newState;
}

function _handleEditBookmarkTagPendingAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const newState = currentState.clone(),
        bookmarkTag = newState.findBookmarkTagWithExternalId(action.meta.externalId);

    if (!bookmarkTag) {
        return currentState;
    }

    bookmarkTag.title = action.payload.title;

    return newState;
}

function _handleDeleteBookmarkTagPendingAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    if (!(currentState instanceof User)) {
        return currentState;
    }

    const newState = currentState.clone();

    newState.removeBookmarkTag(action.meta.externalId);

    return newState;
}

function _handleChangeCurrentUserAuthenticationStatusAction(
    currentState: CurrentUserReducerState,
    action: Action
): CurrentUserReducerState {
    // @ts-ignore -> typescript does not know the action contents
    if (action.payload.data.isLoggedIn === false) {
        return null;
    }

    return currentState;
}

export default function (currentState: CurrentUserReducerState = null, action: Action): CurrentUserReducerState {
    switch (action.type) {
        case `${FETCH_CURRENT_USER}_${ActionType.Fulfilled}`:
            // @ts-ignore -> typescript does not know the action contents
            return createUserFromApiData(action.payload.data.result);

        case `${EDIT_PROFILE}_${ActionType.Pending}`:
            return _handleEditProfilePendingAction(currentState, action);

        case `${ADD_BOOKMARK}_${ActionType.Fulfilled}`:
            return _handleAddBookMarkFulfilledAction(currentState, action);

        case `${REMOVE_BOOKMARK}_${ActionType.Pending}`:
            return _handleRemoveBookMarkPendingAction(currentState, action);

        case `${GET_BOOKMARKS}_${ActionType.Fulfilled}`:
            return _handeGetCurrentUserBookmarksFulfilledAction(currentState, action);

        case `${ADD_BOOKMARK_TAG}_${ActionType.Pending}`:
            return _handleAddBookmarkTagPending(currentState, action);

        case `${ADD_BOOKMARK_TAG}_${ActionType.Fulfilled}`:
            return _handleAddBookmarkTagFullfilled(currentState, action);

        case `${ADD_BOOKMARK_TAG}_${ActionType.Rejected}`:
            return _handleAddBookmarkTagRejected(currentState, action);

        case `${EDIT_BOOKMARK_TAG}_${ActionType.Pending}`:
            return _handleEditBookmarkTagPendingAction(currentState, action);

        case `${DELETE_BOOKMARK_TAG}_${ActionType.Pending}`:
            return _handleDeleteBookmarkTagPendingAction(currentState, action);

        case `${CHANGE_CURRENT_USER_AUTHENTICATION_STATUS}`:
            return _handleChangeCurrentUserAuthenticationStatusAction(currentState, action);

        default:
            return currentState;
    }
}
