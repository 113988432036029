import React from 'react';
import PropTypes from 'prop-types';
import createClassName from 'classnames';
import useCurrentTenant from '../../../../hooks/useCurrentTenant';

type Props = {
    children: React.ReactNode;
    isSmall?: boolean;
    isLarge?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isQuaternary?: boolean;
};

const DashboardIsland = ({ children, isSmall, isLarge, isPrimary, isSecondary, isQuaternary }: Props) => {
    const { isSKILZ } = useCurrentTenant();

    const className: string = createClassName('island', {
        'dashboard__island--small': isSmall,
        'island--large': isLarge,
        'dashboard__island island--light': isPrimary,
        'dashboard__island-secondary': isSecondary,
        'island--quaternary': isQuaternary,
        'dashboard__island--shadow': isPrimary && isSKILZ,
    });

    return <article className={className}>{children}</article>;
};

DashboardIsland.defaultProps = {
    isSmall: false,
    isLarge: false,
    isPrimary: true,
    isSecondary: false,
    isQuaternary: false,
};

DashboardIsland.propTypes = {
    isSmall: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DashboardIsland;
