import React from 'react';
import type { PermissionRequirementCallback } from '../../../security/types';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import { ARTICLE_OVERVIEW_UNPUBLISHED } from '../../../security/SecurityAttribute';
import requireAuthenticationStatus from '../../../hoc/RequireAuthenticatedStatus';
import { compose } from 'redux';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../helper/seoHelper';
import HeadingGroup from '../shared/headings/HeadingGroup';
import DashboardContent from '../shared/containers/DashboardContent';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardIsland from '../shared/island/DashboardIsland';
import ArticleOverviewList from './component/ArticleOverviewList';
import ArticleOverviewListItem from './component/ArticleOverviewListItem';
import CollectionPagination, { OnPageChangeCallback } from '../../frontend/shared/CollectionPagination';
import CreateArticleLink from './component/CreateArticleLink';
import CreateArticleSecurityThreshold from './component/CreateArticleSecurityThreshold';
import DeleteArticle from '../deleteArticle/DeleteArticle';
import { createArticleDetailPath } from '../../../routing/urlGenerator';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useFetchArticlesOnFilterChange from '../../../hooks/useFetchArticlesOnFilterChange';
import { extractNumberFromQueryString } from '../../../resolver/queryStringParameterResolver';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import {
    createDashboardArticleOverviewPath,
    createDashboardArticleUpdatePath,
    createDashboardEntryPath,
} from '../../../routing/dashboardUrlGenerator';

const ArticleOverview = () => {
    const history = useHistory();

    const location = useLocation();

    const page = extractNumberFromQueryString(location, 'page', 1);

    const { articles, refetchCurrentPage } = useFetchArticlesOnFilterChange(
        { page, includeUnpublished: true },
        true,
        () => {
            history.push(createDashboardEntryPath());
        }
    );

    const onPageChange: OnPageChangeCallback = (newPage) => {
        history.push(createDashboardArticleOverviewPath(newPage));
    };

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{composeSeoTitle(null, 'Artikelen')}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading>Artikelen</DashboardContentHeading>
                    <CreateArticleSecurityThreshold>
                        <CreateArticleLink />
                    </CreateArticleSecurityThreshold>
                </HeadingGroup>
                {articles && articles.results.length > 0 && (
                    <DashboardIsland>
                        <ArticleOverviewList>
                            {articles.results.map((article) => (
                                <ArticleOverviewListItem article={article} key={article.id}>
                                    <a
                                        href={createArticleDetailPath(article.id, article.slug)}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="button button--cleared button--icon-only icon icon-search"
                                    />
                                    <Link
                                        to={createDashboardArticleUpdatePath(article.id)}
                                        className="button button--cleared button--icon-only icon icon-pencil"
                                    />
                                    <DeleteArticle article={article} onDone={() => refetchCurrentPage()} />
                                </ArticleOverviewListItem>
                            ))}
                        </ArticleOverviewList>
                        <CollectionPagination
                            collection={articles}
                            currentPage={articles.currentPage}
                            onPageChange={onPageChange}
                        />
                    </DashboardIsland>
                )}
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(ARTICLE_OVERVIEW_UNPUBLISHED));
};

export default compose(
    requireAuthenticationStatus,
    requireAuthorizedPermissions(getRequiredPermissions)
)(ArticleOverview);
