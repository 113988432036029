import ProjectMember from './ProjectMember';
import type { Cloneable } from '../contracts';

class WorkingGroupMember extends ProjectMember implements Cloneable<WorkingGroupMember> {
    clone(): WorkingGroupMember {
        return new WorkingGroupMember(this._id, this._externalUserId, this._name, this._branch);
    }
}

export default WorkingGroupMember;
