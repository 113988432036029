import ProjectIndexParagraph from './ProjectIndexParagraph';

export default class ProjectIndexChapter {
    _id: string;
    _externalId: string;
    _title: string;
    _paragraphs: Array<ProjectIndexParagraph>;
    _path: string;

    constructor(id: string, externalId: string, title: string, paragraphs: Array<ProjectIndexParagraph>, path: string) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._paragraphs = paragraphs;
        this._path = path;
    }

    get id() {
        return this._id;
    }

    get externalId() {
        return this._externalId;
    }

    get title() {
        return this._title;
    }

    get paragraphs() {
        return this._paragraphs;
    }

    get path() {
        return this._path;
    }

    containsPath(path: string) {
        if (this._path === path) {
            return true;
        }

        return this._paragraphs.findIndex((paragraph) => paragraph.containsPath(path)) !== -1; // eslint-disable-line no-magic-numbers
    }
}
