import React from 'react';
import { ContentBlock, ContentState } from 'draft-js';

type Props = {
    html?: string;
    contentState?: ContentState;
    block?: ContentBlock;
};

// there is no built in table support, thus tables are not editable in Draft.js editor
const Table = ({ html, contentState, block: contentBlock }: Props) => {
    if (!html && !contentState && contentBlock) return null;

    let entityKey;
    let htmlToBeRendered = html ?? '';

    if (!html && contentBlock && contentState) {
        entityKey = contentBlock.getEntityAt(0);
        const { sanitizedHTML } = contentState.getEntity(entityKey).getData();
        htmlToBeRendered = sanitizedHTML;
    }

    return (
        <div className="block-list__table">
            <div dangerouslySetInnerHTML={{ __html: htmlToBeRendered }} />
        </div>
    );
};

export default Table;
