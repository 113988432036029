import { nl } from 'date-fns/locale';
import { format, formatDistanceToNow } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy';

export const FORMAT_TIME = 'HH:mm';
export const FORMAT_DAY_LONG = 'd MMMM yyyy';
export const FORMAT_DAY_MEDIUM = 'd MMMM';

export function formatDateSimplified(value: Date): string {
    return value.toLocaleDateString('nl-NL', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    })
}

export function formatDurationTillNow(dateTime: Date | string): string {
    let normalizedValue: Date;

    if (typeof dateTime === 'string') {
        normalizedValue = new Date(dateTime);
    } else {
        normalizedValue = dateTime;
    }

    return formatDistanceToNow(normalizedValue, { includeSeconds: true, locale: nl, addSuffix: true });
}

export function formatTime(value: string | Date): string {
    return formatDate(value, FORMAT_TIME);
}

export function formatLongDay(value: string | Date): string {
    return formatDate(value, FORMAT_DAY_LONG);
}

export function formatMediumDay(value: string | Date): string {
    return formatDate(value, FORMAT_DAY_MEDIUM);
}

export function formatShortestReadableDay(value: Date): string {
    const isThisYear = value.getFullYear() === new Date().getFullYear();

    return isThisYear ? formatMediumDay(value) : formatLongDay(value);
}

export function formatNow(format: string = DEFAULT_DATE_FORMAT): string {
    return formatDate(new Date(), format);
}

export function formatLongDateTime(value: string | Date): string {
    const date = formatLongDay(value);
    const time = formatTime(value);

    return `${date}, op ${time}u`;
}

export function formatDate(value: string | Date, formatPattern: string = DEFAULT_DATE_FORMAT): string {
    let normalizedValue: Date;

    if (typeof value === 'string') {
        normalizedValue = new Date(value);
    } else {
        normalizedValue = value;
    }

    return format(normalizedValue, formatPattern, { locale: nl });
}
