import React, { FC, useState } from 'react';
import Button from '../../../shared/buttons/Button';
import type { FormValues } from './components/AnonymousFeedbackForm';
import AnonymousFeedbackForm from './components/AnonymousFeedbackForm';
import { toast } from 'react-toastify';
import { post } from '../../../../api/client/apiClient';
import { logError } from '../../../../utility/Logger';
import Loader from '../../../shared/Loader';
import FeedbackIcon from '../../../../../../icons/ggz/feedback.svg';
import Popover from '../../../shared/Popover';
import { createSupplyFeedbackApiPath } from '../../../../routing/apiUrlGenerator';
import useSettings from '../../../../hooks/useSettings';
import { TenantType } from '../../../../constants/settings';
import { IoChatboxOutline } from 'react-icons/io5';

// Beware! Keep these inline with those defined in GGZ\Bundle\ProjectBundle\Dto\ProjectFeedbackDto!!
export const ANONYMOUS_FEEDBACK_TYPE_PROJECT = 'project';
export const ANONYMOUS_FEEDBACK_TYPE_CHAPTER = 'chapter';
export const ANONYMOUS_FEEDBACK_TYPE_PARAGRAPH = 'paragraph';
export const ANONYMOUS_FEEDBACK_TYPE_SUBPARAGRAPH = 'sub_paragraph';

export type AnonymousFeedbackTargetType = 'project' | 'chapter' | 'paragraph' | 'sub_paragraph';

export type AnonymousFeedbackTarget = {
    type: AnonymousFeedbackTargetType;
    id: string;
};

type Props = {
    target: AnonymousFeedbackTarget;
};

const AnonymousFeedback: FC<Props> = ({ target }) => {
    const settings = useSettings();
    const [showPopover, setShowPopover] = useState(false);
    const [isPushingFeedbackToServer, setIsPushingFeedbackToServer] = useState(false);

    if (!settings?.featureFlags.anonymousFeedbackEnabled || !settings.context.tenant) return null;

    const onPushToServerSuccess = () => {
        setIsPushingFeedbackToServer(false);
        setShowPopover(false);

        toast.success(
            'Bedankt voor je feedback! Als je je e-mailadres ingevuld hebt, koppelen we terug wat we ermee gedaan hebben!'
        );
    };

    const onPushToServerFailed = (error: Error) => {
        setIsPushingFeedbackToServer(false);
        setShowPopover(false);

        toast.error('Er is iets foutgegaan bij het verzenden van je feedback. Probeer het later nog eens!');

        logError(error);
    };

    const onFormSubmittedAndValid = (values: FormValues) => {
        setIsPushingFeedbackToServer(true);

        const body = {
            url: window.location.href,
            targetType: target.type,
            targetId: target.id,
            feedback: values.feedback,
            email: values.email,
        };

        post(createSupplyFeedbackApiPath(), body).then(onPushToServerSuccess).catch(onPushToServerFailed);
    };

    return (
        <Popover
            visible={showPopover}
            placement="right"
            isLarge
            theme="primary popover"
            content={
                isPushingFeedbackToServer ? (
                    <Loader />
                ) : (
                    <AnonymousFeedbackForm
                        onSubmittedAndValid={onFormSubmittedAndValid}
                        onCancelClick={() => setShowPopover(false)}
                    />
                )
            }
        >
            <Button
                onClick={() => setShowPopover((currentShowPopover) => !currentShowPopover)}
                additionalClassName="heading__content-action button--icon-only"
                isCleared
            >
                {settings.context.tenant === TenantType.GGZ ? (
                    <FeedbackIcon className="button__icon" />
                ) : (
                    <IoChatboxOutline className="button__icon" />
                )}
            </Button>
        </Popover>
    );
};

export default AnonymousFeedback;
