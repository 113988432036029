import useTrackVisitedPageTypeOnChange from '../../../../../hooks/useTrackVisitedPageTypeOnChange';
import { pageType } from '../../../../../constants/pageTypes';

export default function useTrackVisitedPageTypeForSearch(query: string, page: number) {
    useTrackVisitedPageTypeOnChange(
        pageType.globalSearch,
        {
            query,
            page,
        },
        [query, page]
    );
}
