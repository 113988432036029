import { CHANGE_CURRENT_USER_AUTHENTICATION_STATUS } from '../actions/types';

import type { Action } from '../actions/factory';

export type AuthenticationReducerState = boolean;

export default function authenticatedReducer(
    currentState: AuthenticationReducerState = false,
    action: Action
): AuthenticationReducerState {
    switch (action.type) {
        case CHANGE_CURRENT_USER_AUTHENTICATION_STATUS:
            // @ts-ignore -> typescript does not know the action contents
            return !!action.payload.data.isLoggedIn;

        default:
            return currentState;
    }
}
