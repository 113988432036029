import React from 'react';
import { PermissionRequirementCallback } from '../../../security/types';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FAQ_ITEM_CREATE, FAQ_ITEM_DELETE } from '../../../security/SecurityAttribute';
import { compose } from 'redux';
import requireAuthenticationStatus from '../../../hoc/RequireAuthenticatedStatus';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import { Helmet } from 'react-helmet';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import { composeSeoTitle } from '../../../helper/seoHelper';
import HeadingGroup from '../shared/headings/HeadingGroup';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import DashboardContent from '../shared/containers/DashboardContent';
import SecurityThreshold from '../../shared/security/SecurityThreshold';
import {
    createDashboardEntryPath,
    createDashboardFaqCreatePath,
    createDashboardFaqOverviewPath,
    createDashboardFaqUpdatePath,
} from '../../../routing/dashboardUrlGenerator';
import DashboardIsland from '../shared/island/DashboardIsland';
import List from '../shared/list/List';
import useRefetchFaqItemsOnFilterChange from '../../../hooks/useRefetchFaqItemsOnFilterChange';
import { extractNumberFromQueryString } from '../../../resolver/queryStringParameterResolver';
import CollectionPagination, { OnPageChangeCallback } from '../../frontend/shared/CollectionPagination';
import FaqOverviewListItem from './components/FaqOverviewListItem';
import DeleteFaq from '../deleteFaq/DeleteFaq';

const FaqOverview = () => {
    const { tenant } = useCurrentTenant();
    const history = useHistory();
    const location = useLocation();
    const page = extractNumberFromQueryString(location, 'page', 1);
    const { faqItems, fetchCurrentPage } = useRefetchFaqItemsOnFilterChange({ page }, true, () =>
        history.push(createDashboardEntryPath())
    );

    if (!faqItems) return null;

    const onPageChange: OnPageChangeCallback = (newPage) => {
        history.push(createDashboardFaqOverviewPath(newPage));
    };

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{composeSeoTitle(tenant, 'Veelgestelde vragen')}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading>Veelgestelde vragen</DashboardContentHeading>
                    <SecurityThreshold requiredAttributes={FAQ_ITEM_CREATE}>
                        <Link
                            to={createDashboardFaqCreatePath()}
                            className="button button--primary button--small icon-plus"
                        >
                            Vraag toevoegen
                        </Link>
                    </SecurityThreshold>
                </HeadingGroup>
                <DashboardIsland>
                    <List>
                        {faqItems.results.map((faqItem) => (
                            <FaqOverviewListItem key={faqItem.id} faqItem={faqItem}>
                                <Link
                                    to={createDashboardFaqUpdatePath(faqItem.id)}
                                    className="button button--cleared button--icon-only icon icon-pencil"
                                />
                                <SecurityThreshold requiredAttributes={FAQ_ITEM_DELETE}>
                                    <DeleteFaq faqItem={faqItem} onDone={fetchCurrentPage} />
                                </SecurityThreshold>
                            </FaqOverviewListItem>
                        ))}
                    </List>
                    <CollectionPagination
                        collection={faqItems}
                        currentPage={faqItems.currentPage}
                        onPageChange={onPageChange}
                    />
                </DashboardIsland>
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(FAQ_ITEM_CREATE));
};

export default compose(requireAuthenticationStatus, requireAuthorizedPermissions(getRequiredPermissions))(FaqOverview);
