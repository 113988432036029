import * as React from 'react';
import { composeSeoTitle } from '../../../../../helper/seoHelper';
import { Helmet } from 'react-helmet';
import ProjectChapterDetail from '../../../../../model/projectChapterDetail/ProjectChapterDetail';
import { generateSectionNumbering } from '../../../../../utility/projectSectionNumberingGenerator';

type Props = {
    details: ProjectChapterDetail;
};

const ChapterDetailMeta = ({ details }: Props) => {
    const numbering = generateSectionNumbering(details.index);
    const seoTitle = composeSeoTitle(null, `${numbering}. ${details.title}`, details.project.title);

    return (
        <Helmet>
            <title>{seoTitle}</title>
        </Helmet>
    );
};

export default ChapterDetailMeta;
