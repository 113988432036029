import type { OnClickCallback } from '../../../ggz/searchResults/components/SearchResult';
import { useHistory } from 'react-router-dom';

export default function useNavigateToResultAfterSearchResultIsSaved() {
    const history = useHistory();

    const onLastSearchResultSaved: OnClickCallback = (result) => {
        history.push(result.path);
    };

    return { onLastSearchResultSaved };
}
