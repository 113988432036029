import validate from 'validate.js';
import * as apiClient from '../../api/client/apiClient';
import type { AxiosError } from 'axios';
import { STATUS_CODE_BAD_REQUEST } from '../../constants/statusCode';
import { createCheckEmailInUseApiPath } from '../../routing/apiUrlGenerator';

const emailDoesNotExistFunction = (value: string): Promise<string | undefined | null> => {
    // @ts-ignore -> this somehow is not reflected in the Typescript types
    return new validate.Promise((resolve, reject) => {
        if (value.length === 0) {
            // let presence validator solve this
            resolve();

            return;
        }

        const url = createCheckEmailInUseApiPath(value);

        apiClient
            .get(url)
            .then((response) => {
                if (response.data.found === true && response.data.is_in_use_by_current_user !== true) {
                    resolve('^Het door u gekozen e-mailadres is al in gebruik');
                } else {
                    resolve();
                }
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    reject();

                    return;
                }

                const response: any = error.response;

                if (response.status === STATUS_CODE_BAD_REQUEST) {
                    // invalid email address, let other validator solve this

                    resolve();
                }

                reject(response);
            });
    });
};

export default emailDoesNotExistFunction;
