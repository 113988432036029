export default class Reference {
    _id: string;
    _externalId: number;
    _description: string;
    _archived: boolean;

    constructor(id: string, externalId: number, description: string, archived: boolean) {
        this._id = id;
        this._externalId = externalId;
        this._description = description;
        this._archived = archived;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): number {
        return this._externalId;
    }

    get title(): string {
        return this._description;
    }

    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }

    get archived(): boolean {
        return this._archived;
    }
}
