import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFetchBranchOverviewItemsAction } from '../actions/branchActionFactory';
import { GlobalState } from '../store/types';
import { BranchOverviewReducerState } from '../reducers/branchOverviewReducer';

export function useFetchBranchOverviewItems() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);

        // @ts-ignore
        dispatch(createFetchBranchOverviewItemsAction()).finally(() => setIsLoading(false));
    }, []);

    const branches = useSelector<GlobalState, BranchOverviewReducerState>(({ branches }) => branches);

    return { branches, isLoading };
}
