import Bookmark from '../Bookmark';
import BookmarkTag from '../BookmarkTag';
import { createUuid } from '../../utility/idGenerator';
import { createProjectBreadcrumbFromApiInput } from './ProjectBreadcrumbFactory';
import type { Action } from '../../actions/factory';
import BookmarkSimple from '../BookmarkSimple';

export function createBookmarkTagFromApiData(apiData: any): BookmarkTag {
    return new BookmarkTag(createUuid(), apiData.id, apiData.title);
}

export function createBookmarkTagFromAddBookmarkTagPendingAction(action: Action): BookmarkTag {
    return new BookmarkTag(action.meta.id, null, action.payload.title);
}

export function createBookmarkSimpleFromAddBookmarkFulfilledAction(action: Action): BookmarkSimple {
    // $ExpectError -> flow does not know about the payload data setup
    const bookmark: any = action.payload.data;

    return new BookmarkSimple(createUuid(), bookmark.id, bookmark.bookmarked_id);
}

export function createBookmarkFromAddBookmarkFulfilledAction(action: Action): Bookmark {
    // $ExpectError -> flow does not know about the payload data setup
    const bookmark: any = action.payload.data;

    return new Bookmark(
        createUuid(),
        bookmark.id,
        bookmark.bookmarked_id,
        bookmark.tags.map((tagApiData: any) => createBookmarkTagFromApiData(tagApiData)),
        bookmark.created_at,
        createProjectBreadcrumbFromApiInput(bookmark.breadcrumb)
    );
}

export function createBookmarkFromUserApiData(bookmarkApiData: any): BookmarkSimple {
    return new BookmarkSimple(createUuid(), bookmarkApiData.id, bookmarkApiData.bookmarked_id);
}

export function createBookmarkFromApiData(bookmarkApiData: any): Bookmark {
    return new Bookmark(
        createUuid(),
        bookmarkApiData.id,
        bookmarkApiData.bookmarked_id,
        bookmarkApiData.tags.map((tagApiData: any) => createBookmarkTagFromApiData(tagApiData)),
        bookmarkApiData.created_at,
        createProjectBreadcrumbFromApiInput(bookmarkApiData.breadcrumb)
    );
}
