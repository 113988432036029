import { FormErrorCollectionType } from '../../../../lib/forms/model/FormState';
import { useState } from 'react';
import { useFormik } from 'formik';
import { extractFormErrorsFromBackendValidationErrors, validateBranchInput } from '../validator/validationUtilities';

export type FormValues = {
    title: string;
    shortTitle: string;
};

const initialValues: FormValues = {
    title: '',
    shortTitle: '',
};

export type OnValidSubmitHandler = (values: FormValues) => Promise<FormErrorCollectionType | void>;

export default function useHandleCreateBranchFormState(onValidSubmit: OnValidSubmitHandler) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const formikValues = useFormik<FormValues>({
        initialValues: { ...initialValues },
        onSubmit: async (values, { setErrors }) => {
            setIsSubmitting(true);

            const backendValidationErrors = await onValidSubmit(values);

            if (backendValidationErrors) {
                setErrors(extractFormErrorsFromBackendValidationErrors(backendValidationErrors, values));

                setIsSubmitting(false);
            }
        },
        validate: validateBranchInput,
    });

    return { ...formikValues, isSubmitting };
}
