import ProjectSideProduct from '../../ProjectSideProduct';
import { createUuid } from '../../../utility/idGenerator';

export function createProjectSideProductFromApiInput(apiInput: any): ProjectSideProduct {
    return new ProjectSideProduct(
        apiInput.id,
        apiInput.title,
        apiInput.type,
        apiInput.toolType,
        apiInput.filename,
        apiInput.resourceUri,
        apiInput.resourceThumbnailUrl,
        apiInput.resourceDurationInSeconds,
        apiInput.path,
        apiInput.description,
        apiInput.highlightText,
        apiInput.visibleOnOverview,
        apiInput.breadcrumbs
    );
}

export function createProjectSideProductPendingActionPayload(payload: any): ProjectSideProduct {
    return new ProjectSideProduct(
        createUuid(),
        payload.title,
        payload.type,
        payload.toolType,
        null,
        payload.resourceUri,
        payload.resourceThumbnailUrl,
        payload.resourceDurationInSeconds,
        null,
        null,
        null,
        payload.visibleOnOverview,
        payload.breadcrumbs
    );
}
