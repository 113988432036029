import React from 'react';
import Button, { ButtonStyles } from '../../../../shared/buttons/Button';
import Loader from '../../../../shared/Loader';
import createClassName from 'classnames';

type Props = {
    isPending: boolean;
    isHighlighted?: boolean;
    trackingData?: string; // temporary prop for A/B test NewsletterSubscriptionPopup
};

const SubscribeSubmitButton: React.FC<Props> = ({ isPending, isHighlighted, trackingData }) => {
    const style = isPending ? ButtonStyles.link : ButtonStyles.primary;

    const buttonClassNames = createClassName('newsletter-form__button', {
        'newsletter-form__button--highlighted': isHighlighted,
    });

    return (
        <Button
            type="submit"
            style={style}
            additionalClassName={buttonClassNames}
            data-track-id={trackingData ?? 'newsletter-subscribe'}
            disabled={isPending}
        >
            {isPending ? <Loader sizeSmall /> : 'Aanmelden'}
        </Button>
    );
};

export default SubscribeSubmitButton;
