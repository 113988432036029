import React from 'react';
import type { PermissionRequirementCallback } from '../../../security/types';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import { STANDARD_VIEW_OVERVIEW_PUBLISHED } from '../../../security/SecurityAttribute';
import DashboardContent from '../shared/containers/DashboardContent';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import HeadingGroup from '../shared/headings/HeadingGroup';
import useFetchStandardAllOverviewItems from '../../../hooks/useFetchStandardAllOverviewItems';
import DashboardIsland from '../shared/island/DashboardIsland';
import List from '../shared/list/List';
import StandardOverviewItem from './components/StandardOverviewItem';
import DashboardIslandHeader from '../shared/island/DashboardIslandHeader';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import { standardTabTextItems } from '../../../translations/translations';
import { TenantType } from '../../../constants/settings';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../helper/seoHelper';

const StandardOverview = () => {
    const { archivedItems, items } = useFetchStandardAllOverviewItems();
    const { tenant } = useCurrentTenant();

    const title = tenant ? standardTabTextItems[tenant as TenantType].standards : '';
    const archivedTitle = tenant ? standardTabTextItems[tenant as TenantType].archivedStandards : '';
    const seoTitle = composeSeoTitle(tenant, title);

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{seoTitle}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading={true}>{title}</DashboardContentHeading>
                </HeadingGroup>
                {items && (
                    <DashboardIsland>
                        <DashboardIslandHeader heading={title} />
                        <List>
                            {items.map((item) => (
                                <StandardOverviewItem key={item.externalId} currentStandard={item} standards={items} />
                            ))}
                        </List>
                    </DashboardIsland>
                )}
                {archivedItems && (
                    <DashboardIsland>
                        <DashboardIslandHeader heading={archivedTitle} />
                        <List>
                            {archivedItems.map((archivedItem) => (
                                <StandardOverviewItem
                                    key={archivedItem.externalId}
                                    currentStandard={archivedItem}
                                    standards={archivedItems}
                                />
                            ))}
                        </List>
                    </DashboardIsland>
                )}
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(STANDARD_VIEW_OVERVIEW_PUBLISHED));
};

export default StandardOverview;
