import Modal from '../../../../shared/modal/Modal';
import React, { FC } from 'react';

type Props = {
    title: string;
    onDecline: () => void;
    children: React.ReactNode;
};

const VideoModal: FC<Props> = ({ title, onDecline, children }) => {
    return (
        <Modal title={title} onDecline={onDecline}>
            {children}
        </Modal>
    );
};

export default VideoModal;
