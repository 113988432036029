import {
    CASECARDS_SLUG,
    DECIDE_TOGETHER_SLUG,
    TEAM_TALKS_SLUG,
    TOOLKITS_SLUG,
    VIDEOS_SLUG,
    VISION_ON_CARE_PAGE_SLUG,
    WORKCARDS_SLUG,
    GUEST_LECTURES_SLUG,
} from '../constants/pageSlugs';
import { createPagePath, createSideProductsOverviewPath, generatePath } from './urlGenerator';

export function createVisionOnCarePagePath(): string {
    return createPagePath(VISION_ON_CARE_PAGE_SLUG);
}

export function createDecideTogetherPagePath(): string {
    return createPagePath(DECIDE_TOGETHER_SLUG);
}

export function createTeamTalksPagePath(): string {
    return createPagePath(TEAM_TALKS_SLUG);
}

export function createCasecardsSideProductsOverviewPagePath(): string {
    return createSideProductsOverviewPath(CASECARDS_SLUG);
}

export function createWorkcardsSideProductsOverviewPagePath(): string {
    return createSideProductsOverviewPath(WORKCARDS_SLUG);
}

export function createToolkitsSideProductsOverviewPagePath(): string {
    return createSideProductsOverviewPath(TOOLKITS_SLUG);
}

export function createVideosSideProductsOverviewPagePath(): string {
    return createSideProductsOverviewPath(VIDEOS_SLUG);
}

export function createSideProductsOverviewPagePath(): string {
    return '/hulpmiddelen/:category';
}

export function createForTeachersPagePath(): string {
    return generatePath('frontend_for_teachers');
}

export function createGuestLecturesPagePath(): string {
    return createPagePath(GUEST_LECTURES_SLUG);
}
