import React from 'react';
import classnames from './FormikCheckbox.module.scss';
import { Field } from 'formik';
import createClassname from 'classnames';

type Props = {
    name: string;
    id: string;
    label: string;
    additionalClassName?: string;
    isToggle?: boolean;
};

// @todo: create GGZ variant
const FormikCheckbox = ({ name, id, label, additionalClassName, isToggle }: Props) => {
    const wrapperClassNames = createClassname(
        classnames.wrapper,
        {
            [classnames.isToggle]: isToggle,
        },
        additionalClassName
    );

    const checkboxClassNames = createClassname(classnames.checkbox, {
        [classnames.isToggle]: isToggle,
    });

    const checkmarkClassNames = createClassname(classnames.checkmark, classnames.isPrimary, {
        [classnames.isToggle]: isToggle,
    });

    return (
        <label className={wrapperClassNames}>
            <Field type="checkbox" name={name} id={id} className={checkboxClassNames} />
            <span className={checkmarkClassNames} />
            {label}
        </label>
    );
};

export default FormikCheckbox;
