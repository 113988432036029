import { SecuritySubject } from '../SecuritySubjectType';
import PermissionRequirement from '../model/PermissionRequirement';

import type { SecurityVoteCollection } from '../types';
import { logDebug } from '../../utility/Logger';

export type SecurityAttributes = string | Array<string>;
export type SecuritySubjectId = string | undefined | null | number | undefined | null;

/*
 * Achtung! Keep this functionality in line with GGZ\Application\Security\Generator\SecurityEntryKeyGenerator as they both
 * should generate the same cache keys!
 */
export function generateSecurityEntryKey(
    attributes: SecurityAttributes,
    subjectType: SecuritySubject | null = null,
    subjectId: SecuritySubjectId = null
): string {
    const normalizedAttributes: Array<string> = Array.isArray(attributes) ? attributes : [attributes],
        attributesAsString: string = normalizedAttributes.sort().join('-'),
        subject: string = subjectType && subjectId ? `${subjectType}-${subjectId}` : 'all';

    return [attributesAsString, subject].join('::');
}

export function checkUserIsGranted(
    permissionRequirement: PermissionRequirement,
    securityVotes: SecurityVoteCollection
): boolean {
    const requiredPermissions = permissionRequirement.permissions;

    const votes = requiredPermissions.map((requiredPermission: string) => {
        return typeof securityVotes[requiredPermission] !== 'undefined' ? securityVotes[requiredPermission] : false;
    });

    let vote;

    if (permissionRequirement.requireAll()) {
        vote = !votes.some((value) => value === false);
    } else if (permissionRequirement.requireOne()) {
        vote = votes.some((value) => value === true);
    } else {
        throw new Error('requirement type not supported');
    }

    logDebug('security vote', permissionRequirement.permissions, vote);

    return vote;
}
