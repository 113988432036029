export function truncate(value: string, maxLength: number, suffix = '...') {
    const maxContentLength = maxLength - suffix.length;

    if (value.length < maxContentLength) {
        return value;
    }

    const words = value.split(' ');

    let truncatedValue = '';

    for (let i = 0, l = words.length; i < l; i++) {
        const word = words[i];

        if (`${truncatedValue} ${word}`.length <= maxContentLength) {
            truncatedValue = `${truncatedValue} ${word}`;
        } else {
            break;
        }
    }

    return truncatedValue.trim() + suffix;
}
