import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { GlobalState } from '../../../../../store/types';
import type { ProjectIndexReducerState } from '../../../../../reducers/projectIndexReducer';
import { createFetchProjectIndexAction } from '../../../../../actions/projectActionFactory';
import ProjectDetail from '../../../../../model/projectDetail/ProjectDetail';
import { useParams } from 'react-router-dom';

type RouteParams = {
    type: string;
    slug: string;
};

export default function useFetchProjectIndex(projectDetails: ProjectDetail | null) {
    const dispatch = useDispatch();

    const { type: currentTypeSlug, slug: currentSlug } = useParams<RouteParams>();

    // first check if the route params match the currently available project details
    // to make sure that the right project index is loaded.
    const externalProjectId =
        projectDetails && projectDetails.typeSlug === currentTypeSlug && projectDetails.slug === currentSlug
            ? projectDetails.externalId
            : null;

    useEffect(() => {
        if (externalProjectId) {
            dispatch(createFetchProjectIndexAction(externalProjectId));
        }
    }, [externalProjectId]);

    const projectIndex = useSelector<GlobalState, ProjectIndexReducerState>(
        (globalState: GlobalState) => globalState.projectIndex
    );

    if (!projectIndex) {
        return { projectIndex: null };
    }

    // index in store does not match the one we need to render for this route
    if (projectIndex.externalId !== externalProjectId) {
        return { projectIndex: null };
    }

    return { projectIndex };
}
