import * as React from 'react';
import { STANDARD_START_REVISION } from '../../../../security/SecurityAttribute';
import { SecuritySubject } from '../../../../security/SecuritySubjectType';
import SecurityThreshold from '../../../shared/security/SecurityThreshold';

type Props = {
    children: React.ReactNode;
    externalId: string;
};

const StartRevisionStandardThreshold = ({ children, externalId }: Props) => (
    <SecurityThreshold requiredAttributes={STANDARD_START_REVISION} subjectType={SecuritySubject.standard} subjectId={externalId}>
        {children}
    </SecurityThreshold>
);

export default StartRevisionStandardThreshold;
