import React from 'react';
import Page from '../../../shared/page/Page';
import PageToggleItem from '../../../shared/page/PageToggleItem';
import Divider from '../../../../shared/divider/Divider';

const AboutPage = () => {
    return (
        <Page title="Over ons">
            <p>
                Het platform Richtlijnen Langdurige Zorg is een initiatief van de Stichting KwaliteitsImpuls Langdurige
                Zorg (SKILZ). SKILZ ontwikkelt en beheert richtlijnen en handreikingen en kiest daarbij voor een
                multidisciplinaire aanpak. Door deze samenwerking vanuit verschillende vakgebieden en organisaties,
                ontstaan richtlijnen die breed toepasbaar zijn binnen de langdurige zorg.
            </p>
            <p>
                Richtlijnen Langdurige Zorg is een online platform waar zorgprofessionals en cliënten terecht kunnen om
                richtlijnen te vinden. Naast de richtlijnen zijn er ook producten te vinden voor de toepassing in de
                praktijk zoals samenvattingen en werkkaarten.
            </p>
            <p>
                Op het platform vind je ook de richtlijnen van het Samenwerkingsverband Richtlijnen Infectiepreventie
                (SRI) voor de langdurige zorg. Afgekort de SRI-Richtlijnen. Deze worden ook onder begeleiding van SKILZ
                opgesteld en herzien.
            </p>
            <p>
                Op dit platform zal vooral de term richtlijnen gebruikt worden, maar kunnen ook handreikingen bedoeld
                worden.
            </p>

            <PageToggleItem title="Waarom worden richtlijnen gebruikt?">
                <p>
                    Richtlijnen dragen bij aan de verbetering van de kwaliteit van zorg. Omdat de laatste
                    wetenschappelijke inzichten en ervaringen van zorgprofessionals en cliënten erin worden verwerkt,
                    bieden richtlijnen recente kennis en handvatten zodat zorgverleners de juiste zorg kunnen leveren.
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Hoe worden richtlijnen gemaakt?">
                <p>
                    Een richtlijn wordt ontwikkeld samen met zorgprofessionals, cliënten en hun naasten. Zorgverleners
                    vanuit beroepsverenigingen en vertegenwoordigers van cliënten- en patiëntenorganisaties nemen deel
                    aan een werkgroep die een richtlijn ontwikkelt. SKILZ begeleidt dit proces. Kwaliteit is belangrijk
                    bij de ontwikkeling en daarom heeft SKILZ een{' '}
                    <a href="https://www.skilz.nu/skilz-werkwijze" target="_blank" rel="noopener noreferrer">
                        eigen richtlijnmethode
                    </a>{' '}
                    ontwikkeld, gebaseerd op de AQUA-leidraad.
                </p>
                <p>
                    De richtlijnen moeten goed aan (blijven) sluiten bij de praktijk van goede zorgverlening. Daarom
                    werkt SKILZ onder andere samen met de beroepsverenigingen in de langdurige zorg, academische
                    werkplaatsen, cliëntenvertegenwoordigers, brancheverenigingen, Zorginstituut Nederland en Inspectie
                    Gezondheidszorg en Jeugd (IGJ).
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Voor wie zijn richtlijnen bedoeld?">
                <p>
                    De richtlijnen zijn bedoeld voor zorgprofessionals en cliënten in de langdurige zorg. Dit is zorg
                    aan mensen die thuis of in een zorginstelling wonen en die dagelijks, blijvende zorg nodig hebben.
                    Een richtlijn geeft zorgverleners inzichten en handvatten om de meest passende zorg te bieden op
                    basis van de laatste wetenschappelijke en ervaringskennis. Cliënten kunnen aan de hand van een
                    richtlijn informatie krijgen en nagaan of ze de juiste zorg krijgen.
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Wat is het verschil tussen een richtlijn en handreiking?">
                <p>
                    <strong>Richtlijn:</strong> Is een document met aanbevelingen voor de ondersteuning van
                    zorgverleners en cliënten gebaseerd op samenvattingen van wetenschappelijk onderzoek, klinische
                    expertise en ervaringen van cliënten.
                </p>
                <p>
                    <strong>Handreiking:</strong> Een handreiking is vergelijkbaar met een richtlijn, maar beschrijft
                    met name onderwerpen die niet zorginhoudelijk zijn en/of waar weinig tot geen wetenschappelijk
                    bewijs voor is. Een handreiking is meestal beperkter in omvang omdat het een specifiek (gedeelte van
                    het) probleem beschrijft.
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Hoe weet ik of een richtlijn geldend is?">
                <p>
                    Richtlijnen zijn zogenaamde ‘levende documenten’ en kunnen worden aangepast als dat nodig is. Dan is
                    er sprake van een herziening. Dit kan gebeuren als er bijvoorbeeld nieuwe ontwikkelingen zijn in
                    wetenschappelijk onderzoek, er nieuwe innovaties zijn of als er sprake is van goede ervaringen in de
                    praktijk. Bij elke richtlijn vind je op het tabblad ‘Status’ wanneer en door wie de richtlijn is
                    geautoriseerd en gepubliceerd op Richtlijnen Langdurige Zorg.
                </p>
                <p>Elke richtlijn beschrijft in welk jaar de richtlijn wordt beoordeeld voor herziening.</p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Hoe weet ik welke richtlijnen in ontwikkeling zijn?">
                <p>
                    Op de homepage Richtlijnen Langdurige Zorg staat aangegeven in welke ontwikkelingsfase de richtlijn
                    zich bevindt.
                </p>
                <p>
                    Op de site van SKILZ onder{' '}
                    <a href="https://www.skilz.nu/skilz-meerjarenagenda/" target="_blank" rel="noopener noreferrer">
                        meerjarenagenda
                    </a>{' '}
                    staat een overzicht van toekomstige thema's waar een richtlijn voor wordt ontwikkeld.
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Hoe kan ik een vraag stellen over richtlijn?">
                <p>
                    Heb je een vraag over een van de richtlijnen op{' '}
                    <a href="https://www.richtlijnenlangdurigezorg.nl" target="_blank" rel="noopener noreferrer">
                        www.richtlijnenlangdurigezorg.nl
                    </a>
                    , neem dan contact op met SKILZ via mailadres:{' '}
                    <a href="mailto:vraag@richtlijnenlangdurigezorg.nl" data-track-id="about_page_mail_to_click">
                        vraag@richtlijnenlangdurigezorg.nl
                    </a>
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Kan ik zelf ook ideeën voor een richtlijn aanleveren?">
                <p>
                    SKILZ hoort graag ideeën voor nieuwe thema's voor toekomstige richtlijnen. Heb je een idee? Je kunt
                    mailen naar:{' '}
                    <a href="mailto:vraag@richtlijnenlangdurigezorg.nl" data-track-id="about_page_mail_to_click">
                        vraag@richtlijnenlangdurigezorg.nl
                    </a>
                </p>
            </PageToggleItem>

            <Divider isSecondary muted />
            <PageToggleItem title="Wil je op de hoogte blijven van SKILZ en de ontwikkeling van richtlijnen?">
                <p>
                    Bekijk onze site{' '}
                    <a href="https://www.richtlijnenlangdurigezorg.nl" target="_blank" rel="noopener noreferrer">
                        www.skilz.nu
                    </a>
                    . Ook kun je je abonneren op onze{' '}
                    <a
                        href="https://mailchi.mp/08ed48e22670/meld-je-aan-voor-de-skilz-nieuwsbrief"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        nieuwsbrief
                    </a>
                    .
                </p>
            </PageToggleItem>
        </Page>
    );
};

export default AboutPage;
