import { SyntheticEvent } from 'react';
import { extractPath } from '../../../helper/objectPathHelper';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated
 */
export function checkIsEventThatWasInitiatedByButtonClick(event: SyntheticEvent): boolean {
    // @ts-ignore -> idea of this helper method is to check various event types
    if (typeof event.relatedTarget === 'undefined') {
        return false;
    }

    let tagName: string | undefined | null = extractPath('relatedTarget.tagName', event);

    if (!tagName) {
        return false;
    }

    // normalize tag name
    tagName = tagName.toLowerCase();

    if (tagName === 'button') {
        return true;
    }

    const targetType: string = extractPath('relatedTarget.type', event);

    // noinspection RedundantIfStatementJS
    if (tagName === 'input' && targetType.toLowerCase() === 'submit') {
        return true;
    }

    return false;
}
