import React, { Fragment, useEffect } from 'react';
import { Formik } from 'formik';
import FormGroup from '../../shared/form/FormGroup';
import Select from '../../shared/form/select/Select';
import Button, { PRIMARY, SUBMIT } from '../../shared/buttons/Button';
import useSubmitRecommendedStandardsFormValuesToServer from './hooks/useSubmitRecommendedStandardsFormValuesToServer';
import { StandardOverviewItem as StandardOverviewItemModel } from '../../../model/standardOverview/StandardOverviewItem.d';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/types';
import { ProjectDetailsReducerState } from '../../../reducers/projectDetailsReducer';
import { createFetchProjectDetailsBySlugAction } from '../../../actions/projectActionFactory';

export type OnValidSubmitCallback = (values: FormValues) => void;

type Props = {
    currentStandard: StandardOverviewItemModel;
    publishedStandardsOptions: any[];
    doHideModal: () => void;
};

type FormValues = {
    [key: string]: { value: string; label: string } | null;
};

const NUMBER_OF_RECOMMENDED_STANDARDS = 3;

const determineInitialValues = (
    currentStandard: StandardOverviewItemModel,
    projectDetails: ProjectDetailsReducerState
): FormValues => {
    const initialValues: FormValues = {};

    // Check if projectDetails exists and its slug matches the currentStandard's slug
    if (!projectDetails || currentStandard.slug !== projectDetails.slug) return initialValues;

    projectDetails.recommendedStandards.forEach((standardOverviewItem, index) => {
        return (initialValues[`item${index + 1}` as string] = {
            value: standardOverviewItem.id,
            label: standardOverviewItem.title,
        });
    });

    return initialValues;
};

const validateForm = (values: FormValues, standardOverviewItem: StandardOverviewItemModel) => {
    const selectedItems = [values.item1, values.item2, values.item3].filter(Boolean);

    // It is allowed to save the form if no items are selected, to clear the recommended standards
    if (selectedItems.length === 0) {
        return {};
    }

    // Check if there are duplicate selected items based on the 'value' property
    const selectedValues = selectedItems.map((item) => item && item.value);
    const uniqueValues = new Set(selectedValues);

    if (uniqueValues.size !== selectedItems.length) {
        return { validationError: 'Opslaan niet mogelijk. Selecteer 3 verschillende standaarden.' };
    }

    // Check if all the selects are filled
    if (selectedItems.length !== NUMBER_OF_RECOMMENDED_STANDARDS) {
        return { validationError: 'Opslaan niet mogelijk. Selecteer 3 standaarden of laat alle velden leeg.' };
    }

    // Check if any of the selected standards is the standard itself
    if (selectedItems.some((item) => item && item.value === standardOverviewItem.externalId)) {
        return { validationError: `Opslaan niet mogelijk. '${standardOverviewItem.title}' is de standaard zelf.` };
    }

    return {};
};

const RecommendedStandardsForm = ({ currentStandard, publishedStandardsOptions, doHideModal }: Props) => {
    const dispatch = useDispatch();
    const { onValidSubmit } = useSubmitRecommendedStandardsFormValuesToServer(currentStandard);

    useEffect(() => {
        if (!currentStandard) return;
        dispatch(createFetchProjectDetailsBySlugAction(currentStandard.typeSlug, currentStandard.slug));
    }, [currentStandard]);

    const projectDetails = useSelector<GlobalState, ProjectDetailsReducerState>(({ projectDetails }) => projectDetails);

    // Check if projectDetails exists and its slug matches the currentStandard's slug
    if (!projectDetails || currentStandard.slug !== projectDetails.slug) return null;

    const initialValues = determineInitialValues(currentStandard, projectDetails);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
                onValidSubmit(values);
                dispatch(createFetchProjectDetailsBySlugAction(currentStandard.typeSlug, currentStandard.slug));
                doHideModal();
                resetForm();
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(values) => validateForm(values, currentStandard)}
        >
            {({ handleSubmit, values, setFieldValue, errors }) => (
                <form onSubmit={handleSubmit} className="form">
                    <FormGroup>
                        {Array.from({ length: NUMBER_OF_RECOMMENDED_STANDARDS }, (_v, i) => (
                            <Fragment key={`${currentStandard.externalId}${i}`}>
                                <label>Aanbevolen standaard {i + 1}</label>
                                <Select
                                    id={`item${i + 1}`}
                                    name={`item${i + 1}`}
                                    onChange={(newValue: [{ value: string; label: string }]) => {
                                        const key = `item${i + 1}`;
                                        if (newValue === null) {
                                            setFieldValue(key, '');
                                        } else {
                                            setFieldValue(key, newValue);
                                        }
                                    }}
                                    value={values[`item${i + 1}`] || null}
                                    options={publishedStandardsOptions}
                                    isClearable
                                    enableReinitialize
                                />
                            </Fragment>
                        ))}
                    </FormGroup>

                    {errors.validationError && <div className="form__error-message">{errors.validationError}</div>}

                    <p>
                        Selecteer hierboven de drie standaarden die je wil aanbevelen bij de standaard. Het is niet
                        mogelijk om er minder of meer dan drie te tonen op de website. Wil je het aanbevolen element op
                        de website niet tonen? Laat dan de bovenstaande velden leeg.
                    </p>

                    <Button type={SUBMIT} style={PRIMARY}>
                        Opslaan
                    </Button>
                </form>
            )}
        </Formik>
    );
};

export default RecommendedStandardsForm;
