import React, { FC } from 'react';
import DashboardContentHolder from '../shared/containers/DashboardContentHolder';
import DashboardContent from '../shared/containers/DashboardContent';
import { Helmet } from 'react-helmet';
import { composeSeoTitle } from '../../../helper/seoHelper';
import HeadingGroup from '../shared/headings/HeadingGroup';
import DashboardContentHeading from '../shared/headings/DashboardContentHeading';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DashboardIsland from '../shared/island/DashboardIsland';
import { compose } from 'redux';
import requireAuthenticationStatus from '../../../hoc/RequireAuthenticatedStatus';
import requireAuthorizedPermissions from '../../../hoc/RequireAuthorizedPermissions';
import { PermissionRequirementCallback } from '../../../security/types';
import PermissionRequirement from '../../../security/model/PermissionRequirement';
import { generateSecurityEntryKey } from '../../../security/helpers/securityHelpers';
import { PAGE_CREATE, PAGE_DELETE, PAGE_OVERVIEW_UNPUBLISHED } from '../../../security/SecurityAttribute';
import useCurrentTenant from '../../../hooks/useCurrentTenant';
import List from '../shared/list/List';
import PageOverviewListItem from './components/PageOverviewListItem';
import SecurityThreshold from '../../shared/security/SecurityThreshold';
import {
    createDashboardEntryPath,
    createDashboardPageCreatePath,
    createDashboardPageOverviewPath,
    createDashboardPageUpdatePath,
} from '../../../routing/dashboardUrlGenerator';
import DeletePage from '../deletePage/DeletePage';
import useRefetchPagesOnFilterChange from '../../../hooks/useRefetchPagesOnFilterChange';
import { extractNumberFromQueryString } from '../../../resolver/queryStringParameterResolver';
import CollectionPagination, { OnPageChangeCallback } from '../../frontend/shared/CollectionPagination';
import { SecuritySubject } from '../../../security/SecuritySubjectType';
import { createPageDetailPath } from "../../../routing/urlGenerator";

const PageOverview: FC = () => {
    const { tenant } = useCurrentTenant();
    const history = useHistory();
    const location = useLocation();
    const page = extractNumberFromQueryString(location, 'page', 1);
    const { contentPages: pages, fetchCurrentPage } = useRefetchPagesOnFilterChange(
        { page, includeUnpublished: true },
        true,
        () => history.push(createDashboardEntryPath())
    );

    if (!pages) return null;

    const onPageChange: OnPageChangeCallback = (newPage) => {
        history.push(createDashboardPageOverviewPath(newPage));
    };

    return (
        <DashboardContentHolder>
            <DashboardContent>
                <Helmet>
                    <title>{composeSeoTitle(tenant, "Pagina's")}</title>
                </Helmet>
                <HeadingGroup>
                    <DashboardContentHeading isHeading>Pagina's</DashboardContentHeading>
                    <SecurityThreshold requiredAttributes={PAGE_CREATE}>
                        <Link
                            to={createDashboardPageCreatePath()}
                            className="button button--primary button--small icon-plus"
                        >
                            Pagina toevoegen
                        </Link>
                    </SecurityThreshold>
                </HeadingGroup>
                <DashboardIsland>
                    <List>
                        {pages.results.map((page) => (
                            <PageOverviewListItem key={page.id} page={page}>
                                <a
                                    href={createPageDetailPath(page.slug)}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="button button--cleared button--icon-only icon icon-search"
                                />
                                <Link
                                    to={createDashboardPageUpdatePath(page.id)}
                                    className="button button--cleared button--icon-only icon icon-pencil"
                                />
                                <SecurityThreshold
                                    requiredAttributes={PAGE_DELETE}
                                    subjectId={page.id}
                                    subjectType={SecuritySubject.page}
                                >
                                    <DeletePage page={page} onDone={fetchCurrentPage} />
                                </SecurityThreshold>
                            </PageOverviewListItem>
                        ))}
                    </List>
                    <CollectionPagination
                        collection={pages}
                        currentPage={pages.currentPage}
                        onPageChange={onPageChange}
                    />
                </DashboardIsland>
            </DashboardContent>
        </DashboardContentHolder>
    );
};

export const getRequiredPermissions: PermissionRequirementCallback = () => {
    return new PermissionRequirement(generateSecurityEntryKey(PAGE_OVERVIEW_UNPUBLISHED));
};

export default compose(requireAuthenticationStatus, requireAuthorizedPermissions(getRequiredPermissions))(PageOverview);
