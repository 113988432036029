import { useFormik } from 'formik';
import { useState } from 'react';
import { extractFormErrorsFromBackendValidationErrors, validateUserInput } from '../validator/validationUtilitites';
import { ROLE_USER } from '../../../../model/User';
import { FormErrorCollectionType } from '../../../../lib/forms/model/FormState';

export type FormValues = {
    firstName: string;
    preposition: string;
    lastName: string;
    profession: string;
    email: string;
    globalRole: string;
    personalMessage: string;
};

const initialValues: FormValues = {
    firstName: '',
    preposition: '',
    lastName: '',
    profession: '',
    email: '',
    globalRole: ROLE_USER,
    personalMessage: '',
};

export type OnValidSubmitHandler = (values: FormValues) => Promise<FormErrorCollectionType | void>;

export default function useHandleCreateUserFormState(onValidSubmit: OnValidSubmitHandler) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const formikValues = useFormik<FormValues>({
        initialValues: { ...initialValues },
        onSubmit: async (values, { setErrors }) => {
            setIsSubmitting(true);

            const backendValidationErrors = await onValidSubmit(values);

            if (backendValidationErrors) {
                setErrors(extractFormErrorsFromBackendValidationErrors(backendValidationErrors, values));

                setIsSubmitting(false);
            }
        },
        validate: validateUserInput,
    });

    return { ...formikValues, isSubmitting };
}
