import useShowHideModal from '../../../../../hooks/useShowHideModal';
import { getValue as getCookieValue } from '../utility/newsletterCookieStorage';
import useDisplayModalWithADelay from './useDisplayModalWithADelay';

export default function useHandleNewsletterModalVisibility(enabled: boolean) {
    const shouldBeDisplayed = getCookieValue() === undefined;

    const { isReadyToDisplay } = useDisplayModalWithADelay();

    const { showModal, doHideModal } = useShowHideModal(shouldBeDisplayed);

    const visible = !enabled || (shouldBeDisplayed && showModal && isReadyToDisplay);

    return {
        visible,
        hideModal: doHideModal,
    };
}
