import type { Cloneable } from '../contracts';

export default class ProjectDetailParagraph implements Cloneable<ProjectDetailParagraph> {
    _id: string;
    _externalId: string | undefined | null;
    _title: string;
    _index: number;
    _inChapters: Array<string>;
    _commentCount: number;
    _answeredCommentCount: number;

    constructor(
        id: string,
        externalId: string | undefined | null,
        title: string,
        index: number,
        inChapters: Array<string>,
        commentCount: number,
        answeredCommentCount: number
    ) {
        this._id = id;
        this._externalId = externalId;
        this._title = title;
        this._index = index;
        this._inChapters = inChapters;
        this._commentCount = commentCount;
        this._answeredCommentCount = answeredCommentCount;
    }

    is(otherParagraph: ProjectDetailParagraph): boolean {
        return this._id === otherParagraph.id;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get externalId(): string | undefined | null {
        return this._externalId;
    }

    get title(): string {
        return this._title;
    }

    get index(): number {
        return this._index;
    }

    get inChapters(): Array<string> {
        return this._inChapters;
    }

    isBeingReused(): boolean {
        return this._inChapters.length > 1;
    }

    get commentCount(): number {
        return this._commentCount;
    }

    incrementCommentCount(): void {
        this._commentCount++;
    }

    get answeredCommentCount(): number {
        return this._answeredCommentCount;
    }

    incrementAnsweredCommentCount(): void {
        this._answeredCommentCount++;
    }

    clone(): ProjectDetailParagraph {
        return new ProjectDetailParagraph(
            this._id,
            this._externalId,
            this._title,
            this._index,
            this._inChapters,
            this._commentCount,
            this._answeredCommentCount
        );
    }
}
