import queryString from 'qs';
import type { Location } from 'history';

export const extractNumberFromQueryString = (location: Location, requiredKey: string, defaultValue: number): number => {
    const params = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const requiredValue = params[requiredKey];

    if (requiredValue === undefined || typeof requiredValue !== 'string') {
        return defaultValue;
    }

    return parseInt(requiredValue);
};
