import { FormikErrors, useFormik } from 'formik';
import { OnFormValidHandler } from '../ProjectForm';
import { useState } from 'react';
import { TenantType } from '../../../../constants/settings';

export type FormValues = {
    title: string;
    shortTitle: string;
    type: string;
    tags: string[];
    templateId: string;
};

export default function useHandleProjectFormEvents(
    onSubmit: OnFormValidHandler,
    onDone: () => void,
    initialValues?: FormValues,
    tenant?: TenantType | null
) {
    const [submitPending, setSubmitPending] = useState<boolean>(false);

    // GGZ currently does not have the option to choose a template, hence default should be selected as initial value
    const initialTemplateIdentifier = tenant === TenantType.GGZ ? 'default' : '';

    const isUpdate = !!initialValues;

    const { handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormik<FormValues>({
        initialValues: initialValues || {
            title: '',
            shortTitle: '',
            type: '',
            tags: [],
            templateId: initialTemplateIdentifier,
        },

        onSubmit: async (values, { resetForm }) => {
            setSubmitPending(true);

            const success = await onSubmit(values);

            if (success) {
                resetForm();

                setTimeout(() => {
                    // prevents situation where the form is unmounted while still in
                    // the on submit callback, causing a React memory leak warning

                    onDone();
                });
            }

            setSubmitPending(false);
        },

        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (!values.title) {
                errors.title = 'Dit veld is verplicht';
            }

            if (!values.type) {
                errors.type = 'Dit veld is verplicht';
            }

            if (!isUpdate && !values.templateId) {
                errors.templateId = 'Dit veld is verplicht';
            }

            return errors;
        },
    });

    return {
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        errors,
        submitPending,
    };
}
